// Skew product of vectors determinate by two vectors A and B
// A = (x1, y1), B = (x2, y2), skew product [a, b] = x1y2 — x2y1
// A coords: a1 - start, a2 - end
// B coords: b1 - start, b2 - end
function skewProduct (a1, a2, b1, b2) {
  const x1 = a2.x - a1.x
  const y1 = a2.y - a1.y
  const x2 = b2.x - b1.x
  const y2 = b2.y - b1.y

  return x1 * y2 - x2 * y1
}

// check position point with line
// https://habr.com/ru/articles/148325/ task 1
function checkPointPosLine (p1, p2, m) {
  return Math.sign(skewProduct(p1, p2, p1, m))
}

// check point inside Convex polygon, for example - rectangle
function checkPointInsideConvexPolygon (p, polygon) { // {x,y}, [{x,y}]
  return polygon.reduce((res, el, idx) => res && checkPointPosLine(el, idx !== polygon.length - 1 ? polygon[idx + 1] : polygon[0], p) === -1, 1)
}

// intersection two line/segment determinate with two points a1-a2 and b1-b2 {x,y} in plane
// type = 'line' for line, type = 'segment or undefine - for segment
// https://habr.com/ru/post/148325/
function findX2Line (a1, a2, b1, b2, type) {
  // quickcheck by skewProduct for segments
  if (
    (type === 'segment' || !type) &&
    (skewProduct(a1, a2, a1, b1) * skewProduct(a1, a2, a1, b2) > 0)
  ) return false

  const x1 = a1.x
  const y1 = a1.y
  const x2 = a2.x
  const y2 = a2.y
  const x3 = b1.x
  const y3 = b1.y
  const x4 = b2.x
  const y4 = b2.y

  // Check if none of the lines are of length 0
  if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) return false
  const denominator = ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1))

  // Lines are parallel
  if (denominator === 0) return false

  const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator
  const ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator

  // is the intersection along the segments
  if ((type === 'segment' || !type) && (ua < 0 || ua > 1 || ub < 0 || ub > 1)) return false

  // Return a object with the x and y coordinates of the intersection
  return {
    x: x1 + ua * (x2 - x1),
    y: y1 + ua * (y2 - y1)
  }
}

// quick check intersection 2 segment - task 6 https://habr.com/ru/articles/148325/
function checkX2Segment (seg1, seg2) { // seg = [{x,y}, {x,y}]
  const p1 = seg1[0]
  const p2 = seg1[1]
  const m1 = seg2[0]
  const m2 = seg2[1]
  return (skewProduct(p1, p2, p1, m2) * skewProduct(p1, p2, p1, m1) < 0 &&
  skewProduct(m1, m2, m1, p1) * skewProduct(m1, m2, m1, p2) < 0)
    ? 1
    : 0
}

// quick check 2 points and line - task 4 https://habr.com/ru/articles/148325/
function check2PointsLine (p1, p2, m1, m2) { // line= p1-p2, point m1, m2
  return Math.sign(skewProduct(p1, p2, p1, m1) * skewProduct(p1, p2, p1, m2)) // -1 - different sides
}

// intersection line determinate with two points p and q {x,y,z} with plane Ax+By+Cz+D=0 {A, B, C, D}
function findXLinePlane (p, q, plane) {
  const tDenom = plane.A * (q.x - p.x) + plane.B * (q.y - p.y) + plane.C * (q.z - p.z)
  if (tDenom === 0) return null

  const t = -(plane.A * p.x + plane.B * p.y + plane.C * p.z + plane.D) / tDenom

  return {
    x: (p.x + t * (q.x - p.x)),
    y: (p.y + t * (q.y - p.y)),
    z: (p.z + t * (q.z - p.z))
  }
}

// rotate2D
function rotate2D (p, angle, clockwise) { // angle in radian
  const cosA = Math.cos(angle)
  const sinA = !clockwise ? Math.sin(angle) : -Math.sin(angle)
  const M = [cosA, -sinA, sinA, cosA]
  return {
    x: p.x * M[0] + p.y * M[1],
    y: p.x * M[2] + p.y * M[3]
  }
}

// rotate3D
function rotate3D (p, axis, angle, clockwise) { // angle in radian
  const cosA = Math.cos(angle)
  const sinA = !clockwise ? Math.sin(angle) : -Math.sin(angle)
  const M =
    (axis === 'X')
      ? [1, 0, 0, 0, cosA, -sinA, 0, sinA, cosA]
      : (axis === 'Y')
        ? [cosA, 0, sinA, 0, 1, 0, -sinA, 0, cosA]
        : (axis === 'Z')
          ? [cosA, -sinA, 0, sinA, cosA, 0, 0, 0, 1]
          : console.log('Alert! Axis undefine')

  return {
    x: p.x * M[0] + p.y * M[1] + p.z * M[2],
    y: p.x * M[3] + p.y * M[4] + p.z * M[5],
    z: p.x * M[6] + p.y * M[7] + p.z * M[8]
  }
}

// move3D
function move3D (p, v) { // v = {x, y, z}
  return {
    x: p.x + v.x,
    y: p.y + v.y,
    z: p.z + v.z
  }
}

// distance between 2 point 2D/3D
function dist2points (p, q) { // p, q = {x, y} or {x, y, z}
  return Math.sqrt((p.x - q.x) ** 2 + (p.y - q.y) ** 2 + (p.z && q.z ? (p.z - q.z) ** 2 : 0))
}

// distance from point to line - task 7 https://habr.com/ru/articles/148325/
function calcDistPointToLine (p1, p2, m) { // p1 = {x,y}, p2 = {x,y}, m = {x,y}
  return Math.abs(skewProduct(p1, p2, p1, m) / dist2points(p1, p2))
}

// get rect from diag and angles
function calcRectFromDiag (p, q, angle) { // p, q = {x, y} or {x, y, z} and angle in radian
  const cPoint = { x: (p.x + q.x) / 2, y: (p.y + q.y) / 2 }
  let p1 = { x: p.x - cPoint.x, y: p.y - cPoint.y }
  let p2 = { x: q.x - cPoint.x, y: q.y - cPoint.y }
  p1 = rotate2D(p1, angle, true)
  p2 = rotate2D(p2, angle, true)
  p1 = { x: -p1.x, y: p1.y }
  p2 = { x: -p2.x, y: p2.y }
  p1 = rotate2D(p1, angle, false)
  p2 = rotate2D(p2, angle, false)
  p1 = { x: p1.x + cPoint.x, y: p1.y + cPoint.y }
  p2 = { x: p2.x + cPoint.x, y: p2.y + cPoint.y }
  return [p2, p, p1, q]
}

// get rect from width and height
function calcRectFromOutImg (outImg, img) { // outImg = {left, top, angle/degree, scaleX, scaleY}, img={width, height}
  const scaleX = outImg.scaleX
  const scaleY = outImg.scaleY
  const rectPoints = [{ x: -1, y: -1 }, { x: -1, y: 1 }, { x: 1, y: 1 }, { x: 1, y: -1 }]
    .map(el => ({ x: el.x * img.width / 2 * scaleX, y: el.y * img.height / 2 * scaleY }))
    .map(el => ({ x: el.x + img.left * scaleX, y: el.y + img.top * scaleY }))
    .map(el => rotate2D(el, outImg.angle * Math.PI / 180, false))
    .map(el => ({ x: el.x + outImg.left, y: el.y + outImg.top }))
  return rectPoints
}

// get width and height from rect diagonal and angle - !!! in canvas px
function calcRectWHFromDiag (tl, br, angle) { // tl = {x,y}, br={x,y}, angle in radian
  const cPoint = { x: (tl.x + br.x) / 2, y: (tl.y + br.y) / 2 }
  let p1 = { x: tl.x - cPoint.x, y: tl.y - cPoint.y }
  let p2 = { x: br.x - cPoint.x, y: br.y - cPoint.y }
  p1 = rotate2D(p1, angle, true)
  p2 = rotate2D(p2, angle, true)
  return {
    width: Math.abs(p1.x - p2.x),
    height: Math.abs(p1.y - p2.y)
  }
}

function getPerpendicular (point1, point2) { // to point 1
  return [
    point1,
    {
      x: point1.x - (point2.y - point1.y),
      y: point1.y + (point2.x - point1.x)
    }
  ]
}

function getSymPointByLine (p1, p2, pT) { // https://www.cyberforum.ru/geometry/thread2598878-page2.html, post jogano
  const R = (p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2
  return {
    x: (pT.x * (p2.x - p1.x) ** 2 +
      2 * (pT.y - p1.y) * (p2.x - p1.x) * (p2.y - p1.y) +
      (2 * p1.x - pT.x) * (p2.y - p1.y) ** 2) / R,
    y: ((2 * p1.y - pT.y) * (p2.x - p1.x) ** 2 +
      2 * (pT.x - p1.x) * (p2.x - p1.x) * (p2.y - p1.y) +
      pT.y * (p2.y - p1.y) ** 2) / R
  }
}

export {
  skewProduct,
  findXLinePlane,
  findX2Line,
  rotate2D,
  rotate3D,
  move3D,
  dist2points,
  checkX2Segment,
  calcRectFromDiag,
  calcRectFromOutImg,
  calcDistPointToLine,
  checkPointPosLine,
  calcRectWHFromDiag,
  check2PointsLine,
  getPerpendicular,
  getSymPointByLine
}
