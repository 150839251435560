function getPointDensity (point, cam, canvasData) {
  const MC = {}
  const f = cam.focal
  const k = cam.resX / cam.sizeX * f
  if (cam.typeInst) {
    MC.level = cam.hCam - (cam.hMaxAim + cam.hMinAim) / 2
    MC.gamma = cam.gamma === 'auto' ? Math.atan((cam.hCam - cam.hMaxAim) / cam.DH) + cam.beta / 2 : cam.gamma
  }

  if (cam.distortion) {
    MC.k1 = cam.distortion.k1
    MC.k2 = cam.distortion.k2
    if (MC.k1 < 0 && MC.k2 > 0 && 9 / 20 * MC.k1 ** 2 < MC.k2) MC.k2 = 0 // if PV exist - use one parametric model
  }

  const coords = getCoordsByZone(point)
  const density = !cam.distortion
    ? cam.typeInst
      ? k / (coords.x * Math.cos(MC.gamma) + MC.level * Math.sin(MC.gamma))
      : k / coords.x
    : cam.typeInst
      ? getAvdDistDensity()
      : k / coords.x * (1 + 3 * MC.k1 * (f * coords.y / coords.x) ** 2 + 5 * MC.k2 * (f * coords.y / coords.x) ** 4)
  return density > 0 ? density.toFixed(0) : 0

  function getAvdDistDensity () { // {x, y}
    const newX = coords.x * Math.cos(MC.gamma) + MC.level * Math.sin(MC.gamma)
    const a = coords.x * Math.sin(MC.gamma) - MC.level * Math.cos(MC.gamma)
    const newY = Math.sqrt(coords.y ** 2 + a ** 2)
    return k / newX * (1 + 3 * MC.k1 * (f * newY / newX) ** 2 + 5 * MC.k2 * (f * newY / newX) ** 4)
  }

  function getCoordsByZone (point) { // {x, y}
    const theta = Math.atan((cam.position.dir.y - cam.position.cam.y) / (cam.position.dir.x - cam.position.cam.x)) +
    ((cam.position.dir.x < cam.position.cam.x) ? Math.PI : 0) // angle to axis OX, radian
    const sinT = Math.sin(theta)
    const cosT = Math.cos(theta)
    const x = point.x - cam.position.cam.x
    const y = point.y - cam.position.cam.y
    return {
      x: (x * cosT + y * sinT) / canvasData.zoom / 1000,
      y: (x * sinT - y * cosT) / canvasData.zoom / 1000
    }
  }
}

export { getPointDensity }
