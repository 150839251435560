<template>
  <div id = "editor-wrapper" class = "d-flex overflow-hidden flex-column">
    <v-overlay :value = "progressBar" style = "z-index: 10000">
      <div class = "d-flex justify-center flex-column align-center">
        <v-progress-circular
          indeterminate
          color = "primary"
          size = "64"
          class = "align-self-center"
        />
        <div class = "blink mt-3 text-h6 warning--text" v-if = "progressText">{{ $t(progressText) }}</div>
      </div>
    </v-overlay>
    <editor-menu
      id = "editor-menu"
      class = "elevation-1 mb-0"
      style = "z-index: 30"
      :class = "IS_AUTH || IS_SHARED.access === 'edit' ? 'd-block' : 'd-none'"
    />
    <div id = "wrapper-bottom-editor" class = "d-flex align-center">
      <editor-left-menu id = "editor-left-menu" class = "elevation-2"/>
      <v-navigation-drawer
        v-model = "drawer"
        app
        absolute
        right
        class = "elevation-2 drawer-panel"
        stateless
        :width = "350"
        >
        <template v-slot:prepend>
          <v-toolbar flat dense  :class = "shadow ? 'shadow' : ''" >
            <v-toolbar-title class = "text-body-1 font-weight-medium">
              <span v-if="obj.typeOf !== 'camera'">{{ $t('properties') }}</span>
              <span v-else>{{ $t('camera properties') }}</span>
            </v-toolbar-title>
            <v-spacer/>
            <v-btn
              @click = "closeProperties()"
              icon
              >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template>
          <editor-properties/>
        </template>
      </v-navigation-drawer>
      <editor-legend v-if = "$store.state.editorStore.legend"/>
      <editor-canvas id = "canvas-wrapper"/>
    </div>
    <v-snackbar
      v-model = "saveShareProject"
      timeout = "3000"
      multi-line
      text
      :color = "saveShareProjectStatus ? 'info' : 'error'"
      elevation = "4"
      >
      <div class = "d-flex justify-center align-center">
        {{ saveShareProjectStatus ? this.$t('project_shared_save_success') : this.$t('project_shared_save_fail') }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import EditorMenu from '~/components/Editor-components/EditorMenu'
import EditorLeftMenu from '~/components/Editor-components/EditorLeftMenu'
import EditorCanvas from '~/components/Editor-components/EditorCanvas'
import EditorProperties from '~/components/Editor-components/EditorProperties'
import EditorLegend from '~/components/Editor-components/EditorLegend'
import { eventBus } from '~/main'
import { mapState } from 'vuex'

export default {

  name: 'Editor',

  components: {
    EditorMenu,
    EditorCanvas,
    EditorLeftMenu,
    EditorProperties,
    EditorLegend
  },

  data: () => ({
    drawer: false,
    canvasNotReady: true,
    reportGettingViews: false,
    shadow: false,
    progressText: false,
    saveShareProject: false,
    saveShareProjectStatus: ''
  }),

  computed: {
    ...mapState({
      selObj: state => state.objectsStore.selObj,
      IS_AUTH: state => state.auth.isAuth,
      IS_SHARED: state => state.auth.isShared
    }),

    obj () {
      return this.selObj ? this.selObj : false
    },

    progressBar () {
      return this.canvasNotReady || this.reportGettingViews
    }
  },

  methods: {
    closeProperties () {
      eventBus.$emit('hide-properties')
    },

    setDrawerHeaderShadow (e) {
      this.shadow = e.target.scrollTop < 24 ? 0 : 1
    }
  },

  watch: {
    reportGettingViews (value) {
      this.progressText = value ? 'report_warning_progress_bar' : false
    }
  },

  mounted () {
    eventBus.$on('show-properties', _ => { this.drawer = true })
    eventBus.$on('hide-properties', _ => { this.drawer = false })
    eventBus.$on('canvas-ready', _ => { this.canvasNotReady = false })
    eventBus.$on('canvas-not-ready', _ => { this.canvasNotReady = true })
    eventBus.$on('need-report-views', _ => { this.reportGettingViews = true })
    eventBus.$on('report-ready', _ => { this.reportGettingViews = false })
    eventBus.$on('shared-project-save', evt => {
      this.saveShareProject = true
      this.saveShareProjectStatus = evt
    })
    this.$el.querySelector('.v-navigation-drawer__content').addEventListener('scroll', this.setDrawerHeaderShadow)
  },

  beforeDestroy () {
    eventBus.$off([
      'show-properties', 'hide-properties',
      'canvas-ready', 'canvas-not-ready',
      'need-report-views', 'report-ready',
      'shared-project-save'
    ])
    this.$el.querySelector('.v-navigation-drawer__content').removeEventListener('scroll', this.setDrawerHeaderShadow)
  }

}

</script>

<style scoped>
  #editor-wrapper {
    width: 100vw;
  }

  #wrapper-bottom-editor {
    position: relative;
    height: 100%;
  }

  #editor-left-menu {
    position: absolute;
    z-index: 10;
    left: 12px;
    border-radius: 18px;
    background-color: white;
  }

  #canvas-wrapper {
    height: 100%;
    width: 100%;
  }

  .drawer-panel {
    height: auto !important;
    margin-top: 3px;
    z-index: 30
  }

  .shadow {
    box-shadow: 0px 6px 5px 0px rgba(238, 238, 238, 0.5) !important;
  }

  .blink {
    animation: blinker 2s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
</style>
