function positioningFoV (position, zones, zoom) {
  const theta = Math.atan((position.dir.y - position.cam.y) / (position.dir.x - position.cam.x)) +
  ((position.dir.x < position.cam.x) ? Math.PI : 0) // angle to axis OX, radian
  const sinT = Math.sin(theta)
  const cosT = Math.cos(theta)

  return {
    fov: {
      angle: zones.fov.angle,
      radius: zones.fov.radius * zoom * 1000,
      radius2: zones.fov.radius2 ? zones.fov.radius2 * zoom * 1000 : false,
      lines: zones.fov.lines.map(setLineCanvasCoords),
      lines2: zones.fov.lines2 ? zones.fov.lines2.map(setLineCanvasCoords) : false
    },
    zonesPoints: zones.zonesPoints.map(setPointsCanvasCoords)
  }

  function setLineCanvasCoords (line) { // [x1, y1, x2, y2] move and rotate on theta in canvas coords
    return [
      position.cam.x + (line[0] * cosT - line[1] * sinT) * zoom * 1000, // x1
      position.cam.y + (line[0] * sinT + line[1] * cosT) * zoom * 1000, // y1
      position.cam.x + (line[2] * cosT - line[3] * sinT) * zoom * 1000, // x2
      position.cam.y + (line[2] * sinT + line[3] * cosT) * zoom * 1000 // y2
    ]
  }

  function setPointsCanvasCoords (points) { // [{x, y}] move and rotate on theta in canvas coords
    if (!points) return { x: position.cam.x, y: position.cam.y }
    return points.map(e => ({
      x: position.cam.x + (e.x * cosT - e.y * sinT) * zoom * 1000,
      y: position.cam.y + (e.x * sinT + e.y * cosT) * zoom * 1000
    }))
  }
}

export { positioningFoV }
