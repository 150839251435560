<template>
  <v-container
    fluid
    class="d-flex justify-center align-center"
    >
    <v-alert
      width = "500"
      type = "error"
      prominent
      >
      <p>{{$t('shared_block')}}</p>
      <a @click = "$router.push({ name: 'login' })" class="white--text">{{$t('shared_block_url')}} >></a>
    </v-alert>
  </v-container>
</template>

<script>
export default {
  name: 'SharedBlock',

  components: {
  },

  data: () => ({
  }),

  methods: {
  }
}
</script>

<style scoped>
</style>
