<template>
  <div id = "storage-config" class = "py-2" scrollable>
    <v-expansion-panels flat>
      <v-expansion-panel class = "grayscale">
        <v-expansion-panel-header>
          <span>{{$t('storage common settings')}}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :headers="settingsHeaders"
            :items = "[COMMON_CONFIG_SETTINGS]"
            class="elevation-0 py-1 grayscale"
            hide-default-footer
            dense
            >
            <template v-for = "header in headers" #[headerSlotName(header.value)]>
              <div :key = "header.text" align = "center"> {{$t(header.text)}} </div>
            </template>

            <template #item.RAID = "{ item }">
              <div class="d-flex justify-center">
                <v-select
                  :value = "item.RAID"
                  @change = "updateCommonConfigSettings({ RAID: $event })"
                  :items = "RAIDTypes"
                  item-text = "name"
                  item-value = "name"
                  :hint = "$t(RAIDTypes.find(el => el.name === item.RAID).descr)"
                  hide-details = "auto"
                  flat
                  dense
                  class="middle-input shrink ma-0 pa-0"
                />
              </div>
            </template>

            <template #item.capacityHDD = "{ item }">
              <div class="d-flex justify-center">
                <v-select
                  :value = "item.capacityHDD"
                  @change = "updateCommonConfigSettings({ capacityHDD: $event })"
                  :items = "capacitiesHDD"
                  flat
                  dense
                  hide-details
                  class = "middle-input shrink ma-0 pa-0"
                />
              </div>
            </template>

            <template #item.maxGroup = "{ item }">
              <div class="d-flex justify-center">
                <v-text-field
                  :value = "item.maxGroup"
                  @input = "setMaxGroupCommonConfigSettings(item, $event)"
                  hide-details = "auto"
                  flat
                  dense
                  class = "middle-input shrink ma-0 pa-0"
                  type = "number"
                  :rules = "[rules.maxGroup(item)]"
                />
              </div>
            </template>

            <template #item.hotSpare = "{ item }">
              <div class="d-flex justify-center">
                <v-select
                  :value="item.hotSpare"
                  @change="updateCommonConfigSettings({ hotSpare: $event })"
                  :items="hotSpare"
                  flat
                  dense
                  hide-details
                  class="middle-input shrink ma-0 pa-0"
                />
              </div>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-data-table
      :headers="headers"
      :items="STORAGE_CONFIGS"
      class="elevation-0 mt-2"
      :items-per-page="-1"
      :height="STORAGE_CONFIGS.length < 8 ? 'auto' : 410"
      fixed-header
      hide-default-footer
      >
      <template v-for="header in headers" #[headerSlotName(header.value)]>
        <div :key="header.text" align="center"> {{$t(header.text)}} </div>
      </template>

      <template #item.group="{ item }">
        <span class = "text-body-1">{{item.group}}</span>
      </template>

      <template #item.RAID = "{ item }">
        <div class="d-flex justify-center">
          <v-select
            :value = "item.RAID"
            @change = "updateItem({ group: item.group, RAID: $event })"
            :items = "RAIDTypes"
            item-text = "name"
            item-value = "name"
            flat
            dense
            hide-details
            class="middle-input shrink ma-0 pa-0"
          />
        </div>
      </template>

      <template #item.capacityHDD = "{ item }">
        <div class="d-flex justify-center">
          <v-select
            :value = "item.capacityHDD"
            @change = "updateItem({ group: item.group, capacityHDD: $event })"
            :items = "capacitiesHDD"
            flat
            dense
            hide-details
            class = "middle-input shrink ma-0 pa-0"
          />
        </div>
      </template>

      <template #item.maxGroup = "{ item }">
        <div class="d-flex justify-center">
          <v-text-field
            :value = "item.maxGroup"
            @input = "setMaxGroup(item, $event)"
            hide-details = "auto"
            flat
            dense
            class = "middle-input shrink ma-0 pa-0"
            type = "number"
            :rules = "[rules.maxGroup(item)]"
          />
        </div>
      </template>

      <template #item.hotSpare = "{ item }">
        <div class="d-flex justify-center">
          <v-select
            :value="item.hotSpare"
            @change="updateItem({ group: item.group, hotSpare: $event })"
            :items="hotSpare"
            flat
            dense
            hide-details
            class="middle-input shrink ma-0 pa-0"
          />
        </div>
      </template>

      <template slot="no-data">
        {{$t('table no data available')}}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {

  name: 'StorageConfig',

  components: {
  },

  data: () => ({
    headers: [
      { text: 'storage camera group', align: 'center', sortable: true, value: 'group', cellClass: 'column20' },
      { text: 'storage redundancy', align: 'center', sortable: false, value: 'RAID', cellClass: 'column20' },
      { text: 'storage disk capacity', align: 'center', sortable: false, value: 'capacityHDD', cellClass: 'column20' },
      { text: 'storage max disk group', align: 'center', sortable: false, value: 'maxGroup', cellClass: 'column20' },
      { text: 'storage hotspare', align: 'center', sortable: false, value: 'hotSpare', cellClass: 'column20' }
    ],
    settingsHeaders: [
      { text: 'storage redundancy', align: 'center', sortable: false, value: 'RAID', width: '25%' },
      { text: 'storage disk capacity', align: 'center', sortable: false, value: 'capacityHDD', width: '25%' },
      { text: 'storage max disk group', align: 'center', sortable: false, value: 'maxGroup', width: '25%' },
      { text: 'storage hotspare', align: 'center', sortable: false, value: 'hotSpare', width: '25%' }
    ],
    RAIDTypes: [
      { name: 'JBOD', descr: 'non-raid' },
      { name: 'RAID0', descr: 'stripe' },
      { name: 'RAID1', descr: 'mirror' },
      { name: 'RAID5', descr: 'striping with parity' },
      { name: 'RAID6', descr: 'striping with dual parity' },
      { name: 'RAID10', descr: 'striping and mirroring' }
    ],
    capacitiesHDD: [1, 2, 3, 4, 6, 7, 8, 10, 12, 14, 16, 18, 20],
    hotSpare: [0, 1, 2]
  }),

  computed: {
    ...mapGetters([
      'CAMERAS'
    ]),

    ...mapState({
      STORAGE_CONFIGS: state => state.storageStore.storageConfigs,
      COMMON_CONFIG_SETTINGS: state => state.storageStore.commonConfigSettings,
      STREAMS: state => state.storageStore.streams,
      ARCHIVE_DAYS: state => state.storageStore.archiveDays
    }),

    rules () {
      const self = this
      return {
        maxGroup (item) {
          const minHDD = self.calcRAID(item.RAID, 1)
          return value => (Number.isInteger(+value) && (+value >= minHDD) && (+value <= 18) &&
          ((item.RAID === 'RAID1' || item.RAID === 'RAID10') && !(+value % 2))) ||
          `${(item.RAID === 'RAID1' || item.RAID === 'RAID10') && (+value >= minHDD) && (+value <= 18)
              ? self.$t('storage must be even')
              : self.$t('storage maxgroup out of range') + ' ' + minHDD + '-18'
          }`
        }
      }
    }
  },

  methods: {
    headerSlotName (value) {
      return 'header.' + value
    },

    updateItem (data) {
      this.$store.commit('UPDATE_STORAGE_CONFIG', data)
    },

    setMaxGroup (item, value) {
      (typeof this.rules.maxGroup(item)(value) === 'boolean') && this.rules.maxGroup(item)(value) &&
        this.$store.commit('UPDATE_STORAGE_CONFIG', { group: item.group, maxGroup: +value })
    },

    updateCommonConfigSettings (data) {
      this.$store.commit('UPDATE_STORAGE_COMMON_CONFIG_SETTINGS', data)
      this.STORAGE_CONFIGS.forEach(el => this.$store.commit('UPDATE_STORAGE_CONFIG', { group: el.group, ...data }))
    },

    setMaxGroupCommonConfigSettings (item, value) {
      if (typeof this.rules.maxGroup(item)(value) === 'boolean' && this.rules.maxGroup(item)(value)) {
        this.$store.commit('UPDATE_STORAGE_COMMON_CONFIG_SETTINGS', { maxGroup: +value })
        this.STORAGE_CONFIGS.forEach(el => this.$store.commit('UPDATE_STORAGE_CONFIG', { group: el.group, maxGroup: +value }))
      }
    },

    calcRAID (RAID, hdds) {
      let hddsOut
      switch (RAID) {
        case 'JBOD': hddsOut = hdds; break
        case 'RAID0': hddsOut = (hdds !== 1) ? hdds : 2; break
        case 'RAID1': hddsOut = hdds * 2; break
        case 'RAID5': hddsOut = (hdds !== 1) ? hdds + 1 : 3; break
        case 'RAID6': hddsOut = (hdds !== 1) ? hdds + 2 : 4; break
        case 'RAID10': hddsOut = (hdds !== 1) ? hdds * 2 : 4; break
        default: console.log('RAID type incorrect')
      }
      return hddsOut
    }
  }
}
</script>

<style scoped>

#storage-config {
  max-width: 1100px;
}

>>> .v-input__icon > .v-icon {
  height: 0;
  width: 0;
}

.x-small-input {
  width: 40px !important
}

.small-input {
  width: 60px !important
}

.middle-input {
  width: 90px !important
}

.middle2-input {
  width: 120px !important
}

>>> .v-select__selections input {
  display: none;
}

</style>
