import * as fabric from 'fabric'

const HANDLE_RADIUS = 6
const HANDLE_COLOR = '#1976d2'

export default class LayoutRect {
  constructor (objData = {}) {
    this.canvasObjects = [] // for canvas drawing
    this.requireNextClick = true // true - if necessary determinate more one vertex for object
    this.site = objData.site
    this.typeOf = 'layoutRect'
    this.id = objData.id
    this.zOrder = objData.zOrder
    this.rect = new fabric.Rect({
      left: objData.left || Infinity,
      top: objData.top || Infinity,
      width: objData.width || 0,
      height: objData.height || 0,
      fill: objData.fill || 'transparent',
      stroke: objData.stroke || '#000000',
      strokeWidth: objData.strokeWidth || 1,
      angle: objData.angle || 0,
      opacity: objData.opacity || 1,
      hasBorders: false,
      selectable: false,
      evented: false,
      hoverCursor: 'move',
      cornerColor: HANDLE_COLOR,
      cornerSize: HANDLE_RADIUS,
      originX: 'center',
      originY: 'center',
      cornerStyle: 'circle',
      transparentCorners: false,
      perPixelTargetFind: true,
      objectCaching: false,
      strokeUniform: true,
      isSelectable: true, // for set selectable obj on canvas
      typeOf: this.typeOf,
      id: this.id
    })

    // add copy rectangle for selection obj only by border
    this.handleRect = new fabric.Rect({
      fill: 'transparent',
      stroke: 'red', // any color
      strokeWidth: 6,
      opacity: 0.01,
      hoverCursor: 'pointer',
      selectable: true,
      evented: true,
      hasBorders: false,
      hasControls: false,
      perPixelTargetFind: true,
      strokeUniform: true,
      objectCaching: false,
      originX: 'center',
      originY: 'center',
      isAux: true, // temporary obj, only for selection
      subTypeOf: 'handleContur',
      typeOf: this.typeOf,
      id: this.id
    })

    this.canvasObjects = [this.rect, this.handleRect] // origin rect - first element, selectable element - second
    this.#copyPosFromRectToHandleRect()
  }

  // private method for set coords handleRect as Rect
  // copy params from Rect
  #copyPosFromRectToHandleRect = function () {
    this.handleRect.set({
      left: this.rect.left,
      top: this.rect.top,
      width: this.rect.width,
      height: this.rect.height,
      angle: this.rect.angle
    }).setCoords()
  }

  is (elemFabric) {
    return (elemFabric === this.handleRect || elemFabric === this.rect) && this
  }

  zooming (scale, point) { // point - point of zooming {x, y}, scale = zoomNew/zoomOld
    this.rect.set({
      left: point.x + scale * (this.rect.left - point.x),
      top: point.y + scale * (this.rect.top - point.y),
      width: scale * this.rect.width,
      height: scale * this.rect.height
    }).setCoords()
    this.#copyPosFromRectToHandleRect()
  }

  panning (startPoint, endPoint) { // point - {x, y}
    this.rect.set({
      left: endPoint.x - startPoint.x + this.rect.left,
      top: endPoint.y - startPoint.y + this.rect.top
    }).setCoords()
    this.handleRect.set({
      left: this.rect.left,
      top: this.rect.top
    }).setCoords()
  }

  createFirstClick (event) {
    const evt = event.pointer
    this.rect.set({
      originX: 'left',
      originY: 'top',
      left: evt.x,
      top: evt.y
    }).setCoords()
  }

  createWaitNextClick (event) {
    const evt = event.pointer
    this.rect.set({
      width: Math.abs(evt.x - this.rect.left),
      height: Math.abs(evt.y - this.rect.top),
      originX: (this.rect.left < evt.x) ? 'left' : 'right',
      originY: (this.rect.top < evt.y) ? 'top' : 'bottom'
    }).setCoords()
  }

  createNextClick () {
    if (this.rect.originX !== 'left') this.rect.set({ left: this.rect.left - this.rect.width })
    if (this.rect.originY !== 'top') this.rect.set({ top: this.rect.top - this.rect.height })
    this.rect.set({ originX: 'center', originY: 'center', left: this.rect.left + this.rect.width / 2, top: this.rect.top + this.rect.height / 2 })
    this.#copyPosFromRectToHandleRect()
    this.requireNextClick = false
  }

  selected () {
    this.rect.set({ selectable: true, evented: true }).setCoords()
    this.handleRect.set({ selectable: false, evented: false }).setCoords()
  }

  unselected () {
    this.rect.set({ selectable: false, evented: false }).setCoords()
    this.handleRect.set({ selectable: true, evented: true }).setCoords()
  }

  update () {
    this.rect.set({
      width: this.rect.width * this.rect.scaleX,
      height: this.rect.height * this.rect.scaleY,
      scaleX: 1,
      scaleY: 1
    })
    this.#copyPosFromRectToHandleRect()
  }

  freeze () {
    this.handleRect.set({ selectable: false, evented: false })
  }

  unfreeze () {
    this.handleRect.set({ selectable: true, evented: true })
  }

  toStoreData () {
    return {
      left: this.rect.left,
      top: this.rect.top,
      angle: +parseFloat(this.rect.angle).toFixed(0),
      width: this.rect.width,
      height: this.rect.height,
      stroke: this.rect.stroke,
      fill: this.rect.fill,
      strokeWidth: this.rect.strokeWidth,
      opacity: this.rect.opacity,
      zOrder: this.zOrder,
      site: this.site,
      typeOf: this.typeOf,
      id: this.id
    }
  }

  updateParamsFromStore (objData) {
    this.rect.set({
      stroke: objData.stroke,
      fill: objData.fill,
      strokeWidth: objData.strokeWidth,
      opacity: objData.opacity,
      angle: objData.angle
    })
    this.zOrder = objData.zOrder
  }
}
