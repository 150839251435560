<template>
  <v-select
    v-model = "select"
    solo
    :items = "SITES"
    item-text = "name"
    item-value = "id"
    style = "z-index: 150;"
    dense
    flat
    hide-details
    class = "shrink ma-0 pa-0"
    background-color = "transparent"
    ref = 'siteSelect'
    >
    <template #selection = "{ item }"><span v-if = "SITES.length > 1">{{item.name}}</span></template>
    <template #append-item v-if = "IS_AUTH || IS_SHARED.access === 'edit'">
      <v-divider/>
      <frame-dialog class = "pa-0 ma-0" @dialog-open = "$refs.siteSelect.blur()">
        <template #dialog-btn>
          <div class = "mx-4 text-caption text-capitalize mt-2">
            {{$t('sites manage')}}
          </div>
        </template>
        <template #dialog-body = "{ onClose }">
          <sites-dialog @close = "onClose"/>
        </template>
      </frame-dialog>
    </template>
  </v-select>
</template>

<script>
import { mapState } from 'vuex'
import FrameDialog from './../lib/srvFrameDialog'
import SitesDialog from './SitesDialog'

export default {

  name: 'ToolbarMenuSites',

  components: {
    FrameDialog,
    SitesDialog
  },

  props: [
    'item'
  ],

  data: () => ({
  }),

  computed: {
    ...mapState({
      SITES: state => state.sitesStore.sites,
      CURRENT_SITE: state => state.sitesStore.currentSite,
      IS_AUTH: state => state.auth.isAuth,
      IS_SHARED: state => state.auth.isShared
    }),

    select: {
      get () {
        return this.CURRENT_SITE
      },
      set (id) {
        this.$store.commit('SET_CURRENT_SITE', id)
      }
    }
  },

  methods: {
  },

  mounted () {

  }
}

</script>

<style scoped>
>>> .v-select__selections input {
  display: none;
}

>>> .v-select__selection--comma {
  overflow: visible;
  margin-top: 0px;
}

>>> .v-input__slot {
  padding: 0px !important;
}

</style>
