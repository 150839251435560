<template>
<v-dialog
  v-model="dialog"
  fullscreen
  hide-overlay
  transition="dialog-bottom-transition"
  scrollable
  >
  <template #activator="{ on, attrs }">
    <div
      v-bind="attrs"
      v-on="on"
    >
      <slot name="dialog-btn"/>
    </div>
  </template>
  <v-card align = "center">
    <v-card-title class = "pa-0 ma-0" style = "z-index: 5">
      <v-toolbar
        height="32px"
        style="border-radius: 0"
        dark
        dense
        color="primary"
      >
        <v-toolbar-title class="text-body-1">{{ $t('storage calculator') }}</v-toolbar-title>
        <v-spacer/>
        <v-toolbar-items>
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <div id = "storage-wrapper" class="pt-2 d-flex justify-center" scrollable>
        <v-stepper
          non-linear
          class = "elevation-0 ma-0 pa-0"
          width = "1100"
          >
          <v-stepper-header class = "elevation-0 ma-0 pa-0">
            <v-stepper-step
              editable
              step="1"
            >
            {{$t('storage cam and streams')}}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              editable
              step="2"
            >
            {{$t('storage config')}}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              step="3"
              editable
            >
            {{$t('storage result')}}
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items class = "pa-0 ma-0">
            <v-stepper-content step = "1" class = "pa-0 my-0">
              <storage-cameras/>
            </v-stepper-content>
            <v-stepper-content step = "2" class = "pa-0 my-0">
              <storage-config/>
            </v-stepper-content>
            <v-stepper-content step = "3" class = "pa-0 my-0">
              <storage-result/>
            </v-stepper-content>

          </v-stepper-items>
        </v-stepper>
      </div>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
import StorageCameras from './StorageCameras'
import StorageConfig from './StorageConfig'
import StorageResult from './StorageResult'

export default {

  name: 'StorageDialog',

  components: {
    StorageCameras,
    StorageConfig,
    StorageResult
  },

  data: () => ({
    dialog: false
  })
}
</script>

<style scoped>

#storage-wrapper {
  max-width: 1100px;
}

</style>
