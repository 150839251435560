<template>
  <v-container fluid class = "ma-0 pa-0">
    <v-app-bar
      id = "app-bar"
      color = "primary"
      dark
      height = "32"
      class = "elevation-0 ma-0 pa-0"
      >
      <span class = "fix"/> <!-- use for fixed first load icon in chrome -->
      <div class = "d-flex justify-space-between align-center flex-nowrap ma-0 pa-0" style = "width: 100%">
        <div id = "toolbar-left" class = "d-flex justify-start align-center toolbar-section">
          <span v-if = "!$route.params.id"> SURVy </span>
          <span v-else class = "d-flex align-end ma-0 pa-0">
            <toolbar-dialog-to-projects
              v-if = "storeChange"
              @save = "saveProject(); $router.push({ name: 'projects'})"
              @no-save = "storeChange = false; $router.push({ name: 'projects'})"
              >
              <template #dialog-btn>
                <toolbar-btn
                  :params = "{ icon: 'mdi-arrow-left', descr: 'toolbar to projects', event: '' }"
                />
              </template>
            </toolbar-dialog-to-projects>
            <!-- without store change btn -->
            <toolbar-btn v-else
              :params = "{ icon: 'mdi-arrow-left', descr: 'toolbar to projects', event: 'to-projects' }"
              @to-projects = "$router.push({ name: 'projects'})"
            />
            <!-- without store change btn -->
            <v-btn
              @click = "saveProject()"
              plain
              small
              class="text-initial"
              >
              {{$t('save')}}
            </v-btn>
          </span>
          <span v-if = "IS_SHARED.access === 'edit'">
            <v-btn
              @click = "saveShareProject()"
              plain
              small
              class="text-initial"
              >
              {{$t('save')}}
            </v-btn>
          </span>
        </div>

        <div id = "toolbar-center" class = "d-flex justify-center align-center toolbar-section" v-if = "$route.params.id || $route.params.uri">
          <span
            class = "overflow-hidden ma-0 pa-0 d-flex"
            style = "text-overflow: ellipsis; white-space: nowrap;"
            >
            {{nameOfActiveProject}}
          </span>
          <div class = "d-flex align-center">
            <span class = "ml-4" v-if = "$store.state.sitesStore.sites.length > 1">/</span>
            <toolbar-menu-sites :class = "$store.state.sitesStore.sites.length > 1 ? 'ml-4' : ''"/>
          </div>
        </div>
        <div id = "toolbar-right" class = "d-flex justify-end align-center toolbar-section">
          <div class = "d-flex pa-0 ma-0" v-if = "$route.params.id">
            <report-dialog>
              <template #dialog-btn>
                <v-btn
                  plain
                  small
                  class = "text-initial"
                  >
                  {{$t('report')}}
                </v-btn>
              </template>
            </report-dialog>
            <storage-dialog >
              <template #dialog-btn>
                <v-btn
                  plain
                  small
                  class="text-initial"
                  >
                  {{$t('storage')}}
                </v-btn>
              </template>
            </storage-dialog>
            <settings-dialog>
              <template #dialog-btn>
                <v-btn
                  plain
                  small
                  class="text-initial"
                  >
                  {{$t('settings')}}
                </v-btn>
              </template>
            </settings-dialog>
          </div>
          <lang-switch/>
          <div v-if="IS_AUTH">
          <toolbar-dialog-to-projects
            v-if = "storeChange"
            @save = "logoutWithSave()"
            @no-save = "storeChange = false; logout()"
            >
            <template #dialog-btn>
              <toolbar-btn
                :params = "{ icon: 'mdi-logout-variant', descr: 'logout', event: 'logout', smallSize: true }"
              />
            </template>
          </toolbar-dialog-to-projects>
          <!-- without store change btn -->
          <toolbar-btn v-else
            :params = "{ icon: 'mdi-logout-variant', descr: 'logout', event: 'logout', smallSize: true }"
            @logout = "logout()"
          />
          <!-- without store change btn -->
          </div>
          <toolbar-menu-more>
            <template #btn>
              <v-btn
                icon
                plain
                class = "pa-0 ma-0"
                >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
          </toolbar-menu-more>
        </div>
      </div>
    </v-app-bar>

    <div
      id="page-body"
      class="d-flex justify-center overflow-y-auto"
    >
      <slot name="page-body"/>
    </div>
  </v-container>
</template>

<script>
import ToolbarBtn from '../components/Toolbar-component/ToolbarBtn'
import ToolbarDialogToProjects from '../components/Toolbar-component/ToolbarDialogToProjects'
import LangSwitch from './lib/srvLangSwitch'
import { mapActions, mapGetters, mapState } from 'vuex'
import { eventBus } from '../main'
import ToolbarMenuMore from './Toolbar-component/ToolbarMenuMore'
import ToolbarMenuSites from './Toolbar-component/ToolbarMenuSites'
import ReportDialog from './Toolbar-component/TBSites/ReportDialog'
import SettingsDialog from './Toolbar-component/TBSites/SettingsDialog'
import StorageDialog from './Toolbar-component/TBSites/StorageDialog'

export default {
  name: 'Toolbar',

  components: {
    ToolbarBtn,
    ToolbarDialogToProjects,
    LangSwitch,
    ToolbarMenuMore,
    ToolbarMenuSites,
    ReportDialog,
    SettingsDialog,
    StorageDialog
  },

  data: () => ({
    storeChange: false
  }),

  computed: {
    ...mapGetters([
      'CHECK_STORE_CHANGE',
      'PROJECT_BY_ID'
    ]),

    ...mapState({
      IS_AUTH: state => state.auth.isAuth,
      IS_SHARED: state => state.auth.isShared
    }),

    nameOfActiveProject () {
      let projectName = ''
      if (this.$route.params.id && this.IS_AUTH) projectName = this.PROJECT_BY_ID(+this.$route.params.id).name
      if (this.IS_SHARED) projectName = this.IS_SHARED.name
      return projectName
    }
  },

  methods: {
    ...mapActions([
      'SAVE_PROJECT',
      'SAVE_SHARED_PROJECT',
      'GET_LOGOUT_TO_STATE'
    ]),

    async saveProject () {
      await this.SAVE_PROJECT({ id: this.$route.params.id, data: this.$store.getters.TO_DB })
      eventBus.$emit('project-save')
      this.storeChange = false
    },

    async saveShareProject () {
      await this.SAVE_SHARED_PROJECT({ uri: this.$route.params.uri, data: this.$store.getters.TO_DB })
    },

    async logout () {
      await this.GET_LOGOUT_TO_STATE()
      this.$router.push({ name: 'login' }) // .catch(() => {})
    },

    async logoutWithSave () {
      await this.saveProject()
      this.logout()
    }
  },

  watch: {
  },

  mounted () {
    eventBus.$on('store-change', () => { this.storeChange = true })
    eventBus.$on('project-close', () => { this.storeChange = false })
  },

  beforeDestroy () {
    eventBus.$off(['store-change', 'project-close'])
  }
}
</script>

<style scoped>
.text-initial {
  text-transform: initial !important;
}
.toolbar-section {
  flex: 1 1 0px;
}

.fix:before {
  font-family: icomoon;
  content: "\e901";
  font-size: 0;
}

.fix:after {
  font-family: icomoon;
  content: "\e909";
  font-size: 0;
}
</style>
