import * as fabric from 'fabric'
import { calcRectFromDiag, calcRectFromOutImg, checkPointPosLine, calcDistPointToLine, calcRectWHFromDiag, rotate2D } from './cameraCalcZone/mathFunction'
import store from '../../../store/index'

const HANDLE_RADIUS = 6
const HANDLE_COLOR = '#1976d2'
const HANDLE_IMG_STROKE = 6
const HANDLE_WIDTH = 3
const HANDLE_LENGTH = 15

// img - original image, not evented
// cropArea - crop area, rectangle, not evented
// outImg - group with img and clip-path = cropArea. cropArea have position {0,0} and move img inside group
// handleImg - for selected image from unselected state
// cropShadow - grey polygon, show removing area, not evented
// cropHandle - handle crop area
// hoverArea - area for check click inside crop image

export default class LayoutImg {
  constructor (objData = {}, cb) {
    this.canvasObjects = [] // for canvas drawing
    this.requireNextClick = false // true - if necessary determinate more one vertex for object
    this.site = objData.site
    this.typeOf = 'layoutImg'
    this.isCrop = false
    this.id = objData.id
    this.zOrder = objData.zOrder
    this.imgScale = objData.mapScale * 1000 * store.getters.CURRENT_ZOOM || objData.imgScale
    const self = this
    fabric.Image.fromURL(objData.imgURL)
      .then(async oImg => {
        oImg.set({
          scaleX: 1,
          scaleY: 1,
          originX: 'center',
          originY: 'center',
          selectable: false,
          evented: false,
          typeOf: this.typeOf,
          id: this.id
        })
        this.outImg.add(oImg)
        let scale = 1
        if (!this.imgScale && ((1.25 * oImg.width > document.documentElement.clientWidth) || (1.25 * oImg.height > document.documentElement.clientHeight))) {
          scale = Math.min(document.documentElement.clientWidth / 1.25 / oImg.width, document.documentElement.clientHeight / 1.25 / oImg.height)
        }

        this.outImg.set({
          left: objData.left || -4000,
          top: objData.top || -4000,
          scaleX: this.imgScale || scale,
          scaleY: this.imgScale || scale,
          angle: objData.angle || 0
        }).setCoords()

        this.clip.set({
          width: objData.cropWidth || this.outImg.width,
          height: objData.cropHeight || this.outImg.height
        }).setCoords()
        this.outImg.set({
          clipPath: this.clip,
          layout: 'clip-path'
        }).setCoords()
        this.img = oImg
        this.img.set({
          left: objData.cropLeft || 0,
          top: objData.cropTop || 0
        }).setCoords()
        if (objData.BWFilter) {
          oImg.filters.push(new fabric.filters.Grayscale())
          oImg.applyFilters()
        }

        this.#copyPosFromImgToHandleImg()

        const blob = await fetch(this.img._originalElement.src).then(res => res.blob())
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          this.img64 = reader.result
          cb && cb(self) // eslint-disable-line
        }
      })

    // add clip zone
    this.clip = new fabric.Rect({
      top: 0,
      left: 0,
      fill: '#ff0000',
      selectable: false,
      evented: false,
      hasBorders: false,
      hasControls: false,
      originX: 'center',
      originY: 'center',
      typeOf: this.typeOf,
      id: this.id
    })

    // group - wrapper for image and clip
    this.outImg = new fabric.Group([], {
      selectable: false,
      evented: false,
      // subTargetCheck: true,
      stroke: HANDLE_COLOR,
      strokeWidth: objData.border || 0,
      // hoverCursor: 'move',
      opacity: objData.opacity || 0,
      hasBorders: true,
      borderOpacity: 0.4,
      borderColor: HANDLE_COLOR,
      hasControls: true,
      cornerColor: HANDLE_COLOR,
      cornerSize: HANDLE_RADIUS,
      cornerStyle: 'circle',
      transparentCorners: false,
      originX: 'center',
      originY: 'center',
      objectCaching: false,
      isSelectable: true, // for set selectable obj on canvas
      typeOf: this.typeOf,
      id: this.id
    })

    // add copy rectangle for selection obj only by border
    this.handleImg = new fabric.Rect({
      fill: 'transparent',
      stroke: HANDLE_COLOR,
      strokeWidth: HANDLE_IMG_STROKE,
      opacity: 0.01,
      hoverCursor: 'pointer',
      selectable: true,
      evented: true,
      hasBorders: false,
      hasControls: false,
      perPixelTargetFind: true,
      objectCaching: false,
      originX: 'center',
      originY: 'center',
      isAux: true, // temporary obj, only for selection
      subTypeOf: 'handleContur',
      typeOf: this.typeOf,
      id: this.id
    })

    // add crop handle
    const cropHandleParams = {
      fill: 'red',
      hoverCursor: 'pointer',
      opacity: 0,
      selectable: false,
      evented: false,
      hasBorders: false,
      hasControls: false,
      // perPixelTargetFind: true,
      objectCaching: false,
      subTypeOf: 'handleCrop',
      typeOf: this.typeOf,
      id: this.id
    }
    this.cropHandleTL = new fabric.Polygon([
      { x: 0, y: 0 }, { x: HANDLE_LENGTH, y: 0 },
      { x: HANDLE_LENGTH, y: HANDLE_WIDTH }, { x: HANDLE_WIDTH, y: HANDLE_WIDTH },
      { x: HANDLE_WIDTH, y: HANDLE_LENGTH }, { x: 0, y: HANDLE_LENGTH }
    ], {
      originX: 'left',
      originY: 'top',
      ...cropHandleParams
    })
    this.cropHandleBR = new fabric.Polygon([
      { x: HANDLE_LENGTH - HANDLE_WIDTH, y: 0 }, { x: HANDLE_LENGTH, y: 0 },
      { x: HANDLE_LENGTH, y: HANDLE_LENGTH }, { x: 0, y: HANDLE_LENGTH },
      { x: 0, y: HANDLE_LENGTH - HANDLE_WIDTH }, { x: HANDLE_LENGTH - HANDLE_WIDTH, y: HANDLE_LENGTH - HANDLE_WIDTH }
    ], {
      originX: 'right',
      originY: 'bottom',
      ...cropHandleParams
    })

    // add crop shadow
    this.cropShadow = new fabric.Polygon([
      { x: 0, y: 0 },
      { x: store.getters.CANVASDATA.width, y: store.getters.CANVASDATA.height }
    ], {
      top: 0,
      left: 0,
      fill: 'grey',
      opacity: 0,
      selectable: false,
      evented: false,
      hasBorders: false,
      hasControls: false,
      originX: 'left',
      originY: 'top',
      typeOf: this.typeOf,
      id: this.id
    })

    // add hover area for crop
    this.hoverArea = new fabric.Rect({
      fill: 'transparent',
      selectable: false,
      evented: false,
      hoverCursor: 'default',
      hasBorders: false,
      hasControls: false,
      objectCaching: false,
      originX: 'center',
      originY: 'center',
      typeOf: this.typeOf,
      id: this.id
    })

    this.canvasObjects.push(this.outImg, this.handleImg, this.hoverArea, this.cropShadow, this.cropHandleTL, this.cropHandleBR)
  }

  // private method
  // copy position from img
  #copyPosFromImgToHandleImg = function () {
    this.handleImg.set({
      left: this.outImg.left,
      top: this.outImg.top,
      width: this.outImg.width * this.outImg.scaleX,
      height: this.outImg.height * this.outImg.scaleY,
      angle: this.outImg.angle
    }).setCoords()
  }

  #setShadowCoords = function () {
    const externalContur = calcRectFromOutImg(this.outImg, this.outImg.item(0))
    const internalContur = calcRectFromDiag(
      { x: this.cropHandleTL.left, y: this.cropHandleTL.top },
      { x: this.cropHandleBR.left, y: this.cropHandleBR.top },
      this.outImg.angle * Math.PI / 180
    )
    this.cropShadow.set({
      points: [
        ...externalContur, // 0 - 3
        externalContur[0], // 4
        ...internalContur,
        internalContur[0],
        externalContur[0] // 10
      ]
    }).setCoords()
  }

  #setHoverCoords = function () {
    this.hoverArea.set({
      width: this.img.width * this.outImg.scaleX,
      height: this.img.height * this.outImg.scaleY,
      left: (this.cropShadow.points[0].x + this.cropShadow.points[2].x) / 2,
      top: (this.cropShadow.points[0].y + this.cropShadow.points[2].y) / 2,
      angle: this.outImg.angle
    }).setCoords()
  }

  #setClip = function () {
    const clipWH = calcRectWHFromDiag( // get heigth and width from handles and angle
      { x: this.cropHandleTL.left, y: this.cropHandleTL.top },
      { x: this.cropHandleBR.left, y: this.cropHandleBR.top },
      this.outImg.angle * Math.PI / 180
    )
    this.clip.set({
      width: clipWH.width / this.outImg.scaleX,
      height: clipWH.height / this.outImg.scaleY
    }).setCoords()
  }

  is (elemFabric) {
    return (elemFabric === this.outImg ||
      elemFabric === this.handleImg ||
      elemFabric === this.cropHandleTL ||
      elemFabric === this.cropHandleBR ||
      elemFabric === this.hoverArea) && this
  }

  zooming (scale, point) { // point - point of zooming {x, y}, scale = zoomNew/zoomOld
    this.outImg.set({
      left: point.x + scale * (this.outImg.left - point.x),
      top: point.y + scale * (this.outImg.top - point.y),
      scaleX: scale * this.outImg.scaleX,
      scaleY: scale * this.outImg.scaleY
    }).setCoords()
    this.#copyPosFromImgToHandleImg()
    if (this.isCrop) {
      this.cropHandleTL.set({
        left: point.x + scale * (this.cropHandleTL.left - point.x),
        top: point.y + scale * (this.cropHandleTL.top - point.y)
      }).setCoords()
      this.cropHandleBR.set({
        left: point.x + scale * (this.cropHandleBR.left - point.x),
        top: point.y + scale * (this.cropHandleBR.top - point.y)
      }).setCoords()
      this.#setShadowCoords()
      this.#setHoverCoords()
    }
  }

  panning (startPoint, endPoint) { // point - {x, y}
    this.outImg.set({
      left: endPoint.x - startPoint.x + this.outImg.left,
      top: endPoint.y - startPoint.y + this.outImg.top
    }).setCoords()
    this.handleImg.set({
      left: this.outImg.left,
      top: this.outImg.top
    }).setCoords()
    this.#setShadowCoords()
    this.#setHoverCoords()
  }

  createFirstClick (event) {
    const evt = event.pointer
    this.outImg.set({
      left: evt.x + this.outImg.width * this.outImg.scaleX / 2,
      top: evt.y + this.outImg.height * this.outImg.scaleY / 2,
      opacity: 0.5
    }).setCoords()
    this.#copyPosFromImgToHandleImg()
  }

  crop () {
    this.isCrop = true
    this.outImg.set({ selectable: false, evented: false, clipPath: null }).setCoords()
    this.handleImg.set({ selectable: false, evented: false })
    const fixPos = rotate2D({ x: HANDLE_IMG_STROKE / 2, y: HANDLE_IMG_STROKE / 2 }, this.outImg.angle * Math.PI / 180, false) // need for fix handle position - handleImg has strokewidth
    this.cropHandleTL
      .set({
        left: this.handleImg.aCoords.tl.x + fixPos.x,
        top: this.handleImg.aCoords.tl.y + fixPos.y,
        angle: this.outImg.angle,
        selectable: true,
        evented: true,
        opacity: 1
      })
      .setCoords()
    this.cropHandleBR
      .set({
        left: this.handleImg.aCoords.br.x - fixPos.x,
        top: this.handleImg.aCoords.br.y - fixPos.y,
        angle: this.outImg.angle,
        selectable: true,
        evented: true,
        opacity: 1
      })
      .setCoords()
    this.cropShadow.set({ opacity: 0.5 })
    this.hoverArea.set({ evented: true })
    this.#setShadowCoords()
    this.#setHoverCoords()
  }

  exitCrop () {
    this.isCrop = false
    // hide shadow and crop handles
    this.cropHandleTL.set({ selectable: false, evented: false, opacity: 0 })
    this.cropHandleBR.set({ selectable: false, evented: false, opacity: 0 })
    this.cropShadow.set({ opacity: 0 })
    this.hoverArea.set({ evented: false })
    this.#setClip()
    // update outImg - center coords and set clip area
    const centerCropArea = {
      x: (this.cropHandleTL.left + this.cropHandleBR.left) / 2,
      y: (this.cropHandleTL.top + this.cropHandleBR.top) / 2
    }
    const centerImg = {
      x: (this.cropShadow.points[0].x + this.cropShadow.points[2].x) / 2,
      y: (this.cropShadow.points[0].y + this.cropShadow.points[2].y) / 2
    }
    const v = {
      x: centerImg.x - centerCropArea.x,
      y: centerImg.y - centerCropArea.y
    }
    const shiftImg = rotate2D(v, this.outImg.angle * Math.PI / 180, true)
    const shiftCenterImg = rotate2D({ x: this.img.left, y: this.img.top }, this.outImg.angle * Math.PI / 180, false)
    this.outImg
      .set({ layout: null })
      .set({
        left: centerCropArea.x - shiftCenterImg.x * this.outImg.scaleX,
        top: centerCropArea.y - shiftCenterImg.y * this.outImg.scaleY,
        clipPath: this.clip,
        layout: 'clip-path'
      }).setCoords()
    this.img.set({
      left: shiftImg.x / this.outImg.scaleX,
      top: shiftImg.y / this.outImg.scaleY
    }).setCoords()
    this.#copyPosFromImgToHandleImg()
  }

  selected () {
    this.outImg.set({ selectable: true, evented: true })
    this.handleImg.set({ selectable: false, evented: false })
  }

  unselected () {
    this.outImg.set({ selectable: false, evented: false })
    this.handleImg.set({ selectable: true, evented: true })
  }

  update (clickTarget) {
    if (this.isCrop) {
      if (clickTarget.subTypeOf === 'handleCrop') {
        const minSize = 1000 * store.getters.CURRENT_ZOOM // in mm * zoom -> px
        const sinA = Math.sin(this.outImg.angle * Math.PI / 180)
        const cosA = Math.cos(this.outImg.angle * Math.PI / 180)
        const handle = { x: clickTarget.left, y: clickTarget.top }
        const conturParams = clickTarget === this.cropHandleTL
          ? [
            this.cropShadow.points[0],
            {
              x: this.cropHandleBR.left - minSize * (cosA - sinA),
              y: this.cropHandleBR.top - minSize * (cosA + sinA)
            }
          ]
          : [{
            x: this.cropHandleTL.left + minSize * (cosA - sinA),
            y: this.cropHandleTL.top + minSize * (cosA + sinA)
          },
          this.cropShadow.points[2]
          ]
        const contur = calcRectFromDiag(...conturParams, this.outImg.angle * Math.PI / 180)
        // check handle in contur / min width-heigth, bl - 0, tl - 1, tr - 2, br - 3
        // top
        const checkTop = checkPointPosLine(contur[1], contur[2], handle) !== 1
        const checkLeft = checkPointPosLine(contur[0], contur[1], handle) !== 1
        const checkBottom = checkPointPosLine(contur[3], contur[0], handle) !== 1
        const checkRight = checkPointPosLine(contur[2], contur[3], handle) !== 1
        let checkPoint = false
        if (checkTop) {
          const dist = calcDistPointToLine(contur[1], contur[2], handle)
          checkPoint = { left: handle.x - sinA * dist, top: handle.y + cosA * dist }
        }
        // left
        if (checkLeft) {
          const dist = calcDistPointToLine(contur[0], contur[1], handle)
          checkPoint = { left: handle.x + cosA * dist, top: handle.y + sinA * dist }
        }
        // bottom
        if (checkBottom) {
          const dist = calcDistPointToLine(contur[3], contur[0], handle)
          checkPoint = { left: handle.x + sinA * dist, top: handle.y - cosA * dist }
        }
        // right
        if (checkRight) {
          const dist = calcDistPointToLine(contur[2], contur[3], handle)
          checkPoint = { left: handle.x - cosA * dist, top: handle.y - sinA * dist }
        }
        if (checkTop && checkLeft) checkPoint = { left: contur[1].x, top: contur[1].y }
        if (checkTop && checkRight) checkPoint = { left: contur[2].x, top: contur[2].y }
        if (checkBottom && checkLeft) checkPoint = { left: contur[0].x, top: contur[0].y }
        if (checkBottom && checkRight) checkPoint = { left: contur[3].x, top: contur[3].y }
        // write result of check
        if (checkPoint) clickTarget.set(checkPoint).setCoords()

        const points = this.cropShadow.points.map(e => ({ x: e.x, y: e.y }))
        const calcPoints = calcRectFromDiag(
          { x: this.cropHandleTL.left, y: this.cropHandleTL.top },
          { x: this.cropHandleBR.left, y: this.cropHandleBR.top },
          this.outImg.angle * Math.PI / 180
        ).map(e => ({ x: e.x, y: e.y }))
        points[5] = calcPoints[0] // 5
        points[6] = calcPoints[1] // 6
        points[7] = calcPoints[2] // 7
        points[8] = calcPoints[3] // 8
        points[9] = calcPoints[0] // 9
        this.cropShadow.set({ points: points.map(e => ({ x: e.x, y: e.y })) }).setCoords()
      }
      this.#setClip()
    } else {
      this.#setShadowCoords()
    }
    this.#setHoverCoords()
    this.#copyPosFromImgToHandleImg()
  }

  freeze () {
    this.handleImg.set({ selectable: false, evented: false })
  }

  unfreeze () {
    this.handleImg.set({ selectable: true, evented: true })
  }

  toStoreData () {
    return {
      imgURL: this.img64,
      left: this.outImg.left,
      top: this.outImg.top,
      cropLeft: this.img.left, // in px inside group
      cropTop: this.img.top, // in px inside group
      cropWidth: this.clip.width, // in px, outside group
      cropHeight: this.clip.height, // in px, outside group
      angle: +parseFloat(this.outImg.angle).toFixed(1),
      imgScale: this.outImg.scaleX,
      opacity: this.outImg.opacity,
      BWFilter: (this.img.filters[0]) ? 1 : 0,
      border: this.outImg.strokeWidth,
      zOrder: this.zOrder,
      site: this.site,
      typeOf: this.typeOf,
      id: this.id
    }
  }

  updateParamsFromStore (objData) {
    this.outImg.set({
      opacity: objData.opacity,
      angle: objData.angle,
      strokeWidth: objData.border ? 1 : 0
    })
    objData.BWFilter
      ? this.img.filters.push(new fabric.filters.Grayscale())
      : this.img.filters = []
    this.img.applyFilters()
    this.zOrder = objData.zOrder
  }
}
