export function setCoordsG2C (objStore, canvasData) {
  const obj = {}
  Object.assign(obj, objStore)
  switch (obj.typeOf) {
    case 'limitsLine': obj.vertices = obj.vertices.map(el => coordsG2C({ x: el.x, y: el.y })); break
    case 'layoutImg': {
      const centerPoint = coordsG2C({ x: obj.left, y: obj.top })
      obj.left = centerPoint.x
      obj.top = centerPoint.y
      obj.imgScale = obj.imgScale * canvasData.zoom
    }; break
    case 'layoutBrush': {
      const centerPoint = coordsG2C({ x: obj.left, y: obj.top })
      obj.left = centerPoint.x
      obj.top = centerPoint.y
      obj.path = obj.path.map(el => {
        const arrOut = [el[0]]
        const point1 = coordsG2C({ x: el[1], y: el[2] })
        arrOut.push(point1.x, point1.y)
        if (el[3]) {
          const point2 = coordsG2C({ x: el[3], y: el[4] })
          arrOut.push(point2.x, point2.y)
        }
        return arrOut
      })
    }; break
    case 'tape':
    case 'layoutLine': {
      const point1 = coordsG2C({ x: obj.x1, y: obj.y1 })
      const point2 = coordsG2C({ x: obj.x2, y: obj.y2 })
      obj.x1 = point1.x
      obj.y1 = point1.y
      obj.x2 = point2.x
      obj.y2 = point2.y
    }; break
    case 'layoutText':
    case 'layoutRect': {
      const centerPoint = coordsG2C({ x: obj.left, y: obj.top })
      obj.left = centerPoint.x
      obj.top = centerPoint.y
      obj.width = obj.width * canvasData.zoom // zoom = px/mm
      obj.height = obj.height * canvasData.zoom
    }; break
    case 'layoutEquip': {
      const centerPoint = coordsG2C({ x: obj.left, y: obj.top })
      obj.left = centerPoint.x
      obj.top = centerPoint.y
    }; break
    case 'layoutCircle': {
      const centerPoint = coordsG2C({ x: obj.left, y: obj.top })
      obj.left = centerPoint.x
      obj.top = centerPoint.y
      obj.radius = obj.radius * canvasData.zoom // zoom = px/mm
    }; break
    case 'camera': {
      const centerPoint = coordsG2C({ x: obj.left, y: obj.top })
      obj.left = centerPoint.x
      obj.top = centerPoint.y
      const dirPoint = coordsG2C({ x: obj.dirLeft, y: obj.dirTop })
      obj.dirLeft = dirPoint.x
      obj.dirTop = dirPoint.y
    }; break
    default: console.log('Object Global coords data incorrect')
  }
  return obj

  function coordsG2C (coordsGlobal) {
    return {
      x: canvasData.centerGlobalCoords.x + canvasData.zoom * coordsGlobal.x,
      y: canvasData.centerGlobalCoords.y - canvasData.zoom * coordsGlobal.y
    }
  }
}

export function setCoordsC2G (objCanvas, canvasData) {
  const obj = {}
  Object.assign(obj, objCanvas)
  switch (obj.typeOf) {
    case 'limitsLine': obj.vertices = obj.vertices.map(el => coordsC2G({ x: el.x, y: el.y })); break
    case 'layoutImg': {
      const centerPoint = coordsC2G({ x: obj.left, y: obj.top })
      obj.left = centerPoint.x
      obj.top = centerPoint.y
      obj.imgScale = obj.imgScale / canvasData.zoom
    }; break
    case 'layoutBrush': {
      const centerPoint = coordsC2G({ x: obj.left, y: obj.top })
      obj.left = centerPoint.x
      obj.top = centerPoint.y
      obj.path = obj.path.map(el => {
        const arrOut = [el[0]]
        const point1 = coordsC2G({ x: el[1], y: el[2] })
        arrOut.push(point1.x, point1.y)
        if (el[3]) {
          const point2 = coordsC2G({ x: el[3], y: el[4] })
          arrOut.push(point2.x, point2.y)
        }
        return arrOut
      })
    }; break
    case 'tape':
    case 'layoutLine': {
      const point1 = coordsC2G({ x: obj.x1, y: obj.y1 })
      const point2 = coordsC2G({ x: obj.x2, y: obj.y2 })
      obj.x1 = point1.x
      obj.y1 = point1.y
      obj.x2 = point2.x
      obj.y2 = point2.y
    }; break
    case 'layoutText':
    case 'layoutRect': {
      const centerPoint = coordsC2G({ x: obj.left, y: obj.top })
      obj.left = centerPoint.x
      obj.top = centerPoint.y
      obj.width = obj.width / canvasData.zoom
      obj.height = obj.height / canvasData.zoom
    }; break
    case 'layoutEquip': {
      const centerPoint = coordsC2G({ x: obj.left, y: obj.top })
      obj.left = centerPoint.x
      obj.top = centerPoint.y
    }; break
    case 'layoutCircle': {
      const centerPoint = coordsC2G({ x: obj.left, y: obj.top })
      obj.left = centerPoint.x
      obj.top = centerPoint.y
      obj.radius = obj.radius / canvasData.zoom
    }; break
    case 'camera': {
      const centerPoint = coordsC2G({ x: obj.left, y: obj.top })
      obj.left = centerPoint.x
      obj.top = centerPoint.y
      const dirPoint = coordsC2G({ x: obj.dirLeft, y: obj.dirTop })
      obj.dirLeft = dirPoint.x
      obj.dirTop = dirPoint.y
    }; break
    default: console.log('Object Canvas coords data incorrect')
  }
  return obj

  function coordsC2G (coordsCanvas) {
    return {
      x: (coordsCanvas.x - canvasData.centerGlobalCoords.x) / canvasData.zoom,
      y: (canvasData.centerGlobalCoords.y - coordsCanvas.y) / canvasData.zoom
    }
  }
}
