<template>
<div class = "canvasContainer" ref = "canvasContainer">
  <v-overlay :value="isLoading" style = "z-index: 10000">
    <div class = "d-flex justify-center flex-column align-center">
      <v-progress-circular
        indeterminate
        color = "primary"
        size = "64"
        class = "align-self-center"
      />
      <div>{{ progressText }}</div>
    </div>
  </v-overlay>
  <v-snackbar
    v-if = "!error"
    bottom
    timeout = "-1"
    value = "true"
    color= 'transparent'
    elevation = "0"
    class = "pa-0 ma-0"
    >
    <div class="d-flex justify-center align-center pa-0 ma-0">
    <v-btn
      color = "primary"
      @click = "insertDxf()"
      >
      {{ $t('dxf_viewer_ok') }}
    </v-btn>
    </div>
  </v-snackbar>

  <v-snackbar
      v-else
      v-model = "error"
      timeout = "-1"
      multi-line
      text
      elevation="4"
      color = "error"
      >
      <div class="d-flex justify-center align-center pa-0 ma-0 flex-column">
        <div>{{ $t('dxf_viewer_error_occurred') + ': ' + error }}</div>
        <v-btn
          outlined
          plain
          color="error"
          @click = "$emit('close')"
          class = "mt-3"
          >
          {{ $t('dxf_viewer_exit') }}
        </v-btn>
      </div>
    </v-snackbar>
</div>
</template>

<script>
import { DxfViewer } from 'dxf-viewer'
import * as three from 'three'
import DxfViewerWorker from 'worker-loader!./DxfViewerWorker' // eslint-disable-line
import { eventBus } from '../../main'

export default {
  name: 'DxfViewer',

  props: {
    dxfUrl: {
      default: null
    },
    fonts: {
      default: null
    },
    options: {
      default () {
        return {
          clearColor: new three.Color('#fff'),
          autoResize: true,
          colorCorrection: true,
          sceneOptions: { wireframeMesh: true },
          preserveDrawingBuffer: true
        }
      }
    }
  },

  data () {
    return {
      isLoading: false,
      progress: null,
      progressText: null,
      curProgressPhase: null,
      error: false
    }
  },

  methods: {
    async Load (url) {
      this.isLoading = true
      this.error = null
      try {
        await this.dxfViewer.Load({
          url,
          fonts: this.fonts,
          progressCbk: this._OnProgress.bind(this),
          workerFactory: DxfViewerWorker
        })
      } catch (error) {
        console.warn(error)
        this.error = error.toString()
      } finally {
        this.isLoading = false
        this.progressText = null
        this.progress = null
        this.curProgressPhase = null
      }
    },

    /** @return { DxfViewer } */
    GetViewer () {
      return this.dxfViewer
    },

    _OnProgress (phase, size, totalSize) {
      if (phase !== this.curProgressPhase) {
        switch (phase) {
          case 'font':
            this.progressText = this.$t('dxf_viewer_fetching_fonts')
            break
          case 'fetch':
            this.progressText = this.$t('dxf_viewer_fetching_file')
            break
          case 'parse':
            this.progressText = this.$t('dxf_viewer_parsing_file')
            break
          case 'prepare':
            this.progressText = this.$t('dxf_viewer_preparing_rendering_data')
            break
        }
        this.curProgressPhase = phase
      }
      this.progress = totalSize === null ? -1 : size / totalSize
    },

    async insertDxf () {
      const canvas = this.$refs.canvasContainer.querySelector('canvas')
      canvas.toBlob(blob => eventBus.$emit('dxf-ready', { data: blob }))
      this.$emit('close')
    }
  },

  mounted () {
    this.dxfViewer = new DxfViewer(this.$refs.canvasContainer, this.options)
    const Subscribe = eventName => {
      this.dxfViewer.Subscribe(eventName, e => this.$emit('dxf-' + eventName, e))
    }
    for (const eventName of [
      'loaded', 'cleared', 'destroyed', 'resized',
      'pointerdown', 'pointerup', 'viewChanged', 'message'
    ]) { Subscribe(eventName) }
    // add watcher dxfUrl. if add watcher in section "watcher" - loading before mount
    this.$watch('dxfUrl', async (dxfUrl) => {
      if (dxfUrl !== null) {
        await this.Load(dxfUrl)
      } else {
        this.dxfViewer.Clear()
        this.error = null
        this.isLoading = false
        this.progress = null
      }
    }, { immediate: true })
  },

  destroyed () {
    this.dxfViewer.Destroy()
    this.dxfViewer = null
  }
}
</script>

<style scoped>

.canvasContainer {
    position: relative;
    width: 100%;
    height: calc(100vh - 32px);
    min-width: 100px;
    min-height: 100px;
}

</style>
