import * as fabric from 'fabric'

const typeEquip = {
  monitor: '\ue908',
  videowall: '\ue909',
  pc: '\ue90a',
  printer: '\ue90b',
  recorder: '\ue90c',
  storage: '\ue90d',
  coreRouter: '\ue90e',
  switch: '\ue90f',
  router: '\ue910',
  ups: '\ue911',
  upsDc: '\ue912',
  battery: '\ue913',
  light: '\ue914',
  rack: '\ue915',
  coupler: '\ue916',
  fiberOptic: '\ue917',
  junctionBox: '\ue91a',
  arrowFromPoint: '\ue918',
  arrowToPoint: '\ue919'
}

export default class LayoutEquip {
  constructor (objData = {}) {
    this.canvasObjects = [] // for canvas drawing
    this.requireNextClick = false // true - if necessary determinate more one vertex for object
    this.type = objData.type || 'monitor'
    this.site = objData.site
    this.typeOf = 'layoutEquip'
    this.id = objData.id
    this.zOrder = objData.zOrder
    this.icon = new fabric.Textbox(typeEquip[this.type], { //eslint-disable-line
      left: +objData.left || Infinity,
      top: +objData.top || Infinity,
      fill: objData.color || '#1976d2',
      fontFamily: 'icomoon',
      fontSize: objData.size || 48,
      editable: false,
      cursorWidth: 0,
      originX: 'center',
      originY: 'center',
      hoverCursor: 'pointer',
      selectable: true,
      evented: true,
      hasBorders: false,
      hasControls: false,
      isSelectable: true, // for set selectable obj on canvas
      opacity: 0.8,
      caching: false,
      textAlign: 'center',
      subTypeOf: 'icon',
      typeOf: this.typeOf,
      id: this.id
    })

    this.name = new fabric.Textbox(objData.name || '', {
      fontFamily: 'Roboto',
      fontSize: 12,
      editable: false,
      cursorWidth: 0,
      originX: 'center',
      originY: 'bottom',
      selectable: false,
      evented: false,
      hasBorders: false,
      hasControls: false,
      caching: false,
      textAlign: 'center',
      typeOf: this.typeOf,
      id: this.id
    })

    this.#setNameCoords()
    this.canvasObjects.push(this.icon, this.name)
  }

  // private method for set coords handleLine as line
  #setNameCoords = function () {
    this.name.set({
      left: this.icon.left,
      top: this.icon.top - this.icon.fontSize / 2 + 8
    }).setCoords()
  }

  is (elemFabric) {
    return (elemFabric === this.icon || elemFabric === this.name) && this
  }

  zooming (scale, point) { // point - point of zooming {x, y}, scale = zoomNew/zoomOld
    this.icon.set({
      left: point.x + scale * (this.icon.left - point.x),
      top: point.y + scale * (this.icon.top - point.y)
    }).setCoords()
    this.#setNameCoords()
  }

  panning (startPoint, endPoint) { // point - {x, y}
    this.icon.set({
      left: endPoint.x - startPoint.x + this.icon.left,
      top: endPoint.y - startPoint.y + this.icon.top
    }).setCoords()
    this.#setNameCoords()
  }

  createFirstClick (event) {
    const evt = event.pointer
    this.icon.set({
      left: evt.x,
      top: evt.y
    }).setCoords()
    this.#setNameCoords()
  }

  selected () {
    this.icon.set({ hoverCursor: 'move', opacity: 0.75 }).setCoords()
  }

  unselected () {
    this.icon.set({ hoverCursor: 'pointer', opacity: 1, width: 0 }).setCoords()
  }

  update () {
    this.icon.set({ width: 0 }).setCoords()
    this.#setNameCoords()
  }

  freeze () {
    this.icon.set({ selectable: false, evented: false })
  }

  unfreeze () {
    this.icon.set({ selectable: true, evented: true })
  }

  toStoreData () {
    return {
      left: this.icon.left,
      top: this.icon.top,
      type: this.type,
      color: this.icon.fill,
      size: this.icon.fontSize,
      name: this.name.text,
      zOrder: this.zOrder,
      site: this.site,
      typeOf: this.typeOf,
      id: this.id
    }
  }

  updateParamsFromStore (objData) {
    this.type = objData.type
    this.name.set({
      text: objData.name
    })
    console.log(this.name)
    this.icon.set({
      text: typeEquip[objData.type],
      fill: objData.color,
      fontSize: objData.size
    }).setCoords()
    this.#setNameCoords()
    this.zOrder = objData.zOrder
  }
}
