export const matrixSizeArray = {
  '16:9': [
    { name: '1/4', sizeX: 3.92 },
    { name: '1/3', sizeX: 5.23, default: true },
    { name: '1/2.9', sizeX: 5.41 },
    { name: '1/2.8', sizeX: 5.6 },
    { name: '1/2.7', sizeX: 5.81 },
    { name: '1/2.5', sizeX: 6.28 },
    { name: '1/2', sizeX: 6.97 },
    { name: '1/1.8', sizeX: 7.75 },
    { name: '1/1.2', sizeX: 11.62 }
  ],
  '4:3': [
    { name: '1/4', sizeX: 3.6 },
    { name: '1/3', sizeX: 4.8, default: true },
    { name: '1/2.9', sizeX: 4.97 },
    { name: '1/2.8', sizeX: 5.14 },
    { name: '1/2.7', sizeX: 5.33 },
    { name: '1/2.5', sizeX: 5.76 },
    { name: '1/2', sizeX: 6.4 },
    { name: '1/1.8', sizeX: 7.11 },
    { name: '1/1.2', sizeX: 10.67 }
  ]
}

export const matrixResolutionArray = {
  '16:9': [
    { name: 'nHD', resX: 640 },
    { name: 'FWVGA', resX: 848 },
    { name: 'qHD', resX: 960 },
    { name: 'HD 720p', resX: 1280 },
    { name: 'WXGA++', resX: 1600 },
    { name: 'Full HD 1080p', resX: 1920, default: true },
    { name: 'QWXGA', resX: 2048 },
    { name: 'WQXGA', resX: 2560 },
    { name: 'WQXGA+', resX: 3200 },
    { name: 'Ultra HD 2160p', resX: 3840 },
    { name: 'Ultra HD', resX: 4128 },
    { name: 'Super Hi-Vision', resX: 7680 }
  ],
  '4:3': [
    { name: 'QVGA', resX: 320 },
    { name: 'VGA', resX: 640 },
    { name: 'SVGA', resX: 800 },
    { name: 'XGA', resX: 1024, default: true },
    { name: 'XGA+', resX: 1152 },
    { name: 'SXGA+', resX: 1400 },
    { name: 'UXGA', resX: 1600 },
    { name: 'QXGA', resX: 2048 },
    { name: 'QUXGA', resX: 3200 },
    { name: 'HUXGA', resX: 6400 }
  ]
}

export const matrixTypeArray = [
  { type: 'box', descr: 'camera box', icon: 'camera-box' },
  { type: 'bullet', descr: 'camera bullet', icon: 'camera-bullet', default: true },
  { type: 'dome', descr: 'camera dome', icon: 'camera-dome' },
  { type: 'wedge', descr: 'camera wedge', icon: 'camera-wedge' },
  { type: 'eyeball', descr: 'camera eyeball', icon: 'camera-eyeball' },
  { type: 'cube', descr: 'camera cube', icon: 'camera-cube' },
  { type: 'speedDome', descr: 'camera speed dome', icon: 'camera-speed-dome' },
  { type: 'pinhole', descr: 'camera pinhole', icon: 'camera-pinhole' }
]
