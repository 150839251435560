import LayoutLine from './layoutLine'
import LayoutRect from './layoutRect'
import LayoutCircle from './layoutCircle'
import LayoutBrush from './layoutBrush'
import LayoutText from './layoutText'
import LayoutEquip from './layoutEquip'
import LayoutImg from './layoutImg'
import LimitsLine from './limitsLine'
import Tape from './tape'
import Camera from './camera'

export default class LayoutManager {
  list = []
  zOrder = {
    grid: 0,
    layoutImg: 100,
    layoutRect: 200,
    layoutLine: 300,
    layoutCircle: 400,
    layoutEquip: 450,
    layoutBrush: 500,
    layoutText: 600,
    camera: 700,
    limitsLine: 800,
    tape: 900
  }

  static instance

  static getInstance () {
    if (!LayoutManager.instance) LayoutManager.instance = new LayoutManager()
    return LayoutManager.instance
  }

  init () {
    this.list = []
  }

  find (el) {
    const obj = this.list.find(e => e.is(el))
    return obj
  }

  findById (id) {
    const obj = this.list.find(e => e.id === id)
    return obj
  }

  getTargetByID (id) { // target - obj on canvas
    const obj = this.list.find(e => e.id === id)
    return obj.canvasObjects.find(e => e.isSelectable === true)
  }

  create (dataObj, cb) { // from Canvas Editor - {typeOf}, from api - full object
    if (!dataObj.id) dataObj.id = Math.random().toString(16).slice(2)
    if (!dataObj.zOrder) dataObj.zOrder = this.zOrder[dataObj.typeOf]
    let obj = null
    switch (dataObj.typeOf) {
      case 'layoutLine': obj = new LayoutLine(dataObj); break
      case 'layoutRect': obj = new LayoutRect(dataObj); break
      case 'layoutCircle': obj = new LayoutCircle(dataObj); break
      case 'layoutBrush': obj = new LayoutBrush(dataObj); break
      case 'layoutText': obj = new LayoutText(dataObj); break
      case 'layoutEquip': obj = new LayoutEquip(dataObj); break
      case 'limitsLine': obj = new LimitsLine(dataObj); break
      case 'layoutImg': obj = new LayoutImg(dataObj, cb); break
      case 'camera': obj = new Camera(dataObj); obj.updateDataFoV(this.#getLimitsLines()); break
      case 'tape': obj = new Tape(dataObj); break
      default: throw Error('Object type incorrect')
    }
    if (obj) this.list.push(obj)
    if (obj.typeOf !== 'layoutImg') cb(obj)
  }

  setActiveObject (target) {
    const obj = this.find(target)
    obj.selected()
    return obj
  }

  panning (startPoint, endPoint) {
    this.list.forEach(el => el.panning(startPoint, endPoint))
    this.updateFoVs()
  }

  zooming (scale, point) {
    this.list.forEach(el => el.zooming(scale, point))
    this.updateFoVs()
  }

  update (target) {
    const obj = this.find(target)
    obj.update(target)
    if (target.typeOf === 'limitsLine') this.updateFoVs()
    return obj
  }

  freeze () {
    this.list.forEach(el => el.freeze())
  }

  unfreeze () {
    this.list.forEach(el => el.unfreeze?.())
  }

  delete (obj) {
    const idx = this.list.indexOf(obj)
    this.list.splice(idx, 1)
    if (obj.typeOf === 'limitsLine') this.updateFoVs()
  }

  createFirstClick (id, event) {
    const obj = this.findById(id)
    obj.createFirstClick(event)
  }

  createWaitNextClick (id, event) {
    const obj = this.findById(id)
    obj.createWaitNextClick(event)
    if (obj.typeOf === 'limitsLine') this.updateFoVs()
  }

  createNextClick (id, event) {
    const obj = this.findById(id)
    obj.createNextClick(event)
    if (obj.typeOf === 'limitsLine') this.updateFoVs()
  }

  updateFoVs () {
    this.list.filter(elem => elem.typeOf === 'camera').forEach(el => el.updateDataFoV(this.#getLimitsLines()))
  }

  recalcFoVs () {
    this.list.filter(elem => elem.typeOf === 'camera').forEach(el => el.recalcFoVs())
  }

  lightUpdate () {
    this.list.filter(elem => elem.typeOf === 'camera').forEach(el => el.updateLightColors())
  }

  #getLimitsLines = function () {
    return this.list.filter(elem => elem.typeOf === 'limitsLine').map(el => el.getSegmentCoords()).flat()
  }
}
