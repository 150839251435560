<template>
  <div id = "camlight" style="width: 430px" scrollable>
    <v-data-table
      class="mt-2"
      :headers="headers"
      :items="colors"
      hide-default-footer
      hide-default-header
      >
      <template #header="{ props }">
        <th v-for="(head,idx) in props.headers" :key="idx"><div class="text-body-2" style = "text-align: center">{{ $t(head.name) }}</div></th>
      </template>
      <template #item.name="{ item }">
        {{$t(item.name)}}
      </template>
      <template #item.color="{ item }">
        <color-picker
          :value="item.color"
          @input="changeLightColor($event, item)"
          align="center"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import colorPicker from './../../Editor-components/EditorColorPicker'
import { mapState } from 'vuex'
import { eventBus } from '../../../main'

export default {

  name: 'SettingsLight',

  components: {
    colorPicker
  },

  data: () => ({
    headers: [
      { name: 'settings light name', align: 'start', sortable: false, value: 'name', width: '80%' },
      { name: 'settings light color', align: 'center', sortable: false, value: 'color', width: '20%' }
    ]
  }),

  computed: {
    ...mapState({
      lightColors: state => state.editorStore.lightColors // obj {IR, WL}
    }),

    colors () {
      const arr = Object.entries(this.lightColors).map(el => ({ prop: el[0], color: el[1] }))
      const names = {
        IR: 'camera light IR',
        WL: 'camera light white'
      }
      return arr.map(el => ({ ...el, name: names[el.prop] }))
    },

    colorsTrigger () {
      return JSON.stringify(this.lightColors)
    }
  },

  methods: {

    changeLightColor (value, item) {
      this.$store.commit('UPDATE_LIGHT_COLOR', { [item.prop]: value })
    }
  },

  watch: {
    colorsTrigger () {
      eventBus.$emit('need-cameras-light-update')
    }
  },

  updated () {
  },

  mounted () {
  }
}
</script>

<style scoped>

</style>
