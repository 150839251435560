<template>
  <div id="grids" style = "width: 600px">
    <settings-row>
      <span class="text-body-2">{{ $t('settings_grid') }}</span>
      <template #primary>
        <span class="text-body-2">{{ $t('settings_grid_primary') }}</span>
      </template>
      <template #secondary>
        <span class="text-body-2">{{ $t('settings_grid_secondary') }}</span>
      </template>
    </settings-row>

    <settings-row>
      <span class="text-body-2">{{ $t('settings_grid_show') }}</span>
      <template #primary>
        <div class="d-flex justify-center">
          <v-switch
            v-model="isShow1"
            dense
            class="my-0"
            hide-details
          />
        </div>
      </template>
      <template #secondary>
        <div class="d-flex justify-center">
          <v-switch
            v-model="isShow2"
            dense
            class="my-0"
            :disabled="!isShow1"
            hide-details
          />
        </div>
      </template>
    </settings-row>

    <settings-row>
      <span class="text-body-2">{{ $t('settings_grid_view') }}</span>
      <template #primary>
        <v-radio-group
          v-model="type1"
          row
          dense
          hide-details
          class="my-0 mx-1"
          style="max-width: 120px"
          >
          <v-radio :value="0">
            <template #label><v-icon small class="mesh_line"/></template>
          </v-radio>
          <v-radio :value="1">
            <template #label><v-icon small class="mesh_cross"/></template>
          </v-radio>
        </v-radio-group>
      </template>
      <template #secondary>
        <v-radio-group
          v-model="type2"
          row
          dense
          hide-details
          style="max-width: 120px"
          class = "ma-0 pa-0"
          >
          <v-radio :value="0">
            <template #label><v-icon small class="mesh_line"/></template>
          </v-radio>
          <v-radio :value = "1">
            <template #label><v-icon small class="mesh_cross"/></template>
          </v-radio>
        </v-radio-group>
      </template>
    </settings-row>

    <settings-row>
      <span class="text-body-2">{{ $t('settings_grid_color') }}</span>
      <template #primary>
        <color-picker v-model="color1"/>
      </template>
      <template #secondary>
        <color-picker v-model="color2"/>
      </template>
    </settings-row>

    <settings-row>
      <span class="text-body-2">{{ $t('settings_grid_thickness') }}</span>
      <template #primary>
        <v-text-field
          v-model="thickness1"
          class="text-body-2 small-input"
          :suffix="$t('px')"
          flat
          dense
          type="number"
          :rules = rules.thickness
        />
      </template>
      <template #secondary>
        <v-text-field
          v-model="thickness2"
          class="text-body-2 small-input"
          :suffix="$t('px')"
          flat
          dense
          type="number"
          :rules = rules.thickness
        />
      </template>
    </settings-row>

    <settings-row>
      <span class="text-body-2">{{ $t('settings_grid_opacity') }}</span>
      <template #primary>
        <v-text-field
          v-model="opacity1"
          class="text-body-2 small-input"
          suffix="%"
          flat
          dense
          type="number"
          step="10"
          :rules = rules.opacity
        />
      </template>
      <template #secondary>
        <v-text-field
          v-model="opacity2"
          class="text-body-2 small-input"
          suffix="%"
          flat
          dense
          type="number"
          step="10"
          :rules = rules.opacity
        />
      </template>
    </settings-row>

    <settings-row>
      <span class="text-body-2">{{ $t('settings_grid_unit') }}</span>
      <template #primary>
        <v-text-field
          :value="unit1"
          class="text-body-2 small-input"
          :suffix="$t('m')"
          flat
          dense
          disabled
        />
      </template>
      <template #secondary>
        <v-text-field
          :value="unit2"
          class="text-body-2 small-input"
          :suffix="$t('m')"
          flat
          dense
          disabled
        />
      </template>
    </settings-row>
    <hr>
    <settings-row>
      <span class="text-body-2">{{ $t('settings_grid_base_unit') }}</span>
      <template #primary>
        <v-text-field
          v-model = "baseUnit"
          class="text-body-2 small-input"
          :suffix="$t('m')"
          flat
          dense
          type="number"
          step="1"
          :rules = rules.unit
        />
      </template>
      <template #secondary>
        <v-btn
          @click = "setGridDefaultBaseUnit()"
          plain
          text
          small
          class = "text-initial"
          >
          {{$t('settings_grid_by_default')}}
        </v-btn>
      </template>
    </settings-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ColorPicker from './../../Editor-components/EditorColorPicker.vue'
import SettingsRow from './SettingsRow'

export default {

  name: 'EditorSettingsGrids',

  components: {
    ColorPicker,
    SettingsRow
  },

  data: function () {
    return {}
  },

  computed: {
    ...mapState({
      grid1: state => state.editorStore.grids.primaryGrid,
      grid2: state => state.editorStore.grids.secondaryGrid,
      gridBaseUnit: state => state.editorStore.grids.baseUnit
    }),

    isShow1: {
      get () {
        return this.grid1.display
      },
      set (value) {
        this.$store.commit('UPDATE_PRIMARY_GRID', { display: Boolean(value) })
        value && this.$store.commit('UPDATE_SECONDARY_GRID', { display: Boolean(value) })
      }
    },

    isShow2: {
      get () {
        return this.grid2.display
      },
      set (value) {
        this.$store.commit('UPDATE_SECONDARY_GRID', { display: Boolean(value) })
      }
    },

    type1: {
      get () {
        return +this.grid1.type
      },
      set (value) {
        this.$store.commit('UPDATE_PRIMARY_GRID', { type: value })
      }
    },

    type2: {
      get () {
        return +this.grid2.type
      },
      set (value) {
        this.$store.commit('UPDATE_SECONDARY_GRID', { type: value })
      }
    },

    color1: {
      get () {
        return this.grid1.color
      },
      set (value) {
        this.$store.commit('UPDATE_PRIMARY_GRID', { color: value })
      }
    },

    color2: {
      get () {
        return this.grid2.color
      },
      set (value) {
        this.$store.commit('UPDATE_SECONDARY_GRID', { color: value })
      }
    },

    thickness1: {
      get () {
        return this.grid1.thickness
      },
      set (value) {
        this.rules.thickness.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
        this.$store.commit('UPDATE_PRIMARY_GRID', { thickness: +value })
      }
    },

    thickness2: {
      get () {
        return this.grid2.thickness
      },
      set (value) {
        this.rules.thickness.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
        this.$store.commit('UPDATE_SECONDARY_GRID', { thickness: +value })
      }
    },

    opacity1: {
      get () {
        return this.grid1.opacity * 100
      },
      set (value) {
        this.rules.opacity.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
        this.$store.commit('UPDATE_PRIMARY_GRID', { opacity: +value / 100 })
      }
    },

    opacity2: {
      get () {
        return this.grid2.opacity * 100
      },
      set (value) {
        this.rules.opacity.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
        this.$store.commit('UPDATE_SECONDARY_GRID', { opacity: +value / 100 })
      }
    },

    unit1 () {
      return (this.grid1.unit / 1000).toFixed(1)
    },

    unit2 () {
      return (this.grid2.unit / 1000).toFixed(1)
    },

    baseUnit: {
      get () {
        return (this.gridBaseUnit / 1000).toFixed(1)
      },
      set (value) {
        if (this.rules.unit.map(f => f(value)).every(el => (typeof el === 'boolean') && el)) {
          this.$store.commit('UPDATE_GRID_BASEUNIY', +value * 1000)
        }
      }
    },

    rules () {
      return {
        thickness: [value => (Number.isInteger(+value) && (+value > 0) && (+value < 11)) || (this.$t('settings_grid_out_of_range') + ' 0-10')],
        unit: [value => (!isNaN(value) && (+value >= 1) && (+value < 21)) || (this.$t('settings_grid_out_of_range') + ' 1-20')],
        opacity: [value => (!isNaN(value) && (+value >= 1) && (+value <= 100)) || (this.$t('settings_grid_out_of_range') + ' 1-100')]
      }
    }
  },

  methods: {
    setGridDefaultBaseUnit () {
      this.$store.commit('UPDATE_GRID_BASEUNIY', +5000)
    }
  },

  watch: {

  },

  updated () {
  },

  mounted () {
  }
}
</script>

<style scoped>
.small-input {
  width: 60px !important
}

.text-initial {
  text-transform: initial !important;
}

>>> .v-radio {
  margin-right: 0px !important;
}

>>> .v-input--radio-group__input
{
  justify-content: space-between;
}
</style>
