import * as fabric from 'fabric'

const HANDLE_RADIUS = 6
const HANDLE_COLOR = '#1976d2'
const BRUSH_PARAMS = {
  hoverCursor: 'pointer',
  cornerColor: HANDLE_COLOR,
  cornerSize: HANDLE_RADIUS,
  cornerStyle: 'circle',
  transparentCorners: false,
  hasBorders: true,
  borderColor: HANDLE_COLOR,
  hasControls: true,
  perPixelTargetFind: true
}

export default class LayoutBrush {
  constructor (objData = {}) {
    this.canvasObjects = [] // for canvas drawing
    this.site = objData.site
    this.typeOf = 'layoutBrush'
    this.id = objData.id
    this.zOrder = objData.zOrder
    this.brush = new fabric.Path(objData.path || [], {
      left: objData.left || Infinity,
      top: objData.top || Infinity,
      stroke: objData.stroke || '#000000',
      strokeWidth: objData.strokeWidth || 1,
      opacity: objData.opacity || 1,
      angle: objData.angle || 0,
      fill: 'transparent',
      originX: 'center',
      originY: 'center',
      selectable: true,
      evented: true,
      ...BRUSH_PARAMS,
      isSelectable: true, // for set selectable obj on canvas
      typeOf: this.typeOf,
      id: this.id
    })
    this.brush.setCoords()
    this.canvasObjects.push(this.brush)
  }

  is (elemFabric) {
    return (elemFabric === this.brush) && this
  }

  zooming (scale, point) { // point - point of zooming {x, y}, scale = zoomNew/zoomOld
    this.brush.set({
      left: point.x + scale * (this.brush.left - point.x),
      top: point.y + scale * (this.brush.top - point.y)
    }).setCoords()
    this.brush.path.map(el => {
      el[1] = point.x + scale * (el[1] - point.x)
      el[2] = point.y + scale * (el[2] - point.y)
      if (el[3]) el[3] = point.x + scale * (el[3] - point.x)
      if (el[4]) el[4] = point.y + scale * (el[4] - point.y)
    })
    const dims = this.brush._calcDimensions()
    this.brush.set({
      width: dims.width,
      height: dims.height,
      pathOffset: {
        x: dims.left + dims.width / 2,
        y: dims.top + dims.height / 2
      },
      dirty: true
    })
    this.brush.setCoords()
  }

  panning (startPoint, endPoint) { // point - {x, y}
    this.brush.set({
      left: endPoint.x - startPoint.x + this.brush.left,
      top: endPoint.y - startPoint.y + this.brush.top
    }).setCoords()
  }

  setPath (eventObj) {
    this.brush.set({
      originX: 'left',
      originY: 'top'
    }).setCoords()
    this.brush = Object.assign(this.brush, eventObj)
    this.brush.set({
      originX: 'center',
      originY: 'center',
      left: this.brush.left + this.brush.width / 2,
      top: this.brush.top + this.brush.height / 2,
      ...BRUSH_PARAMS
    }).setCoords()
  }

  selected () {
    this.brush.set({ hoverCursor: 'move' })
  }

  unselected () {
    this.brush.set({ hoverCursor: 'pointer' })
  }

  update () {
  }

  freeze () {
    this.brush.set({ selectable: false, evented: false })
  }

  unfreeze () {
    this.brush.set({ selectable: true, evented: true })
  }

  toStoreData () {
    return {
      path: this.brush.path,
      left: this.brush.left,
      top: this.brush.top,
      angle: +parseFloat(this.brush.angle).toFixed(0),
      stroke: this.brush.stroke,
      strokeWidth: this.brush.strokeWidth,
      opacity: this.brush.opacity,
      site: this.site,
      zOrder: this.zOrder,
      typeOf: this.typeOf,
      id: this.id
    }
  }

  updateParamsFromStore (objData) {
    this.brush.set({
      stroke: objData.stroke,
      strokeWidth: objData.strokeWidth,
      opacity: objData.opacity,
      angle: objData.angle
    }).setCoords()
    this.zOrder = objData.zOrder
  }
}
