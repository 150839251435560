import * as fabric from 'fabric'
import i18n from '~/helpers/i18n'

function makeGrid (canvasData, grids) {
  const width = canvasData.width // canvas width in px
  const height = canvasData.height // canvas height in px
  const zoom = canvasData.zoom // px/m
  const grid1 = grids.primaryGrid
  const grid2 = grids.secondaryGrid
  const rect = new fabric.Rect({ left: 0, top: 0, fill: '', stroke: '#eee', width: width - 1, height: height - 1 })

  // lines of grid
  const grid = new fabric.Group([], {
    top: 0,
    left: 0,
    selectable: false,
    evented: false,
    caching: false,
    typeOf: 'grid'
  })

  const Xgc0 = -canvasData.centerGlobalCoords.x / zoom // X position point (0,0) of Canvas in Global coords - px
  const Ygc0 = canvasData.centerGlobalCoords.y / zoom // X position point (0,0) of Canvas in Global coords - px

  const offsetX = (Xgc0 <= 0) ? (-Xgc0 % grid1.unit) * zoom : (grid1.unit - Xgc0 % grid1.unit) * zoom
  const offsetY = (Ygc0 > 0) ? (Ygc0 % grid1.unit) * zoom : (Ygc0 % grid1.unit + grid1.unit) * zoom
  let line
  let lineSegment
  const crossSize = 5
  const primaryLineParams = {
    stroke: grid1.color,
    strokeWidth: grid1.thickness,
    opacity: grid1.opacity,
    strokeDashArray: (grid1.type === 1) ? [crossSize, grid1.unit * zoom - 2 * crossSize, crossSize, 0] : null,
    selection: false,
    evented: false,
    originX: 'center',
    originY: 'center',
    typeOf: 'grid'
  }
  const secondaryLineParams = {
    stroke: grid2.color,
    strokeWidth: grid2.thickness,
    opacity: grid2.opacity,
    selection: false,
    evented: false,
    originX: 'center',
    originY: 'center',
    typeOf: 'grid'
  }

  const secondaryLineParams1 = {
    ...secondaryLineParams,
    strokeDashArray: (grid2.type === 1) ? [
      crossSize, grid2.unit * zoom - 2 * crossSize,
      crossSize, 0
    ] : null
  }

  const secondaryLineParams2 = {
    ...secondaryLineParams,
    strokeDashArray: (grid2.type === 1) ? [
      0, grid2.unit * zoom - crossSize, // draw, empty
      2 * crossSize, grid2.unit * zoom - 2 * crossSize,
      2 * crossSize, grid2.unit * zoom - 2 * crossSize,
      2 * crossSize, grid2.unit * zoom - 2 * crossSize,
      2 * crossSize, grid2.unit * zoom - crossSize
    ] : null
  }
  if (grid1.display) {
    // primary grid
    // vertical lines
    for (let i = 0; offsetX + i * grid1.unit * zoom < width; i++) {
      const x = offsetX + i * grid1.unit * zoom
      const y = offsetY < height ? offsetY : height + 10
      if (checkXCoord(x)) {
        line = new fabric.Line([x, y, x, height], primaryLineParams)
        lineSegment = new fabric.Line([x, y, x, 0], primaryLineParams)
        grid.add(line, lineSegment)
      }
    }
    // horizontal lines
    for (let i = 0; offsetY + i * grid1.unit * zoom < height; i++) {
      const x = offsetX < width ? offsetX : width + 10
      const y = offsetY + i * grid1.unit * zoom
      if (checkYCoord(y)) {
        line = new fabric.Line([x, y, width, y], primaryLineParams)
        lineSegment = new fabric.Line([x, y, 0, y], primaryLineParams)
        grid.add(line, lineSegment)
      }
    }
  }

  if ((grid2.display) && (grid1.unit * zoom > 100)) {
    // secondary grid
    if (grid1.type === 0) {
      // vertical lines
      for (let i = -4; offsetX + i * grid2.unit * zoom < width; i++) {
        if (i % 5 !== 0) {
          const x = offsetX + i * grid2.unit * zoom
          const y = offsetY < height ? offsetY : height + (offsetY - height) % (grid2.unit * zoom)
          if (checkXCoord(x)) {
            line = new fabric.Line([x, y, x, height], secondaryLineParams2)
            lineSegment = new fabric.Line([x, y, x, 0], secondaryLineParams2)
            grid.add(line, lineSegment)
          }
        }
      }
      // horizontal lines
      for (let i = -4; offsetY + i * grid2.unit * zoom < height; i++) {
        if (i % 5 !== 0) {
          const x = offsetX < width ? offsetX : width + (offsetX - width) % (grid2.unit * zoom)
          const y = offsetY + i * grid2.unit * zoom
          if (checkYCoord(y)) {
            line = new fabric.Line([x, y, width, y], secondaryLineParams2)
            lineSegment = new fabric.Line([x, y, 0, y], secondaryLineParams2)
            grid.add(line, lineSegment)
          }
        }
      }
    } else {
      // vertical lines
      for (let i = -4; offsetX + i * grid2.unit * zoom < width; i++) {
        const x = offsetX + i * grid2.unit * zoom
        const y = offsetY < height ? offsetY : height + (offsetY - height) % (grid2.unit * zoom)
        if (checkXCoord(x)) {
          line = new fabric.Line([x, y, x, height], (i % 5 !== 0) ? secondaryLineParams1 : secondaryLineParams2)
          lineSegment = new fabric.Line([x, y, x, 0], (i % 5 !== 0) ? secondaryLineParams1 : secondaryLineParams2)
          grid.add(line, lineSegment)
        }
      }
      // horizontal lines
      for (let i = -4; offsetY + i * grid2.unit * zoom < height; i++) {
        const x = offsetX < width ? offsetX : width + (offsetX - width) % (grid2.unit * zoom)
        const y = offsetY + i * grid2.unit * zoom
        if (checkYCoord(y)) {
          line = new fabric.Line([x, y, width, y], (i % 5 !== 0) ? secondaryLineParams1 : secondaryLineParams2)
          lineSegment = new fabric.Line([x, y, 0, y], (i % 5 !== 0) ? secondaryLineParams1 : secondaryLineParams2)
          grid.add(line, lineSegment)
        }
      }
    }
  }

  const scaleLineParams = {
    stroke: '#000000',
    strokeWidth: 1,
    opacity: 1,
    selection: false,
    evented: false,
    originX: 'center',
    originY: 'center',
    typeOf: 'grid'
  }
  const scaleLineMain = new fabric.Line([20, height - 20, getLength().px + 20, height - 20], scaleLineParams)
  const scaleLineLimit1 = new fabric.Line([20, height - 15, 20, height - 25], scaleLineParams)
  const scaleLineLimit2 = new fabric.Line([getLength().px + 20, height - 15, getLength().px + 20, height - 25], scaleLineParams)
  const scaleLineText = new fabric.IText(String(getLength().m + ' ' + i18n.t('m')), {
    left: (20 + getLength().px + 20) / 2,
    top: height - 20,
    width: 0,
    fontFamily: 'Roboto',
    fontSize: 12,
    originX: 'center',
    originY: 'bottom',
    typeOf: 'grid'
  })
  grid.add(scaleLineMain, scaleLineLimit1, scaleLineLimit2, scaleLineText)
  if (checkXCoord(canvasData.centerGlobalCoords.x) && checkYCoord(canvasData.centerGlobalCoords.y)) {
    const center = new fabric.Circle({
      left: canvasData.centerGlobalCoords.x,
      top: canvasData.centerGlobalCoords.y,
      fill: 'red',
      radius: 2,
      originX: 'center',
      originY: 'center',
      typeOf: 'grid'
    })
    grid.add(center)
  }
  grid.add(rect)
  return grid

  function checkXCoord (x) {
    return (x >= 0 && x <= width) ? 1 : 0
  }
  function checkYCoord (y) {
    return (y >= 0 && y <= height) ? 1 : 0
  }

  function getLength () {
    let lengthPx = null
    let baseM = [1, 2, 3, 5] // m
    let idx = -1
    while (!lengthPx) {
      idx = baseM.findIndex(el => el >= 1 / zoom * 100 / 1000) // *100px / 1000 mm
      if (idx !== -1) lengthPx = baseM[idx] * zoom * 1000
      baseM = baseM.map(el => el * 10)
    }
    return { px: lengthPx, m: baseM[idx] / 10 }
  }
}

export { makeGrid }
