<template>
  <v-container fill-height fluid class="grayscale overflow-hidden">
    <v-row class = "justify-center">
      <v-col align = "center">
        <v-card
          width="350px"
          class="grayscale ma-0 px-2 py-0 elevation-0"
        >
          <v-card-text>
            <div class="rotate-scene">
              <div class="rotate-card">

                  <login-frame class="rotate-card__face rotate-card__face--front login">
                    <template #login-title>
                      {{$t('welcome')}}
                    </template>
                    <template #login-body>
                      <v-form ref="loginForm" v-model="validLoginForm">
                        <v-text-field
                          v-model="login"
                          :label="$t('email')"
                          :rules="emailRules"
                          validate-on-blur
                          :name="Math.random()"
                        />
                        <v-text-field
                          v-model="password"
                          :label="$t('password')"
                          :rules="passwordRules"
                          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="showPassword ? 'text' : 'password'"
                          @click:append="showPassword = !showPassword"
                          autocomplete="new-password"
                        />
                        <v-btn
                          :disabled="!validLoginForm || !termsAgree"
                          class="primary elevation-0 my-4"
                          @click=sendAuth()
                          >
                          {{$t('login')}}
                        </v-btn>
                      </v-form>
                    </template>

                    <template #login-actions>
                      <v-dialog
                        v-model="recoverDialog"
                        fullscreen
                        hide-overlay
                        transition="dialog-bottom-transition"
                        >
                        <template #activator="{ on, attrs }">
                          <span
                            class="text-caption primary--text clickable"
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{$t('forgot_password')}}
                          </span>
                        </template>

                        <v-toolbar class="elevation-0">
                          <v-spacer/>
                          <v-btn icon @click="recoverDialog = false; forgotPasswordEmail = ''">
                            <v-icon large>mdi-close</v-icon>
                          </v-btn>
                        </v-toolbar>
                        <v-card elevation="0" align="center">
                          <v-form ref="RecoverMailForm" v-model="validRecoverEmail">
                            <v-card
                              class="text-center elevation-0"
                              width="350px"
                            >
                              <v-card-title class="text-h5 justify-center">
                                {{$t('forgot_password')}}
                              </v-card-title>
                                {{$t('forgot_password_body')}}
                              <v-text-field
                                v-model="forgotPasswordEmail"
                                autofocus
                                :label="$t('email')"
                                :rules="emailRules"
                                validate-on-blur
                                />
                              <v-card-actions class="justify-center">
                                <v-btn
                                  color="primary elevation-0"
                                  :disabled="!validRecoverEmail"
                                  @click=sendRecoverEmail()
                                  >
                                  {{$t('recover')}}
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-form>
                        </v-card>
                      </v-dialog>
                      <v-spacer/>
                      <span class="d-flex align-self-center text-caption primary--text clickable" @click=rotateCard()>
                        {{$t('register')}}
                        <v-icon small color="primary">mdi-menu-right</v-icon>
                      </span>
                    </template>
                  </login-frame>

                  <login-frame class="rotate-card__face rotate-card__face--back reg noevent">
                    <template #login-title>{{$t('join')}}</template>

                    <template #login-body>
                      <v-form ref="regForm" v-model="validRegForm">
                        <v-text-field
                          v-model="email"
                          :label="$t('your-email')"
                          :rules="emailRules"
                          validate-on-blur
                        />
                        <v-text-field
                          v-model="createdPassword"
                          :label="$t('create-password')"
                          :rules="passwordRules"
                          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="showPassword ? 'text' : 'password'"
                          @click:append="showPassword = !showPassword"
                          autocomplete="off"
                        />
                        <v-btn
                          class="primary elevation-0 my-4"
                          :disabled="!validRegForm || !termsAgree"
                          @click=sendReg()
                          >
                          {{$t('register')}}
                        </v-btn>
                      </v-form>
                    </template>

                    <template #login-actions>
                      <v-spacer/>
                      <span class="d-flex align-self-center text-caption primary--text clickable" @click=rotateCard()>
                        {{$t('have-account')}}
                        <v-icon small color="primary">mdi-menu-right</v-icon>
                      </span>
                    </template>
                  </login-frame>

              </div>
            </div>
            <div class = "my-2 pa-0 d-flex justify-center align-center">
              <v-checkbox
                v-model = "termsAgree"
                hide-details
                class = "ma-0 pa-0 shrink"
              />
              <span class = "text-caption">{{$t('agree terms of use')}}
                <v-dialog
                  width = "600"
                  scrollable
                  v-model = "dialogTerms"
                  >
                  <template #activator = "{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      class = "primary--text"
                    >
                      {{$t('terms of use')}}
                    </span>
                  </template>
                  <terms-of-use
                    @input = "dialogTerms = false; termsAgree = true"
                  />
                </v-dialog>
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      multi-line
      text
      elevation="4"
      :color = "snackbarColor"
      >
      {{ snackbarMessage }}
    </v-snackbar>
  </v-container>

</template>

<script>
import LoginFrame from '../components/lib/srvLoginFrame'
import TermsOfUse from '../components/Toolbar-component/TBMore/TermsOfUse.vue'
import { mapActions } from 'vuex'

export default {
  name: 'Login',

  components: {
    LoginFrame,
    TermsOfUse
  },

  data: () => ({
    validLoginForm: false,
    validRegForm: false,
    validRecoverEmail: false,
    showPassword: false,
    login: '',
    password: '',
    email: '',
    createdPassword: '',
    recoverDialog: false,
    forgotPasswordEmail: '',
    snackbar: false,
    snackbarMessage: '',
    snackbarColor: 'info',
    termsAgree: true,
    dialogTerms: false
  }),

  methods: {

    ...mapActions([
      'SET_AUTH_TO_STATE',
      'POST_REG',
      'FORGOT_PASSWORD'
    ]),

    async sendAuth () {
      await this.SET_AUTH_TO_STATE({ email: this.login, password: this.password })
      if (!this.$store.state.auth.isAuth) {
        this.snackbarMessage = this.$t('incorrect-login-data')
        this.snackbarColor = 'error'
        this.snackbar = true
      } else {
        this.$router.push({ name: 'projects' })
      }
    },

    async sendReg () {
      try {
        await this.POST_REG({ email: this.email, password: this.createdPassword })
        this.snackbarMessage = this.$t('registration message ok')
        this.snackbarColor = 'info'
        this.rotateCard()
      } catch (err) {
        this.snackbarMessage = this.$t('registration message error') + ': ' + this.$t(err.response.data.error.code)
        this.snackbarColor = 'error'
      }
      this.snackbar = true
    },

    rotateCard () {
      this.$el.querySelector('.rotate-card').classList.toggle('is-flipped')
      this.$el.querySelector('.login').classList.toggle('noevent')
      this.$el.querySelector('.reg').classList.toggle('noevent')
      this.$refs.loginForm.resetValidation()
      this.$refs.regForm.resetValidation()
      this.login = ''
      this.password = ''
      this.email = ''
      this.createdPassword = ''
    },

    async sendRecoverEmail () {
      try {
        await this.FORGOT_PASSWORD({ email: this.forgotPasswordEmail })
        this.snackbarMessage = this.$t('forgot password message ok')
        this.snackbarColor = 'info'
      } catch (err) {
        this.snackbarMessage = this.$t('forgot password message error') + ': ' + this.$t(err.response.data.error.code)
        this.snackbarColor = 'error'
      }
      this.recoverDialog = false
      this.login = ''
      this.password = ''
      this.forgotPasswordEmail = ''
      this.$refs.loginForm.resetValidation()
      this.snackbar = true
    }

  },

  computed: {
    emailRules () {
      return [
        // v => !!v || this.$t('email-require'),
        v => /.+@.+\..+/.test(v) || this.$t('email-must-be-valid')
      ]
    },

    passwordRules () {
      return [
        v => !!v || this.$t('password-require')
      ]
    }
  }
}
</script>

<style scoped>

.rotate-scene {
  width: 100%;
  height: 300px;
  margin: 0;
  perspective: 600px;
}

.rotate-card {
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
  position: relative;
}

.is-flipped {
  transform: rotateY(180deg);
}

.noevent {
  pointer-events: none;
}

.rotate-card__face {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.rotate-card__face--back {
  transform: rotateY(180deg);
}

.clickable {
  cursor: pointer;
}

.wrapper-btn-messege {
  height: 48px;
}

</style>
