import { render, staticRenderFns } from "./SharedBlock.vue?vue&type=template&id=442fcc35&scoped=true&"
import script from "./SharedBlock.vue?vue&type=script&lang=js&"
export * from "./SharedBlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "442fcc35",
  null
  
)

export default component.exports