<template>
        <v-menu offset-y style = "z-index: 152;">
        <template #activator = "{ on, attrs }">
          <v-btn
            plain
            v-bind="attrs"
            v-on="on"
          >
            {{lang}}
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group
          v-model="currentLang"
          color="primary"
          >
            <v-list-item
              v-for="(item, index) in langList"
              :key="index"
              link
              @click="lang = item.value"
            >
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LangSwitch',

  components: {
  },

  data: () => ({

  }),

  computed: {

    ...mapState([
      'locale'
    ]),

    langList () {
      return [
        { title: 'EN', value: 'en' },
        { title: 'RU', value: 'ru' }
      ]
    },

    lang: {
      get () {
        return this.locale
      },
      set (newValue) {
        this.$store.commit('UPDATE_LOCALE', newValue) // without action
        document.documentElement.setAttribute('lang', this.$store.state.locale)
      }
    },

    currentLang: {
      get () {
        return this.langList.findIndex(e => e.value === this.lang)
      },
      set () { }
    }
  },

  methods: {

  }
}
</script>
