import axios from 'axios'
import createReport from 'docx-templates'
import i18n from '~/helpers/i18n'
import { matrixSizeArray } from '~/assets/cameraParams'
import { calcStorage, checkStorageBandwidth, checkStorageCapacity } from '~/components/lib/calcStorageFunc'

async function getReport (sections, info, data, imgViews) {
  // cameras section
  const siteTableTitle = {
    name: i18n.t('report_cameras_table_title_name'),
    camQty: i18n.t('report_cameras_table_title_qty'),
    total: i18n.t('report_cameras_table_title_total')
  }

  const camTypesTableTitle = {
    name: i18n.t('report_camera_type_name'),
    descr: i18n.t('report_camera_type_descr'),
    qty: i18n.t('report_camera_type_qty')
  }

  const camTypes = getCameraTypes(data.cameras)

  const camTableTitle = {
    name: i18n.t('camera name'),
    type: i18n.t('camera type'),
    matrixSize: i18n.t('camera matrix size'),
    resolution: i18n.t('camera matrix resolution'),
    ar: i18n.t('camera matrix aspect ratio'),
    focal: i18n.t('camera focal'),
    angles: i18n.t('camera viewing angles') + ' ' + i18n.t('camera viewing angles hor vert')
  }

  const sites = data.sites.map((site, idx) => ({
    ...site,
    num: idx + 1,
    camQty: data.cameras.filter(cam => cam.site === site.id).length,
    cameras: getCamerasParams(data.cameras.filter(cam => cam.site === site.id))
  }))

  function getCamerasParams (cameras) {
    return cameras.length !== 0
      ? cameras.map((cam, idx) => ({
        num: idx + 1,
        name: cam.name,
        type: i18n.t('camera' + ' ' + cam.type),
        focal: cam.focal + ' ' + i18n.t('mm'),
        ar: cam.AR,
        res: getCamResolution(cam.resX, cam.AR).res,
        resTotal: getCamResolution(cam.resX, cam.AR).total + ' ' + i18n.t('mpx'),
        matrixSize: getMatrixSize(cam.sizeX, cam.AR, cam.matrixUserDef),
        anglesUserDef: cam.anglesUserDef,
        angleA: cam.anglesUserDef ? cam.anglesUserDef.alpha : getAlpha(cam.sizeX, cam.focal),
        angleB: cam.anglesUserDef ? cam.anglesUserDef.beta : getBeta(cam.sizeX, cam.focal, cam.AR),
        anglesCalc: cam.anglesUserDef ? false : i18n.t('camera viewing angles calc'),
        lightIR: cam.lightIR,
        lightWL: cam.lightWL
      }))
      : []
  }

  function getCameraTypes (cameras) {
    const cams = getCamerasParams(cameras)
    const camTypes = []
    cams.forEach(cam => {
      const key =
      cam.type +
      cam.focal +
      cam.matrixSize +
      cam.res +
      cam.resTotal +
      cam.ar +
      (cam.lightIR ? cam.lightIR : 0) +
      (cam.lightWL ? cam.lightWL : 0) +
      ((cam.anglesUserDef ? cam.anglesUserDef.alpha + '' + cam.anglesUserDef.beta : ''))

      const camTypesIdx = camTypes.findIndex(type => type.key === key)
      if (camTypesIdx !== -1) {
        camTypes[camTypesIdx].qty += 1
      } else {
        camTypes.push({
          key,
          num: camTypes.length + 1,
          name: i18n.t('camera type user def') + (camTypes.length + 1),
          descr:
            cam.matrixSize + ', ' +
            cam.resTotal + ' (' + cam.res + ' ' + i18n.t('px') + ', ' + cam.ar + '), ' +
            (i18n.t('camera focal').toLowerCase()) + ': ' + cam.focal + ', ' +
            (i18n.t('camera viewing angles (user)').toLowerCase()) +
            (cam.anglesUserDef ? '' : (' (' + i18n.t('camera viewing angles calc') + ')')) + ': ' +
              cam.angleA + '\u00b0' + ' (' + i18n.t('camera horizontal angle').toLowerCase() + ') / ' +
              cam.angleB + '\u00b0' + ' (' + i18n.t('camera vertical angle').toLowerCase() + '), ' +
            (cam.type).toLowerCase() +
            ((cam.lightIR || cam.lightWL)
              ? ', ' +
                (i18n.t('camera light').toLowerCase()) + ': ' +
                (cam.lightIR ? i18n.t('camera light IR') + ' ' + cam.lightIR + ' ' + i18n.t('m') + ' / ' : '') +
                (cam.lightWL ? i18n.t('camera light white').toLowerCase() + ' ' + cam.lightWL + ' ' + i18n.t('m') : '')
              : ''
            ),
          qty: 1
        })
      }
    })
    return camTypes
  }

  // end cameras section
  // views section
  const viewsTableTitle = {
    name: i18n.t('settings zone name'),
    color: i18n.t('settings zone color'),
    density: i18n.t('settings zone px-m') + ', ' + i18n.t('px') + '/' + i18n.t('m')
  }
  const views = imgViews.map(el => ({
    name: data.sites.find(site => site.id === el.siteId).name + '. ' + (el.name === 'current' ? i18n.t('project_report_current') : el.name),
    img: { width: 16.5 * el.ar < 23.5 ? 16.5 * el.ar : 23.5, height: 16.5 * el.ar < 23.5 ? 16.5 : 23.5 / el.ar, rotation: -90, data: el.img, extension: '.png' }
  }))

  // end views section
  // storage section
  const storSumTableTitle = { // summary table
    group: i18n.t('storage group'),
    capacity: i18n.t('storage necessary capacity'),
    necessaryDisks: i18n.t('storage necessary hdd'),
    schemeDisks: i18n.t('storage scheme group'),
    bandwidth: i18n.t('storage bandwidth'),
    total: i18n.t('storage total')
  }

  const storConfTableTitle = { // configuration table
    RAID: i18n.t('storage raid type'),
    capacityHDD: i18n.t('storage capacity hdd'),
    maxDiskInGroup: i18n.t('storage max disk group'),
    hotSpare: i18n.t('storage hotspare')
  }

  const storCamTableTitle = { // cameras and streams table
    name: i18n.t('camera name'),
    site: i18n.t('storage camera site'),
    resolution: i18n.t('camera matrix resolution'),
    recordParams: i18n.t('report_storage_record_params'),
    activity: i18n.t('storage camera activity'),
    interval: i18n.t('storage camera activity interval'),
    qty: i18n.t('storage qty streams') // for streams table
  }

  function getStorageGroups (cameras) {
    return cameras.reduce((outArr, cam) => {
      if (!outArr.find(el => el === cam.group)) outArr.push(cam.group)
      return outArr
    }, [])
  }

  function getCamerasStorage (cameras) {
    return cameras.length !== 0
      ? cameras.map((cam, idx) => ({
        num: idx + 1,
        name: cam.name,
        site: data.sites.find(site => cam.site === site.id).name,
        res: getCamResolution(cam.resX, cam.AR).res,
        ar: cam.AR,
        resTotal: getCamResolution(cam.resX, cam.AR).total + ' ' + i18n.t('mpx'),
        codec: cam.codec,
        fps: cam.fps + ' ' + i18n.t('frames per second'),
        bitrate: cam.bitrate + ' ' + i18n.t('mbit') + '/' + i18n.t('sec'),
        activity: i18n.t('storage camera activity' + ' ' + cam.activity),
        interval: cam.interval
      }))
      : []
  }

  function getStreamsStorage (streams) {
    return streams.length !== 0
      ? streams.map((stream, idx) => ({
        num: idx + 1,
        qty: stream.qty,
        res: stream.resX + 'x' + stream.resY,
        resTotal: getStreamResolution(stream.resX, stream.resY) + ' ' + i18n.t('mpx'),
        codec: stream.codec,
        fps: stream.fps + ' ' + i18n.t('frames per second'),
        bitrate: stream.bitrate + ' ' + i18n.t('mbit') + '/' + i18n.t('sec'),
        activity: i18n.t('storage camera activity' + ' ' + stream.activity),
        interval: stream.interval
      }))
      : []
  }

  const storageGroups = getStorageGroups(data.cameras).map(group => {
    const cameras = data.cameras.filter(cam => cam.group === group)
    const streams = data.streams.filter(stream => stream.group === group)
    const conf = data.storageConfigs.find(conf => conf.group === group)
    const summaryStor = calcStorage(cameras, streams, conf, data.archiveDays)
    const capacity = checkStorageCapacity(summaryStor.requireCapacity)
    const bandwidth = checkStorageBandwidth(summaryStor.bandwidth)
    const schemeDisks1 = summaryStor.fullHDDGroup !== 0
      ? summaryStor.fullHDDGroup + ' x (' + summaryStor.maxGroup + ' + ' + summaryStor.hotSpare + 'HS) '
      : false
    const schemeDisks2 = summaryStor.lastGroupHDDQty !== 0
      ? summaryStor.lastGroupHDDQty + ' + ' + summaryStor.hotSpare + 'HS'
      : false
    return {
      num: group,
      sum: {
        group,
        capacity: capacity.value + ' ' + i18n.t(capacity.unit1),
        necessaryDisks: summaryStor.qtyHDD + ' x ' + summaryStor.capacityHDD + i18n.t('tbyte'),
        schemeDisks1,
        schemeDisks2,
        bandwidth: bandwidth.value + ' ' + i18n.t(bandwidth.unit1) + '/' + i18n.t(bandwidth.unit2),
        summaryStor
      },
      conf,
      cameras: getCamerasStorage(cameras),
      streams: getStreamsStorage(streams)
    }
  })

  const storSumTotal = getStorSumTotal()
  function getStorSumTotal () {
    const capacity = checkStorageCapacity(storageGroups.reduce((sum, curGroup) => sum + curGroup.sum.summaryStor.requireCapacity, 0))
    const bandwidth = checkStorageBandwidth(storageGroups.reduce((sum, curGroup) => sum + curGroup.sum.summaryStor.bandwidth, 0))
    const listOfHDDs = [...new Set(storageGroups.map(el => +el.conf.capacityHDD))].sort((a, b) => a - b)
    const necessaryDisks = listOfHDDs.map(capacityHDD => ({
      capacityHDD,
      qty: storageGroups
        .filter(e => e.conf.capacityHDD === capacityHDD)
        .reduce((sum, el) => sum + el.sum.summaryStor.qtyHDD, 0)
    }))

    return {
      capacity: capacity.value + ' ' + i18n.t(capacity.unit1),
      necessaryDisks: necessaryDisks.map(el => (el.qty + ' x ' + el.capacityHDD + i18n.t('tbyte'))),
      bandwidth: bandwidth.value + ' ' + i18n.t(bandwidth.unit1) + '/' + i18n.t(bandwidth.unit2)
    }
  }

  // end storage section
  // create report and define text fields
  const res = await axios.get('/report-template.docx', { responseType: 'arraybuffer', baseURL: '/' })
  const template = res.data

  const onTemplateChosen = async () => {
    const report = await createReport({
      template,
      data: {
        report: i18n.t('report_title'),
        projectName: info.name,
        projectDescr: info.description,
        date: i18n.t('report_date') + ': ' + new Date().toLocaleDateString(),
        warning1: i18n.t('report_warning_1'),
        warning2: i18n.t('report_warning_2'),
        warningL1: i18n.t('report_warning_list_1'),
        warningL2: i18n.t('report_warning_list_2'),
        warningL3: i18n.t('report_warning_list_3'),
        warningNoPrint: i18n.t('report_warning_noprint'),
        page: i18n.t('report_page'),
        of: i18n.t('report_of'),
        sectionTitleContent: i18n.t('report_section_content'),
        sectionAnnotation: sections.annotation,
        sectionTitleAnnotation: i18n.t('report_section_annotation'),
        annotationText: i18n.t('report_annotation_text'),
        sectionCameras: sections.cameras,
        sectionTitleCameras: i18n.t('report_section_cameras'),
        camerasTotalQtyText: i18n.t('report_cameras_qty'),
        camerasTotalQty: data.cameras.length,
        siteTableTitle,
        camTypesText: i18n.t('report_camera_used_types'),
        camTypesTableTitle,
        camTypes,
        camTableTitle,
        sites,
        sectionViews: true, // sections.views,
        sectionTitleViews: i18n.t('report_section_views'),
        viewsLegend: i18n.t('report_views_legend'),
        viewsTableTitle,
        views,
        camzones: data.camZone.map(zone => ({ px: zone.px, color: zone.color, name: i18n.te(zone.name) ? i18n.t(zone.name) : zone.name })),
        sectionStorage: sections.storage,
        sectionTitleStorage: i18n.t('report_section_storage'),
        storageArchiveDays: i18n.t('strorage archive') + ': ' + data.archiveDays + ' ' + i18n.t('days'),
        storageGroup: i18n.t('storage camera group'),
        storageConfig: i18n.t('storage config'),
        storSumTableTitle,
        storConfTableTitle,
        storageCameras: i18n.t('storage on plan'),
        storageStreams: i18n.t('storage additional streams'),
        storCamTableTitle,
        storSumTotal,
        groups: storageGroups,
        storageInfoBlock: i18n.t('report_storage_infoblock'),
        storageInfoBlock1: i18n.t('report_storage_infoblock1'),
        storageInfoBlock2: i18n.t('report_storage_infoblock2'),
        storageInfoBlock3: i18n.t('report_storage_infoblock3'),
        storageInfoBlock4: i18n.t('report_storage_infoblock4'),
        storageInfoBlock5: 1 + ' ' + i18n.t('tbyte') + ' = ' + 931 + ' ' + i18n.t('gibyte') + '.',
        storageInfoTableTitle: {
          RAID: i18n.t('report_storage_infoRAID_title_RAID'),
          descr: i18n.t('report_storage_infoRAID_title_descr'),
          minHDD: i18n.t('report_storage_infoRAID_title_minHDD'),
          protection: i18n.t('report_storage_infoRAID_title_protection'),
          performanceRead: i18n.t('report_storage_infoRAID_title_performance_read'),
          performanceWrite: i18n.t('report_storage_infoRAID_title_performance_write')
        },
        storageInfoRAIDlevels: [
          {
            name: 'JBOD',
            descr: i18n.t('report_storage_infoRAID_JBOD'),
            minHDD: 1,
            protection: i18n.t('report_storage_infoRAID_protection_no'),
            performanceRead: i18n.t('report_storage_infoRAID_perfomance_M'),
            performanceWrite: i18n.t('report_storage_infoRAID_perfomance_M')
          },
          {
            name: 'RAID0',
            descr: i18n.t('report_storage_infoRAID_RAID0'),
            minHDD: 2,
            protection: i18n.t('report_storage_infoRAID_protection_no'),
            performanceRead: i18n.t('report_storage_infoRAID_perfomance_H'),
            performanceWrite: i18n.t('report_storage_infoRAID_perfomance_H')
          },
          {
            name: 'RAID1',
            descr: i18n.t('report_storage_infoRAID_RAID1'),
            minHDD: 2,
            protection: i18n.t('report_storage_infoRAID_protection_1'),
            performanceRead: i18n.t('report_storage_infoRAID_perfomance_H'),
            performanceWrite: i18n.t('report_storage_infoRAID_perfomance_M')
          },
          {
            name: 'RAID5',
            descr: i18n.t('report_storage_infoRAID_RAID5'),
            minHDD: 3,
            protection: i18n.t('report_storage_infoRAID_protection_1'),
            performanceRead: i18n.t('report_storage_infoRAID_perfomance_H'),
            performanceWrite: i18n.t('report_storage_infoRAID_perfomance_L')
          },
          {
            name: 'RAID6',
            descr: i18n.t('report_storage_infoRAID_RAID6'),
            minHDD: 4,
            protection: i18n.t('report_storage_infoRAID_protection_2'),
            performanceRead: i18n.t('report_storage_infoRAID_perfomance_H'),
            performanceWrite: i18n.t('report_storage_infoRAID_perfomance_L')
          },
          {
            name: 'RAID10',
            descr: i18n.t('report_storage_infoRAID_RAID10'),
            minHDD: 4,
            protection: i18n.t('report_storage_infoRAID_protection_1'),
            performanceRead: i18n.t('report_storage_infoRAID_perfomance_H'),
            performanceWrite: i18n.t('report_storage_infoRAID_perfomance_L')
          }
        ]
      }
    })
    saveDataToFile(
      report,
      'SURVy-Report-' + info.name + '_' + new Date().toLocaleDateString() + '.docx',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    )
  }

  await onTemplateChosen()
  // Load the user-provided file into an ArrayBuffer

  function saveDataToFile (data, fileName, mimeType) {
    const blob = new Blob([data], { type: mimeType })
    const url = window.URL.createObjectURL(blob)
    downloadURL(url, fileName, mimeType)
    setTimeout(() => {
      window.URL.revokeObjectURL(url)
    }, 1000)
  }

  function downloadURL (data, fileName) {
    const a = document.createElement('a')
    a.href = data
    a.download = fileName
    document.body.appendChild(a)
    a.style = 'display: none'
    a.click()
    a.remove()
  }

  function getCamResolution (resX, AR) {
    const ar = +AR.split(':')[0] / +AR.split(':')[1]
    const resY = +resX / ar
    const total = (+resX * +resY / 1000000).toFixed(1)
    return { res: resX + 'x' + resY, total }
  }

  function getStreamResolution (resX, resY) {
    return (+resX * +resY / 1000000).toFixed(1)
  }

  function getMatrixSize (sizeX, AR, matrixUserDef) {
    const ar = +AR.split(':')[0] / +AR.split(':')[1]
    return matrixUserDef
      ? sizeX + ' x ' + (sizeX / ar).toFixed(2) + ' ' + i18n.t('mm')
      : matrixSizeArray[AR]?.find(el => el.sizeX === sizeX)?.name + '"'
  }

  function getAlpha (sizeX, f) {
    return (2 * Math.atan(sizeX / 2 / f) * 180 / Math.PI).toFixed(0)
  }

  function getBeta (sizeX, f, AR) {
    const ar = +AR.split(':')[0] / +AR.split(':')[1]
    return (2 * Math.atan(sizeX / ar / 2 / f) * 180 / Math.PI).toFixed(0)
  }
}

export { getReport }
