<template>
<v-card>
  <v-card-title>
    {{$t('autocad block')}}
    <v-spacer/>
    <v-icon @click="$emit('input')">mdi-close</v-icon>
  </v-card-title>
  <v-card-text>
    {{$t('autocad block text 1')}}
    <a href="./CCTV_DORI_block_v3b.dwg" download>{{$t('autocad block text 2')}}</a>
    {{$t('autocad block text 3')}}
  </v-card-text>
</v-card>
</template>

<script>
export default {
  name: 'AutocadBlock',

  components: {
  },

  data: () => ({
  }),

  computed: {
  },

  methods: {

  },

  mounted () {

  }
}

</script>

<style scoped>
>>> a {
  color: inherit !important
}
</style>
