function getItemByAttr (attr, name) {
  let object = null
  const objects = this.getObjects()
  for (let i = 0, len = this.size(); i < len; i++) {
    if (objects[i][attr] && objects[i][attr] === name) {
      object = objects[i]
      break
    }
  }
  return object
}

function addFabricExtension (fabric) {
  fabric.Canvas.prototype.getItemByAttr = getItemByAttr
}

export default addFabricExtension
