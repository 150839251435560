const getDefaultState = () => {
  const baseUnit = 5000
  return {
    canvasData: { // global data of canvas
      height: 300,
      width: 300,
      isPanMode: true // on/off pan mode
    },
    grids: {
      baseUnit: baseUnit,
      primaryGrid: {
        unit: baseUnit,
        color: '#808080', // primary grid
        thickness: 1, // primary grid, dimension - px
        opacity: 0.5,
        type: 0, // 0 - solid, 1 - cross
        display: true
      },
      secondaryGrid: {
        unit: baseUnit / 5, // mm, secondaryGrid = unit/5 (not change)
        color: '#C0C0C0',
        thickness: 1,
        opacity: 0.5,
        type: 0, // 0 - solid, 1 - cross
        display: true
      }
    },
    camZone: [
      { id: 'fov0', name: 'strong identification', px: 1000, color: '#800080' },
      { id: 'fov1', name: 'identification', px: 250, color: '#FF0000' },
      { id: 'fov2', name: 'recognition', px: 125, color: '#FFFF00' },
      { id: 'fov3', name: 'observation', px: 62, color: '#00FF00' },
      { id: 'fov4', name: 'detection', px: 25, color: '#00FFFF' },
      { id: 'fov5', name: 'monitoring', px: 12, color: '#0000FF' },
      { id: 'fov6', name: 'overview', px: 1, color: '#C0C0C0' }
    ],
    lightColors: { IR: '#000000', WL: '#ffc107' },
    views: [], // list of named views
    legend: false,
    isShowFoV: true
  }
}

export default {
  state: getDefaultState(),

  getters: {
    CANVASDATA: state => state.canvasData,
    CAMZONE: state => state.camZone,
    CURRENT_ZOOM: (state, rootGetters) => state.views.find(el => el.siteId === rootGetters.CURRENT_SITE && el.id === 'current')?.zoom || 0.01,
    CURRENT_COORDS: (state, rootGetters) => state.views.find(el => el.siteId === rootGetters.CURRENT_SITE && el.id === 'current')?.centerGlobalCoords || { x: 500, y: 100 },
    IS_SHOW_FOV: state => state.isShowFoV,
    LIGHT_COLORS: state => state.lightColors
  },

  mutations: {
    DEFAULT_EDITOR_STATE (state) {
      Object.assign(state, getDefaultState())
    },

    ADD_DEFUALT_VIEW (state, siteId) {
      state.views.unshift({
        id: 'current',
        siteId: siteId,
        name: 'current', // no used
        centerGlobalCoords: { x: 100, y: 100 },
        zoom: 0.02
      })
    },

    ADD_VIEW (state, value) { // value = {id, name, descr, site_id, coords, zoom}
      state.views.unshift(value)
    },

    UPDATE_VIEW (state, value) { // value = {id, name, descr}
      const idx = state.views.findIndex(e => e.id === value.id)
      state.views[idx] = Object.assign(state.views[idx], value)
    },

    DELETE_VIEW (state, id) {
      const idx = state.views.findIndex(e => e.id === id)
      state.views.splice(idx, 1)
    },

    DELETE_VIEWS (state, siteId) {
      state.views.filter(e => e.site === siteId).forEach(el => {
        const idx = state.views.findIndex(e => e === el)
        state.views.splice(idx, 1)
      })
    },

    SET_CURRENT_ZOOM (state, data) { // data = { siteId, zoom }
      state.views.find(el => el.siteId === data.siteId && el.id === 'current').zoom = data.zoom
    },

    SET_CURRENT_GLOBAL_COORDS (state, data) { // data = { siteId, coords }
      state.views.find(el => el.siteId === data.siteId && el.id === 'current').centerGlobalCoords = data.coords
    },

    SET_CANVAS_DIM (state, newDim) {
      state.canvasData.height = newDim.height
      state.canvasData.width = newDim.width
    },

    SET_PAN_MODE (state, newValue) {
      state.canvasData.isPanMode = newValue
    },

    ADD_START_CAMZONE (state, value) {
      state.camZone.unshift(value)
    },

    ADD_END_CAMZONE (state, value) {
      state.camZone.push(value)
    },

    DELETE_CAMZONE: (state, id) => {
      const idx = state.camZone.findIndex(el => el.id === id)
      state.camZone.splice(idx, 1)
    },

    DEFAULT_CAMZONES: (state) => {
      state.camZone.splice(0)
      state.camZone = getDefaultState().camZone
    },

    ONE_CAMZONE: (state) => {
      state.camZone.splice(0)
      state.camZone = [{ id: 'fov-base', name: 'zone', px: 1, color: '#00ff00' }]
    },

    SET_CAMZONE_NAME (state, value) {
      state.camZone.find(el => el.id === value.id).name = value.name
    },

    SET_CAMZONE_PX (state, value) {
      state.camZone.find(el => el.id === value.id).px = value.px
    },

    SET_CAMZONE_COLOR (state, value) {
      state.camZone.find(el => el.id === value.id).color = value.color
    },

    SET_LEGEND_SHOW (state, value) {
      state.legend = value
    },

    UPDATE_GRID_BASEUNIY (state, value) {
      state.grids.baseUnit = +value
    },

    UPDATE_PRIMARY_GRID (state, value) {
      state.grids.primaryGrid = Object.assign(state.grids.primaryGrid, value)
    },

    UPDATE_SECONDARY_GRID (state, value) {
      state.grids.secondaryGrid = Object.assign(state.grids.secondaryGrid, value)
    },

    UPDATE_CAMZONE (state, value) {
      state.camZone = JSON.parse(JSON.stringify(value))
    },

    UPDATE_LIGHT_COLOR (state, value) {
      state.lightColors = Object.assign(state.lightColors, value)
    },

    UPDATE_SHOW_FOV (state, value) {
      state.isShowFoV = value
    }
  },

  actions: {
  }
}
