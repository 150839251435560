import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'
import ChangePassword from '../views/ChangePassword'
import page404 from '../views/page404'
import ProjectTable from '../views/ProjectTable'
import Editor from '../views/Editor'
import SharedBlock from '../views/SharedBlock'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'projects' }
  },
  {
    name: 'projects',
    path: '/projects',
    component: ProjectTable
  },
  {
    path: '/project/:id',
    component: Editor,
    children: [
      {
        path: '*',
        redirect: '/err404'
      }
    ]
  },
  {
    name: 'login',
    path: '/login',
    component: Login
  },
  {
    name: 'change-password',
    path: '/change-password',
    component: ChangePassword
  },
  {
    name: 'shared',
    path: '/shared/:uri',
    component: Editor
  },
  {
    name: 'sharedBlock',
    path: '/sharedBlock',
    component: SharedBlock
  },
  {
    name: 'page404',
    path: '/err404',
    component: page404
  },
  {
    path: '*',
    redirect: { name: 'projects' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (store.state.auth.isAuth) return next()

  await store.dispatch('UPDATE_AUTH_STATE')
  if (store.state.auth.isAuth) {
    if (to.name === 'login' || to.name === 'change-password') return next({ name: 'projects' })
    if (to.name === 'shared') {
      await store.dispatch('GET_LOGOUT_TO_STATE')
      return next()
    }
    if (to.path.includes('/project/')) {
      await store.dispatch('GET_PROJECTS_FROM_API');
      (store.getters.PROJECTS.map(el => el.id).find(e => e === +to.params.id))
        ? next()
        : next({ name: 'projects' })
    } else {
      next()
    }
  } else {
    to.name === 'login' || to.name === 'change-password' || to.name === 'shared' || to.name === 'page404' || to.name === 'sharedBlock'
      ? next()
      : next({ name: 'login' })
  }
})

export default router
