<template>
  <v-container fluid>
    <properties-row v-if="isShow[0]">
      <template #name> {{ $t('type') }} </template>
      <v-text-field class="my-1 text-body-2" hide-details disabled v-model="typeObject" flat dense/>
    </properties-row>

    <properties-row v-if="isShow[1]">
      <template #name> {{ $t('color') }} </template>
      <color-picker v-model="colorObject"/>
    </properties-row>
    <properties-row v-if="isShow[2]">
      <template #name> {{ $t('backgrond color') }} </template>
      <div class = "d-flex justify-start align-center" style = "min-height: 52px;">
        <v-expand-x-transition>
          <div v-if = "bgColorObject !== 'transparent'" class = "rounded" :style = "{ backgroundColor: lastBgColor }" style = "width: 24px; height: 24px">
           <color-picker class="shrink" v-model="bgColorObject"/>
          </div>
        </v-expand-x-transition>
        <v-switch
          v-model="isTransparent"
          dense
          class="my-0 ml-2"
          hide-details
          >
          <template #label><span class="text-body-2">{{ $t('no fill') }}</span></template>
        </v-switch>
      </div>
    </properties-row>

    <properties-row v-if="isShow[3]">
      <template #name> {{ $t('thickness') }} </template>
      <v-text-field
        v-model = "thicknessObject"
        class = "text-body-2 my-1 small-input"
        hide-details = "auto"
        :suffix = "$t('px')"
        flat
        dense
        type = "number"
        :rules = rules.thickness
      />
    </properties-row>

    <properties-row v-if="isShow[4]">
      <template #name> {{ $t('rotation angle') }} </template>
      <v-text-field
        v-model = "angleObject"
        class = "text-body-2 my-2 small-input"
        type = "number"
        hide-details = "auto"
        flat
        dense
        :rules = rules.angle
        >
        <template #append>&deg;</template>
      </v-text-field>
    </properties-row>

    <properties-row v-if="isShow[5]">
      <template #name> {{ $t('opacity') }} </template>
      <slider-with-data v-model="opacityObject" sliderParams="{ max: 1, min: 0 }"/>
    </properties-row>

    <properties-row v-if="isShow[6]">
      <template #name> {{ $t('font size') }} </template>
      <v-text-field
        v-model = "fontSizeObject"
        class = "text-body-2 my-1 small-input"
        hide-details = "auto"
        :suffix="$t('px')"
        flat
        dense
        type="number"
        :rules = rules.fontSize
      />
    </properties-row>

    <properties-row v-if="isShow[6]">
      <template #name> {{ $t('font style') }} </template>
      <font-style-btns v-model="fontStyleObject"/>
    </properties-row>

    <properties-row v-if="isShow[7]">
      <template #name> {{ $t('black white image') }} </template>
        <v-switch
          v-model="BWFilterObject"
          dense
          class="my-0"
          hide-details
          >
        </v-switch>
    </properties-row>

    <properties-row v-if="isShow[7]">
      <template #name> {{ $t('border image') }} </template>
        <v-switch
          v-model="borderObject"
          dense
          class="my-0"
          hide-details
          >
        </v-switch>
    </properties-row>
    <properties-camera v-if = "isShow[8]"/>
    <properties-equip v-if = "isShow[9]"/>
  </v-container>
</template>

<script>
import { eventBus } from '../../main'
import { mapState } from 'vuex'
import colorPicker from './EditorColorPicker'
import sliderWithData from './EditorSlider'
import fontStyleBtns from './EditorFontStyle'
import EditorPropertiesRow from './EditorPropertiesRow'
import EditorPropertiesCamera from './EditorPropertiesCamera'
import EditorPropertiesEquip from './EditorPropertiesEquip'

export default {

  name: 'EditorProperties',

  components: {
    colorPicker,
    sliderWithData,
    propertiesRow: EditorPropertiesRow,
    fontStyleBtns,
    propertiesCamera: EditorPropertiesCamera,
    propertiesEquip: EditorPropertiesEquip
  },

  data: () => ({
    lastBgColor: ''
  }),

  computed: {
    ...mapState({
      selObj: state => state.objectsStore.selObj,
      objectList: state => state.objectsStore.list
    }),

    obj () {
      return (this.selObj)
        ? this.selObj
        : false
    },

    typeObject () {
      let type = 'n/a'
      if (this.obj) {
        switch (this.obj.typeOf) {
          case 'layoutLine': type = this.$t('line'); break
          case 'layoutRect': type = this.$t('rectangle'); break
          case 'layoutCircle': type = this.$t('circle'); break
          case 'layoutBrush': type = this.$t('brush'); break
          case 'layoutText': type = this.$t('text'); break
          case 'layoutEquip': type = this.$t('equipment'); break
          case 'layoutImg': type = this.$t('image'); break
          case 'limitsLine': type = this.$t('limits line'); break
          case 'tape': type = this.$t('tape'); break
          case 'camera': type = this.$t('camera'); break
          default: type = 'n/a'
        }
      }
      return type
    },

    isShow () {
      let arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      if (this.obj) {
        switch (this.obj.typeOf) {
          // arr = [type, color, bgcolor, thickness, angle, opacity, font size / style, b&w filter / border, camera properties, equip-selector]
          case 'layoutLine': arr = [1, 1, 0, 1, 0, 1, 0, 0, 0, 0]; break
          case 'layoutRect': arr = [1, 1, 1, 1, 1, 1, 0, 0, 0, 0]; break
          case 'layoutCircle': arr = [1, 1, 1, 1, 0, 1, 0, 0, 0, 0]; break
          case 'layoutBrush': arr = [1, 1, 0, 1, 1, 1, 0, 0, 0, 0]; break
          case 'layoutText': arr = [1, 1, 0, 0, 1, 1, 1, 0, 0, 0]; break
          case 'layoutEquip': arr = [1, 0, 0, 0, 0, 0, 0, 0, 0, 1]; break
          case 'layoutImg': arr = [1, 0, 0, 0, 1, 1, 0, 1, 0, 0]; break
          case 'limitsLine': arr = [1, 0, 0, 0, 0, 0, 0, 0, 0, 0]; break
          case 'tape': arr = [1, 0, 0, 0, 0, 0, 0, 0, 0, 0]; break
          case 'camera': arr = [0, 0, 0, 0, 0, 0, 0, 0, 1, 0]; break
        }
      }
      return arr
    },

    thicknessObject: {
      get () {
        return (this.obj) ? this.obj.strokeWidth : 'n/a'
      },
      set (value) {
        this.obj && this.rules.thickness.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
          this.$store.commit('SET_THINKNESS_SELECTED_OBJECT', value)
      }
    },

    colorObject: {
      get () {
        return (this.obj) ? this.obj.stroke : '#000000'
      },
      set (value) {
        if (this.obj) this.$store.commit('SET_COLOR_SELECTED_OBJECT', value)
      }
    },

    bgColorObject: {
      get () {
        return (this.obj) ? this.obj.fill : this.lastBgColor
      },
      set (value) {
        if (this.obj) this.$store.commit('SET_BGCOLOR_SELECTED_OBJECT', value)
        this.lastBgColor = value
      }
    },

    isTransparent: {
      get () {
        return this.obj
          ? (this.obj.fill) === 'transparent' ? 1 : 0
          : false
      },
      set (status) {
        (status)
          ? this.$store.commit('SET_BGCOLOR_SELECTED_OBJECT', 'transparent')
          : this.$store.commit('SET_BGCOLOR_SELECTED_OBJECT', (this.lastBgColor) ? this.lastBgColor : '#000000')
      }
    },

    angleObject: {
      get () {
        return (this.obj) ? this.obj.angle : 'n/a'
      },
      set (value) {
        this.obj && this.rules.angle.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
          this.$store.commit('SET_ANGLE_SELECTED_OBJECT', value)
      }
    },

    opacityObject: {
      get () {
        return (this.obj) ? this.obj.opacity : 'n/a'
      },
      set (value) {
        this.obj && this.rules.opacity.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
          this.$store.commit('SET_OPACITY_SELECTED_OBJECT', value)
      }
    },

    fontSizeObject: {
      get () {
        return (this.obj) ? this.obj.fontSize : 'n/a'
      },
      set (value) {
        this.obj && this.rules.fontSize.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
          this.$store.commit('SET_FONTSIZE_SELECTED_OBJECT', value)
      }
    },

    fontStyleObject: {
      get () {
        return (this.obj)
          ? { fontWeight: this.obj.fontWeight, fontStyle: this.obj.fontStyle, underline: this.obj.underline, linethrough: this.obj.linethrough }
          : { fontWeight: 'normal', fontStyle: 'normal', underline: false, linethrough: false }
      },
      set (value) {
        if (this.obj) this.$store.commit('SET_FONTSTYLE_SELECTED_OBJECT', value)
      }
    },

    BWFilterObject: {
      get () {
        return (this.obj) ? this.obj.BWFilter : 0
      },
      set (value) {
        if (this.obj) this.$store.commit('SET_BWFILTER_SELECTED_OBJECT', value)
      }
    },

    borderObject: {
      get () {
        return (this.obj) ? this.obj.border : 0
      },
      set (value) {
        if (this.obj) this.$store.commit('SET_BORDER_SELECTED_OBJECT', value)
      }
    },

    rules () {
      return {
        thickness: [value => (Number.isInteger(+value) && (+value >= 1) && (+value <= 10)) || (this.$t('properties object out of range') + ' 1-10')],
        angle: [value => ((value !== '') && (+value >= 0) && (+value < 360)) || (this.$t('properties object out of range') + ' 0-360')],
        opacity: [value => ((value !== '') && (+value >= 0) && (+value <= 100)) || (this.$t('properties object out of range') + ' 0-100')],
        fontSize: [value => (Number.isInteger(+value) && (+value >= 10) && (+value < 50)) || (this.$t('properties object out of range') + ' 10-50')]
      }
    }
  },

  methods: {
  },

  watch: {
    selObj () {
      if (this.selObj) this.lastBgColor = (this.selObj.fill !== 'transparent') ? this.selObj.fill : '#000000'
    }
  },

  mounted () {
  },

  beforeUnmount () {
  },

  beforeUpdate () {
  }
}

</script>

<style scoped>
.small-input {
  width: 60px !important
}
</style>
