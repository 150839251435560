<template>
<div>
  <v-btn-toggle
    :value="btnStatus"
    v-on:change="$emit('input', statusToObj($event))"
    dense
    multiple
    >
    <v-tooltip
      v-for="(btn, idx) in btns"
      :key="idx"
      top
      >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          x-small
          v-bind="attrs"
          v-on="on"
          >
          <span>
          <v-icon >{{ btn.name }}</v-icon>
          </span>
        </v-btn>
      </template>
      <span>{{ btn.descr }}</span>
    </v-tooltip>
  </v-btn-toggle>
</div>
</template>

<script>

export default {

  name: 'fontStyleBtns',

  components: {
  },

  props: ['value'],

  data: function () {
    return {
      btns: [
        { name: 'mdi-format-bold', descr: this.$t('text bold') },
        { name: 'mdi-format-italic', descr: this.$t('text italic') },
        { name: 'mdi-format-underline', descr: this.$t('text underline') },
        { name: 'mdi-format-strikethrough', descr: this.$t('text strikethrough') }
      ]
    }
  },

  computed: {
    btnStatus () {
      const arr = []
      if (this.value) {
        if (this.value.fontWeight !== 'normal') arr.push(0)
        if (this.value.fontStyle !== 'normal') arr.push(1)
        if (this.value.underline) arr.push(2)
        if (this.value.linethrough) arr.push(3)
      }
      return arr
    }
  },

  methods: {
    statusToObj (arr) {
      return {
        fontWeight: arr.includes(0) ? 'bold' : 'normal',
        fontStyle: arr.includes(1) ? 'italic' : 'normal',
        underline: arr.includes(2) ? 1 : 0,
        linethrough: arr.includes(3) ? 1 : 0
      }
    }
  },

  mounted () {
  }
}

</script>

<style scoped>

</style>
