const matrixBitrate = { // in kbit/s
  'H.264': [
    [320, 320, 192, 192, 192, 128], // 352x288
    [832, 832, 576, 448, 448, 288], // 640x360
    [1024, 1024, 768, 512, 512, 384], // 704x576
    [2048, 2048, 1536, 1024, 1024, 768], // 1MP 1280x720 720p & 1280x960
    [4096, 4096, 3072, 2048, 2048, 1536], // 2MP FullHD
    [6144, 6144, 4608, 3072, 3072, 2304], // 3MP QXGA
    [8192, 8192, 6144, 4096, 4096, 3072], // 4MP
    [9216, 9216, 6912, 4608, 4608, 3456], // 5MP
    [10240, 10240, 7680, 5120, 5120, 3840], // 6-7MP
    [16384, 16384, 12288, 8192, 8192, 6144], // 8-9MP
    [20480, 20480, 15360, 10240, 10240, 7680] // 12MP
  ],
  'H.265': [
    [320, 320, 192, 192, 192, 128], // 352x288
    [576, 576, 384, 320, 320, 192], // 640x360
    [704, 704, 512, 384, 384, 256], // 704x576
    [1024, 1024, 768, 512, 512, 384], // 1MP 1280x720 720p & 1280x960
    [2048, 2048, 1536, 1024, 1024, 768], // 2MP FullHD
    [3072, 3072, 2304, 1536, 1536, 1152], // 3MP QXGA
    [4096, 4096, 3072, 2048, 2048, 1536], // 4MP
    [4608, 4608, 3456, 2304, 2304, 1728], // 5MP
    [5120, 5120, 3840, 2560, 2560, 1920], // 6-7MP
    [8192, 8192, 6144, 4096, 4096, 3072], // 8-9MP
    [10240, 10240, 7680, 5120, 5120, 3840] // 12MP
  ]
}

function calcBitrate (codec, fps, res, activity) {
  const resCalc = res < 1 ? Math.round(res * 10) / 10 : Math.round(res)
  const fpsIdx = [10, 12, 15, 20, 25, 30].reverse().findIndex(el => el === fps)
  let resIdx = [0.1, 0.25, 0.4, 1, 2, 3, 4, 5, 7, 9, 12]
    .findIndex((_, idx, arr) => resCalc < arr[idx + 1])
  if (resIdx === -1) resIdx = 10 // for biggest resolution - max 12 mpx
  const calcActivity =
    activity === 'extra-low' ? 0.25
      : activity === 'low' ? 0.5
        : activity === 'high' ? 2 : 1 // else - middle
  let codecCalc
  let k = 1
  if (codec !== 'H.265+') {
    codecCalc = codec
  } else {
    codecCalc = 'H.265'
    k = 0.7
  }
  return matrixBitrate[codecCalc][resIdx][fpsIdx] * calcActivity * k / 1024 // 1024 convert to Mbit/s
}

export { calcBitrate }
