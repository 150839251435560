<template>
  <!-- $el.ownerDocument.defaultView.console.log() -->
  <v-container class="pa-0 mt-0 fluid">
    <v-row class="mt-1 justify-center">
    <v-card
      width="1200px"
      elevation="0"
    >
      <v-card-title>
        <table-btn
          :btnData = "{ icon: 'mdi-plus', descr: 'addproject' }"
          @click = "showDialogNewProject()"
        />
        <project-dialog
          v-model="showDialog"
          @project-info-edit = "newProject"
          >
          <template #dialog-title>{{$t('project create')}}</template>
          <template #dialog-btn-ok>{{$t('project create ok')}}</template>
        </project-dialog>
        <v-spacer/>
        <v-text-field
          id = "search"
          v-model = "search"
          prepend-icon = "mdi-magnify"
          :label = "$t('search')"
          hide-details
          clearable
          dense
        />
      </v-card-title>
      <v-data-table
          id = "projects-table"
          :headers = "tableHeaders"
          :items = "projects"
          v-model="selectedRow"
          class = "elevation-0 fixed--header"
          dense
          :options.sync = tableView
          @pagination = "tableView = $event"
          :search = "search"
          :footer-props = "{
            'items-per-page-text': $t('table-footer-per-page'),
            'page-text': '{0}-{1} '+ $t('table-footer-of') +' {2}',
            'items-per-page-all-text': $t('all')
          }"
          :no-results-text = "$t('table no records found')"
          >
          <template v-for = "header in tableHeaders" #[headerSlotName(header.value)]>
            <div :key = "header.text" align = "center"> {{$t(header.text)}} </div>
          </template>

          <template #item.id = "{ item }">
            <span class='grey--text'>{{item.id}}</span>
          </template>

          <template #item.name = "{ item }" >
            <div style="text-align: left;">
              <a :href = "'/project/' + item.id" class="font-weight-bold text-decoration-none" style = "color: inherit">{{item.name}}</a><br>
              <span class="grey--text text-caption">{{item.description}}</span>
              <div v-if = "item.sites && item.sites.length > 1" class = "d-flex flex-wrap">
                <v-chip
                  v-for = "site in item.sites"
                  :key = "site.id"
                  x-small
                  label
                  color = "info"
                  outlined
                  class = "my-1 mr-2"
                  >
                <span @click = "goProject(item, site.id)" class="text-caption cursor-pointer">
                  {{site.name}}
                </span>
              </v-chip>
              </div>
              </div>
          </template>

          <template #item.updated="{ item }">
            <time-2-line :time = "item.updated" />
          </template>

          <template #item.created="{ item }">
            <time-2-line :time = "item.created" />
          </template>

          <template #item.actions="{ item }">
            <table-actions-menu :item="item"/>
          </template>

          <template #item.shared="{ item }">
            <project-share-dialog :project = "item" v-if = "item.shared_uri_access !== 'none' && item.shared_uri_access !== undefined">
              <template #dialog-btn>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        :color = "item.shared_uri_access === 'edit' ? 'primary' : 'grey'"
                        small
                        v-bind = "attrs"
                        v-on = "on"
                        >
                        mdi-link-variant
                      </v-icon>
                  </template>
                  {{ $t('table_project_shared_' + item.shared_uri_access) }}
                </v-tooltip>
              </template>
            </project-share-dialog>
          </template>

          <template slot = "no-data">
            {{$t('table no data available')}}
          </template>

      </v-data-table>
    </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Time2Line from '~/components/lib/srvTime2Line'
import ProjectDialog from '~/components/lib/srvProjectDialog'
import TableActionsMenu from '~/components/ProjectTable-components/TableActionsMenu'
import TableBtn from '~/components/lib/srvTableBtn.vue'
import { eventBus } from '~/main'
import { sortItems } from 'vuetify/lib/util/helpers'
import ProjectShareDialog from '~/components/ProjectTable-components/ProjectShareDialog'

export default {

  name: 'ProjectTable',

  components: {
    Time2Line,
    TableActionsMenu,
    ProjectDialog,
    ProjectShareDialog,
    TableBtn
  },

  data: () => ({
    search: '',
    showDialog: false,
    selectedRow: [],
    tableHeaders: [
      { text: 'id', value: 'id', width: '5%', align: 'center', sortable: true },
      { text: 'project name', value: 'name', width: '40%', align: 'center', sortable: true },
      { text: 'last modify', value: 'updated', width: '15%', align: 'center', sortable: true },
      { text: 'created', value: 'created', width: '15%', align: 'center', sortable: true },
      { text: 'cameras', value: 'camera_count', align: 'center', width: '10%', sortable: true },
      { text: '', value: 'shared', align: 'center', width: '5%', sortable: true },
      { text: '', value: 'actions', width: '15%', sortable: false }
    ]
  }),

  computed: {
    ...mapState({
      projects: state => state.projectsStore.projects
    }),

    tableView: {
      get () {
        const view = this.$store.state.projectsStore.tableView
        return {
          page: view.page || '1',
          itemsPerPage: view.itemsPerPage || 10,
          sortBy: view.sortBy || [this.tableHeaders[2].value],
          sortDesc: view.sortDesc || [true]
        }
      },
      set (value) {
        this.$store.commit('SET_TABLE_VIEW', value)
      }
    }
  },

  methods: {
    ...mapActions([
      'GET_PROJECTS_FROM_API',
      'ADD_NEW_PROJECT'
    ]),

    showDialogNewProject () {
      this.showDialog = true
    },

    newProject (projectData) {
      this.ADD_NEW_PROJECT(projectData)
    },

    headerSlotName (value) {
      return 'header.' + value
    },

    selectionRow (idx) {
      setTimeout(() => {
        this.selectedRow.push({ id: idx })
        setTimeout(() => this.selectedRow.pop(), 2000)
      }, 500)
      const rows = sortItems(this.projects.slice(), this.tableView.sortBy, this.tableView.sortDesc, 'en') // use locale Vuetify!!!
      const rowNo = rows.findIndex(el => el.id === idx)
      this.$store.commit('SET_TABLE_VIEW', {
        page: String(Math.ceil((rowNo + 1) / this.tableView.itemsPerPage))
      })
    },

    goProject (project, site) {
      if (site) this.$store.commit('SET_CURRENT_SITE', site)
      this.$router.push({ path: '/project/' + project.id })
    }
  },

  mounted () {
    this.$store.commit('DEFAULT_SITE_STATE')
    this.$store.commit('DEFAULT_EDITOR_STATE')
    this.$store.commit('DEFAULT_OBJECT_STATE')
    this.$store.commit('DEFAULT_STORAGE_STATE')
    this.GET_PROJECTS_FROM_API()
    eventBus.$on('project create', this.selectionRow)
  },

  beforeDestroy () {
    eventBus.$off(['project create'])
  }
}

</script>

<style scoped>

.cursor-pointer {
  cursor: pointer
}

>>> tr.v-data-table__selected {
  background: #E3F2FD !important;
}

.v-data-table >>> tr:hover {
  background: transparent !important;
}

>>> .v-input__icon > .v-icon {
  font-size: 18px !important;
}

</style>
