<template>
  <v-card>
    <v-card-title>
      {{$t('add view')}}
      <v-spacer/>
      <v-btn
        icon
        @click = "closeDialog()"
        >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-textarea
        v-model = "name"
        auto-grow
        :label = "$t('view name')"
        rows="1"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        color = "primary"
        plain
        @click = "closeDialog()"
      >
        {{$t('cancel')}}
      </v-btn>
      <v-btn
        color="primary"
        plain
        :disabled = "(name === '')||(typeof name == 'undefined')"
        @click = "pushOK()"
      >
        {{$t('accept')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  name: 'viewDialog',

  components: {
  },

  props: {
    item: {
      type: Object,
      default: () => {
        return {
          name: ''
        }
      }
    }
  },

  data: () => ({
    name: ''
  }),

  computed: {
    ...mapGetters([
      'CURRENT_SITE',
      'CURRENT_ZOOM',
      'CURRENT_COORDS'
    ])
  },

  methods: {
    closeDialog () {
      this.name = ''
      this.$emit('close')
    },

    pushOK () {
      this.$store.commit('ADD_VIEW', {
        id: Math.random().toString(8).slice(2),
        siteId: this.CURRENT_SITE,
        name: this.name,
        centerGlobalCoords: this.CURRENT_COORDS,
        zoom: this.CURRENT_ZOOM
      })
      this.name = this.item.name
      this.$emit('close')
    }
  },

  watch: {
  },

  updated () {
  },

  mounted () {
    this.name = this.item.name
  }
}
</script>

<style scoped>

</style>
