<template>
  <div>
    <v-dialog
      v-model="dialogBackToProject"
      width="500"
      >
      <template #activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <slot name="dialog-btn"/>
        </div>
      </template>

      <v-card>
        <v-card-text class="text-body-1 pa-5 overflow-hidden">
          {{$t('toolbar exit alert')}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color = "grey"
            text
            @click = "$emit('save'); dialogBackToProject = false"
            >
            {{$t('toolbar save')}}
          </v-btn>
          <v-btn
            color = "primary"
            text
            @click = "$emit('no-save'); dialogBackToProject = false"
            >
            {{$t('toolbar no save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {

  name: 'ToolbarDialogToProjects',

  components: {
  },

  data: () => ({
    dialogBackToProject: false
  }),

  computed: {
  },

  methods: {
  },

  mounted () {
  }
}

</script>

<style scoped>

</style>
