import * as fabric from 'fabric'

const HANDLE_RADIUS = 3
const HANDLE_COLOR = '#1976d2'

export default class LayoutCircle {
  constructor (objData = {}) {
    this.canvasObjects = [] // for canvas drawing
    this.requireNextClick = true // true - if necessary determinate more one vertex for object
    this.site = objData.site
    this.typeOf = 'layoutCircle'
    this.id = objData.id
    this.zOrder = objData.zOrder
    this.circle = new fabric.Circle({
      left: objData.left || Infinity,
      top: objData.top || Infinity,
      radius: objData.radius || 0,
      fill: objData.fill || 'transparent',
      stroke: objData.stroke || '#000000',
      strokeWidth: objData.strokeWidth || 1,
      opacity: objData.opacity || 1,
      selectable: false,
      evented: false,
      hoverCursor: 'move',
      hasBorders: false,
      hasControls: false,
      strokeUniform: true,
      originX: 'center',
      originY: 'center',
      perPixelTargetFind: true,
      isSelectable: true, // for set selectable obj on canvas
      typeOf: this.typeOf,
      id: this.id
    })

    // add copy circle for selection obj only by border
    this.handleCircle = new fabric.Circle({
      fill: 'transparent',
      stroke: 'red', // any color
      strokeWidth: 6,
      opacity: 0.01,
      originX: 'center',
      originY: 'center',
      hoverCursor: 'pointer',
      selectable: true,
      evented: true,
      hasBorders: false,
      hasControls: false,
      perPixelTargetFind: true,
      strokeUniform: true,
      objectCaching: false,
      isAux: true, // temporary obj, only for selection by border
      subTypeOf: 'handleContur',
      typeOf: this.typeOf,
      id: this.id
    })

    const handleParams = {
      radius: 0,
      fill: HANDLE_COLOR,
      originX: 'center',
      originY: 'center',
      hoverCursor: 'nesw-resize',
      hasBorders: false,
      hasControls: false,
      subTypeOf: 'handle',
      typeOf: this.typeOf,
      id: this.id
    }
    // handle1 - center, handle2 - radius-handle
    this.handle1 = new fabric.Circle(handleParams)
    this.handle1.set({ hoverCursor: 'move' })
    this.handle2 = new fabric.Circle(handleParams)
    this.#copyPosFromCircleToHandles()
    this.canvasObjects = [this.circle, this.handleCircle, this.handle1, this.handle2] // origin circle - first element
  }

  // private method for set coords handleCircle as Circle
  #copyPosFromCircleToHandles = function () {
    this.handleCircle.set({
      left: this.circle.left,
      top: this.circle.top,
      radius: this.circle.radius
    }).setCoords()
    this.handle1.set({
      left: this.circle.left,
      top: this.circle.top
    }).setCoords()
    this.handle2.set({
      left: this.circle.left + this.circle.radius,
      top: this.circle.top
    }).setCoords()
  }

  is (elemFabric) {
    return (elemFabric === this.handle1 || elemFabric === this.handle2 || elemFabric === this.handleCircle || elemFabric === this.circle) && this
  }

  zooming (scale, point) { // point - point of zooming {x, y}, scale = zoomNew/zoomOld
    this.circle.set({
      left: point.x + scale * (this.circle.left - point.x),
      top: point.y + scale * (this.circle.top - point.y),
      radius: scale * this.circle.radius
    }).setCoords()
    this.#copyPosFromCircleToHandles()
  }

  panning (startPoint, endPoint) { // point - {x, y}
    this.circle.set({
      left: endPoint.x - startPoint.x + this.circle.left,
      top: endPoint.y - startPoint.y + this.circle.top
    }).setCoords()
    this.#copyPosFromCircleToHandles()
  }

  createFirstClick (event) {
    const evt = event.pointer
    this.circle.set({
      left: evt.x,
      top: evt.y
    }).setCoords()
  }

  createWaitNextClick (event) {
    const evt = event.pointer
    this.circle.set({
      radius: Math.sqrt((evt.x - this.circle.left) ** 2 + (evt.y - this.circle.top) ** 2)
    }).setCoords()
  }

  createNextClick (event) {
    const evt = event.pointer
    this.circle.set({
      radius: Math.sqrt((evt.x - this.circle.left) ** 2 + (evt.y - this.circle.top) ** 2)
    }).setCoords()
    this.#copyPosFromCircleToHandles()
    this.requireNextClick = false
  }

  selected () {
    this.circle.set({ selectable: true, evented: true })
    this.handleCircle.set({ selectable: false, evented: false })
    this.handle1.set({ radius: HANDLE_RADIUS, selectable: true, evented: true })
    this.handle2.set({ radius: HANDLE_RADIUS, selectable: true, evented: true })
    this.#copyPosFromCircleToHandles()
    this.canvasObjects.forEach(e => e.setCoords())
  }

  unselected () {
    this.circle.set({ selectable: false, evented: false })
    this.handleCircle.set({ selectable: true, evented: true })
    this.handle1.set({ radius: 0, selectable: false, evented: false })
    this.handle2.set({ radius: 0, selectable: false, evented: false })
    this.#copyPosFromCircleToHandles()
    this.canvasObjects.forEach(e => e.setCoords())
  }

  update (clickTarget) {
    if ((clickTarget === this.handle1) || (clickTarget === this.handle2)) {
      if (clickTarget === this.handle1) {
        this.circle.set({
          left: clickTarget.left,
          top: clickTarget.top
        }).setCoords()
        this.handleCircle.set({
          left: this.circle.left,
          top: this.circle.top
        }).setCoords()
        this.handle2.set({
          left: clickTarget.left + this.circle.radius,
          top: clickTarget.top
        }).setCoords()
      } else {
        this.circle.set({
          radius: Math.sqrt((clickTarget.left - this.circle.left) ** 2 + (clickTarget.top - this.circle.top) ** 2)
        }).setCoords()
        this.handleCircle.set({
          radius: this.circle.radius
        }).setCoords()
      }
    } else {
      this.#copyPosFromCircleToHandles()
    }
  }

  freeze () {
    this.handleCircle.set({ selectable: false, evented: false })
  }

  unfreeze () {
    this.handleCircle.set({ selectable: true, evented: true })
  }

  toStoreData () {
    return {
      left: this.circle.left,
      top: this.circle.top,
      radius: this.circle.radius,
      stroke: this.circle.stroke,
      fill: this.circle.fill,
      strokeWidth: this.circle.strokeWidth,
      opacity: this.circle.opacity,
      site: this.site,
      zOrder: this.zOrder,
      typeOf: this.typeOf,
      id: this.id
    }
  }

  updateParamsFromStore (objData) {
    this.circle.set({
      stroke: objData.stroke,
      fill: objData.fill,
      strokeWidth: objData.strokeWidth,
      opacity: objData.opacity
    }).setCoords()
    this.zOrder = objData.zOrder
  }
}
