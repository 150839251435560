<template>
  <v-container
    fluid
    class="d-flex justify-center align-center"
    >
    <v-alert
      width = "500"
      type = "info"
      prominent
      >
      <p>{{$t('page 404')}}</p>
      <a @click = "$router.push({ name: 'projects' })" class="white--text">{{$t('page 404 back')}} >></a>
    </v-alert>
  </v-container>
</template>

<script>
export default {
  name: 'page404',

  components: {
  },

  data: () => ({
  }),

  methods: {
  }
}
</script>

<style scoped>
</style>
