import * as fabric from 'fabric'
import { calcZonesPoints } from './cameraCalcZone/calcZonesPoints'
import { calcFieldOfView } from './cameraCalcZone/calcFov'
import { positioningFoV } from './cameraCalcZone/positioningFoV'
import { getPointDensity } from './cameraCalcZone/getPointDensity'
import { getCamParams } from './cameraCalcZone/getCamParams'
import { dist2points } from './cameraCalcZone/mathFunction'
import { calcLightRadius } from './cameraCalcZone/calcLightRadius'
import { calcBitrate } from '~/assets/cameraBitrate'
import store from '~/store/index'
import i18n from '~/helpers/i18n'

const HANDLE_RADIUS = 3
const HANDLE_COLOR = '#1976d2'
const ALT_HANDLE_COLOR = '#FF5252'
const typeCam = { box: '\ue900', bullet: '\ue901', cube: '\ue902', dome: '\ue903', eyeball: '\ue904', pinhole: '\ue905', speedDome: '\ue906', wedge: '\ue907' }
const IR_COLOR = store.getters.LIGHT_COLORS.IR
const WL_COLOR = store.getters.LIGHT_COLORS.WL
const DEFAULT_CAM = store.getters.STORAGE_COMMON_CAMERA_SETTINGS

export default class Camera {
  constructor (objData = {}) {
    this.canvasObjects = [] // for canvas drawing
    this.requireNextClick = true // true - if necessary determinate more one vertex for object
    this.site = objData.site
    this.isShowFoV = store.getters.IS_SHOW_FOV
    this.typeOf = 'camera'
    this.id = objData.id
    this.zOrder = objData.zOrder
    this.attr = {
      name: objData.name || 'Cam',
      type: objData.type || 'box',
      matrixUserDef: objData.matrixUserDef || false,
      sizeX: +objData.sizeX || +4.92,
      AR: objData.AR || '16:9',
      resX: +objData.resX || +1920, // default resolution - FullHD
      corr: objData.corr || false, // corridor format
      focal: +objData.focal || +4, // in mm
      gamma: objData.gamma || 'auto', // in degree
      anglesUserDef: objData.anglesUserDef || false, // {alpha, beta} in degree or false
      typeInst: objData.typeInst || false, // false - simple, true - advance
      hCam: +objData.hCam || +2.7, // installation camera height, m
      hMaxAim: +objData.hMaxAim || +1.8, // top of aim, m
      hMinAim: +objData.hMinAim || +1, // bottom of aim, m
      light: +objData.light || false, // exist light
      lightIR: +objData.lightIR || 0, // m
      lightWL: +objData.lightWL || 0, // m
      group: objData.group || DEFAULT_CAM.group,
      codec: objData.codec || DEFAULT_CAM.codec,
      fps: objData.fps || DEFAULT_CAM.fps,
      activity: objData.activity || DEFAULT_CAM.activity,
      interval: objData.interval || DEFAULT_CAM.interval,
      bitrate: objData.bitrate || calcBitrate(DEFAULT_CAM.codec, DEFAULT_CAM.fps, 1920 ** 2 / (16 / 9) / 1000000, DEFAULT_CAM.activity)
    }
    this.icon = new fabric.Textbox(typeCam[this.attr.type], { //eslint-disable-line
      left: +objData.left || Infinity,
      top: +objData.top || Infinity,
      fill: objData.color || '#1976d2',
      fontFamily: 'icomoon',
      fontSize: objData.fontSize || 32,
      editable: false,
      cursorWidth: 0,
      originX: 'center',
      originY: 'center',
      hoverCursor: 'pointer',
      selectable: true,
      evented: true,
      hasBorders: false,
      hasControls: false,
      isSelectable: true, // for set selectable obj on canvas
      subTypeOf: 'icon',
      typeOf: this.typeOf,
      id: this.id
    })

    this.camName = new fabric.Textbox(this.attr.name, {
      fontFamily: 'Roboto',
      fontSize: 12,
      editable: false,
      cursorWidth: 0,
      originX: 'center',
      originY: 'bottom',
      selectable: false,
      evented: false,
      hasBorders: false,
      hasControls: false,
      caching: false,
      textAlign: 'center',
      typeOf: this.typeOf,
      id: this.id
    })

    this.dirHandle = new fabric.Circle({
      left: +objData.dirLeft || Infinity,
      top: +objData.dirTop || Infinity,
      radius: 0,
      fill: this.attr.gamma === 'auto' ? HANDLE_COLOR : ALT_HANDLE_COLOR,
      originX: 'center',
      originY: 'center',
      hoverCursor: 'nesw-resize',
      selectable: false,
      evented: false,
      hasBorders: false,
      hasControls: false,
      subTypeOf: 'handle',
      typeOf: this.typeOf,
      id: this.id
    })

    this.dirLine = new fabric.Line([], {
      stroke: '#999',
      strokeWidth: 0.5,
      strokeDashArray: [10, 4],
      opacity: 1,
      originX: 'center',
      originY: 'center',
      selectable: false,
      evented: false,
      hasBorders: false,
      hasControls: false,
      objectCaching: false,
      typeOf: this.typeOf,
      id: this.id
    })

    this.dist = new fabric.Textbox('', {
      fontFamily: 'Roboto',
      fontSize: 12,
      width: 100,
      textAlign: 'center',
      editable: false,
      cursorWidth: 0,
      originX: 'center',
      originY: 'bottom',
      selectable: false,
      evented: false,
      hasBorders: false,
      hasControls: false,
      typeOf: this.typeOf,
      id: this.id
    })

    this.density = new fabric.Textbox('230', {
      left: -Infinity,
      top: -Infinity,
      fontFamily: 'Roboto',
      fontSize: 12,
      width: 100,
      textAlign: 'left',
      editable: false,
      cursorWidth: 0,
      originX: 'left',
      originY: 'bottom',
      selectable: false,
      evented: false,
      hasBorders: false,
      hasControls: false,
      typeOf: this.typeOf,
      id: this.id
    })

    this.lightIR = new fabric.Circle({
      left: 0,
      top: 0,
      originX: 'left',
      originY: 'top',
      fill: 'transparent',
      stroke: IR_COLOR,
      strokeWidth: 0.5,
      strokeDashArray: [10, 4],
      selectable: false,
      evented: false,
      hasBorders: false,
      hasControls: false,
      subTypeOf: 'light',
      typeOf: this.typeOf,
      id: this.id
    })

    this.lightWL = new fabric.Circle({
      left: 0,
      top: 0,
      originX: 'left',
      originY: 'top',
      fill: 'transparent',
      stroke: WL_COLOR,
      strokeWidth: 0.5,
      strokeDashArray: [10, 4],
      selectable: false,
      evented: false,
      hasBorders: false,
      hasControls: false,
      subTypeOf: 'light',
      typeOf: this.typeOf,
      id: this.id
    })

    this.clipFoV = new fabric.Polygon([
      { x: 0, y: 0 },
      { x: store.getters.CANVASDATA.width, y: store.getters.CANVASDATA.height }
    ], {
      top: 0,
      left: 0,
      originX: 'center',
      originY: 'center',
      selectable: false,
      evented: false,
      subTypeOf: 'fov',
      typeOf: this.typeOf,
      id: this.id
    })

    this.FoV = new fabric.Group(
      store.getters.CAMZONE.map(_ =>
        new fabric.Polygon(
          [
            { x: 0, y: 0 },
            { x: store.getters.CANVASDATA.width, y: store.getters.CANVASDATA.height }
          ], {
            selectable: false,
            evented: false,
            typeOf: this.typeOf,
            id: this.id
          }
        )
      ), {
        left: 0,
        top: 0,
        opacity: 0.25,
        originX: 'left',
        originY: 'top',
        selectable: false,
        evented: false,
        subTypeOf: 'fov',
        typeOf: this.typeOf,
        id: this.id
      }
    )

    this.outFoV = new fabric.Group([this.FoV, this.lightIR, this.lightWL],
      {
        left: 0,
        top: 0,
        originX: 'left',
        originY: 'top',
        clipPath: this.clipFoV,
        selectable: false,
        evented: true,
        perPixelTargetFind: true,
        subTypeOf: 'fov',
        typeOf: this.typeOf,
        id: this.id
      }
    )
    this.#setDirLineCoords()
    this.#setCamNameCoords()
    this.#setCamIconAngle()
    this.#setDist()
    this.updateLightColors() // fix for init project
    this.canvasObjects.push(this.icon, this.camName, this.dirHandle, this.dirLine, this.outFoV, this.dist, this.density)
    this.limitsLines = []
  }

  // private method for set coords handleLine as line
  #setDirLineCoords = function () {
    this.dirLine.set({
      x1: this.icon.left,
      y1: this.icon.top,
      x2: this.dirHandle.left,
      y2: this.dirHandle.top
    }).setCoords()
  }

  #setDist = function () {
    this.dist.set({
      left: (this.icon.left + this.dirHandle.left) / 2,
      top: (this.icon.top + this.dirHandle.top) / 2,
      text: (dist2points({ x: this.icon.left, y: this.icon.top }, { x: this.dirHandle.left, y: this.dirHandle.top }) / store.getters.CURRENT_ZOOM / 1000).toFixed(1) + ' ' + i18n.t('m'),
      angle: 180 / Math.PI * Math.atan((this.icon.top - this.dirHandle.top) / (this.icon.left - this.dirHandle.left))
    }).setCoords()
  }

  #updateDensity = function (evt) {
    const position = {
      cam: { x: this.icon.left, y: this.icon.top },
      dir: { x: this.dirHandle.left, y: this.dirHandle.top }
    }
    const canvasData = { ...store.getters.CANVASDATA, zoom: store.getters.CURRENT_ZOOM }
    const camParams = getCamParams(position, this.attr, canvasData)
    const cam = {
      position,
      sizeX: this.attr.sizeX,
      AR: this.attr.AR,
      resX: this.attr.resX,
      corr: this.attr.corr,
      ...camParams
    }
    const density = getPointDensity(evt.pointer, cam, canvasData)
    this.density.set({
      left: evt.pointer.x + 4,
      top: evt.pointer.y - 2,
      text: density + ' ' + i18n.t('px') + '/' + i18n.t('m')
    }).setCoords()
    this.density.canvas.renderAll()
  }

  #setCamNameCoords = function () {
    this.camName.set({
      left: this.icon.left,
      top: this.icon.top - 12
    }).setCoords()
  }

  #setCamIconAngle = function () {
    if (['box', 'bullet', 'pinhole'].includes(this.attr.type)) {
      const angle = (Math.atan2(this.dirHandle.top - this.icon.top, this.dirHandle.left - this.icon.left) * 180) / Math.PI;
      (angle < 90 && angle > -90)
        ? this.icon.set({ angle: angle, flipX: false }).setCoords()
        : this.icon.set({ angle: Math.abs(angle + 180), flipX: true }).setCoords()
    } else {
      this.icon.set({ angle: 0, flipX: false }).setCoords()
    }
  }

  #zones = false
  #updateFoV = function () {
    const camZone = store.getters.CAMZONE
    if (store.getters.IS_SHOW_FOV) {
      const position = {
        cam: { x: this.icon.left, y: this.icon.top },
        dir: { x: this.dirHandle.left, y: this.dirHandle.top }
      }
      const canvasData = { ...store.getters.CANVASDATA, zoom: store.getters.CURRENT_ZOOM }
      const S = camZone.map(el => this.attr.resX * this.attr.focal / this.attr.sizeX / el.px) // array of distance, in m: px * mm / mm / (px / m) -> m
      const camParams = getCamParams(position, this.attr, canvasData)
      const checkLastCamZonePx = camZone.at(-1).px <= 1 ? 1 : 0 // check for last zone constract with distortion
      const zonesPoints = calcZonesPoints(camParams, S, checkLastCamZonePx)

      this.#zones = positioningFoV(position, zonesPoints, canvasData.zoom)
      this.#zones.zonesPoints.forEach((el, i) => {
        this.FoV.item(i).set({
          points: el,
          fill: store.getters.CAMZONE[i].color
        }).setCoords()
      })

      const updateLights = () => {
        this.outFoV.remove(this.lightIR, this.lightWL)
        this.lightIR.set({ radius: calcLightRadius(camParams, this.attr.lightIR, canvasData.zoom) })
        this.lightIR.set({
          left: this.icon.left - this.lightIR.width / 2,
          top: this.icon.top - this.lightIR.height / 2
        }).setCoords()
        this.lightWL.set({ radius: calcLightRadius(camParams, this.attr.lightWL, canvasData.zoom) }).setCoords()
        this.lightWL.set({
          left: this.icon.left - this.lightWL.width / 2,
          top: this.icon.top - this.lightWL.height / 2
        }).setCoords()
        this.outFoV.add(this.lightIR, this.lightWL)
      }

      updateLights()
    } else {
      camZone.forEach((_, i) => {
        this.FoV.item(i).set({ points: [0, 0] }).setCoords()
      })
      this.lightIR.set({ radius: 0 }).setCoords()
      this.lightWL.set({ radius: 0 }).setCoords()
    }
    this.updateClipFoV()
  }

  updateClipFoV = function () {
    if (!this.#zones) return
    if (!store.getters.IS_SHOW_FOV) return
    this.clipFoV.set({
      points: [
        ...calcFieldOfView(
          {
            x: this.icon.left,
            y: this.icon.top,
            dirX: this.dirHandle.left,
            dirY: this.dirHandle.top,
            angleFoV: this.#zones.fov.angle,
            radius: this.#zones.fov.radius,
            linesFoV: this.#zones.fov.lines
          },
          this.limitsLines
        ),
        ...(this.#zones.fov.radius2 // look down
          ? calcFieldOfView(
            {
              x: this.icon.left,
              y: this.icon.top,
              dirX: 2 * this.icon.left - this.dirHandle.left,
              dirY: 2 * this.icon.top - this.dirHandle.top,
              angleFoV: Math.PI,
              radius: this.#zones.fov.radius2,
              linesFoV: this.#zones.fov.lines2
            },
            this.limitsLines
          )
          : [])
      ]
    }).setCoords()
    this.outFoV
      .set({ clipPath: false })
      .set({ clipPath: this.clipFoV })
      .setCoords()
  }

  is (elemFabric) {
    return (elemFabric === this.icon || elemFabric === this.camName || elemFabric === this.dirHandle || elemFabric === this.dirLine || elemFabric === this.outFoV) && this
  }

  recalcFoVs () {
    this.FoV.forEachObject(el => this.FoV.remove(el))
    this.FoV.setCoords()
    store.getters.CAMZONE.map(_ => this.FoV.add(
      new fabric.Polygon(
        [
          { x: 0, y: 0 },
          { x: store.getters.CANVASDATA.width, y: store.getters.CANVASDATA.height }
        ], {
          selectable: false,
          evented: false,
          typeOf: this.typeOf,
          id: this.id
        }
      )
    ))
    this.#updateFoV()
  }

  updateLightColors () {
    this.lightIR.set({ stroke: store.getters.LIGHT_COLORS.IR })
    this.lightWL.set({ stroke: store.getters.LIGHT_COLORS.WL })
  }

  zooming (scale, point) { // point - point of zooming {x, y}, scale = zoomNew/zoomOld
    this.icon.set({
      left: point.x + scale * (this.icon.left - point.x),
      top: point.y + scale * (this.icon.top - point.y)
    }).setCoords()
    this.dirHandle.set({
      left: point.x + scale * (this.dirHandle.left - point.x),
      top: point.y + scale * (this.dirHandle.top - point.y)
    }).setCoords()
    this.#setDirLineCoords()
    this.#setCamNameCoords()
    this.#setCamIconAngle()
    this.#setDist()
    this.#updateFoV()
  }

  panning (startPoint, endPoint) { // point - {x, y}
    this.icon.set({
      left: endPoint.x - startPoint.x + this.icon.left,
      top: endPoint.y - startPoint.y + this.icon.top
    }).setCoords()
    this.dirHandle.set({
      left: endPoint.x - startPoint.x + this.dirHandle.left,
      top: endPoint.y - startPoint.y + this.dirHandle.top
    }).setCoords()
    this.#setDirLineCoords()
    this.#setCamNameCoords()
    this.#setCamIconAngle()
    this.#setDist()
    this.#updateFoV()
  }

  createFirstClick (event) {
    const evt = event.pointer
    this.icon.set({
      left: evt.x,
      top: evt.y
    }).setCoords()
    this.dirHandle.set({
      left: evt.x,
      top: evt.y
    }).setCoords()
  }

  createWaitNextClick (event) {
    const evt = event.pointer
    this.dirHandle.set({
      left: evt.x,
      top: evt.y
    }).setCoords()
    this.#setDirLineCoords()
    this.#setDist()
    this.#setCamIconAngle()
    this.#updateFoV()
  }

  createNextClick () {
    this.requireNextClick = false
    this.#setDist()
    this.#setCamNameCoords()
    this.#setCamIconAngle()
    this.#updateFoV()
  }

  selected () {
    this.icon.set({ hoverCursor: 'move' }).setCoords()
    this.dirHandle.set({ radius: HANDLE_RADIUS, selectable: true, evented: true }).setCoords()
    this.dirLine.set({ opacity: 1 })
    this.dist.set({ opacity: 1 })
    this.FoV.set({ opacity: 0.4 })
    this.outFoV.set({ hoverCursor: 'crosshair' })
    this.#setDirLineCoords()
    this.outFoV.on('mouseover', _ => this.density.set({ opacity: 1 }))
    this.outFoV.on('mouseout', _ => { this.density.set({ opacity: 0, left: -Infinity, top: -Infinity }); this.density.canvas.renderAll() })
    this.outFoV.on('mousemove', e => this.#updateDensity(e))
  }

  unselected () {
    this.icon.set({ hoverCursor: 'pointer' }).setCoords()
    this.dirHandle.set({ radius: 0, selectable: false, evented: false }).setCoords()
    this.dirLine.set({ opacity: 0 })
    this.dist.set({ opacity: 0 })
    this.density.set({ opacity: 0, left: -Infinity, top: -Infinity })
    this.FoV.set({ opacity: 0.25 })
    this.outFoV.set({ hoverCursor: 'default' })
    this.outFoV.off('mouseover')
    this.outFoV.off('mouseout')
    this.outFoV.off('mousemove')
  }

  update (clickTarget) {
    if (clickTarget.subTypeOf === 'icon') {
      this.dirHandle.set({
        left: this.icon.left + this.dirLine.x2 - this.dirLine.x1,
        top: this.icon.top + this.dirLine.y2 - this.dirLine.y1
      }).setCoords()
    }
    this.#setCamNameCoords()
    this.#setDirLineCoords()
    this.#setCamIconAngle()
    this.#setDist()
    this.#updateFoV()
  }

  freeze () {
    this.icon.set({ selectable: false, evented: false })
  }

  unfreeze () {
    this.icon.set({ selectable: true, evented: true })
  }

  updateDataFoV (limitsLines) {
    this.limitsLines = limitsLines
    this.updateClipFoV()
    this.#updateFoV()
  }

  toStoreData () {
    return {
      ...this.attr,
      left: this.icon.left,
      top: this.icon.top,
      dirLeft: this.dirHandle.left,
      dirTop: this.dirHandle.top,
      color: this.icon.fill,
      zOrder: this.zOrder,
      site: this.site,
      typeOf: this.typeOf,
      id: this.id
    }
  }

  updateParamsFromStore (objData) {
    this.attr = {
      name: objData.name,
      type: objData.type,
      sizeX: objData.sizeX,
      matrixUserDef: objData.matrixUserDef,
      AR: objData.AR,
      resX: objData.resX,
      corr: objData.corr,
      focal: objData.focal,
      gamma: objData.gamma,
      anglesUserDef: objData.anglesUserDef,
      typeInst: objData.typeInst,
      hCam: objData.hCam,
      hMaxAim: objData.hMaxAim,
      hMinAim: objData.hMinAim,
      light: objData.light,
      lightIR: objData.lightIR,
      lightWL: objData.lightWL
    }
    this.camName.set({
      text: objData.name
    })
    this.icon.set({
      fill: objData.color,
      text: typeCam[objData.type]
    })
    this.dirHandle.set({
      fill: objData.gamma === 'auto' ? HANDLE_COLOR : ALT_HANDLE_COLOR
    })
    this.#setCamIconAngle()
    this.#updateFoV()
    this.zOrder = objData.zOrder
  }
}
