import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const languages = Object.getOwnPropertyNames(loadLocaleMessages())

function loadLocaleMessages () {
  const locales = require.context(
    '@/locales', // Path to json-files i18n
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}
function checkDefaultLanguage () { // Define browser lang
  const navLang = navigator.language.split('-')[0]
  return languages.find(e => e === navLang)
}

export const selectedLocale = localStorage.getItem('locale') || checkDefaultLanguage() || 'en'
export { languages }
export default new VueI18n({
  locale: selectedLocale || 'en',
  globalInjection: true,
  fallbackLocale: 'en',
  messages: loadLocaleMessages()
})
