<template>
  <v-app>
    <v-container
      fluid id = "app-wrapper"
      class = "ma-0 pa-0 d-flex flex-column overflow-hidden"
      >
      <toolbar id = "app-bar"/>
      <div
        id = "page-body"
        class = "d-flex justify-center overflow-y-auto"
        >
        <router-view/>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import Toolbar from './components/Toolbar.vue'

export default {
  name: 'App',

  components: {
    Toolbar
  },

  data: () => ({
    //
  }),

  mounted () {
    document.documentElement.setAttribute('lang', this.$store.state.locale)
  }
}
</script>

<style>

  html {
    overflow-y: auto !important;
  }
  /* only scroll for chrome */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
</style>

<style scoped>

#app-wrapper {
  background-color: var(--v-primary-base) !important;
  flex-grow: 2;
  height: 100vh;
}

#app-bar {
  flex-grow: 0;
}

#page-body {
  flex-grow: 5;
  background-color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-top: 2px;
}
</style>
