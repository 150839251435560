<template>
<div>
  <v-slider
    :value="value * 100"
    v-on:change="$emit('input', +parseFloat($event, 10).toFixed(0) / 100)"
    class = "align-center slider"
    :max = "sliderParams.max"
    :min = "sliderParams.min"
    hide-details
    >
    <template v-slot:append>
      <v-text-field
        :value = "value * 100"
        v-on:change = "$emit('input', +parseFloat($event, 10).toFixed(0) / 100)"
        class="text-body-2 my-0"
        suffix="%"
        hide-details = "auto"
        style = "width: 60px"
        dense
        type = "number"
        :rules=rules.percent
      />
    </template>
  </v-slider>
</div>
</template>

<script>

export default {

  name: 'sliderWithData',

  components: {
  },

  props: ['value', 'sliderParams'],

  data: () => ({
  }),

  computed: {
    rules () {
      return {
        percent: [value => ((value !== '') && (+value >= 0) && (+value <= 100)) || this.$t('properties object out of range 0-100')]
      }
    }
  },

  methods: {

  },

  mounted () {
  }
}

</script>

<style scoped>
 >>> .v-slider--horizontal {
  margin-left: 0px !important;
}

</style>
