function calcStorage (cameras, streams, config, days) {
  const reqCap = requireCapacity() // GiByte
  const fullHDDGroup = Math.floor(reqCap / (backRAID(config.RAID, config.maxGroup) * config.capacityHDD * 931)) // 1TB = 931GiB
  const lastHDDGroupCapacity = reqCap - fullHDDGroup * backRAID(config.RAID, config.maxGroup) * config.capacityHDD * 931 // last group may contain fewer disks than a full group
  const lastGroupHDDQty = calcRAID(config.RAID, Math.ceil(lastHDDGroupCapacity / (config.capacityHDD * 931)))
  // add HDD with RAID + hotspare
  const qtyHDD = fullHDDGroup * config.maxGroup + lastGroupHDDQty + (fullHDDGroup + (lastGroupHDDQty ? 1 : 0)) * config.hotSpare
  function requireCapacity () {
    let capacity = 0 // in GiByte
    if (cameras.length > 0 || streams.length > 0) {
      capacity =
        cameras.reduce((sum, el) => +sum + el.bitrate / 8 / 1024 * days * el.interval * 60 * 60, 0) +
        streams.reduce((sum, el) => +sum + el.bitrate / 8 / 1024 * days * el.interval * 60 * 60 * el.qty, 0)
    }
    return capacity
  }

  function calcRAID (RAID, hdds) {
    let hddsOut
    switch (RAID) {
      case 'JBOD': hddsOut = hdds; break
      case 'RAID0': hddsOut = (hdds !== 1) ? hdds : 2; break
      case 'RAID1': hddsOut = hdds * 2; break
      case 'RAID5': hddsOut = (hdds !== 1) ? hdds + 1 : 3; break
      case 'RAID6': hddsOut = (hdds !== 1) ? hdds + 2 : 4; break
      case 'RAID10': hddsOut = (hdds !== 1) ? hdds * 2 : 4; break
      default: console.log('RAID type incorrect')
    }
    return hddsOut
  }

  function backRAID (RAID, hdds) {
    let hddsOut
    switch (RAID) {
      case 'JBOD': hddsOut = hdds; break
      case 'RAID0': hddsOut = hdds; break
      case 'RAID1': hddsOut = hdds / 2; break
      case 'RAID5': hddsOut = hdds - 1; break
      case 'RAID6': hddsOut = hdds - 2; break
      case 'RAID10': hddsOut = hdds / 2; break
      default: console.log('RAID type incorrect')
    }
    return hddsOut
  }

  function bandwidth () {
    let bandwidth = 0 // mbit
    if (cameras.length > 0 || streams.length > 0) {
      bandwidth =
        cameras.reduce((sum, el) => +sum + el.bitrate, 0) +
        streams.reduce((sum, el) => +sum + el.bitrate * el.qty, 0)
    }
    return bandwidth
  }

  return {
    bandwidth: bandwidth(),
    requireCapacity: reqCap,
    qtyHDD,
    fullHDDGroup: lastGroupHDDQty === +config.maxGroup ? fullHDDGroup + 1 : fullHDDGroup,
    lastGroupHDDQty: lastGroupHDDQty === +config.maxGroup ? 0 : lastGroupHDDQty,
    ...config
  }
}

function checkStorageBandwidth (a) {
  return a < 500
    ? { value: (+a).toFixed(2), unit1: 'mbit', unit2: 'sec' }
    : { value: (a / 1024).toFixed(2), unit1: 'gbit', unit2: 'sec' }
}

function checkStorageCapacity (a) {
  return a < 500
    ? { value: (+a).toFixed(2), unit1: 'gibyte', unit2: '' }
    : a < 550000
      ? { value: (+a / 1024).toFixed(2), unit1: 'tibyte', unit2: '' }
      : { value: (+a / 1024 / 1024).toFixed(2), unit1: 'pibyte', unit2: '' }
}

export { calcStorage, checkStorageBandwidth, checkStorageCapacity }
