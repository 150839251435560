<template>
  <div id="legend">
    <v-card class="ma-0 pa-1" max-width="350">
      <v-card-title class="justify-center text-body-2 ma-1 pa-1">
        {{$t('fov legend')}}
      </v-card-title>
      <v-card-text>
        <div class="ma-1 pa-1">
          <v-simple-table
            dense
            class="ma-0 pa-0"
            >
            <template #default>
              <thead>
                <tr>
                  <th class="text-center text-caption ">
                    {{$t('px') + '/' + $t('m')}}
                  </th>
                  <th class="text-center text-caption">
                    {{$t('settings zone name')}}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in camZone"
                  :key="item.id"
                >
                  <td class = "text-caption" >
                    <div
                      :style="{ 'background-color': item.color }"
                      class="pa-1 ma-1 rounded d-flex justify-center align-center font-weight-medium"
                      >
                      {{ item.px }}
                    </div>
                  </td>
                  <td class = "text-caption" > {{ $t(item.name) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {

  name: 'EditorLegend',

  data: () => ({
  }),

  computed: {
    ...mapState({
      camZone: state => state.editorStore.camZone
    })
  },

  methods: {
  },

  watch: {
  },

  updated () {
  },

  mounted () {
  }
}
</script>

<style scoped>

#legend {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
  opacity: 0.8;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto
}

#legend >>> tr:hover {
  background: transparent !important;
}

#legend >>> td, th {
  border: none !important;
}

</style>
