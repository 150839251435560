<template>
  <div>
    <v-list dense>

      <v-list-item>
        <v-icon color="primary" class="mr-2">mdi-email-outline</v-icon> <a href="mailto:support@survy.ru" class="text-decoration-none">support@survy.ru </a>
      </v-list-item>

      <v-list-item>
        <v-icon color="primary" class="mr-2">mdi-send</v-icon> <a href="https://t.me/survycctv" class="text-decoration-none" target="_blank">Telegram</a>
      </v-list-item>

      <v-list-item>
        <v-icon color="primary" class="mr-2">mdi-phone</v-icon> +7 (926) 339-04-25 ({{$t('about-moscow')}})
      </v-list-item>

      <v-list-item>
        {{$t('about-alexey')}}
      </v-list-item>

    </v-list>
  </div>
</template>

<script>
export default {

  name: 'ContactsBody',

  components: {
  },

  data: () => ({
  }),

  computed: {

  },

  methods: {

  },

  mounted () {

  }
}

</script>

<style scoped>

</style>
