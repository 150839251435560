<template>
  <v-dialog
    persistent
    width = "600px"
    :value = "isShowDialog"
    >
    <v-card>
      <v-card-title>
        <slot name = "dialog-title"/>
        <v-spacer/>
        <v-btn
          icon
          @click = "$emit('close')"
          >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-textarea
          v-model = "name"
          auto-grow
          :label = "$t('project name')"
          rows="1"
        />
        <v-textarea
          v-model = "description"
          auto-grow
          :label = "$t('project description')"
          rows="1"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          color = "primary"
          plain
          @click = "closeDialog()"
        >
          {{$t('cancel')}}
        </v-btn>
        <v-btn
          color="primary"
          plain
          :disabled = "(name === '')||(typeof name == 'undefined')"
          @click = "pushOK()"
        >
          <slot name = "dialog-btn-ok"/>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'ProjectDialog',

  components: {
  },

  model: {
    prop: 'showDialog',
    event: 'close'
  },

  props: {
    showDialog: {
      type: Boolean
    },

    item: {
      type: Object,
      default: function () {
        return { }
      }
    }
  },

  data: () => ({
    name: '',
    description: ''
  }),

  computed: {
    isShowDialog () {
      return this.showDialog
    }

  },

  watch: {
    isShowDialog () {
      if (this.item) {
        this.name = this.item.name
        this.description = this.item.description
      }
    }
  },

  methods: {

    pushOK () {
      this.$emit('project-info-edit', { name: this.name, description: this.description }, this.item.id)
      this.closeDialog()
    },

    closeDialog () {
      this.$emit('close', false)
    }

  }

}
</script>

<style scoped>

</style>
