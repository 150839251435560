<template>
  <v-card>
    <v-card-title :class = "shadow ? 'shadow' : ''">
      {{$t('list of views')}}
      <v-spacer/>
      <v-btn
        icon
        @click = "$emit('close')"
        >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text id = "body_views_dialog">
      <v-data-table
          :headers = "headers"
          :items = "siteViews"
          item-key="itemKey"
          class = "elevation-0 mt-1"
          :items-per-page = "-1"
          hide-default-footer
          hide-default-header
          group-by = "siteN"
          sort-by = "id"
          dense
          >
          <template #group.header = "{items, isOpen, toggle}">
            <th colspan="2">
              <div class ="d-flex align-center">
                <v-icon @click = "toggle" small class = "mr-2">
                  {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                </v-icon>
                <span class = "text-body-1 font-weight-medium">
                  {{ siteName(items[0].siteId) }}
                </span>
              </div>
            </th>
          </template>
          <template #item.name = "{ item }" >
            <div
              v-if="editItemId !== item.id "
              @click = "editItemId ? '' : setView(item)"
              :class = "editItemId ? '' : 'cursor-pointer'"
              class = "text-body-1"
              >
              {{item.name}}
            </div>
            <v-text-field v-else
              :value = "item.name"
              @input = "editName($event, item.id)"
              autofocus
              @blur = "editItemId = ''"
              dense
              hide-details = "auto"
              background-color = "transparent"
              class = "ma-0 pa-0"
              :rules = rules.name
            />
          </template>

          <template #item.actions = "{ item }">
              <v-btn
                @click = "editItemId = item.id"
                icon
                >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                icon
                @click = deleteView(item.id)
                >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
          </template>
          <template slot = "no-data">
            {{$t('table no data available')}}
          </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { eventBus } from '../../main'

export default {

  name: 'ViewsDialog',

  data: () => ({
    headers: [
      { text: '', value: 'name', align: 'left', cellClass: 'name-column' },
      { text: '', value: 'actions', align: 'center', cellClass: 'action-column' }
    ],
    editItemId: '',
    shadow: false
  }),

  computed: {
    ...mapState({
      VIEWS: state => state.editorStore.views,
      CURRENT_SITE: state => state.sitesStore.currentSite,
      SITES: state => state.sitesStore.sites
    }),

    siteViews () {
      const siteIds = this.SITES.map(el => el.id)
      return this.VIEWS
        .filter(el => el.id !== 'current') // remove current view
        .filter(el => this.VIEWS.filter(view => view.siteId === el.siteId).length > 1) // remove site with only current view
        .map(el => ({ ...el, itemKey: el.id + '-' + el.siteId, siteN: siteIds.indexOf(el.siteId) })) // add properties
    },

    rules () {
      return {
        name: [value => (value !== '') || this.$t('views must be not empty')]
      }
    }
  },

  methods: {
    editName (value, id) {
      this.rules.name.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
      this.$store.commit('UPDATE_VIEW', { id: id, name: value })
    },

    deleteView (id) {
      this.$store.commit('DELETE_VIEW', id)
    },

    setView (item) {
      if (item.siteId !== this.CURRENT_SITE) this.$store.commit('SET_CURRENT_SITE', item.siteId)
      // switch from current view
      const oldCenterCoords = this.VIEWS.find(el => el.siteId === this.CURRENT_SITE && el.id === 'current').centerGlobalCoords
      const oldZoom = this.VIEWS.find(el => el.siteId === this.CURRENT_SITE && el.id === 'current').zoom
      this.$store.commit('SET_CURRENT_ZOOM', { siteId: this.CURRENT_SITE, zoom: item.zoom })
      this.$store.commit('SET_CURRENT_GLOBAL_COORDS', { siteId: this.CURRENT_SITE, coords: item.centerGlobalCoords })
      eventBus.$emit('update-view', { pos: oldCenterCoords, zoom: oldZoom }, { pos: item.centerGlobalCoords, zoom: item.zoom }) // evt, start, end
    },

    setDrawerHeaderShadow (e) {
      this.shadow = e.target.scrollTop < 24 ? 0 : 1
    },

    siteName (siteId) {
      return this.SITES.find(el => el.id === siteId).name
    }
  },

  mounted () {
    this.$el.querySelector('#body_views_dialog').addEventListener('scroll', this.setDrawerHeaderShadow)
  },

  beforeDestroy () {
    this.$el.querySelector('#body_views_dialog').removeEventListener('scroll', this.setDrawerHeaderShadow)
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer !important
}

.v-data-table >>> tr:hover {
  background: transparent !important;
}

.v-data-table >>> .v-row-group__header {
  background: transparent !important;
}

.shadow {
  box-shadow: 0px 6px 5px 0px rgba(238, 238, 238, 0.5) !important;
  z-index: 100;
}

</style>

<style>
.name-column {
  width: 80%;
}

.action-column {
  width: 20%;
}
</style>
