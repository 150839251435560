<template>
  <v-dialog
    v-model = "dialog"
    fullscreen
    hide-overlay
    transition = "dialog-bottom-transition"
    >
    <v-card height = "100%" class = "justify-space-between">
      <v-toolbar
        height = "32px"
        style = "border-radius: 0"
        dark
        dense
        color = "primary"
        >
        <v-toolbar-title class="text-body-1">{{ $t('dxf_select_dialog') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            icon
            dark
            @click = "dialog = false"
            >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <DxfViewer
        ref="viewer"
        :dxfUrl="dxfUrl"
        :fonts="fonts"
        @dxf-loaded="_OnLoaded"
        @dxf-cleared="_OnCleared"
        @dxf-message="_OnMessage"
        @close = "dialog = false"
      />
      <LayersList
        id = "dxf-layer-list"
        :layers="layers"
        @toggleLayer="_OnToggleLayer"
        @toggleAll="_OnToggleAll"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { eventBus } from '../../main'
import DxfViewer from '../DxfViewer/DxfViewer'
import LayersList from '../DxfViewer/LayersList'
import { DxfViewer as _DxfViewer } from 'dxf-viewer'
import Vue from 'vue'
import mainFont from '../../assets/fonts/dxf-viewer/Roboto-LightItalic.ttf'
import aux1Font from '../../assets/fonts/dxf-viewer/NotoSansDisplay-SemiCondensedLightItalic.ttf'
import aux2Font from '../../assets/fonts/dxf-viewer/HanaMinA.ttf'
import aux3Font from '../../assets/fonts/dxf-viewer/NanumGothic-Regular.ttf'

export default {
  name: 'EditorDxfDialog',

  components: {
    LayersList,
    DxfViewer
  },

  props: {
    dialogVisible: Boolean,
    dxfUrl: [String, Boolean]
  },

  data: () => ({
    layers: null
  }),

  computed: {
    dialog: {
      get () {
        return this.dialogVisible
      },
      set () {
        this.$emit('close')
      }
    }
  },

  methods: {
    _OnLoaded () {
      const layers = this.$refs.viewer.GetViewer().GetLayers()
      layers.forEach(lyr => Vue.set(lyr, 'isVisible', true))
      this.layers = layers
    },

    _OnCleared () {
      this.layers = null
    },

    _OnToggleLayer (layer, newState) {
      layer.isVisible = newState
      this.$refs.viewer.GetViewer().ShowLayer(layer.name, newState)
    },

    _OnToggleAll (newState) {
      if (this.layers) {
        for (const layer of this.layers) {
          if (layer.isVisible !== newState) {
            this._OnToggleLayer(layer, newState)
          }
        }
      }
    },

    _OnMessage (e) {
      let type = 'info'
      switch (e.detail.level) {
        case _DxfViewer.MessageLevel.WARN:
          type = 'warning'
          break
        case _DxfViewer.MessageLevel.ERROR:
          type = 'negative'
          break
      }
      // console.log({ type, message: e.detail.message })
    }
  },

  watch: {
  },

  created () {
    this.fonts = [mainFont, aux1Font, aux2Font, aux3Font]
  }
}
</script>

<style scoped>
#dxf-layer-list {
  position: absolute;
  top: 60px;
  right: 0px;
  margin-right:30px;
}
</style>
