import axios from 'axios'
import { eventBus } from '~/main'
import router from '~/router'

const getDefaultState = () => {
  return {
    projects: [],
    tableView: {
      page: 1,
      itemsPerPage: 10,
      sortBy: null,
      sortDesc: null
    }
  }
}

export default {
  state: getDefaultState(),

  getters: {
    PROJECTS: state => state.projects,
    PROJECT_BY_ID: state => id => state.projects.find(el => el.id === id)
  },

  mutations: {
    DEFAULT_PROJECTS_STATE (state) {
      Object.assign(state, getDefaultState())
    },

    GET_PROJECTS_FROM_API: (state, projects) => {
      state.projects = projects
    },

    ADD_NEW_PROJECT: (state, projectData) => {
      const item = projectData
      delete item.status
      state.projects.push({ ...item, cameraCount: 0 })
      eventBus.$emit('project create', item.id)
    },

    RENAME_PROJECT: (state, projectData) => {
      const idx = state.projects.findIndex(e => e.id === projectData.id)
      state.projects[idx].name = projectData.name
      state.projects[idx].description = projectData.description
      state.projects[idx].updated = projectData.updated
    },

    DELETE_PROJECT: (state, projectId) => {
      const idx = state.projects.findIndex(e => +e.id === projectId)
      state.projects.splice(idx, 1)
    },

    CLONE_PROJECT: (state, projectData) => {
      const item = projectData
      delete item.status
      state.projects.push({ ...item })
      eventBus.$emit('project create', item.id)
    },

    SAVE_PROJECT: (state, projectData) => {
      const idx = state.projects.findIndex(e => e.id === projectData.id)
      state.projects[idx].updated = projectData.updated
      state.projects[idx].cameraCount = projectData.camera_count
    },

    SHARE_PROJECT: (state, projectData) => {
      const idx = state.projects.findIndex(e => e.id === projectData.id)
      state.projects[idx].shared_uri = projectData.shared_uri
      state.projects[idx].shared_uri_access = projectData.shared_uri_access
    },

    SET_TABLE_VIEW: (state, params) => {
      Object.assign(state.tableView, params)
    },

    DEFAULT_PROJECTS_STORE: (state) => {
      state.projects = []
      state.tableView = {
        page: null,
        itemsPerPage: null,
        sortBy: null,
        sortDesc: null
      }
    }
  },

  actions: {
    async GET_PROJECTS_FROM_API ({ commit }) {
      await axios.get('project')
        .then(response => commit('GET_PROJECTS_FROM_API', response.data.data))
        .catch(error => console.error(error, 'err: get project from api'))
    },

    async ADD_NEW_PROJECT ({ commit }, projectData) {
      await axios.post('project', projectData)
        .then(response => commit('ADD_NEW_PROJECT', response.data.data))
        .catch(error => console.error(error, 'err: add new project'))
    },

    async RENAME_PROJECT ({ commit }, projectDataId) {
      await axios.post('project/' + projectDataId.id + '/rename', projectDataId.data)
        .then(response => commit('RENAME_PROJECT', response.data.data))
        .catch(error => console.error(error, 'err: rename project'))
    },

    async DELETE_PROJECT ({ commit }, projectId) {
      await axios.delete('project/' + projectId)
        .then(_ => commit('DELETE_PROJECT', projectId))
        .catch(error => console.error(error, 'err: remove project'))
    },

    async CLONE_PROJECT ({ commit }, projectId) {
      await axios.post('project/' + projectId + '/clone')
        .then(response => commit('CLONE_PROJECT', response.data.data))
        .catch(error => console.error(error, 'err: clone project'))
    },

    async SAVE_PROJECT ({ commit }, projectData) {
      await axios.post('project/' + projectData.id, projectData.data)
        .then(response => commit('SAVE_PROJECT', response.data.data))
        .catch(error => console.error(error, 'err: save project'))
    },

    async SHARE_PROJECT ({ commit }, projectData) {
      await axios.post('project/' + projectData.id + '/share?access=' + projectData.shared_uri_access)
        .then(response => commit('SHARE_PROJECT', response.data.data))
        .catch(error => console.error(error, 'err: share project'))
    },

    async SAVE_SHARED_PROJECT ({ commit }, projectData) {
      await axios.post('project/shared/' + projectData.uri, projectData.data)
        .then(response => eventBus.$emit('shared-project-save', response.data.success))
        .catch(error => console.error(error, 'err: save shared project'))
    },

    async GET_PROJECT_DATA ({ commit }, projectId) {
      await axios.get('project/' + projectId)
        .then(response => this.dispatch('INIT_PROJECT', response.data.data))
        .catch(error => console.error(error, 'err: get project data'))
    },

    async GET_SHARED_PROJECT_DATA ({ commit }, uri) {
      await axios.get('project/shared/' + uri)
        .then(response => {
          if (!response.data.success) throw Error('err: shared project data not corrected')
          this.commit('SET_SHARED_TO_STATE', { name: response.data.name, access: response.data.access })
          this.dispatch('INIT_PROJECT', response.data.data)
        })
        .catch(error => {
          console.error(error, 'err: get project data')
          router.push({ name: 'sharedBlock' })
        })
    }
  }

}
