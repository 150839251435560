<template>
<v-dialog
  v-model = "dialog"
  fullscreen
  hide-overlay
  transition = "dialog-bottom-transition"
  scrollable
  >
  <template #activator="{ on, attrs }">
    <div
      v-bind="attrs"
      v-on="on"
      >
      <slot name="dialog-btn"/>
    </div>
  </template>
  <v-card align="center">
    <v-card-title class = "pa-0 ma-0" style = "z-index: 5">
      <v-toolbar
        height="32px"
        style="border-radius: 0"
        dark
        dense
        color = "primary"
      >
        <v-toolbar-title class="text-body-1">{{ $t('settings') }}</v-toolbar-title>
        <v-spacer/>
        <v-toolbar-items>
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <div id = "settings-wrapper" class="py-2 d-flex align-center" style="text-align: left" scrollable>
        <v-tabs vertical>
          <v-tab> {{$t('settings grids')}} </v-tab>
          <v-tab> {{$t('settings fov')}} </v-tab>
          <v-tab> {{$t('settings light')}} </v-tab>
          <v-tab-item> <settings-grids/> </v-tab-item>
          <v-tab-item> <settings-fov/> </v-tab-item>
          <v-tab-item> <settings-light/> </v-tab-item>
        </v-tabs>
      </div>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
import SettingsGrids from './SettingsGrids'
import SettingsFov from './SettingsFov'
import SettingsLight from './SettingsLight'

export default {

  name: 'SettingsDialog',

  components: {
    SettingsGrids,
    SettingsFov,
    SettingsLight
  },

  data: () => ({
    dialog: false
  })
}
</script>

<style scoped>

#settings-wrapper {
  max-width: 1100px;
}
.v-tab {
  justify-content: start;
  align-items: center;
}

</style>
