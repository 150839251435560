<template>
  <v-container fluid class="ma-0 pa-0">
    <properties-row>
      <template #name> {{ $t('camera name') }} </template>
      <v-text-field
        v-model.trim = "cameraName"
        class = "mx-2 my-1 text-body-2"
        hide-details = "auto"
        flat
        dense
        :rules=rules.name
      />
    </properties-row>

    <properties-row>
      <template #name> {{ $t('camera type') }} </template>
      <v-select
        v-model = "selectedType"
        :items = "matrixType"
        item-value = "type"
        dense
        class="text-body-2 my-0 mx-2"
        hide-details
        >
        <template #item = "{ item }">
          <v-icon>{{item.icon}}</v-icon>
          <span class="mx-2 text-body-2">{{ $t(item.descr) }}</span>
        </template>
        <template #selection = "data">
          <v-icon>{{data.item.icon}}</v-icon>
          <span class = "mx-2 text-body-2"> {{ $t(data.item.descr) }} </span>
        </template>
      </v-select>
    </properties-row>

    <properties-row>
      <template #name> {{ $t('color') }} </template>
      <color-picker v-model = "cameraColor"/>
    </properties-row>

    <properties-row>
      <template #name> <span class = "text-body-2 font-weight-medium primary--text ma-0 pa-0" dense color = "primary">{{ $t('camera image sensor') }}</span></template>
      <div class = "d-flex pa-0 ma-0">
        <v-switch
          v-model = "matrixUserDef"
          class = "mx-2 my-0 pa-0"
          dense
          hide-details
        />
        <span class = "text-body-2">{{$t('camera matrix user define')}}</span>
      </div>
    </properties-row>
    <!-- pre-config matrix params -->
    <div v-if="!matrixUserDef" class="pa-0 ma-0">
      <properties-row>
        <template #name> {{ $t('camera matrix size') }} </template>
        <v-select
          v-model = "selectedSize"
          :items = "matrixSize()"
          item-value = "sizeX"
          dense
          class = "text-body-2 my-0 mx-2"
          persistent-hint
          :hint = "$t('WxH') + ` ${selectedSize.sizeX}x${(selectedSize.sizeX / getARvalue()).toFixed(2)} ` + $t('mm')"
          >
          <template #item = "{ item }">
            <span class="mx-2 text-body-2"> {{item.name}} "</span>
          </template>
          <template #selection = "{ item }">
            <span class="mx-2 text-body-2"> {{item.name }} "</span>
          </template>
        </v-select>
      </properties-row>

      <properties-row>
        <template #name> {{ $t('camera matrix aspect ratio') }} </template>
          <v-radio-group
            v-model = "selectedAR"
            row
            dense
            hide-details
            class = "my-0 mx-1"
            >
          <v-radio value = "16:9">
            <template #label><span class="text-body-2 ml-0">16:9</span></template>
          </v-radio>
          <v-radio value = "4:3">
            <template #label><span class="text-body-2 ml-0">4:3</span></template>
          </v-radio>
        </v-radio-group>
      </properties-row>

      <properties-row>
        <template #name> {{ $t('camera matrix resolution') }} </template>
        <v-select
          v-model = "selectedRes"
          :items = "matrixRes()"
          item-value = "resX"
          dense
          class = "text-body-2 my-0 mx-2"
          persistent-hint
          :hint = "`${(selectedRes.resX **2 / getARvalue() / 1000000 ).toFixed(1)}` + $t('mpx') + ` ${selectedRes.name}`"
          >
          <template #item = "{ item }">
            <span class = "mx-2 text-body-2"> {{item.resX}}x{{(item.resX / getARvalue()).toFixed(0)}} px</span>
          </template>
          <template #selection = "{ item }">
            <span class = "mx-2 text-body-2"> {{item.resX}}x{{(item.resX / getARvalue()).toFixed(0)}} px</span>
          </template>
        </v-select>
      </properties-row>
    </div>
    <!-- end of pre-config matrix params -->
    <!-- user define matrix params -->
    <div v-if="matrixUserDef" class="pa-0 ma-0">
      <properties-row>
        <template #name> {{ $t('camera matrix aspect ratio') }} </template>
          <div class = "pa-0 ma-0 d-flex align-center">
            <v-text-field
              v-model = "ARHor"
              class = "mx-2 my-0 text-body-2 x-small-input"
              flat
              dense
              type = "number"
              hide-details = "auto"
              style = "flex-grow: 0"
              :rules = rules.AR
            />
            <span>:</span>
            <v-text-field
              v-model = "ARVert"
              class="mx-2 my-0 text-body-2 x-small-input"
              flat
              dense
              type = "number"
              hide-details = "auto"
              style = "flex-grow: 0"
              :rules = rules.AR
            />
          </div>
      </properties-row>

      <properties-row>
        <template #name> {{ $t('camera matrix dimensions') }} </template>
          <div class = "d-flex align-center">
            <v-text-field
              v-model = "userSizeX"
              :suffix = "$t('mm')"
              class="mx-2 my-0 text-body-2 small-input"
              flat
              dense
              type = "number"
              :hint = "$t('width')"
              persistent-hint
              :rules = rules.sizeX
            />
            <span class = "mx-2 my-0 text-body-2">x</span>
            <v-text-field
              :value = "`${ userSizeX / getARvalue() }`"
              :suffix = "$t('mm')"
              class = "mx-2 my-0 text-body-2 small-input"
              flat
              dense
              disabled
              :hint = "$t('height')"
              persistent-hint
            />
      </div>
      </properties-row>

      <properties-row>
        <template #name> {{ $t('camera matrix resolution') }} </template>
          <div class = "pa-0 ma-0 d-flex align-center">
            <v-text-field
              v-model = "userResX"
              :suffix = "$t('px')"
              class = "mx-2 my-0 text-body-2 small-input"
              flat
              dense
              :hint = "$t('width')"
              persistent-hint
              :rules = rules.resX
            />
            <span class = "mx-2 my-0 text-body-2">x</span>
            <v-text-field
              :value = "`${userResX / getARvalue() }`"
              :suffix = "$t('px')"
              class="mx-2 my-0 text-body-2 small-input"
              flat
              dense
              disabled
              :hint = "$t('height')"
              persistent-hint
            />
      </div>
      </properties-row>
    </div>
    <!-- end of user define matrix params -->

    <properties-row>
      <template #name> {{ $t('camera corridor format') }} </template>
      <v-checkbox class="pa-0 mx-1" dense hide-details v-model = "corrFormat" />
    </properties-row>

    <properties-row>
      <template #name> <span class = "text-body-2 font-weight-medium primary--text ma-0 pa-0" dense color="primary">{{ $t('camera lens') }} </span></template>
    </properties-row>

    <properties-row>
      <template #name> {{ $t('camera focal') }} </template>
      <v-text-field
        v-model = "focal"
        class = "mx-2 my-0 text-body-2 small-input"
        style = "flex-grow: 0"
        :suffix = "$t('mm')"
        flat
        dense
        hide-details = "auto"
        type="number"
        :rules = rules.focal
      />
    </properties-row>

    <properties-row>
      <template #name>
        <span v-if = "anglesDef"> {{ $t('camera viewing angles (user)') }} </span>
        <span v-else> {{ $t('camera viewing angles (calc)') }} </span>
      </template>
      <div class = "d-flex align-center">
        <div class = "d-flex flex-column">
          <v-text-field
            v-model="userAngleH"
            class="mx-2 my-0 text-body-2 small-input"
            flat
            dense
            :hint="$t('camera horizontal angle')"
            persistent-hint
            type="number"
            :rules = rules.userAngleH
            >
            <template #append><span class = "text-body-2">&deg;</span></template>
          </v-text-field>
          <v-text-field
            v-model = "userAngleV"
            class="mx-2 my-0 text-body-2 small-input"
            flat
            dense
            :hint = "$t('camera vertical angle')"
            persistent-hint
            type = "number"
            :rules = rules.userAngleV
            >
            <template #append><span class = "text-body-2">&deg;</span></template>
          </v-text-field>
        </div>
        <v-btn plain text small class = "text-initial" @click = "anglesDef = false">{{ $t('camera viewing angles calculate') }}</v-btn>
      </div>
    </properties-row>

    <properties-row>
      <template #name> <span class = "text-body-2 primary--text font-weight-medium ma-0 pa-0" dense color = "primary">{{ $t('camera fov calculation') }} </span></template>
      <div class = "d-flex pa-0 ma-0">
        <v-switch
          v-model = "typeInst"
          class="mx-2 my-0 pa-0"
          dense
          hide-details
        />
        <span class = "text-body-2">{{$t('camera advanced')}}</span>
      </div>
    </properties-row>

    <properties-row v-if = "typeInst">
      <template #name> {{ $t('camera angle') }} </template>
      <div class = "d-flex align-center">
        <v-text-field
          v-model = "gammaShow"
          class = "mx-2 my-0 text-body-2 small-input"
          style = "flex-grow: 0"
          flat
          dense
          hide-details = "auto"
          type = "number"
          :disabled = "gamma === 'auto' ? true : null"
          :rules = rules.gamma
        >
        <template #append><span class = "text-body-2">&deg;</span></template>
        </v-text-field>
        <v-btn plain text small class = "text-initial" @click = "setMethodGamma">{{ calcMethodBtnText }}</v-btn>
    </div>
    </properties-row>

    <properties-row v-if = "typeInst">
      <template #name> {{ $t('camera elevation') }} </template>
        <div class = "d-flex">
          <v-text-field
            v-model = "hCam"
            class = "mx-2 text-body-2 small-input"
            :suffix = "$t('m')"
            flat
            dense
            type = "number"
            step = "0.1"
            :hint = "$t('camera height installation')"
            persistent-hint
            :rules = rules.hCam
            :error = !checkElevation.hCam
          />
          <v-text-field
            v-model = "hMaxAim"
            class = "mx-2 text-body-2 small-input"
            :suffix = "$t('m')"
            flat
            dense
            type = "number"
            step = "0.1"
            :hint = "$t('camera top of aim')"
            persistent-hint
            :rules = rules.hMaxAim
            :error = !checkElevation.hMaxAim
          />
          <v-text-field
            v-model = "hMinAim"
            class = "mx-2 text-body-2 small-input"
            :suffix = "$t('m')"
            flat
            dense
            type = "number"
            step = "0.1"
            :hint = "$t('camera bottom of aim')"
            persistent-hint
            :rules = rules.hMinAim
            :error = !checkElevation.hMinAim
          />
        </div>
          <span
            v-if = "!Object.values(checkElevation).every(el => (typeof el === 'boolean') && el)"
            style = "max-width: 150px; text-align: left;"
            class = "error--text ma-0 pa-0 font-weight-regular text-caption"
            >
            {{$t('camera error elevation')}}
          </span>
    </properties-row>

    <properties-row>
      <template #name>
        <span class="text-body-2 font-weight-medium primary--text ma-0 pa-0" dense color="primary">{{ $t('camera light') }} </span>
      </template>
      <v-switch
          v-model = "light"
          class="mx-2 my-0 pa-0"
          dense
          hide-details
        />
    </properties-row>

    <properties-row v-if = "light">
      <template #name> {{ $t('camera light IR') }} </template>
        <v-text-field
          v-model = "lightIR"
          class = "mx-2 mr-5 my-0 text-body-2 small-input"
          style = "flex-grow: 0"
          :suffix = "$t('m')"
          flat
          dense
          type = "number"
          step = "5"
          :rules = rules.light
        />
    </properties-row>

    <properties-row v-if = "light">
      <template #name> {{ $t('camera light white') }} </template>
      <v-text-field
        v-model = "lightWL"
        class = "mx-2 my-0 text-body-2 small-input"
        style = "flex-grow: 0"
        :suffix = "$t('m')"
        flat
        dense
        type = "number"
        step = "5"
        :rules = rules.light
      />
    </properties-row>

  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import colorPicker from './EditorColorPicker'
import EditorPropertiesRow from './EditorPropertiesRow'
import { matrixSizeArray, matrixResolutionArray, matrixTypeArray } from '~/assets/cameraParams'

export default {

  name: 'EditorPropertiesCamera',

  components: {
    colorPicker,
    propertiesRow: EditorPropertiesRow
  },

  data: () => ({
    matrixType: matrixTypeArray,
    inputElevation: { hCam: '', hMaxAim: '', hMinAim: '' },
    focusLightWL: false,
    focusLightIR: false
  }),

  computed: {
    ...mapState({
      selObj: state => state.objectsStore.selObj,
      objectList: state => state.objectsStore.list
    }),

    cameraName: {
      get () {
        return this.obj ? this.obj.name : 'CAM'
      },
      set (value) {
        this.obj && this.rules.name.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
          this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, name: value })
      }
    },

    selectedType: {
      get () {
        return this.obj && this.matrixType.find(el => el.type === this.obj.type)
      },
      set (value) {
        this.obj && this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, type: value })
      }
    },

    cameraColor: {
      get () {
        return (this.obj) ? this.obj.color : '#000000'
      },
      set (value) {
        this.obj && this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, color: value })
      }
    },

    matrixUserDef: {
      get () {
        return this.obj.matrixUserDef
      },
      set (value) {
        this.obj && this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, matrixUserDef: Boolean(value) })
      }
    },

    selectedAR: {
      get () {
        return this.obj.AR
      },
      set (value) {
        this.obj && this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, AR: value })
      }
    },

    selectedSize: {
      get () {
        return this.obj && matrixSizeArray[this.obj.AR].find(el => el.sizeX === this.obj.sizeX)
      },
      set (value) {
        this.obj && this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, sizeX: +value })
      }
    },

    selectedRes: {
      get () {
        return this.obj && matrixResolutionArray[this.obj.AR].find(el => el.resX === this.obj.resX)
      },
      set (value) {
        this.obj && this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, resX: +value })
      }
    },

    ARHor: {
      get () {
        return this.obj.AR.split(':')[0]
      },
      set (value) {
        const a = String(value) + ':' + this.obj.AR.split(':')[1]
        this.obj && this.rules.AR.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
          this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, AR: a })
      }
    },

    ARVert: {
      get () {
        return this.obj.AR.split(':')[1]
      },
      set (value) {
        const a = this.obj.AR.split(':')[0] + ':' + value
        this.obj && this.rules.AR.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
          this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, AR: a })
      }
    },

    userSizeX: {
      get () {
        return this.obj && this.obj.sizeX
      },
      set (value) {
        this.obj && this.rules.sizeX.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
          this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, sizeX: +value })
      }
    },

    userResX: {
      get () {
        return this.obj && this.obj.resX
      },
      set (value) {
        this.obj && this.rules.resX.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
          this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, resX: +value })
      }
    },

    corrFormat: {
      get () {
        return this.obj && this.obj.corr
      },
      set (value) {
        this.obj && this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, corr: value })
      }
    },

    light: {
      get () {
        return this.obj && this.obj.light
      },
      set (value) {
        this.obj && this.rules.light.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
          this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, light: Boolean(value) })
      }
    },

    lightIR: {
      get () {
        if (!(this.obj && this.obj.lightIR)) this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, lightIR: 30 })
        return this.obj && this.obj.lightIR
      },
      set (value) {
        this.obj && this.rules.light.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
          this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, lightIR: value ? +value : 0 })
      }
    },

    lightWL: {
      get () {
        if (!(this.obj && this.obj.lightWL)) this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, lightWL: 35 })
        return this.obj && this.obj.lightWL
      },
      set (value) {
        this.obj && this.rules.light.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
          this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, lightWL: value ? +value : 0 })
      }
    },

    focal: {
      get () {
        return this.obj && this.obj.focal
      },
      set (value) {
        this.obj && this.rules.focal.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
          this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, focal: +value })
      }
    },

    anglesDef: {
      get () {
        return this.obj.anglesUserDef ? 1 : 0
      },
      set (value) {
        this.obj && this.$store.commit('UPDATE_OBJECT', {
          id: this.obj.id,
          anglesUserDef: value ? { alpha: +this.angleH, beta: +this.angleV } : false
        })
      }
    },

    userAngleH: {
      get () {
        return this.anglesDef ? this.obj.anglesUserDef.alpha : this.angleH
      },
      set (value) {
        if (!this.anglesDef) this.anglesDef = true

        this.obj && this.rules.userAngleH.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
          this.$store.commit('UPDATE_OBJECT', {
            id: this.obj.id,
            anglesUserDef: {
              alpha: +value,
              beta: this.obj.anglesUserDef.beta
            }
          })
      }
    },

    userAngleV: {
      get () {
        return this.anglesDef ? this.obj.anglesUserDef.beta : this.angleV
      },
      set (value) {
        if (!this.anglesDef) this.anglesDef = true

        this.obj && this.rules.userAngleV.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
          this.$store.commit('UPDATE_OBJECT', {
            id: this.obj.id,
            anglesUserDef: {
              alpha: this.obj.anglesUserDef.alpha,
              beta: +value
            }
          })
      }
    },

    angleH () {
      return (180 / Math.PI * 2 * Math.atan(this.obj.sizeX / 2 / this.obj.focal)).toFixed(2)
    },

    angleV () {
      return (180 / Math.PI * 2 * Math.atan(this.obj.sizeX / this.getARvalue() / 2 / this.obj.focal)).toFixed(2)
    },

    typeInst: {
      get () {
        return this.obj && this.obj.typeInst
      },
      set (value) {
        this.obj && this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, typeInst: Boolean(value) })
        this.setStartElevation()
      }
    },

    gamma: {
      get () {
        return this.obj && this.obj.gamma
      },
      set (value) {
        this.obj && this.rules.gamma.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
        this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, gamma: value === 'auto' ? value : +value })
      }
    },

    gammaShow: {
      get () {
        return this.gamma === 'auto' ? this.calcGamma : this.gamma
      },
      set (value) {
        this.gamma = value
      }
    },

    calcGamma () {
      const angle = !this.obj.corr
        ? this.obj.anglesUserDef ? this.userAngleV : this.angleV
        : this.obj.anglesUserDef ? this.userAngleH : this.angleH
      return (180 / Math.PI * Math.atan((this.obj.hCam - this.obj.hMaxAim) * 1000 / Math.sqrt((this.obj.left - this.obj.dirLeft) ** 2 + (this.obj.top - this.obj.dirTop) ** 2)) +
      angle / 2).toFixed(2)
    },

    calcMethodBtnText () {
      return this.gamma === 'auto' ? this.$t('camera method manual') : this.$t('camera method auto')
    },

    hCam: {
      get () {
        return this.obj && this.obj.typeInst && this.obj.hCam
      },
      set (input) {
        if (this.rules.hCam.map(f => f(input)).every(el => (typeof el === 'boolean') && el)) {
          this.inputElevation.hCam = +input
          this.updateElevation()
        }
      }
    },

    hMaxAim: {
      get () {
        return this.obj && this.obj.typeInst && this.obj.hMaxAim
      },
      set (input) {
        if (this.rules.hMaxAim.map(f => f(input)).every(el => (typeof el === 'boolean') && el)) {
          this.inputElevation.hMaxAim = +input
          this.updateElevation()
        }
      }
    },

    hMinAim: {
      get () {
        return this.obj && this.obj.typeInst && this.obj.hMinAim
      },
      set (input) {
        if (this.rules.hMinAim.map(f => f(input)).every(el => (typeof el === 'boolean') && el)) {
          this.inputElevation.hMinAim = +input
          this.updateElevation()
        }
      }
    },

    obj () {
      return (this.selObj)
        ? this.selObj
        : false
    },

    rules () {
      return {
        name: [value => (value.length <= 25) || this.$t('camera max 25 characters')],
        focal: [value => ((value !== '') && (+value > 0)) || (this.$t('camera must be more') + ' ' + '0')],
        angle: [value => ((value !== '') && (+value > 0) && (+value < 170)) || (this.$t('camera interval out of range') + ' ' + '0-170')],
        userAngleH: [value => ((value !== '') && (+value > 0) && (+value < 170) && (+value >= this.angleH) && (+value > this.userAngleV)) || (this.$t('camera interval out of range') + ' ' + this.angleH + '-170')],
        userAngleV: [value => ((value !== '') && (+value > 0) && (+value < 170) && (+value >= this.angleV) && (+value < this.userAngleH)) || (this.$t('camera interval out of range') + ' ' + this.angleV + '-' + this.userAngleH)],
        gamma: [value => ((value === 'auto') || ((value !== '') && (+value >= 1) && (+value <= 90 + (this.anglesDef ? this.userAngleV / 2 : this.angleV / 2)))) || (this.$t('camera interval out of range') + ' 1-' + (90 + (this.anglesDef ? this.userAngleV / 2 : this.angleV / 2)).toFixed(1))],
        hCam: [value => ((value !== '') && (+value > 0)) || (this.$t('camera must be more') + ' ' + '0')],
        hMaxAim: [value => ((value !== '') && (+value > 0)) || (this.$t('camera must be more') + ' ' + '0')],
        hMinAim: [value => ((value !== '') && (+value > 0)) || (this.$t('camera must be more') + ' ' + '0')],
        AR: [value => ((value !== '') && (+value > 0)) || (this.$t('camera must be more') + ' ' + '0')],
        sizeX: [value => ((value !== '') && (+value > 0)) || (this.$t('camera must be more') + ' ' + '0')],
        resX: [value => ((value !== '') && (+value > 400)) || (this.$t('camera must be more') + ' ' + '400')],
        light: [value => ((!value) || (+value >= 0)) || (this.$t('camera must be more') + ' ' + '0')]
      }
    },

    checkElevation () {
      return {
        hCam: (this.inputElevation.hCam > this.inputElevation.hMaxAim) ? true : false, // eslint-disable-line
        hMaxAim: ((this.inputElevation.hMaxAim < this.inputElevation.hCam) && (this.inputElevation.hMaxAim > this.inputElevation.hMinAim)) ? true : false, // eslint-disable-line
        hMinAim: (this.inputElevation.hCam > this.inputElevation.hMaxAim) ? true : false // eslint-disable-line
      }
    }
  },

  methods: {
    matrixSize () {
      return this.obj.AR === '16:9'
        ? matrixSizeArray['16:9'].reverse()
        : matrixSizeArray['4:3'].reverse()
    },

    matrixRes () {
      return this.obj.AR === '16:9'
        ? matrixResolutionArray['16:9']
        : matrixResolutionArray['4:3']
    },

    getARvalue () {
      const arr = this.obj.AR.split(':')
      return +arr[0] / +arr[1]
    },

    updateElevation () {
      if (this.checkElevation.hCam) this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, hCam: this.inputElevation.hCam })
      if (this.checkElevation.hMaxAim) this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, hMaxAim: this.inputElevation.hMaxAim })
      if (this.checkElevation.hMinAim) this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, hMinAim: this.inputElevation.hMinAim })
    },

    setStartElevation () {
      this.inputElevation.hCam = this.hCam
      this.inputElevation.hMaxAim = this.hMaxAim
      this.inputElevation.hMinAim = this.hMinAim
    },

    setMethodGamma () {
      this.gamma = this.gamma === 'auto' ? this.calcGamma : 'auto'
    }
  },

  watch: {
    selectedAR () { // if change AR - set default params
      if (!this.matrixUserDef) {
        this.selectedSize = matrixSizeArray[this.obj.AR].find(el => el.default === true).sizeX
        this.selectedRes = matrixResolutionArray[this.obj.AR].find(el => el.default === true).resX
      }
    },

    matrixUserDef () {
      if (!this.matrixUserDef) {
        this.selectedAR = '16:9'
        this.selectedSize = matrixSizeArray[this.obj.AR].find(el => el.default === true).sizeX
        this.selectedRes = matrixResolutionArray[this.obj.AR].find(el => el.default === true).resX
      }
    },

    typeInst () {
      this.gamma = 'auto'
    },

    focal () {
      if (this.anglesDef) this.anglesDef = false
    },

    userAngleH () {
      if (this.anglesDef && (Math.abs(this.userAngleH - this.angleH) < 0.05) && (Math.abs(this.userAngleV - this.angleV) < 0.05)) this.anglesDef = false
    },

    userAngleV () {
      if (this.anglesDef && (Math.abs(this.userAngleH - this.angleH) < 0.05) && (Math.abs(this.userAngleV - this.angleV) < 0.05)) this.anglesDef = false
    }
  },

  mounted () {
    this.setStartElevation()
  }
}

</script>

<style scoped>
.text-initial {
  text-transform: initial !important;
}

.small-input {
  width: 70px !important
}

.x-small-input {
  width: 35px !important
}
</style>
