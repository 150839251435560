<template>
  <v-dialog
    id = "report_dialog"
    v-model = "dialog"
    persistent
    scrollable
    width = "600px"
    >
    <template #activator = "{ on, attrs }">
      <div
        v-bind = "attrs"
        v-on = "on"
        >
        <slot name = "dialog-btn"/>
      </div>
    </template>
    <v-card>
      <v-card-title :class = "shadow ? 'shadow' : ''">
        {{$t('project_report_title')}}
        <v-spacer/>
        <v-btn icon @click = "closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text id = "body_report_dialog">
        <div class = "text-h6">{{$t('project_report_content')}}</div>
        <v-checkbox
          :label = "$t('project_report_annotation')"
          hide-details
          v-model = "annotationReport"
        />
        <v-checkbox
          :label = "$t('project_report_cameras')"
          hide-details
          v-model = "camerasReport"
        />
        <v-checkbox
          :label = "$t('project_report_plans')"
          hide-details
          v-model = "plansReport"
        />
        <v-expand-transition>
          <v-alert v-if = "plansReport && views.length > 1"
            border = "left"
            colored-border
            color = "primary"
            elevation = "0"
            class="pa-2 ma-2"
            >
            <v-data-table
              v-model = "selectedViews"
              :headers = "headers"
              :items = "views"
              item-key="itemKey"
              class = "elevation-0 ma-2 my-0"
              :items-per-page = "-1"
              show-select
              hide-default-header
              hide-default-footer
              group-by = "siteN"
              sort-by = "id"
              dense
              >
              <template #group.header = "{ items }">
                <th colspan="3">
                  <div class ="d-flex align-center">
                    <span class = "text-body-1 font-weight-medium">
                      {{ siteName(items[0].siteId) }}
                    </span>
                  </div>
                </th>
              </template>
              <template #item.data-table-select = "{ isSelected, select }">
                <v-simple-checkbox
                  class = "ma-0 pa-0"
                  color = "primary"
                  :value = "isSelected"
                  @input = "select($event)"
                />
              </template>
              <template #item.name = "{ item }" >
                <div v-if = "item.name !== 'current'" class = "text-body-1">
                  {{item.name}}
                </div>
                <div v-else class = "text-body-1">
                  {{$t('project_report_current')}}
                </div>
              </template>
              <template slot = "no-data">
                {{$t('table no data available')}}
              </template>
            </v-data-table>
          </v-alert>
        </v-expand-transition>
        <v-checkbox
          :label = "$t('project_report_storage')"
          hide-details
          v-model = "storageReport"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          color = "primary"
          plain
          @click = "closeDialog()"
        >
          {{$t('project_report_cancel')}}
        </v-btn>
        <v-btn
          color = "primary"
          plain
          @click = "clickGetReport()"
          :disabled = "!checkSelectReport"
          >
          {{$t('project_report_ok')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { getReport } from './GetReport'
import { eventBus } from '~/main'

export default {
  name: 'ReportDialog',

  data: () => ({
    dialog: false,
    annotationReport: true,
    camerasReport: true,
    plansReport: true,
    storageReport: true,
    headers: [
      { text: '', value: 'name', align: 'left', sortable: false, cellClass: 'column-width-95' }
    ],
    selectedViews: [],
    shadow: false // for shadow toolbar
  }),

  computed: {
    ...mapState({
      SITES: state => state.sitesStore.sites,
      VIEWS: state => state.editorStore.views
    }),

    ...mapGetters([
      'TO_DB',
      'PROJECT_BY_ID'
    ]),

    checkSelectReport () {
      return this.camerasReport || this.selectedViews.length !== 0 || this.storageReport
    },

    projectInfo () {
      return this.PROJECT_BY_ID(+this.$route.params.id)
    },

    views () {
      const siteIds = this.SITES.map(el => el.id)
      return this.VIEWS.map(el => ({ ...el, itemKey: el.id + '-' + el.siteId, siteN: siteIds.indexOf(el.siteId) })) // add properties
    }
  },

  methods: {
    clickGetReport () {
      this.selectedViews.length !== 0
        ? eventBus.$emit('need-report-views', this.selectedViews)
        : eventBus.$emit('ready-report-views', [])
      this.closeDialog()
    },

    async createReport (imgViews) {
      try {
        await getReport(
          {
            annotation: this.annotationReport,
            cameras: this.camerasReport,
            views: this.viewsReport,
            storage: this.storageReport
          },
          this.projectInfo, // WARNING! projectInfo include old info about sites! Take info from_DB
          this.TO_DB,
          imgViews
        )
      } catch (err) {
        console.error('createReport error', err)
      } finally {
        eventBus.$emit('report-ready')
      }
    },

    closeDialog () {
      this.dialog = false
    },

    setDrawerHeaderShadow (e) {
      this.shadow = e.target.scrollTop < 24 ? 0 : 1
    },

    siteName (siteId) {
      return this.SITES.find(el => el.id === siteId).name
    }

  },

  watch: {
    views (newViews, oldViews) {
      let selKeys = this.selectedViews.map(el => el.itemKey)
      const newKeys = newViews.map(el => el.itemKey)
      const oldKeys = oldViews.map(el => el.itemKey)
      const newElem = newKeys.filter(x => !oldKeys.includes(x))
      selKeys.push(...newElem) // add new elems
      const delElem = oldKeys.filter(x => !newKeys.includes(x))
      selKeys = selKeys.filter(x => !delElem.includes(x)) // delete old views
      this.selectedViews = [...this.views.filter(x => selKeys.includes(x.itemKey))] // create new data for selected views
    },

    plansReport (value) {
      this.selectedViews = value ? this.views : []
    }
  },

  mounted () {
    eventBus.$on('ready-report-views', this.createReport)
  },

  beforeDestroy () {
    eventBus.$off(['ready-report-views'])
  },

  updated () {
    const report = document.querySelector('#body_report_dialog')
    if (report) {
      this.dialog
        ? report.addEventListener('scroll', this.setDrawerHeaderShadow)
        : report.removeEventListener('scroll', this.setDrawerHeaderShadow)
    }
  }
}
</script>

<style scoped>
.v-data-table >>> tr:hover {
  background: transparent !important;
}

.v-data-table >>> .v-data-table__selected:hover {
  background: transparent !important;
}

.v-data-table >>> .v-data-table__selected {
  background: transparent !important;
}

.v-data-table >>> .v-row-group__header {
  background: transparent !important;
}

.shadow {
  box-shadow: 0px 6px 5px 0px rgba(238, 238, 238, 0.5) !important;
  z-index: 100;
}

.v-alert >>> .v-alert__border--left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

</style>

<style>
.column-width-95 {
  width: 95% !important;
}
.column-width-5 {
  width: 5% !important;
  margin: 0 !important;
  padding: 0 !important;
}
</style>
