<template>
  <div>
    <v-row dense style="min-height: 38px;" class="my-1">
      <v-col cols="4" align-self="center" align="right" class="text-body-2 text-break">
        <slot/>
      </v-col>
      <v-col cols="1"/>
      <v-col cols="3" align-self="center" align="center">
        <slot name = "primary"/>
      </v-col>
      <v-col cols="1"/>
      <v-col cols="3" align-self="center"  align="center">
        <slot name = "secondary"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {

  name: 'EditorSettingsRow'

}

</script>

<style scoped>

</style>
