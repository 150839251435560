<template>
  <v-dialog
    v-model = "dialogShare"
    width = "400"
    >
    <template #activator="{ on, attrs }">
      <div
        v-bind = "attrs"
        v-on = "on"
        >
        <slot name = "dialog-btn"/>
      </div>
    </template>
    <v-card>
      <v-card-title>
        {{$t('project_share')}}
        <v-spacer/>
        <v-btn
          icon
          @click = "dialogShare = false"
          >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class = "my-0">
        <div class = "ma-1">
          <div class = "text-h6" >{{ project.name }}</div>
          <div v-if = "project.description && project.description !== ''" class = "text-caption" >{{ project.description }}</div>
        </div>
        <v-expand-transition>
        <div class = "d-flex align-center" v-if = "sharedUriAccess !== 'none'">
          <v-text-field
            :value = "sharedUri"
            prepend-inner-icon = "mdi-link-variant"
            single-line
            readonly
            ref = "urlString"
          />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
            <v-btn
                icon
                @click = "copyTextToClipboard()"
                class = "ml-1"
                v-bind = "attrs"
                v-on = "on"
                >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('project_share_copy_to_clipboard') }}</span>
          </v-tooltip>
          <!-- <v-tooltip bottom>
            <template #activator="{ on, attrs }">
            <v-btn
                icon
                @click = "sendMailUrl()"
                class = "ml-1"
                v-bind = "attrs"
                v-on = "on"
                >
                <v-icon>mdi-email-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('project_share_email') }}</span>
          </v-tooltip> -->
        </div>
        </v-expand-transition>
        <v-radio-group
          v-model = "sharedUriAccess"
          class = "ma-0 pa-0"
          hide-details
          >
          <v-radio
            value = 'view'
            :label="$t('project_share_read_only')"
          />
          <v-radio
            value = 'edit'
            :label="$t('project_share_read_and_write')"
          />
          <v-radio
            value = 'none'
            on-icon = "mdi-close-circle-outline"
            :label="$t('project_share_close_access')"
          />
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          color = "primary"
          text
          @click = "dialogShare = false; $emit('input', false)"
          >
          {{$t('project_share_dialog_close')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {

  name: 'ProjectShareDialog',

  components: {
  },

  props: [
    'project'
  ],

  data: () => ({
    dialogShare: false
  }),

  computed: {
    ...mapGetters([
      'PROJECT_BY_ID'
    ]),

    sharedUriAccess: {
      get () {
        return this.PROJECT_BY_ID(this.project.id).shared_uri_access
      },
      set (value) {
        this.SHARE_PROJECT({ shared_uri_access: value, id: this.project.id })
      }
    },

    sharedUri () {
      const baseSharedUri = (process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://survy.ru') + '/shared/'
      return baseSharedUri + this.PROJECT_BY_ID(this.project.id).shared_uri
    }
  },

  methods: {
    ...mapActions([
      'SHARE_PROJECT'
    ]),

    copyTextToClipboard () {
      const input = this.$refs.urlString.$el.querySelector('input')
      if (!navigator.clipboard) {
        input.focus()
        document.execCommand('copy')
        input.blur()
      } else {
        navigator.clipboard.writeText(input.value)
          .catch(_ => {
            throw Error('Cannot copy to clipboard')
          })
      }
    },

    sendMailUrl () {
      const newTab = window.open('mailto:?subject=' + this.$t('project_share_mail_subject') + '&body=' + this.$refs.urlString.$el.querySelector('input').value)
      newTab.close()
    }
  }
}

</script>

<style scoped>
.text-initial {
  text-transform: initial !important;
}

</style>
