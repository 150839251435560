function calcLightRadius (cam, radius, zoom) { // camParams = alpha, beta, gamma, focal, typeInst, hCam, hMaxAim, hMinAim, distortion, DH in m, angles in radians
  let outRadius = 0
  if (cam.typeInst) {
    const level = cam.hCam - (cam.hMaxAim + cam.hMinAim) / 2
    const gamma = cam.gamma === 'auto' ? Math.atan((cam.hCam - cam.hMaxAim) / cam.DH) + cam.beta / 2 : cam.gamma
    const r = Math.sqrt(radius ** 2 - level ** 2)
    const R = level * Math.sqrt(
      (Math.cos(cam.beta / 2) * Math.tan(cam.alpha / 2) / Math.sin(gamma - cam.beta / 2)) ** 2 +
      1 / Math.tan(gamma - cam.beta / 2) ** 2
    )
    outRadius = R > r ? r * zoom * 1000 : 0
  } else {
    const R = cam.DH / Math.cos(cam.alpha / 2)
    outRadius = R > radius ? radius * zoom * 1000 : 0
  }
  return cam.light ? outRadius : 0
}

export { calcLightRadius }
