<template>
<div>
  <v-menu
    offset-y
    ref = "menu-more"
    style = "z-index: 150"
    transition="slide-y-transition"
    >
    <template #activator="{ on: menu, attrs }">
      <div
        v-bind = "attrs"
        v-on = "menu"
        >
        <slot name = "btn"/>
      </div>
    </template>
    <v-list style = "width: 250px">
      <v-list-item
        v-for = "(menuItem, idx) in menus"
        v-show = "!($store.state.locale === 'en' && menuItem.name === 'help')"
        :key = "idx"
        link
        class="align-stretch"
        >
        <div
          class="d-flex flex-grow-1 my-1"
          @click.stop = "menuItem.name !== 'help' ? showDialog(menuItem) : clickGuide()"
          >
          <v-icon class = "pa-0 ma-0">
            {{menuItem.icon}}
          </v-icon>
          <div class = "align-self-center ml-3">
            {{$t(menuItem.name)}}
          </div>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
  <v-dialog
    v-model = "isShowDialog"
    width = "600"
    scrollable
    >
    <component :is = "dialogComponent" @input = "closeDialog()"/>
  </v-dialog>
</div>
</template>

<script>
import AboutDialog from './TBMore/AboutDialog'
import Pricing from './TBMore/Pricing'
import License from './TBMore/License'
import Changelog from './TBMore/Changelog'
import TermsOfUse from './TBMore/TermsOfUse'
import AutocadBlock from './TBMore/AutocadBlock'
import Contacts from './TBMore/Contacts'

export default {

  name: 'ToolbarMenuMore',

  components: {
  },

  props: [
    'item'
  ],

  data: () => ({
    menus: [
      { name: 'about', icon: 'mdi-information-outline', component: AboutDialog },
      // { name: 'pricing', icon: 'mdi-currency-usd', component: Pricing },
      { name: 'changelog', icon: 'mdi-notebook-edit-outline', component: Changelog },
      { name: 'terms of use', icon: 'mdi-copyright', component: TermsOfUse },
      { name: '3rd party software', icon: 'mdi-scale-balance', component: License },
      { name: 'autocad block', icon: 'mdi-caps-lock', component: AutocadBlock },
      { name: 'help', icon: 'mdi-help-circle-outline', component: '' },
      { name: 'contacts', icon: 'mdi-email-outline', component: Contacts }
    ],
    isShowDialog: false,
    dialogComponent: undefined
  }),

  computed: {

  },

  methods: {
    clickGuide () {
      window.open('/help', '_blank')
    },

    showDialog (menuItem) {
      this.isShowDialog = true
      this.dialogComponent = menuItem.component
      this.$refs['menu-more'].isActive = false
    },

    closeDialog () {
      this.isShowDialog = false
      this.dialogComponent = undefined
    }
  },

  mounted () {
  }
}

</script>

<style scoped>

</style>
