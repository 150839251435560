<template>
<v-btn
    text
    color = "primary"
    class = "text-none"
    :disabled = "disabled"
    @click = "$emit('click')"
  >
  <v-icon>
    {{btnData.icon}}
  </v-icon>
  {{$t(btnData.descr)}}
</v-btn>
</template>

<script>
export default {

  name: 'TableBtn',

  props: {
    btnData: Object,
    disabled: {
      type: Boolean,
      default: false
    }
  },

  components: {

  },

  data: () => ({
  }),

  computed: {
  },

  methods: {
  },

  mounted () {
  }

}

</script>

<style scoped>

</style>
