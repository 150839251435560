import Vue from 'vue'
import Vuex from 'vuex'
import i18n, { selectedLocale } from '../helpers/i18n'
import auth from './modules/auth'
import projectsStore from './modules/projectsStore'
import editorStore from './modules/editorStore'
import objectsStore from './modules/objectsStore'
import storageStore from './modules/storageStore'
import sitesStore from './modules/sitesStore'
import { eventBus } from '~/main'
import { version } from '../../package'
import { versionCompare } from '../helpers/versionCompare'
import { matrixSizeArray } from '~/assets/cameraParams'
import { matrixSizeArray as oldMatrixSizeArray } from '~/assets/oldCameraParams'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth, // user auth params
    projectsStore, // data of projects
    editorStore, // properties of canvas, mesh and coords
    objectsStore, // objects on canvas, cameras and etc
    storageStore, // calc Storage data
    sitesStore // sites inside one project
  },

  state: {
    locale: selectedLocale
  },

  mutations: {
    // i18n
    UPDATE_LOCALE (state, newLocale) {
      i18n.locale = newLocale
      state.locale = newLocale
      localStorage.setItem('locale', newLocale)
    }
  },

  getters: {
    TO_DB () {
      return {
        // objects
        cameras: objectsStore.state.list.filter(el => el.typeOf === 'camera'),
        limitsLines: objectsStore.state.list.filter(el => el.typeOf === 'limitsLine'),
        images: objectsStore.state.list.filter(el => el.typeOf === 'layoutImg'),
        lines: objectsStore.state.list.filter(el => el.typeOf === 'layoutLine'),
        rectangles: objectsStore.state.list.filter(el => el.typeOf === 'layoutRect'),
        circles: objectsStore.state.list.filter(el => el.typeOf === 'layoutCircle'),
        brushes: objectsStore.state.list.filter(el => el.typeOf === 'layoutBrush'),
        texts: objectsStore.state.list.filter(el => el.typeOf === 'layoutText'),
        equip: objectsStore.state.list.filter(el => el.typeOf === 'layoutEquip'),
        // editor
        canvasData: {
          centerGlobalCoords: editorStore.state.canvasData.centerGlobalCoords,
          zoom: editorStore.state.canvasData.zoom
        },
        grids: editorStore.state.grids,
        camZone: editorStore.state.camZone,
        lightColors: editorStore.state.lightColors,
        views: editorStore.state.views,
        legend: editorStore.state.legend,
        // storage
        archiveDays: storageStore.state.archiveDays,
        streams: storageStore.state.streams,
        commonConfigSettings: storageStore.state.commonConfigSettings,
        commonCameraSettings: storageStore.state.commonCameraSettings,
        storageConfigs: storageStore.state.storageConfigs,
        // sites
        sites: sitesStore.state.sites,
        currentSite: sitesStore.state.currentSite,
        // application version
        version
      }
    },

    CHECK_STORE_CHANGE () {
      return {
        objects: objectsStore.state.list.filter(el => el.typeOf !== 'tape'),
        grids: editorStore.state.grids,
        lightColors: editorStore.state.lightColors,
        camZone: editorStore.state.camZone,
        views: editorStore.state.views.filter(e => e.id !== 'current'),
        archiveDays: storageStore.state.archiveDays,
        streams: storageStore.state.streams,
        commonConfigSettings: storageStore.state.commonConfigSettings,
        commonCameraSettings: storageStore.state.commonCameraSettings,
        storageConfigs: storageStore.state.storageConfigs,
        sites: sitesStore.state.sites
      }
    }
  },

  actions: {
    INIT_PROJECT ({ commit }, projectData) {
      commit('INIT_STORAGE_CONFIGS', projectData.storageConfigs) // for compatibility with v1.0 and set new project config
      if (projectData.cameras) { // check new project or saved, new project projectData = {}, check any keys, for example 'cameras'
        projectData.sites.forEach(el => commit('ADD_SITE', el))
        projectData.views.forEach(el => commit('ADD_VIEW', el))
        commit('UPDATE_GRID_BASEUNIY', projectData.grids.baseUnit || 5000) // for compatibility with v1.0
        commit('UPDATE_PRIMARY_GRID', projectData.grids.primaryGrid)
        commit('UPDATE_SECONDARY_GRID', projectData.grids.secondaryGrid)
        commit('UPDATE_CAMZONE', projectData.camZone)
        commit('UPDATE_LIGHT_COLOR', projectData.lightColors || { IR: '#000080', WL: '#ffff00' }) // for compatibility with v1.0
        commit('SET_LEGEND_SHOW', projectData.legend)
        commit('SET_ARCHIVE_DAYS', projectData.archiveDays)
        projectData.streams.forEach(el => commit('CREATE_STREAM', el))
        commit('UPDATE_STORAGE_COMMON_CONFIG_SETTINGS', projectData.commonConfigSettings)
        commit('UPDATE_STORAGE_COMMON_CAMERA_SETTINGS', projectData.commonCameraSettings);
        ['cameras', 'limitsLines', 'images', 'lines', 'rectangles', 'circles', 'brushes', 'texts', 'equip'].forEach(objType =>
          projectData[objType] && projectData[objType].forEach(el => commit('CREATE_OBJECT', el))
        )
        // fix matrix sizeX vor v1, v1.6
        if (!projectData.version) {
          const cameras = objectsStore.state.list.filter(el => el.typeOf === 'camera')
          if (cameras.length !== 0) {
            cameras.forEach(cam => {
              if (!cam.matrixUserDef) {
                const oldSizeName = oldMatrixSizeArray[cam.AR].find(el => el.sizeX === cam.sizeX).name
                const newSizeX = matrixSizeArray[cam.AR].find(el => el.name === oldSizeName).sizeX
                console.log('oldSizeName', oldSizeName, 'newSizeX', newSizeX)
                commit('UPDATE_OBJECT', { id: cam.id, sizeX: newSizeX })
              }
            })
          }
        }
        eventBus.$emit('store-ready')
      } else {
        eventBus.$emit('store-ready')
      }
    }
  }
})
