<template>
  <div>
    <div
      id="left-sub-menu-parent-btns"
     >
      <v-menu
        :close-on-click="false"
        :close-on-content-click="false"
        nudge-right="45"
        nudge-bottom="-72"
        rounded="pill"
        offset-y
        content-class="elevation-2"
        >
        <template #activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            >
            <menu-btn
              color="primary"
              dark
              :iconData="iconData"
              :tooltipPos = "tooltipPos"
            />
          </div>
        </template>
        <div id="sub-menu-btns">
          <div
            v-for="(menu, idx) in subMenuData"
            :key="idx"
            class="elevation-0"
            >
            <menu-btn
              v-if="idx === 0"
              :iconData="menu"
              tooltipPos = "right"
            />
            <frame-dialog v-else>
              <template #dialog-btn>
                <menu-btn
                  v-if = "menu.isDisabled"
                  :iconData = "menu"
                  tooltipPos = "right"
                  @click.native.stop
                />
                <menu-btn
                  v-else
                  :iconData = "menu"
                  tooltipPos = "right"
                />
              </template>
              <template #dialog-body = "{ onClose }">
                <view-dialog
                  v-if = "idx === 1"
                  @close = "onClose"
                />
                <views-dialog
                  v-if = "idx === 2"
                  @close = "onClose"
                />
              </template>
            </frame-dialog>
          </div>
        </div>
      </v-menu>
    </div>
  </div>
</template>

<script>
import MenuBtn from './EditorMenuBtn'
import FrameDialog from './../lib/srvFrameDialog'
import ViewDialog from './EditorViewDialog'
import ViewsDialog from './EditorViewsDialog'

export default {

  name: 'EditorLeftSubMenu',

  components: {
    MenuBtn,
    FrameDialog,
    ViewDialog,
    ViewsDialog
  },

  props: ['subMenuData', 'iconData', 'tooltipPos'],

  data: () => ({
  }),

  computed: {

  },

  methods: {
  },

  mounted () {
  }
}

</script>

<style scoped>
#sub-menu-btns {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  background-color: white;
}
</style>
