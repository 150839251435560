<template>
<div id="wrapper" class="text-caption">
<span>{{time2line[0]}}</span><br>
<span class="grey--text">{{time2line[1]}}</span>
</div>
</template>

<script>

export default {
  name: 'Time2Line',

  components: {

  },

  props: [
    'time'
  ],

  data: () => ({
  }),

  computed: {
    time2line () {
      let outArr = []
      if (!(this.time) || (this.time === '')) return outArr
      outArr = new Date(1 * this.time).toLocaleString(navigator.language, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }).split(',')
      return outArr
    }

  }
}
</script>

<style scoped>

</style>
