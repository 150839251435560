<template>
  <v-snackbar
    :value="value"
    @input="$emit('input', false)"
    timeout="3000"
    color="info"
    text
    class="pa-0 ma-0"
    >
    <v-container
      fluid
      class="ma-2 pa-0"
      >
      <v-row class="justify-center">
        {{ $t('snackbar vertex') }}
      </v-row>
      <v-row>
        <v-col
          v-for = "(item, idx) in items"
          :key = "idx"
          cols="6"
          class="justify-center"
          >
          <span class = "d-flex align-center justify-center">
            <v-icon class="mr-2">
              {{item.icon}}
            </v-icon>
            {{item.descr}}
          </span>
        </v-col>
      </v-row>
    </v-container>
  </v-snackbar>
</template>

<script>
export default {

  name: 'snackbarLimitsLine',

  components: {
  },

  props: ['value'],

  data: function () {
    return {
      items: [
        { icon: 'mdi-vector-polyline-plus', descr: this.$t('snackbar add vertex') },
        { icon: 'mdi-vector-polyline-minus', descr: this.$t('snackbar remove vertex') }
      ]
    }
  }
}

</script>

<style scoped>

</style>
