<template>
  <div>
    <div @click.stop = "dialog = true; $emit('dialog-open')" style = "cursor: pointer">
      <slot name = "dialog-btn"/>
    </div>
    <v-dialog
      v-model = "dialog"
      persistent
      width = "600px"
      scrollable
      >
      <slot name="dialog-body" :onClose = "onClose"/>
    </v-dialog>
  </div>
</template>

<script>
export default {

  name: 'FrameDialog',

  components: {
  },

  data: () => ({
    dialog: false
  }),

  computed: {
  },

  methods: {
    onClose () {
      this.dialog = false
    }
  },

  watch: {
  },

  updated () {
  },

  mounted () {
  }
}
</script>

<style scoped>

</style>
