<template>
<div>
  <v-menu
    v-model="menu"
    top
    nudge-bottom="80"
    :close-on-content-click="false"
    >
    <template v-slot:activator="{ on }">
      <div :style="swatchStyle" v-on="on" />
    </template>
    <v-color-picker
      :value = "value"
      v-on:input="$emit('input', $event.hex)"
      v-on:update:color="$emit('input', $event.hex)"
      flat
      hide-canvas
      hide-sliders
      hide-inputs
      show-swatches
      :swatches = "palette"
      swatches-max-height = "175"
      style = "width: 275px !important"
    />
  </v-menu>
</div>
</template>

<script>

export default {

  name: 'colorPicker',

  components: {
  },

  props: ['value'],

  data: function () {
    const vColor = this.$vuetify.theme.themes.light
    function getPalette (arrColor) {
      const outPalette = []
      const numInColumn = 4
      for (let i = 0; i < Math.floor(arrColor.length / numInColumn) + 1; i++) {
        const columnPalette = []
        for (let k = 0; k < numInColumn; k++) {
          arrColor[i * numInColumn + k] && columnPalette.push(arrColor[i * numInColumn + k])
        }
        outPalette.push(columnPalette)
      }
      return outPalette
    }
    return {
      menu: false,
      palette: getPalette([
        '#000000', '#000080', '#0000FF', '#008000',
        '#008080', '#00FF00', '#00FFFF', '#800000',
        '#800080', '#808000', '#808080', '#C0C0C0',
        '#FF0000', '#FF00FF', '#FFFF00', '#FFFFFF',
        vColor.primary, vColor.secondary, vColor.accent, vColor.error,
        vColor.info, vColor.success, vColor.warning, vColor.grayscale
      ])
    }
  },

  computed: {
    swatchStyle () {
      const { menu } = this
      return {
        backgroundColor: this.value,
        cursor: 'pointer',
        height: '24px',
        width: '24px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    }
  },

  methods: {

  },

  mounted () {
  }
}

</script>

<style scoped>
>>> .v-color-picker__swatch {
  margin-bottom: 0px !important;
}

>>> .v-color-picker__color {
  height: 32px;
  max-height: 32px;
  width: 32px;
  margin: 2px 2px;
  border-radius: 50%;
  border: 1px solid #eee;
}
</style>
