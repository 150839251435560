import * as fabric from 'fabric'

const HANDLE_RADIUS = 6
const HANDLE_COLOR = '#1976d2'

export default class LayoutText {
  constructor (objData = {}) {
    this.canvasObjects = [] // for canvas drawing
    this.requireNextClick = false // true - if necessary determinate more one vertex for object
    this.site = objData.site
    this.typeOf = 'layoutText'
    this.id = objData.id
    this.zOrder = objData.zOrder
    this.color = objData.stroke || '#000000' // text stroke - fill

    this.text = new fabric.Textbox(objData.text || '', {
      left: objData.left || Infinity,
      top: objData.top || Infinity,
      angle: objData.angle || 0,
      fill: this.color,
      opacity: objData.opacity || 1,
      fontFamily: 'Roboto',
      fontSize: objData.fontSize || 12,
      fontWeight: objData.fontWeight || 'normal',
      underline: objData.underline || false,
      fontStyle: objData.fontStyle || 'normal',
      linethrough: objData.linethrough || false,
      originX: 'center',
      originY: 'center',
      textAlign: 'center',
      hoverCursor: 'pointer',
      cornerColor: HANDLE_COLOR,
      cornerSize: HANDLE_RADIUS,
      cornerStyle: 'circle',
      transparentCorners: false,
      selectable: true,
      evented: true,
      hasBorders: true,
      caching: false,
      borderOpacity: 0.4,
      borderColor: HANDLE_COLOR,
      hasControls: true,
      isSelectable: true, // for set selectable obj on canvas
      typeOf: objData.typeOf,
      id: this.id
    })

    this.canvasObjects = [this.text] // origin text - first element
  }

  is (elemFabric) {
    return (elemFabric === this.text) && this
  }

  zooming (scale, point) { // point - point of zooming {x, y}, scale = zoomNew/zoomOld
    this.text.set({
      left: point.x + scale * (this.text.left - point.x),
      top: point.y + scale * (this.text.top - point.y)
    }).setCoords()
  }

  panning (startPoint, endPoint) { // point - {x, y}
    this.text.set({
      left: endPoint.x - startPoint.x + this.text.left,
      top: endPoint.y - startPoint.y + this.text.top
    }).setCoords()
  }

  createFirstClick (event) {
    const evt = event.pointer
    this.text.set({
      left: evt.x,
      top: evt.y
    }).setCoords()
    this.text.enterEditing()
  }

  selected () {
    this.text.set({ hoverCursor: 'move' })
  }

  unselected () {
    this.text.set({ hoverCursor: 'pointer' })
  }

  update (target) {
    this.text.set({ text: target.text })
  }

  freeze () {
    this.text.set({ selectable: false, evented: false })
  }

  unfreeze () {
    this.text.set({ selectable: true, evented: true })
  }

  toStoreData () {
    return {
      text: this.text.text,
      left: this.text.left,
      top: this.text.top,
      width: this.text.width,
      height: this.text.height,
      angle: +parseFloat(this.text.angle).toFixed(0),
      stroke: this.text.fill,
      opacity: this.text.opacity,
      fontSize: this.text.fontSize,
      fontWeight: this.text.fontWeight,
      underline: this.text.underline,
      fontStyle: this.text.fontStyle,
      linethrough: this.text.linethrough,
      zOrder: this.zOrder,
      site: this.site,
      typeOf: this.typeOf,
      id: this.id
    }
  }

  updateParamsFromStore (objData) {
    this.text.set({
      text: objData.text,
      angle: objData.angle,
      opacity: objData.opacity,
      fill: objData.stroke,
      fontSize: objData.fontSize,
      fontWeight: objData.fontWeight,
      underline: objData.underline,
      fontStyle: objData.fontStyle,
      linethrough: objData.linethrough
    }).setCoords()
    this.zOrder = objData.zOrder
  }
}
