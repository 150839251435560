import * as fabric from 'fabric'

const HANDLE_RADIUS = 3
const HANDLE_COLOR = '#1976d2'

export default class LimitsLine {
  constructor (objData = {}) {
    this.handles = [] // array of handles
    this.lines = [] // array of lines
    this.canvasObjects = [] // for canvas drawing
    this.requireNextClick = true // true - if necessary determinate more one vertex for object
    this.site = objData.site
    this.isClickable = true
    this.typeOf = 'limitsLine'
    this.id = objData.id
    this.zOrder = objData.zOrder
    if (objData.vertices) {
      const arrHandles = objData.vertices.map(el => this.#createHandle(el))
      const arrLines = []
      for (let i = 0; i < arrHandles.length - 1; i++) {
        const handle1 = arrHandles[i]
        const handle2 = arrHandles[i + 1]
        const line = this.#createLine([handle1.left, handle1.top, handle2.left, handle2.top], handle1, handle2)
        arrLines.push(line)
      }
      this.canvasObjects = arrHandles.map((e, i) => arrLines[i] ? [e, arrLines[i]] : e).flat()
    } else {
      const handle1 = this.#createHandle({ x: Infinity, y: Infinity })
      const handle2 = this.#createHandle({ x: Infinity, y: Infinity })
      const line = this.#createLine([0, 0, 0, 0], handle1, handle2)
      this.canvasObjects.push(handle1, line, handle2)
    }
    this.canvasObjects.forEach(el => el.setCoords())
  }

  // private method for set coords handleLine as line

  #createHandle = function (coords) { // coords {x, y}
    const handle = new fabric.Circle({
      left: coords.x,
      top: coords.y,
      radius: 0,
      fill: HANDLE_COLOR,
      selection: false,
      evented: false,
      originX: 'center',
      originY: 'center',
      hoverCursor: 'nesw-resize',
      hasBorders: false,
      hasControls: false,
      isSelectable: true,
      subTypeOf: 'handle',
      typeOf: this.typeOf,
      id: this.id
    })
    return handle
  }

  #createLine = function (coords, handle1, handle2) { // coords [x1, y1, x2, y2]
    const line = new fabric.Line(coords, {
      stroke: '#00FF00', // green
      strokeWidth: 1,
      strokeDashArray: [10, 5],
      opacity: 1,
      selection: this.isClickable,
      evented: this.isClickable,
      originX: 'center',
      originY: 'center',
      hoverCursor: 'pointer',
      hasBorders: false,
      hasControls: false,
      perPixelTargetFind: true,
      subTypeOf: 'line',
      typeOf: this.typeOf,
      id: this.id
    })
    line.handle1 = handle1
    line.handle2 = handle2
    return line
  }

  #setCoords = function () {
    this.canvasObjects.forEach(el => el.setCoords())
  }

  #updateLineFromHandle = function () {
    this.canvasObjects
      .filter(el => el.subTypeOf === 'line')
      .forEach(elem => {
        elem.set({
          x1: elem.handle1.left,
          y1: elem.handle1.top,
          x2: elem.handle2.left,
          y2: elem.handle2.top
        })
      })
    this.#setCoords()
  }

  is (elemFabric) {
    return this.canvasObjects.find(el => el === elemFabric) && this
  }

  zooming (scale, point) { // point - point of zooming {x, y}, scale = zoomNew/zoomOld
    this.canvasObjects.filter(el => el.subTypeOf === 'handle').forEach(elem => {
      elem.set({
        left: point.x + scale * (elem.left - point.x),
        top: point.y + scale * (elem.top - point.y)
      }).setCoords()
    })
    this.#updateLineFromHandle()
  }

  panning (startPoint, endPoint) {
    this.canvasObjects.forEach(el => el.set({
      left: endPoint.x - startPoint.x + el.left,
      top: endPoint.y - startPoint.y + el.top
    }).setCoords())
  }

  createFirstClick (event) {
    const evt = event.pointer
    this.canvasObjects[0].set({ left: evt.x, top: evt.y }).setCoords()
    this.canvasObjects[2].set({ left: evt.x, top: evt.y }).setCoords()
    this.freeze()
  }

  createWaitNextClick (event) {
    const evt = event.pointer
    this.canvasObjects[2].set({ left: evt.x, top: evt.y }).setCoords()
    this.#updateLineFromHandle()
  }

  createNextClick (event) {
    const evt = event.pointer
    this.canvasObjects[2].set({ left: evt.x, top: evt.y }).setCoords()
    this.#updateLineFromHandle()
    this.unfreeze()
  }

  selected () {
    this.canvasObjects.forEach(el => {
      if (el.subTypeOf === 'handle') el.set({ radius: HANDLE_RADIUS, selectable: true, evented: true }).setCoords()
      if (el.subTypeOf === 'line') el.set({ hoverCursor: 'move' }).setCoords()
    })
    this.#setCoords()
  }

  unselected () {
    this.canvasObjects.forEach(el => {
      if (el.subTypeOf === 'handle') el.set({ radius: 0, selectable: false, evented: false }).setCoords()
      if (el.subTypeOf === 'line') el.set({ hoverCursor: 'pointer' }).setCoords()
    })
    this.#setCoords()
  }

  update (clickTarget) {
    if (clickTarget.subTypeOf === 'handle') {
      this.#updateLineFromHandle()
      this.#setCoords()
    }
    if (clickTarget.subTypeOf === 'line') { // line coords only inside bbox
      const startPoint = { x: (clickTarget.handle1.left + clickTarget.handle2.left) / 2, y: (clickTarget.handle1.top + clickTarget.handle2.top) / 2 }
      const endPoint = { x: clickTarget.left, y: clickTarget.top }
      this.canvasObjects.forEach(el => {
        if (el.subTypeOf === 'handle') {
          el.set({
            left: endPoint.x - startPoint.x + el.left,
            top: endPoint.y - startPoint.y + el.top
          }).setCoords()
        }
      })
    }
    this.#updateLineFromHandle()
    this.#setCoords()
  }

  #setClickable = function () {
    this.canvasObjects.forEach(el => {
      if (el.subTypeOf === 'line') el.set({ selectable: this.isClickable, evented: this.isClickable })
    })
  }

  freeze () {
    this.isClickable = false
    this.#setClickable()
  }

  unfreeze () {
    this.isClickable = true
    this.#setClickable()
  }

  editVertex (event) {
    const evt = event.pointer
    if (event.target.subTypeOf === 'line') {
      const targetIdx = this.canvasObjects.findIndex(el => el === event.target)
      const newHandle = this.#createHandle(evt)
      const newLine1 = this.#createLine([0, 0, 0, 0], event.target.handle1, newHandle)
      const newLine2 = this.#createLine([0, 0, 0, 0], newHandle, event.target.handle2)
      this.canvasObjects.splice(targetIdx, 1, newLine1, newHandle, newLine2)
      this.#updateLineFromHandle()
      this.#setCoords()
    }
    if (event.target.subTypeOf === 'handle') {
      const targetIdx = this.canvasObjects.findIndex(el => el === event.target)
      if ((targetIdx !== 0) && (targetIdx !== this.canvasObjects.length - 1)) {
        const newLine = this.#createLine([0, 0, 0, 0], this.canvasObjects[targetIdx - 1].handle1, this.canvasObjects[targetIdx + 1].handle2)
        this.canvasObjects.splice(targetIdx - 1, 3, newLine)
        this.#updateLineFromHandle()
        this.#setCoords()
      }
    }
    this.#setCoords()
  }

  toStoreData () {
    return {
      vertices: this.canvasObjects.filter(el => el.subTypeOf === 'handle').map(elem => ({ x: elem.left, y: elem.top })),
      zOrder: this.zOrder,
      site: this.site,
      typeOf: this.typeOf,
      id: this.id
    }
  }

  updateParamsFromStore (objData) {
    this.zOrder = objData.zOrder
  }

  getSegmentCoords () {
    const arrHandles = this.canvasObjects.filter(el => el.subTypeOf === 'handle').map(elem => ([elem.left, elem.top]))
    const arrSegments = []
    for (let i = 0; i < arrHandles.length - 1; i++) {
      arrSegments.push({ coords: [...arrHandles[i], ...arrHandles[i + 1]] })
    }
    for (let i = 0; i < arrHandles.length - 1; i++) { // заглушка
      arrSegments.push({ coords: [...arrHandles[arrHandles.length - i - 1], ...arrHandles[arrHandles.length - i - 2]] })
    }
    return arrSegments
  }
}
