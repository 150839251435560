<template>
  <div>
    <div id = "sub-menu-parent-btns">
      <v-menu
        :close-on-click = "false"
        :close-on-content-click = "false"
        nudge-left = "128"
        nudge-bottom = "7"
        rounded = "pill"
        offset-y
        content-class = "elevation-2"
        >
        <template #activator = "{ on, attrs }">
          <div
            v-bind = "attrs"
            v-on = "on"
            >
            <menu-btn
              color = "primary"
              dark
              :iconData = "iconData"
              :tooltipPos = "tooltipPos"
            />
          </div>
        </template>
        <div id = "sub-menu-btns">
          <div
            v-for = "(menu, idx) in subMenuData"
            :key = "idx"
            class = "elevation-0"
            >
            <menu-btn
              v-if = "idx != 1"
              :iconData = "menu"
              tooltipPos = "bottom"
            />
            <editor-map-dialog v-else>
              <template #dialog-btn>
                <menu-btn
                  :iconData = "menu"
                  tooltipPos = "bottom"
                />
              </template>
            </editor-map-dialog>
          </div>
        </div>
      </v-menu>
    </div>
  </div>
</template>

<script>
import MenuBtn from './EditorMenuBtn'
import EditorMapDialog from './EditorMapDialog'

export default {

  name: 'EditorSubMenu',

  components: {
    MenuBtn,
    EditorMapDialog
  },

  props: ['subMenuData', 'iconData', 'tooltipPos'],

  data: () => ({

  }),

  computed: {

  },

  methods: {

  },

  mounted () {
  }
}

</script>

<style scoped>
#sub-menu-btns {
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: white;
}
</style>
