<template>
  <div>
    <div id="canvas-event-listener" @contextmenu="showCtxMenu" style = "outline: none !important; height: 100%; width: 100%">
      <!-- <div id="mouse" style = "position: absolute; left: 1400px; top: 400px; z-index: 3000">
        <img v-if = "!mouseDown" src="../../../icons/mouse/mouse.svg"/>
        <img v-else src="../../../icons/mouse/mouse-left-click.svg"/>
      </div> -->
      <canvas id="fabric-canvas"/>
      <input type="file" ref="uploadImgFileInput" style="display: none;" accept="image/x-png,image/gif,image/jpeg" @change="onImgUpload"/>
      <input type="file" ref="uploadDxfFileInput" style="display: none;" accept=".dxf" @change="onDxfUpload"/>
      <v-menu
        v-model = "displayCtxMenu"
        :position-x = "posCtxMenu.x"
        :position-y = "posCtxMenu.y"
        transition = "slide-y-transition"
        close-on-click
        absolute
        >
        <v-list dense class="ma-0 pa-0">
          <v-list-item
            v-for="(item, index) in ctxMenu"
            :key="index"
            @click="ctxMenuAction(item.action)"
            >
            <v-list-item-icon class="mx-0">
              <v-icon> {{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ml-2">
              {{ $t(item.title) }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <snackbar-limits-line v-model="showSnackbarLimitsLine"/>

    <editor-dxf-dialog
      :dialogVisible = "showDxfDialog"
      :dxfUrl="dxfUrl"
      @close = "exitDxfViewer"
    />

    <v-dialog
      v-model="alertFileSize"
      max-width="450"
      >
        <v-card>
          <v-card-title class = "ma-1 pa-1">
            <v-spacer></v-spacer>
            <v-btn
              icon
              plain
              @click = "alertFileSize = false"
              >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            {{ $t('big_file_size-1') }} <b>{{convertBytes(FILEMAXSIZE)}}</b> ({{convertBytes(alertFileSizeValue.oldSize)}}).<br><br>
            {{ $t('big_file_size-2') }} ({{convertBytes(alertFileSizeValue.newSize)}}). <br>
            <b>{{ $t('big_file_size-3') }}</b>
          </v-card-text>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import * as fabric from 'fabric'
import addFabricExtension from './canvas/fabricExtensions'
import { mapState, mapActions, mapGetters } from 'vuex'
import { makeGrid } from './canvas/makeGrid'
import LayoutManager from './canvas/layoutManager'
import { setCoordsG2C, setCoordsC2G } from './canvas/setCoords'
import { setObjControls } from './canvas/setObjControls'
import { eventBus } from '~/main'
import snackbarLimitsLine from './EditorSnackbarLimitsLine'
import EditorDxfDialog from './EditorDxfDialog'
import loadImage from 'blueimp-load-image'

export default {

  name: 'EditorCanvas',

  components: {
    snackbarLimitsLine,
    EditorDxfDialog
  },

  data: () => ({
    canvas: null,
    canvasDiv: null,
    layoutManager: LayoutManager.getInstance(),
    displayCtxMenu: false,
    posCtxMenu: { x: 0, y: 0 },
    ctxMenu: [],
    tempObj: null, // object under constraction
    cropObjID: null, // id of cropping obj
    showSnackbarLimitsLine: false,
    showDxfDialog: false,
    dxfUrl: null,
    storeChangeStatus: false,
    resizeObserver: null,
    storeStatus: null, // , for manual
    // mouseDown: false,
    alertFileSize: false,
    alertFileSizeValue: false,
    FILEMAXSIZE: 5242880 // in bytes
  }),

  computed: {
    ...mapState({
      IS_AUTH: state => state.auth.isAuth,
      CANVAS_HEIGHT: state => state.editorStore.canvasData.height,
      CANVAS_WIDTH: state => state.editorStore.canvasData.width,
      GRIDS: state => state.editorStore.grids,
      CANVAS_IS_PAN_MODE: state => state.editorStore.canvasData.isPanMode,
      OBJECTS_LIST: state => state.objectsStore.list,
      selObj: state => state.objectsStore.selObj,
      SITES: state => state.sitesStore.sites,
      CURRENT_SITE: state => state.sitesStore.currentSite,
      VIEWS: state => state.editorStore.views
    }),

    canvasZoom () {
      return this.$store.state.editorStore.views.find(el => el.siteId === this.CURRENT_SITE && el.id === 'current')?.zoom || 0.02
    },

    centerGlobalCoords () {
      return this.$store.state.editorStore.views.find(el => el.siteId === this.CURRENT_SITE && el.id === 'current')?.centerGlobalCoords || { x: 300, y: 300 }
    },

    canvasData () {
      return { ...this.$store.state.editorStore.canvasData, zoom: this.canvasZoom, centerGlobalCoords: this.centerGlobalCoords }
    },

    ...mapGetters([
      'CHECK_STORE_CHANGE'
    ]),

    selObjUpdatedTrigger () {
      return JSON.stringify(this.selObj)
    },

    gridsUpdatedTrigger () {
      return JSON.stringify(this.GRIDS)
    },

    storeChangeTrigger () {
      return JSON.stringify(this.CHECK_STORE_CHANGE)
    }
  },

  methods: {
    ...mapActions([
      'GET_PROJECT_DATA',
      'GET_SHARED_PROJECT_DATA'
    ]),

    // draw canvas element after change geometry
    redrawGrid () {
      this.updateGridsUnits()
      this.canvas.getObjects().filter(el => el.typeOf === 'grid').forEach(e => this.canvas.remove(e))
      const grid = makeGrid(this.canvasData, this.GRIDS)
      this.canvas.add(grid)
      this.canvas.sendObjectToBack(grid)
    },
    // if change baseUnitGrid
    updateGridsUnits () {
      this.$store.commit('UPDATE_PRIMARY_GRID', this.canvasZoom < 0.02 ? { unit: +this.GRIDS.baseUnit * 5 } : { unit: +this.GRIDS.baseUnit })
      this.$store.commit('UPDATE_SECONDARY_GRID', { unit: +this.GRIDS.primaryGrid.unit / 5 })
    },

    // resize canvas
    resizeCanvas () {
      const dim = {
        height: document.querySelector('#editor-wrapper')?.offsetHeight - document.querySelector('#editor-menu')?.offsetHeight - 3,
        width: document.querySelector('#editor-wrapper')?.offsetWidth
      }
      this.$store.commit('SET_CANVAS_DIM', dim)
      this.canvas.setDimensions({
        height: this.CANVAS_HEIGHT,
        width: this.CANVAS_WIDTH
      })
      if (this.storeStatus) this.loadSiteObjects()
    },

    async onImgUpload (e) { // e.data - from map and dxf, e.target.files[0] - from input files
      if (e.target?.files.length === 0 && !e.data) return this.defaultCanvas()
      let fileImg = false
      if (e.target && e.target.files[0]) fileImg = e.target.files[0]
      if (fileImg.size > this.FILEMAXSIZE) {
        this.alertFileSizeValue = { oldSize: fileImg.size, newSize: '?' }
        this.alertFileSize = true
        const img = await loadImage(e.target.files[0]) // return in .image - <img>-elemenet
        const scaledImg = await loadImage.scale(img.image, { maxWidth: 1980, canvas: true }) // return canvas element
        fileImg = await new Promise(resolve => scaledImg.toBlob(resolve, 'image/jpeg', 0.8))
        this.alertFileSizeValue.newSize = fileImg.size
      }
      this.layoutManager.create(
        {
          typeOf: 'layoutImg',
          site: this.CURRENT_SITE,
          imgURL: URL.createObjectURL(e.data || fileImg),
          mapScale: e.mapScale // for map
        },
        obj => {
          if (e.target) e.target.value = ''
          eventBus.$emit('object-ready', obj)
        }
      )
    },

    async onDxfUpload (e) { // e.target.files[0] - from input files
      if (e.target.files.length === 0) return this.defaultCanvas()
      if (e.target.files[0]) this.dxfUrl = URL.createObjectURL(e.target.files[0])
      this.showDxfDialog = true
    },

    exitDxfViewer () {
      this.showDxfDialog = false
      URL.revokeObjectURL(this.dxfUrl)
      this.dxfUrl = null
      this.$refs.uploadDxfFileInput.value = null
    },

    convertBytes (value) {
      const i = Math.floor(Math.log(value) / Math.log(1024))
      return (value / Math.pow(1024, i)).toFixed(2) * 1 + this.$t(['byte', 'kbyte', 'mbyte', 'gbyte', 'tbyte'][i])
    },

    showCtxMenu (evt) {
      evt.preventDefault()
      this.displayCtxMenu = false
      this.posCtxMenu.x = evt.clientX
      this.posCtxMenu.y = evt.clientY
      this.ctxMenu = this.getCtxMenu().filter(el => el.isShow)
      this.$nextTick(() => { this.displayCtxMenu = true })
    },

    getCtxMenu () {
      return [
        { title: 'duplicate', icon: 'mdi-content-copy', isShow: !this.cropObjID && this.selObj && this.selObj.typeOf !== 'tape', action: 'duplicate' },
        {
          title: 'bring forward',
          icon: 'mdi-arrange-bring-forward',
          isShow: !this.cropObjID && this.selObj && this.selObj.typeOf !== 'limitsLine' && this.selObj.typeOf !== 'tape',
          action: 'bringForward'
        },
        {
          title: 'send backward',
          icon: 'mdi-arrange-send-backward',
          isShow: !this.cropObjID && this.selObj && this.selObj.typeOf !== 'limitsLine' && this.selObj.typeOf !== 'tape',
          action: 'sendBackward'
        },
        { title: 'delete', icon: 'mdi-delete', isShow: !this.cropObjID && !!this.selObj, action: 'delete' },
        { title: 'properties', icon: 'mdi-clipboard-edit-outline', isShow: !this.cropObjID && !!this.selObj, action: 'properties' },
        { title: 'save image', icon: 'mdi-image-outline', isShow: !this.cropObjID && !this.selObj, action: 'saveImage' },
        { title: 'crop', icon: 'mdi-crop', isShow: this.selObj && this.selObj.typeOf === 'layoutImg', action: 'crop' }
      ]
    },

    ctxMenuAction (action) {
      switch (action) {
        case 'duplicate': this.duplicateObject(); this.sortCanvasZOrder(); break
        case 'delete': this.deleteSelectObject(); break
        case 'bringForward': this.bringForwardSelectObject(); this.sortCanvasZOrder(); break
        case 'sendBackward': this.sendBackwardSelectObject(); this.sortCanvasZOrder(); break
        case 'properties': eventBus.$emit('show-properties'); break
        case 'crop': this.cropImg(); break
        case 'saveImage': this.exportToPng(); break
        default: console.log('Ctx menu action incorrect')
      }
    },

    // work with crop
    cropImg () {
      if (!this.cropObjID) {
        this.canvasDiv.addEventListener('keydown', this.onEscCrop)
        const obj = this.layoutManager.find(this.canvas.getActiveObject())
        this.cropObjID = obj.id
        this.canvas.on('mouse:down', this.checkAreaCrop)
        this.canvas.discardActiveObject()
        obj.crop()
        this.layoutManager.freeze()
        this.canvas.setActiveObject(this.layoutManager.findById(obj.id).canvasObjects[4])
        this.canvas.renderAll()
      } else this.exitCrop()
    },

    exitCrop () {
      if (this.cropObjID) {
        const obj = this.layoutManager.findById(this.cropObjID)
        this.cropObjID = null
        obj.exitCrop()
        this.$store.commit('UPDATE_OBJECT', setCoordsC2G(obj.toStoreData(), this.canvasData))
        this.canvas.off('mouse:down', this.checkAreaCrop)
        this.layoutManager.unfreeze()
        this.canvas.renderAll()
        this.canvasDiv.removeEventListener('keydown', this.onEscCrop)
      }
    },

    checkAreaCrop (e) {
      if (!(e.target && e.target.id && e.target.id === this.cropObjID)) this.exitCrop()
    },

    onEscCrop (e) {
      if (e.code === 'Escape' || e.code === 'Enter') this.exitCrop()
    },

    // end crop section

    duplicateObject () {
      const clone = {}
      Object.assign(clone, this.selObj)
      this.canvas.discardActiveObject()
      delete clone.id
      this.layoutManager.create(setCoordsG2C(clone, this.canvasData), obj => {
        this.canvas.add(...obj.canvasObjects)
        this.canvas.renderAll()
        this.$store.commit('CREATE_OBJECT', setCoordsC2G(obj.toStoreData(), this.canvasData))
        this.canvas.setActiveObject(this.layoutManager.getTargetByID(obj.id))
        eventBus.$emit('show-properties')
        this.canvasDiv.focus()
      })
    },

    deleteSelectObject () {
      const obj = this.layoutManager.find(this.canvas.getActiveObject())
      this.deleteObject(obj)
    },

    deleteObject (obj) { // obj from LM
      if (obj) {
        this.canvas.discardActiveObject()
        this.$store.commit('DELETE_OBJECT', obj.id)
        this.canvas.remove(...obj.canvasObjects)
        this.layoutManager.delete(obj)
        this.canvas.renderAll()
      }
    },

    bringForwardSelectObject () {
      if (this.selObj.zOrder < 1000) {
        this.$store.commit('SET_ZORDER_SELECTED_OBJECT', +this.selObj.zOrder + 1)
        this.layoutManager.findById(this.selObj.id).updateParamsFromStore(this.selObj)
      }
    },

    sendBackwardSelectObject () {
      if (this.selObj.zOrder > 50) {
        this.$store.commit('SET_ZORDER_SELECTED_OBJECT', +this.selObj.zOrder - 1)
        this.layoutManager.findById(this.selObj.id).updateParamsFromStore(this.selObj)
      }
    },

    exportToPng () {
      const data = this.canvas.toDataURL('png')
      fetch(data)
        .then(res => res.blob())
        .then(blob => {
          const uploader = window.document.createElement('a')
          uploader.href = URL.createObjectURL(new Blob([blob], { type: 'image/png' }))
          uploader.download = 'SURVy-' + new Date().toLocaleDateString() + '.png'
          document.body.appendChild(uploader)
          uploader.click()
          uploader.remove()
        })
    },

    async getReportViews (reportViews) {
      const self = this
      const outViews = JSON.parse(JSON.stringify(reportViews))
      outViews.sort((a, b) => a.siteN === b.siteN ? a.name.localeCompare(b.name) : a.siteN - b.siteN)
      const curSite = this.CURRENT_SITE // id current site, need for restore current views after getting views
      let SV // selectedView
      const AR = this.CANVAS_WIDTH / this.CANVAS_HEIGHT
      let i = 0 // counter views
      getViews()

      async function getViews () {
        SV = outViews[i]

        if (SV.siteId !== self.CURRENT_SITE) {
          eventBus.$on('canvas-ready', getView)
          self.$store.commit('SET_CURRENT_SITE', SV.siteId)
        } else getView()

        async function getView () {
          eventBus.$off('canvas-ready', getView)
          const currentParams = self.VIEWS.find(el => el.siteId === self.CURRENT_SITE && el.id === 'current')
          const current = { pos: currentParams.centerGlobalCoords, zoom: currentParams.zoom }
          self.$store.commit('SET_CURRENT_ZOOM', { siteId: self.CURRENT_SITE, zoom: SV.zoom })
          self.$store.commit('SET_CURRENT_GLOBAL_COORDS', { siteId: self.CURRENT_SITE, coords: SV.centerGlobalCoords })
          eventBus.$emit('update-view', current, { pos: SV.centerGlobalCoords, zoom: SV.zoom }) // evt, start, end */
          outViews[i].img = await canvasToBlob()
          outViews[i].ar = AR
          if (i < outViews.length - 1) {
            i++
            getViews()
          } else {
            eventBus.$emit('ready-report-views', outViews)
            if (curSite !== self.CURRENT_SITE) self.$store.commit('SET_CURRENT_SITE', curSite)
          }
        }
      }

      async function canvasToBlob () {
        const blob = await fetch(self.canvas.toDataURL('png')).then(res => res.blob())
        function blobToBase64 (blob) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result.split(',')[1])
            reader.onerror = (err) => reject(err)
            reader.readAsDataURL(blob)
          })
        }
        return await blobToBase64(blob)
      }
    },

    // ZOOM
    zooming (evt, scale) {
      const zoom1 = this.canvasZoom
      const centerGlobalCoords1 = this.centerGlobalCoords
      const newZoom = zoom1 * scale
      if ((newZoom > 0.002) && (newZoom < 0.3)) {
        this.$store.commit('SET_CURRENT_ZOOM', { siteId: this.CURRENT_SITE, zoom: newZoom })
        const zoom2 = this.canvasZoom
        this.$store.commit('SET_CURRENT_GLOBAL_COORDS', {
          siteId: this.CURRENT_SITE,
          coords: {
            x: evt.pointer.x + zoom2 / zoom1 * (centerGlobalCoords1.x - evt.pointer.x),
            y: evt.pointer.y + zoom2 / zoom1 * (centerGlobalCoords1.y - evt.pointer.y)
          }
        })
        this.layoutManager.zooming(scale, evt.pointer) // scale = zoomNew/zoomOld
      }
      this.redrawGrid()
    },

    zoomWheel (evt) {
      const scale = 0.999 ** evt.e.deltaY
      this.zooming(evt, scale)
      evt.e.preventDefault()
      evt.e.stopPropagation()
    },

    zoomBtnPlus () {
      const evtPos = { pointer: { x: this.CANVAS_WIDTH / 2, y: this.CANVAS_HEIGHT / 2 } }
      this.zooming(evtPos, 1.1) // 10% for click btn
    },

    zoomBtnMinus () {
      const evtPos = { pointer: { x: this.CANVAS_WIDTH / 2, y: this.CANVAS_HEIGHT / 2 } }
      this.zooming(evtPos, 1 / 1.1)
    },

    pan (evt) {
      let posStart = {}
      if ((this.CANVAS_IS_PAN_MODE) && (!evt.target)) {
        this.canvas.defaultCursor = 'grabbing'
        this.canvas.upperCanvasEl.dispatchEvent(new Event('mousemove'))
        posStart.x = evt.pointer.x
        posStart.y = evt.pointer.y
        this.canvas.on('mouse:move', panMove)
        this.canvas.on('mouse:up:before', panExit) // :before - fabric bug
      }

      const self = this
      function panMove (evt) {
        const centerGlobalCoords1 = self.centerGlobalCoords
        self.$store.commit('SET_CURRENT_GLOBAL_COORDS', {
          siteId: self.CURRENT_SITE,
          coords: {
            x: evt.pointer.x - posStart.x + centerGlobalCoords1.x,
            y: evt.pointer.y - posStart.y + centerGlobalCoords1.y
          }
        })
        self.layoutManager.panning(posStart, evt.pointer)
        posStart = { x: evt.pointer.x, y: evt.pointer.y }
        self.redrawGrid()
      }
      function panExit () {
        self.canvas.defaultCursor = 'default'
        self.canvas.off('mouse:move', panMove)
        self.canvas.off('mouse:up:before', panExit)
      }
    },

    onMenuBtnClick (evtname) {
      this.resetCanvas()
      this.layoutManager.freeze()
      this.canvas.discardActiveObject()
      if (evtname === 'layoutMap') return // layoutMap - open dialog with Map
      this.$store.commit('SET_PAN_MODE', false);
      (evtname !== 'layoutImg' && evtname !== 'layoutDxf')
        ? this.layoutManager.create({ typeOf: evtname, site: this.CURRENT_SITE }, obj => eventBus.$emit('object-ready', obj))
        : evtname === 'layoutImg'
          ? this.$refs.uploadImgFileInput.click()
          : this.$refs.uploadDxfFileInput.click()
    },

    addNewObject (obj) {
      this.tempObj = obj
      this.canvas.add(...obj.canvasObjects)
      this.canvas.defaultCursor = obj.typeOf !== 'layoutText' ? 'crosshair' : 'text'
      this.canvasDiv.focus()
      this.canvasDiv.addEventListener('keydown', this.onEscCreateObj)
      if (obj.typeOf !== 'layoutBrush') {
        this.canvas.on('mouse:down', this.drawElement)
      } else {
        this.canvas.isDrawingMode = true
        this.canvas.on('mouse:down', this.drawBrush)
      }
    },

    drawElement (event) {
      if (!this.tempObj) this.resetCanvas()
      this.layoutManager.createFirstClick(this.tempObj.id, event)
      this.canvas.renderAll()
      this.canvas.off('mouse:down', this.drawElement)
      if (this.tempObj.requireNextClick) {
        this.canvas.on('mouse:move', this.waitNextClick)
        this.canvas.on('mouse:down', this.nextClick)
      } else {
        this.objCreated()
      }
    },

    waitNextClick (event) {
      this.layoutManager.createWaitNextClick(this.tempObj.id, event)
      this.canvas.renderAll()
    },

    nextClick (event) {
      this.layoutManager.createNextClick(this.tempObj.id, event)
      this.objCreated()
    },

    resetCanvas () {
      this.removeTempObj()
      this.tapeDelete()
      this.exitCrop()
      this.defaultCanvas()
    },

    onEscCreateObj (e) {
      if (e.code === 'Escape') this.resetCanvas()
    },

    drawBrush () {
      this.canvasDiv.removeEventListener('keydown', this.onEscCreateObj)
      this.canvas.on('path:created', createdPath)
      const self = this
      function createdPath (event) {
        self.tempObj.setPath(event.path)
        self.canvas.remove(event.path)
        self.canvas.isDrawingMode = false
        self.canvas.off('path:created', createdPath)
        self.canvas.off('mouse:down', self.drawBrush)
        self.objCreated()
      }
    },

    objCreated () {
      this.$store.commit('CREATE_OBJECT', setCoordsC2G(this.tempObj.toStoreData(), this.canvasData))
      this.canvas.setActiveObject(this.layoutManager.getTargetByID(this.tempObj.id))
      eventBus.$emit('show-properties')
      if (this.tempObj.typeOf === 'limitsLine') this.showSnackbarLimitsLine = true
      this.defaultCanvas()
    },

    removeTempObj () { // delete only from Canvas and LM. Clear - in defaultCanvas
      if (this.tempObj) {
        this.canvas.remove(...this.tempObj.canvasObjects)
        this.layoutManager.delete(this.tempObj)
      }
    },

    defaultCanvas () {
      if (this.tempObj && this.tempObj.typeOf !== 'layoutBrush') {
        this.canvas.off('mouse:move', this.waitNextClick)
        this.canvas.off('mouse:down', this.nextClick)
        this.canvas.off('mouse:down', this.drawElement)
        this.canvasDiv.removeEventListener('keydown', this.onEscCreateObj)
      } else {
        this.canvas.isDrawingMode = false
        this.canvas.off('path:created')
        this.canvas.off('mouse:down', this.drawBrush)
      }
      this.tempObj = null
      this.layoutManager.unfreeze()
      this.canvas.defaultCursor = 'default'
      this.$store.commit('SET_PAN_MODE', true)
      this.sortCanvasZOrder()
      this.canvas.renderAll()
      this.canvas.upperCanvasEl.dispatchEvent(new MouseEvent('mousemove')) // fix
    },

    // select and unselect object on canvas
    selectObj (e) {
      const selected = e.selected[0]
      if (!selected) return
      const target = selected.isAux ? this.layoutManager.getTargetByID(selected.id) : selected
      this.canvas.setActiveObject(target)
      this.layoutManager.setActiveObject(target)
      this.$store.commit('SET_SELECTED_OBJECT', target.id)
      this.canvas.renderAll()
    },

    selectionCreated (e) {
      e.selected[0].setControlsVisibility(setObjControls(e.selected[0].typeOf)) // eslint-disable-line
      this.selectObj(e)
    },

    selectionUpdated (e) {
      if (!this.objCropID) {
        const obj = this.layoutManager.find(e.deselected[0])
        obj.unselected()
        e.selected[0].setControlsVisibility(setObjControls(e.selected[0].typeOf))
        this.selectObj(e)
        this.canvas.renderAll()
      }
    },

    selectionCleared (e) {
      if (e.deselected[0]) {
        const obj = this.layoutManager.find(e.deselected[0])
        if (!this.objCropID) obj.unselected()
      }
      this.$store.commit('SET_SELECTED_OBJECT', false)
      this.canvas.renderAll()
      eventBus.$emit('hide-properties')
    },

    onEscUnselectActiveObj (e) {
      if (e.code === 'Escape') {
        const obj = this.canvas.getActiveObject()
        if (obj && !this.cropObjID) {
          this.layoutManager.find(obj).unselected()
          this.canvas.discardActiveObject()
          this.canvas.renderAll()
        }
      }
    },

    objectUpdated (e) {
      const obj = this.layoutManager.update(e.transform.target)
      this.canvas.renderAll()
      this.$store.commit('UPDATE_OBJECT', setCoordsC2G(obj.toStoreData(), this.canvasData))
    },

    objectTextUpdated (e) {
      const obj = this.layoutManager.update(e.target)
      this.$store.commit('UPDATE_OBJECT', setCoordsC2G(obj.toStoreData(), this.canvasData))
    },

    limitsLineEditVertex (evt) {
      if (evt.target && evt.target.typeOf === 'limitsLine') {
        this.canvas.discardActiveObject()
        this.canvas.renderAll()
        this.layoutManager.find(evt.target).editVertex(evt)
        this.canvas.remove(...this.canvas.getObjects().filter(el => el.id === evt.target.id))
        const obj = this.layoutManager.findById(evt.target.id)
        this.canvas.add(...obj.canvasObjects)
        obj.selected()
        this.canvas.setActiveObject(this.layoutManager.getTargetByID(obj.id))
        this.layoutManager.updateFoVs()
        this.$store.commit('UPDATE_OBJECT', setCoordsC2G(obj.toStoreData(), this.canvasData))
      }
    },

    sortCanvasZOrder () {
      const a = this.canvas.getObjects().filter(el => el.typeOf !== 'grid')
      const objects = a.filter(el => (el.subTypeOf !== 'handle') && (el.subTypeOf !== 'handleContur') && (el.subTypeOf !== 'handleCrop') && (el.subTypeOf !== 'icon'))
      const handles = a.filter(el => (el.subTypeOf === 'handle') || (el.subTypeOf === 'handleContur') || (el.subTypeOf === 'handleCrop') || (el.subTypeOf === 'icon'));
      [
        ...this.canvas.getObjects().filter(el => el.typeOf === 'grid'),
        ...objects.sort((a, b) => this.layoutManager.findById(a.id).zOrder - this.layoutManager.findById(b.id).zOrder),
        ...handles.sort((a, b) => this.layoutManager.findById(a.id).zOrder - this.layoutManager.findById(b.id).zOrder)
      ].forEach((el, idx) => { this.canvas.moveObjectTo(el, idx + 1) })
    },

    onDelSelectedObj (evt) {
      if (this.selObj && evt.code === 'Delete') this.deleteSelectObject()
    },

    tapeDelete () {
      const tape = this.OBJECTS_LIST.find(el => el.typeOf === 'tape')
      if (tape) {
        this.canvas.setActiveObject(this.layoutManager.findById(tape.id).canvasObjects[0])
        this.selObj && this.deleteSelectObject()
      }
    },

    camerasUpdate () {
      this.layoutManager.list
        .filter(el => el.typeOf === 'camera')
        .forEach(e => this.layoutManager.update(e.canvasObjects[0])) // update icon
      this.canvas.renderAll()
    },

    camerasRecalcFoVs () {
      this.layoutManager.recalcFoVs()
      this.canvas.renderAll()
    },

    camerasLightUpdate () {
      this.layoutManager.lightUpdate()
      this.canvas.renderAll()
    },

    updateView (start, end) {
      this.layoutManager.panning(start.pos, end.pos)
      this.layoutManager.zooming(end.zoom / start.zoom, end.pos) // scale = zoomNew/zoomOld
      this.redrawGrid()
      this.canvas.renderAll()
    },

    scaleToFit () {
      const canvasObjects = this.canvas.getObjects().filter(el => el && el.typeOf !== 'grid' && el.typeOf !== 'tape' && el.subTypeOf !== 'fov')
      if (canvasObjects.length > 0) {
        const arrX = canvasObjects.map(el => [el.aCoords.tl.x, el.aCoords.tr.x, el.aCoords.bl.x, el.aCoords.br.x]).flat()
        const arrY = canvasObjects.map(el => [el.aCoords.tl.y, el.aCoords.tr.y, el.aCoords.bl.y, el.aCoords.br.y]).flat()
        const point1 = { x: Math.min(...arrX), y: Math.min(...arrY) }
        const point2 = { x: Math.max(...arrX), y: Math.max(...arrY) }
        const centerPoint = { x: (point1.x + point2.x) / 2, y: (point1.y + point2.y) / 2 }
        const centerCanvas = { x: +this.CANVAS_WIDTH / 2, y: +this.CANVAS_HEIGHT / 2 }
        const v = { x: centerCanvas.x - centerPoint.x, y: centerCanvas.y - centerPoint.y }
        this.$store.commit('SET_CURRENT_GLOBAL_COORDS', {
          siteId: this.CURRENT_SITE,
          coords: {
            x: v.x + this.centerGlobalCoords.x,
            y: v.y + this.centerGlobalCoords.y
          }
        })
        this.layoutManager.panning(centerPoint, centerCanvas)
        const scale = 1 / Math.max(Math.abs(point1.x - point2.x) / this.CANVAS_WIDTH, Math.abs(point1.y - point2.y) / this.CANVAS_HEIGHT)
        const zoom1 = this.canvasZoom
        let newZoom = zoom1 * scale
        if (newZoom < 0.005) newZoom = 0.005
        if (newZoom > 0.3) newZoom = 0.3
        this.$store.commit('SET_CURRENT_ZOOM', { siteId: this.CURRENT_SITE, zoom: newZoom })
        const zoom2 = this.canvasZoom
        this.$store.commit('SET_CURRENT_GLOBAL_COORDS', {
          siteId: this.CURRENT_SITE,
          coords: {
            x: centerCanvas.x + zoom2 / zoom1 * (this.centerGlobalCoords.x - centerCanvas.x),
            y: centerCanvas.y + zoom2 / zoom1 * (this.centerGlobalCoords.y - centerCanvas.y)
          }
        })
        this.layoutManager.zooming(scale, centerCanvas) // scale = zoomNew/zoomOld
        this.redrawGrid()
      }
    },

    storeReady () {
      if (this.SITES.length === 0) this.$store.dispatch('ADD_SITE', { id: 'default', name: this.$t('site default') })
      if (!this.SITES.find(el => el.id === this.CURRENT_SITE)) this.$store.commit('SET_CURRENT_SITE', this.SITES[0].id)
      if (document.fonts.check('12px icomoon')) {
        this.loadSiteObjects()
      } else {
        setTimeout(() => { this.loadSiteObjects() }, 1500)
      }
    },

    loadSiteObjects () {
      eventBus.$emit('canvas-not-ready')
      this.tapeDelete()
      this.resetCanvas()
      this.canvas.clear()
      this.layoutManager.init()
      this.redrawGrid()
      const siteObjectList = this.OBJECTS_LIST.filter(el => el.site === this.CURRENT_SITE)
      if (siteObjectList.length === 0) return eventBus.$emit('canvas-ready')

      let canvasObjCount = 0
      siteObjectList.forEach(el => {
        this.layoutManager.create(setCoordsG2C(el, this.canvasData), obj => {
          if (obj.typeOf === 'layoutImg' && obj.subTypeOf !== 'handleContur') {
            const oldObjects = this.canvas.getObjects().filter(el => el.id === obj.id)
            if (oldObjects.length !== 0) oldObjects.forEach(e => this.canvas.remove(e))
          }
          this.canvas.add(...obj.canvasObjects)
          this.$store.commit('UPDATE_OBJECT', setCoordsC2G(obj.toStoreData(), this.canvasData))
          obj.unselected()
          canvasObjCount++
          if (canvasObjCount === siteObjectList.length) {
            this.layoutManager.updateFoVs()
            this.sortCanvasZOrder()
            this.canvas.renderAll()
            this.canvas.discardActiveObject()
            this.layoutManager.unfreeze()
            eventBus.$emit('canvas-ready')
          }
        })
      })
    }
  },

  watch: {
    selObjUpdatedTrigger () {
      if (this.selObj) this.layoutManager.findById(this.selObj.id).updateParamsFromStore(this.selObj)
      this.canvas.renderAll()
    },

    gridsUpdatedTrigger () {
      this.redrawGrid()
    },

    storeChangeTrigger () {
      if (this.storeChangeStatus === '1stUpdate') eventBus.$emit('store-change')
      if (!this.storeChangeStatus) this.storeChangeStatus = '1stUpdate'
    },

    CURRENT_SITE () {
      this.storeReady()
    },

    alertFileSize () {
      if (!this.alertFileSize) this.alertFileSizeValue = false
    }
  },

  mounted () {
    eventBus.$emit('canvas-not-ready')
    this.IS_AUTH
      ? this.GET_PROJECT_DATA(this.$route.params.id)
      : this.GET_SHARED_PROJECT_DATA(this.$route.params.uri)
    this.canvasDiv = document.querySelector('#canvas-event-listener')
    this.canvasDiv.tabIndex = 1000
    // for user manual
    // this.canvasDiv.addEventListener('mousedown', () => { this.mouseDown = true })
    // this.canvasDiv.addEventListener('mouseup', () => { this.mouseDown = false })
    addFabricExtension(fabric)
    this.canvas = new fabric.Canvas('fabric-canvas', {
      backgroundColor: 'white',
      selection: false,
      zoom: 1,
      targetFindTolerance: 1,
      fireRightClick: false,
      fireMiddleClick: false
    })
    this.canvas.freeDrawingBrush = new fabric.PencilBrush(this.canvas)

    // resize browser window
    this.$nextTick(this.resizeCanvas)
    this.resizeObserver = new ResizeObserver(_ => this.resizeCanvas())
    this.resizeObserver.observe(this.canvasDiv)

    // zoom
    eventBus.$on('zoom-plus', this.zoomBtnPlus)
    eventBus.$on('zoom-minus', this.zoomBtnMinus)
    this.canvas.on('mouse:wheel', this.zoomWheel)

    // PAN
    this.canvas.on('mouse:down', this.pan)

    // create layout Elements (exclude layoutBrush)
    eventBus.$on(['layoutLine', 'layoutRect', 'layoutCircle', 'layoutText', 'layoutMap', 'layoutDxf', 'layoutImg', 'layoutEquip', 'layoutBrush', 'limitsLine', 'camera', 'tape'], this.onMenuBtnClick)
    eventBus.$on('object-ready', this.addNewObject)
    eventBus.$on('map-ready', this.onImgUpload) // for async map
    eventBus.$on('dxf-ready', this.onImgUpload)

    eventBus.$on('tape-delete', this.tapeDelete)
    eventBus.$on('need-cameras-update', this.camerasUpdate)
    eventBus.$on('need-cameras-fov-recalc', this.camerasRecalcFoVs)
    eventBus.$on('need-cameras-light-update', this.camerasLightUpdate)
    eventBus.$on('update-view', (start, end) => this.updateView(start, end))
    eventBus.$on('scale-to-fit', this.scaleToFit)
    eventBus.$on('need-report-views', views => this.getReportViews(views))

    this.canvas.on('selection:created', this.selectionCreated)
    this.canvas.on('selection:updated', this.selectionUpdated)
    this.canvas.on('selection:cleared', this.selectionCleared)
    this.canvas.on('object:added', this.zOrder)
    this.canvas.on('text:changed', this.objectTextUpdated)
    this.canvas.on('mouse:dblclick', this.limitsLineEditVertex);
    ['object:moving', 'object:rotating', 'object:scaling']
      .forEach(evtname => this.canvas.on(evtname, this.objectUpdated))

    // update Canvas from Store
    eventBus.$on('store-ready', () => { this.storeReady(); this.storeStatus = 'load' })
    eventBus.$on('project-save', () => { this.storeChangeStatus = '1stUpdate' })

    this.canvasDiv.addEventListener('keydown', this.onEscUnselectActiveObj) // push ESC
    this.canvasDiv.addEventListener('keyup', this.onDelSelectedObj) // push DEL
  }, // end of mounted

  beforeDestroy () {
    this.resizeObserver.unobserve(this.canvasDiv)
    this.canvasDiv.removeEventListener('keydown', this.onEscUnselectActiveObj) // push ESC
    this.canvasDiv.removeEventListener('keyup', this.onDelSelectedObj) // push DEL
    eventBus.$off([
      'zoom-plus', 'zoom-minus',
      'layoutMap', 'layoutImg', 'layoutLine', 'layoutRect', 'layoutCircle', 'layoutBrush', 'layoutText', 'layoutEquip',
      'limitsLine', 'camera', 'tape',
      'object-ready', 'map-ready', 'dxf-ready',
      'tape-delete', 'need-cameras-update', 'need-cameras-fov-recalc', 'need-cameras-light-update',
      'store-ready', 'project-save',
      'update-view', 'scale-to-fit',
      'need-report-views'
    ])
    eventBus.$emit('project-close')
  }
}

</script>

<style scoped>

</style>
