const getDefaultState = () => {
  return {
    sites: [],
    currentSite: 'default'
  }
}

export default {
  state: getDefaultState(),

  getters: {
    CURRENT_SITE: state => state.currentSite
  },

  mutations: {
    DEFAULT_SITE_STATE (state) {
      Object.assign(state, getDefaultState())
    },

    ADD_SITE (state, newSite) { // site = { id, name, descr }
      state.sites.push(newSite)
    },

    UPDATE_SITE (state, site) {
      const idx = state.sites.findIndex(e => e.id === site.id)
      state.sites[idx] = Object.assign(state.sites[idx], site)
    },

    UP_SITE (state, siteId) {
      const idx = state.sites.findIndex(e => e.id === siteId)
      if (idx !== 0) state.sites.splice(idx - 1, 2, state.sites[idx], state.sites[idx - 1])
    },

    DOWN_SITE (state, siteId) {
      const idx = state.sites.findIndex(e => e.id === siteId)
      if (idx !== state.sites.length - 1) state.sites.splice(idx, 2, state.sites[idx + 1], state.sites[idx])
    },

    DELETE_SITE (state, siteId) {
      const idx = state.sites.findIndex(e => e.id === siteId)
      state.sites.splice(idx, 1)
    },

    SET_CURRENT_SITE (state, siteId) {
      state.currentSite = siteId
    }
  },

  actions: {
    ADD_SITE ({ commit }, data) {
      commit('ADD_SITE', data)
      commit('ADD_DEFUALT_VIEW', data.id)
    },

    DELETE_SITE ({ commit }, siteId) {
      commit('DELETE_VIEWS', siteId)
      commit('DELETE_OBJECTS', siteId)
      commit('DELETE_SITE', siteId)
    }
  },

  computed: {

  }
}
