<template>
<div>
  <v-tooltip
    :bottom="position[3]"
    :top="position[2]"
    :right="position[1]"
    :left="position[0]"
    :disabled = "iconData.tooltipDisabled"
    >
    <template #activator="{ on, attrs }">
     <v-btn
        color = "primary"
        icon
        :style = "iconData.isPressed ? {'background-color': '#E0E0E0'} : {}"
        :disabled = "iconData.isDisabled"
        @click = "evt"
        v-bind = "attrs"
        v-on = "on"
        >
        <v-icon>{{iconData.name}}</v-icon>
      </v-btn>
    </template>
    <span>{{$t(iconData.descr)}}</span>
  </v-tooltip>
</div>
</template>

<script>
import { eventBus } from '../../main'

export default {

  name: 'MenuBtn',

  components: {

  },

  computed: {

    position () {
      switch (this.tooltipPos) {
        case 'left': return [true, false, false, false]
        case 'right': return [false, true, false, false]
        case 'top': return [false, false, true, false]
        case 'bottom': return [false, false, false, true]
        default: return [false, false, false, true]
      }
    }
  },

  methods: {
    evt () {
      eventBus.$emit(this.iconData.evtname, this.iconData.evtname)
    }

  },

  mounted () {
  },

  props: {
    iconData: {
      type: Object
    },
    tooltipPos: {
      type: String
    }
  }
}

</script>

<style scoped>

</style>
