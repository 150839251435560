import * as fabric from 'fabric'

const HANDLE_RADIUS = 3
const HANDLE_COLOR = '#1976d2'

function cosLine (point1, point2) {
  return (point2.x - point1.x) / Math.sqrt((point2.x - point1.x) ** 2 + (point2.y - point1.y) ** 2)
}

function sinLine (point1, point2) {
  return (point2.y - point1.y) / Math.sqrt((point2.x - point1.x) ** 2 + (point2.y - point1.y) ** 2)
}

export default class LayoutLine {
  constructor (objData = {}) {
    this.canvasObjects = [] // for canvas drawing
    this.requireNextClick = true // true - if necessary determinate more one vertex for object
    this.site = objData.site
    this.typeOf = 'layoutLine'
    this.id = objData.id
    this.zOrder = objData.zOrder
    const handleParams = {
      radius: 0,
      fill: HANDLE_COLOR,
      originX: 'center',
      originY: 'center',
      hoverCursor: 'nesw-resize',
      selectable: false,
      evented: false,
      hasBorders: false,
      hasControls: false,
      perPixelTargetFind: true,
      objectCaching: false,
      subTypeOf: 'handle',
      typeOf: this.typeOf,
      id: this.id
    }
    const coords = objData.x1 && objData.x2 && objData.y1 && objData.y2 && [objData.x1, objData.y1, objData.x2, objData.y2]
    this.line = new fabric.Line(coords || [], {
      stroke: objData.stroke || '#000000',
      strokeWidth: objData.strokeWidth || 1,
      opacity: objData.opacity || 1,
      originX: 'center',
      originY: 'center',
      selectable: false,
      evented: false,
      hasBorders: false,
      hasControls: false,
      objectCaching: false,
      typeOf: this.typeOf,
      id: this.id
    })

    this.handle1 = new fabric.Circle({ left: objData.x1 || Infinity, top: objData.y1 || Infinity, ...handleParams })
    this.handle2 = new fabric.Circle({ left: objData.x2 || Infinity, top: objData.y2 || Infinity, ...handleParams })

    this.handleLine = new fabric.Line(coords || [], {
      stroke: 'red', // any color
      strokeWidth: this.line.strokeWidth,
      opacity: 0.01,
      originX: 'center',
      originY: 'center',
      hoverCursor: 'move',
      selectable: true,
      evented: true,
      hasBorders: false,
      hasControls: false,
      perPixelTargetFind: true,
      isSelectable: true, // for set selectable obj on canvas
      objectCaching: false,
      subTypeOf: 'handleContur',
      typeOf: this.typeOf,
      id: this.id
    })
    this.canvasObjects.push(this.line, this.handle1, this.handle2, this.handleLine)
  }

  // private method for set coords handleLine as line
  #setLineCoords = function () {
    this.line.set({
      x1: this.handle1.left,
      y1: this.handle1.top,
      x2: this.handle2.left,
      y2: this.handle2.top
    }).setCoords()
  }

  #setHandleLineCoords = function () {
    const point1 = { x: this.handle1.left, y: this.handle1.top }
    const point2 = { x: this.handle2.left, y: this.handle2.top }
    this.handleLine.set({
      x1: this.handle1.left + HANDLE_RADIUS * 1.5 * cosLine(point1, point2),
      y1: this.handle1.top + HANDLE_RADIUS * 1.5 * sinLine(point1, point2),
      x2: this.handle2.left - HANDLE_RADIUS * 1.5 * cosLine(point1, point2),
      y2: this.handle2.top - HANDLE_RADIUS * 1.5 * sinLine(point1, point2)
    }).setCoords()
  }

  is (elemFabric) {
    return (elemFabric === this.line || elemFabric === this.handle1 || elemFabric === this.handle2 || elemFabric === this.handleLine) && this
  }

  zooming (scale, point) { // point - point of zooming {x, y}, scale = zoomNew/zoomOld
    this.handle1.set({
      left: point.x + scale * (this.handle1.left - point.x),
      top: point.y + scale * (this.handle1.top - point.y)
    }).setCoords()
    this.handle2.set({
      left: point.x + scale * (this.handle2.left - point.x),
      top: point.y + scale * (this.handle2.top - point.y)
    }).setCoords()
    this.#setLineCoords()
    this.#setHandleLineCoords()
  }

  panning (startPoint, endPoint) { // point - {x, y}
    this.handle1.set({
      left: endPoint.x - startPoint.x + this.handle1.left,
      top: endPoint.y - startPoint.y + this.handle1.top
    }).setCoords()
    this.handle2.set({
      left: endPoint.x - startPoint.x + this.handle2.left,
      top: endPoint.y - startPoint.y + this.handle2.top
    }).setCoords()
    this.#setLineCoords()
    this.#setHandleLineCoords()
  }

  createFirstClick (event) {
    const evt = event.pointer
    this.handle1.set({ left: evt.x, top: evt.y }).setCoords()
  }

  createWaitNextClick (event) {
    const evt = event.pointer
    this.handle2.set({ left: evt.x, top: evt.y }).setCoords()
    this.#setLineCoords()
    this.#setHandleLineCoords()
  }

  createNextClick (event) {
    this.requireNextClick = false
    const evt = event.pointer
    this.handle2.set({ left: evt.x, top: evt.y }).setCoords()
    this.#setLineCoords()
    this.#setHandleLineCoords()
  }

  selected () {
    this.handleLine.set({ hoverCursor: 'move' }).setCoords()
    this.handle1.set({ radius: HANDLE_RADIUS, selectable: true, evented: true }).setCoords()
    this.handle2.set({ radius: HANDLE_RADIUS, selectable: true, evented: true }).setCoords()
  }

  unselected () {
    this.handle1.set({ radius: 0, selectable: false, evented: false }).setCoords()
    this.handle2.set({ radius: 0, selectable: false, evented: false }).setCoords()
    this.handleLine.set({ hoverCursor: 'pointer' }).setCoords()
  }

  update (clickTarget) {
    if (clickTarget.subTypeOf === 'handle') this.#setHandleLineCoords()
    if (clickTarget.subTypeOf === 'handleContur') {
      const startPoint = { x: (this.handle1.left + this.handle2.left) / 2, y: (this.handle1.top + this.handle2.top) / 2 }
      const endPoint = { x: this.handleLine.left, y: this.handleLine.top }
      this.handle1.set({
        left: endPoint.x - startPoint.x + this.handle1.left,
        top: endPoint.y - startPoint.y + this.handle1.top
      }).setCoords()
      this.handle2.set({
        left: endPoint.x - startPoint.x + this.handle2.left,
        top: endPoint.y - startPoint.y + this.handle2.top
      }).setCoords()
    }
    this.#setLineCoords()
  }

  freeze () {
    this.handleLine.set({ selectable: false, evented: false })
  }

  unfreeze () {
    this.handleLine.set({ selectable: true, evented: true })
  }

  toStoreData () {
    return {
      x1: this.handle1.left,
      y1: this.handle1.top,
      x2: this.handle2.left,
      y2: this.handle2.top,
      stroke: this.line.stroke,
      strokeWidth: this.line.strokeWidth,
      opacity: this.line.opacity,
      zOrder: this.zOrder,
      site: this.site,
      typeOf: this.typeOf,
      id: this.id
    }
  }

  updateParamsFromStore (objData) {
    this.line.set({
      stroke: objData.stroke,
      strokeWidth: objData.strokeWidth,
      opacity: objData.opacity
    }).setCoords()
    this.zOrder = objData.zOrder
  }
}
