<template>
  <div>
  <div id= "menu-btns">
    <div
        v-for="(menu, idx) in menus"
        :key="idx"
        >
      <menu-btn
        v-if = "idx !== 2"
        :iconData="menu"
        :tooltipPos = "defTooltipPos"
      />
      <editor-sub-menu
        v-else-if = "idx === 2"
        :iconData="menu"
        :subMenuData="menu.submenus"
        :tooltipPos = "defTooltipPos"
      />
    </div>
  </div>
  </div>
</template>

<script>
import { eventBus } from '../../main'
import MenuBtn from './EditorMenuBtn'
import EditorSubMenu from './EditorSubMenu'

export default {

  name: 'EditorMenu',

  components: {
    MenuBtn,
    EditorSubMenu
  },

  data: function () {
    return {
      defTooltipPos: 'bottom',
      menus: [
        { name: 'mdi-cctv', descr: 'camera', evtname: 'camera', isPressed: false, isDisabled: false },
        { name: 'mdi-chart-timeline-variant', descr: 'limits', evtname: 'limitsLine', isPressed: false, isDisabled: false },
        {
          name: 'mdi-layers-outline',
          descr: 'background',
          evtname: 'to-background',
          isPressed: false,
          isDisabled: false,
          submenus: [
            { name: 'equip-dxf', descr: 'dxf', evtname: 'layoutDxf', isPressed: false },
            { name: 'mdi-map-marker-radius', descr: 'map', evtname: 'layoutMap', isPressed: false },
            { name: 'mdi-image-area', descr: 'image', evtname: 'layoutImg', isPressed: false },
            { name: 'mdi-vector-line', descr: 'line', evtname: 'layoutLine', isPressed: false },
            { name: 'mdi-vector-rectangle', descr: 'rectangle', evtname: 'layoutRect', isPressed: false },
            { name: 'mdi-vector-circle', descr: 'circle', evtname: 'layoutCircle', isPressed: false },
            { name: 'mdi-brush-outline', descr: 'brush', evtname: 'layoutBrush', isPressed: false },
            { name: 'mdi-format-text-variant', descr: 'text', evtname: 'layoutText', isPressed: false },
            { name: 'mdi-desktop-tower-monitor', descr: 'equipment', evtname: 'layoutEquip', isPressed: false }
          ]
        },
        { name: 'mdi-clipboard-edit-outline', descr: 'properties', evtname: 'show-properties', isPressed: false, isDisabled: true }
      ]
    }
  },

  computed: {
    triggerSelObj () {
      return (this.$store.state.objectsStore.selObj) ? true : false // eslint-disable-line
    }
  },

  methods: {
    pressBGBtn () {
      this.menus[2].isPressed = !this.menus[2].isPressed // background sub menu
      this.menus[2].name = (this.menus[2].name === 'mdi-layers-outline') ? 'mdi-layers' : 'mdi-layers-outline'
      this.menus[0].isDisabled = !this.menus[0].isDisabled
      this.menus[1].isDisabled = !this.menus[1].isDisabled
      this.defTooltipPos = (this.defTooltipPos === 'bottom') ? 'top' : 'bottom'
    }
  },

  watch: {
    triggerSelObj () {
      this.menus[3].isDisabled = !this.triggerSelObj
    }
  },

  mounted () {
    eventBus.$on('to-background', event => this.pressBGBtn(event))
    eventBus.$on('show-properties', () => {
      this.menus[3].isPressed = true
      this.menus[3].evtname = 'hide-properties'
    })
    eventBus.$on('hide-properties', () => {
      this.menus[3].isPressed = false
      this.menus[3].evtname = 'show-properties'
    })
  },

  beforeDestroy () {
    eventBus.$off(['show-properties', 'hide-properties', 'to-background'])
  }
}

</script>

<style scoped>
#menu-btns {
  display: flex;
  width: 100%;
  justify-content: center;
}

</style>
