<template>
  <v-container fill-height fluid class="grayscale overflow-hidden">
    <v-row class = "justify-center">
      <v-col align = "center">
        <v-card
          width="350px"
          class="grayscale ma-0 px-2 py-0 elevation-0"
        >
          <v-card-title class = "justify-center">
            {{$t('change password')}}
          </v-card-title>
          <v-card-text>
            <v-form
              ref="changePasswordForm"
              v-model="validChangePasswordForm"
              >
              <v-text-field
                v-model="password"
                :label="$t('new password')"
                :rules="passwordRules"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword=!showPassword"
                autocomplete="new-password"
              />
              <v-btn
                :disabled="!validChangePasswordForm"
                class="primary elevation-0 my-4"
                @click=sendChangePassword()
                >
                {{$t('change password btn')}}
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      multi-line
      text
      color="info"
      elevation="4"
      >
      {{ $t(snackbarMessage) }}
    </v-snackbar>
  </v-container>

</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ChangePassword',

  components: {
  },

  data: () => ({
    password: '',
    showPassword: false,
    snackbar: false,
    snackbarMessage: '',
    validChangePasswordForm: false
  }),

  methods: {
    ...mapActions([
      'SET_CHANGE_PASSWORD'
    ]),

    async sendChangePassword () {
      try {
        await this.SET_CHANGE_PASSWORD({ key: this.$route.query.key, password: this.password })
        this.snackbarMessage = 'change password ok'
      } catch (err) {
        this.snackbarMessage = 'change password error'
      }
      this.snackbar = true
      setTimeout(() => this.$router.push({ name: 'login' }), 1500)
    }
  },

  computed: {

    passwordRules () {
      return [
        v => !!v || this.$t('password-require')
      ]
    }
  }
}
</script>

<style scoped>

</style>
