<template>
<v-card>
  <v-card-title>
    {{$t('changelog')}}
    <v-spacer/>
    <v-icon @click="$emit('input')">mdi-close</v-icon>
  </v-card-title>
  <v-card-text>
    <v-list two-line>
      <v-list-item
        v-for="(item, idx) in changes"
        :key="idx"
        >
        <v-list-item-content>
          <v-list-item-title>{{ item.date + '  ' }} <span class="font-weight-medium">{{ $t(item.name) }}</span></v-list-item-title>
          <v-list-item-subtitle v-if = "item.descr.length !== 0">
            <ul>
              <li v-for="(itemDescr, i) in item.descr" :key="i">
                <div style = "white-space: normal" class= "ma-0 pa-1">{{ $t(itemDescr) }}</div>
              </li>
            </ul>
          </v-list-item-subtitle>
          <div class = "ma-0 pt-2" v-if = "item.url">
            <a :href = "item.url">{{ $t('changelog_more') }}</a>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card-text>
</v-card>
</template>

<script>
export default {
  name: 'Changelog',

  components: {
  },

  data: () => ({
    changes: [
      { date: '02/2023', name: 'changelog_1', descr: [] },
      { date: '04/2024', name: 'changelog_2', descr: ['changelog_2_descr1', 'changelog_2_descr2', 'changelog_2_descr3'], url: 'https://survy.ru/help/guide/changelog.html#Release-1-6-04-2024' }
    ]
  }),

  computed: {
  },

  methods: {

  },

  mounted () {

  }
}

</script>

<style scoped>
</style>
