<template>
  <v-dialog
    v-model = "dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    >
    <template #activator="{ on, attrs }">
      <div
        v-bind = "attrs"
        v-on = "on"
      >
        <slot name="dialog-btn"/>
      </div>
    </template>
    <v-card height="100%" class="justify-space-between">
      <v-toolbar
        height = "32px"
        style = "border-radius: 0"
        dark
        dense
        color = "primary"
      >
        <v-toolbar-title class="text-body-1">{{ $t('map_select_dialog') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            icon
            dark
            @click = "dialog = false"
            >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div id = "olmap"/>
      <v-snackbar
        bottom
        timeout = "-1"
        value = "true"
        :color= "!snackbarMessage ? 'info' : 'transparent'"
        :text = "!snackbarMessage"
        :elevation = "!snackbarMessage ? 2 : 0"
        class = "pa-0 ma-0"
        max-width = "200"
        >
        <div class="d-flex justify-center align-center pa-0 ma-0">
        <span v-if = "!snackbarMessage" class = "pa-0 ma-0" style = "text-align: center">
          {{ $t('map_snackbar_message') }}
        </span>

        <v-btn
          v-else
          color = "primary"
          @click = "mapSelected"
          >
          {{ $t('map_select_ok') }}
        </v-btn>
        </div>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'
import * as olInteraction from 'ol/interaction'
import * as olControl from 'ol/control'
import { getPointResolution, transform } from 'ol/proj'
import LineString from 'ol/geom/LineString'
import CanvasScaleLine from 'ol-ext/control/CanvasScaleLine'
import SearchPhoton from 'ol-ext/control/SearchPhoton'
import PrintControl from 'ol-ext/control/Print'
import 'ol/ol.css'
import 'ol-ext/dist/ol-ext.css'
import { eventBus } from '../../main'

export default {

  name: 'EditorMapDialog',

  components: {
  },

  data: () => ({
    dialog: false,
    mapCreated: false,
    printControl: {},
    olmap: {},
    snackbarMessage: false
  }),

  computed: {

  },

  methods: {
    mapSelected () {
      const mapSize = this.olmap.getSize()
      const currentView = this.olmap.getView()
      const currentProjection = currentView.getProjection() // type of projection
      const extent = currentView.calculateExtent(mapSize) // map zone in coords
      const currentCenter = currentView.getCenter()
      const currentMPU = currentProjection.getMetersPerUnit()
      const currentPointResolution = getPointResolution(currentProjection, 1 / currentMPU, currentCenter, 'm') * currentMPU
      const mapDiagM = new LineString([[extent[0], extent[1]], [extent[2], extent[3]]]).getLength() * currentPointResolution
      const mapDiagPx = Math.sqrt(
        mapSize[0] ** 2 + mapSize[1] ** 2 +
        2 * mapSize[0] * mapSize[1] * Math.abs(Math.sin(2 * currentView.getRotation()))
      )
      const scale = mapDiagM / mapDiagPx
      this.dialog = false
      this.printControl.on('print', printMap)
      this.printControl.print({ imageType: 'image/png' })
      const self = this
      function printMap (e) {
        e.canvas.toBlob(blob => eventBus.$emit('map-ready', { data: blob, mapScale: scale }))
        self.printControl.un('print', printMap)
      }
    },

    async getCenterMapFromIP () {
      const IP = await fetch('https://ipapi.co/json/').then(res => res.json())
      const coords = await fetch('http://api.sypexgeo.net/nLy2u/json/' + IP.ip).then(res => res.json())
      const center = transform([coords.city.lon, coords.city.lat], 'EPSG:4326', 'EPSG:3857')
      return center.every(Boolean) ? center : (function () { throw Error('Not correct coords') })()
    },

    async initMap () {
      let center = [4188426.7147939987, 7508764.236877314]
      try {
        center = await this.getCenterMapFromIP()
      } catch (err) {
        console.log(err)
      }
      this.olmap = new Map({
        target: 'olmap',
        layers: [
          new TileLayer({ source: new OSM() })
        ],
        view: new View({
          center,
          maxZoom: 22,
          minZoom: 5,
          zoom: 12
        }),
        controls: olControl.defaults().extend([new CanvasScaleLine()]),
        interactions: olInteraction.defaults().extend([new olInteraction.DragRotateAndZoom()])
      })
      const searchControl = new SearchPhoton({
        position: true,
        title: this.$t('map_search_title'),
        placeholder: this.$t('map_search_placeholder')
      })
      this.olmap.addControl(searchControl)
      searchControl.on('select', e => {
        this.olmap.getView().animate({
          center: e.coordinate,
          zoom: Math.max(this.olmap.getView().getZoom(), 15)
        })
      })
      this.olmap.on('moveend', this.checkZoom)

      this.printControl = new PrintControl()
      this.olmap.addControl(this.printControl)
    },

    checkZoom () {
      this.snackbarMessage = this.olmap.getView().getZoom() > 16 ? 1 : 0
    }
  },

  watch: {
  },

  async updated () {
    if (this.dialog && !this.mapCreated) {
      await this.initMap()
      const map = document.querySelector('#olmap')
      map.querySelector('.ol-zoom-in').setAttribute('title', this.$t('map_zoom_in_title'))
      map.querySelector('.ol-zoom-out').setAttribute('title', this.$t('map_zoom_out_title'))
      map.querySelector('.ol-rotate-reset').setAttribute('title', this.$t('map_rotate_reset_title'))
      this.mapCreated = true
    }
    this.dialog && this.$nextTick(() => {
      this.olmap.updateSize()
    })
  },

  mounted () {
  }
}
</script>

<style scoped>
>>> .ol-search {
  left: 0.5em;
  top: 4em;
}

>>> .ol-print {
  display: none
}

#olmap {
  height: calc(100vh - 32px);
  width: 100%;
}
</style>
