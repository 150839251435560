<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel class = "layers-list ma-0 pa-0">
        <v-expansion-panel-header class = "pa-0 px-2">
          <div class = "d-flex align-center">
            <v-icon color="primary" class = "mr-1">mdi-layers-outline</v-icon>{{$t('dxf_viewer_layers')}}
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class = "pa-0 ma-0" v-if = "layers">
          <v-virtual-scroll
            :items = "layers"
            height = "200"
            item-height = "32"
            width = "200"
            class = "ma-0 pa-0"
            >
            <template v-slot:default="{ item }">
              <v-list-item>
                <div class = "d-flex align-center">
                  <v-checkbox
                    on-icon = "mdi-square"
                    off-icon = "mdi-square-off-outline"
                    :input-value = "item.isVisible"
                    @change="e => _ToggleLayer(item, e)"
                    :color ="_GetCssColor(item.color)"
                    hide-details
                    class="ma-0 pa-0"
                  />
                  <div>{{item.name}}</div>
                </div>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>

export default {
  name: 'LayersList',

  props: {
    layers: {
    // Expecting array of {name: string, color: number, isVisible: boolean}
      type: Array,
      default: null
    }
  },

  methods: {
    _ToggleLayer (layer, event) {
      const newState = Boolean(event)
      this.$emit('toggleLayer', layer, newState)
    },

    _GetCssColor (value) {
      let s = value.toString(16)
      while (s.length < 6) {
        s = '0' + s
      }
      return '#' + s
    }
  }
}

</script>

<style scoped>

.root {
    height: 100%;
    max-height: 100%;
    width: 300px;
}

.layers-list {
  max-width: 220px
}

>>> .v-expansion-panel-content__wrap {
  margin: 0;
  padding: 0
}

>>> .v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 48px;
}
</style>
