<template>
<div>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
        <v-btn
          plain
          small
          v-bind = "attrs"
          v-on = "on"
          @click = "$emit(params.event)"
          >
          <v-icon :small = "params.smallSize">{{params.icon}}</v-icon>
        </v-btn>
    </template>
    <span>{{$t(params.descr)}}</span>
  </v-tooltip>
</div>
</template>

<script>
export default {

  name: 'ToolbarBtn',

  components: {
  },

  props: ['params'],

  computed: {
  },

  methods: {
  },

  mounted () {
  }
}

</script>

<style scoped>

</style>
