import axios from 'axios'

axios.defaults.baseURL = (process.env.NODE_ENV === 'development') ? 'http://localhost:3000/api/' : 'https://survy.ru/api/'

export default {
  state: {
    isAuth: false,
    isShared: false
  },

  mutations: {
    SET_AUTH_TO_STATE (state, status) {
      state.isAuth = status
    },

    SET_SHARED_TO_STATE (state, data) {
      state.isShared = data
    }
  },

  actions: {
    async UPDATE_AUTH_STATE ({ commit }) {
      await axios.get('profile')
        .then(_ => commit('SET_AUTH_TO_STATE', true))
        .catch(error =>
          error.response.status === 401
            ? commit('SET_AUTH_TO_STATE', false)
            : console.log(error, 'err: get profile error')
        )
    },

    async SET_AUTH_TO_STATE ({ commit }, userLoginInfo) {
      await axios.post('login', userLoginInfo)
        .then(_ => commit('SET_AUTH_TO_STATE', true))
        .catch(error => console.log(error, 'err: auth error'))
    },

    async GET_LOGOUT_TO_STATE ({ commit }) {
      await axios.get('logout')
        .then(_ => {
          commit('SET_AUTH_TO_STATE', false)
          commit('DEFAULT_PROJECTS_STATE')
          commit('DEFAULT_STORAGE_STATE')
        })
        .catch(error => console.log(error, 'err: logout error'))
    },

    POST_REG ({ rootState }, userInfo) {
      return axios.post('register', { locale: rootState.locale, ...userInfo })
    },

    FORGOT_PASSWORD ({ commit }, email) {
      return axios.post('password/reset', email)
    },

    SET_CHANGE_PASSWORD ({ commit }, newPassword) {
      return axios.post('password/change', newPassword)
    }
  }
}
