<template>
  <div id = "storage-cameras" class = "py-2" scrollable>
    <v-expansion-panels flat>
      <v-expansion-panel class = "grayscale">
        <v-expansion-panel-header>
          <span>{{$t('storage common settings')}}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :headers="settingsHeaders"
            :items="[COMMON_CAMERA_SETTINGS]"
            class="elevation-0 py-1 grayscale"
            hide-default-footer
            dense
            >
            <template v-for="header in headers" #[headerSlotName(header.value)]>
              <div :key="header.text" align="center"> {{$t(header.text)}} </div>
            </template>
            <template #item.codec = "{ item }">
              <div class="d-flex justify-center">
              <v-select
                :value="item.codec"
                @change="setCommonParam('codec', $event)"
                :items="codecs"
                flat
                dense
                hide-details
                class="middle-input shrink"
              />
              </div>
            </template>
            <template #item.fps = "{ item }">
              <div class="d-flex justify-center">
              <v-select
                :value="item.fps"
                @change="setCommonParam('fps', +$event)"
                :items="fps"
                flat
                dense
                hide-details
                class="small-input shrink"
              />
              </div>
            </template>
            <template #item.activity = "{ item }">
              <div class="d-flex justify-center">
              <v-select
                :value = "item.activity"
                @change = "setCommonParam('activity', $event)"
                :items = "activites"
                item-text = "text"
                item-value = "value"
                flat
                dense
                hide-details
                class="shrink"
                >
                <template #item = '{ item }'>
                  <span>{{$t(item.name)}}</span>
                </template>
                <template #selection = '{ item }'>
                  <span class = "pa-1 ma-0">{{$t(item.name)}}</span>
                </template>
              </v-select>
              </div>
            </template>
            <template #item.interval = "{ item }">
              <div class="d-flex justify-center">
              <v-text-field
                :value="item.interval"
                @input="setCommonParam('interval', $event)"
                dense
                flat
                shrink
                class="small-input shrink"
                type="number"
                :rules = rules.interval
                hide-details = "auto"
              />
              </div>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-tabs align-with-title>
      <v-tab>
        {{ $t('storage on plan') }}
        <v-chip
          v-if = "CAMERAS.length !== 0"
          x-small
          outlined
          color = 'grey'
          class = "ml-1 px-1"
          >
          {{ CAMERAS.length }}
        </v-chip>
      </v-tab>
      <v-tab>
        {{ $t('storage additional streams') }}
        <v-chip
          v-if = "STREAMS.length !== 0"
          x-small
          outlined
          color = 'grey'
          class = "ml-1 px-1"
          >
          {{ STREAMS.reduce((sum, el) => sum + el.qty, 0) }}
        </v-chip>
      </v-tab>
      <!-- camera table -->
      <v-tab-item>
        <v-data-table
          :headers="headers"
          :items="CAMERAS"
          class="elevation-0 mt-2"
          :items-per-page="-1"
          :height="CAMERAS.length < 8 ? 'auto' : 410"
          fixed-header
          hide-default-footer
          >
          <template v-for="header in headers" #[headerSlotName(header.value)]>
            <div :key="header.text" align="center"> {{$t(header.text)}} </div>
          </template>

          <template #item.name="{ item }">
            <div class="align-center d-flex">
              <v-icon :color="item.color">{{cameraIcon(item)}}</v-icon>
              <span class="ml-2">{{item.name}}</span>
            </div>
          </template>

          <template #item.site="{ item }">
            <div class="text-left ma-0 pa-0" style = "word-break: break-word">{{getSiteName(item.site)}}</div>
          </template>

          <template #item.group="{ item }">
            <div class="d-flex align-center justify-center">
              <v-text-field
                :value="getParam('camera', 'group', item)"
                @input="setParam('camera', 'group', { id: item.id, group: +$event })"
                type = "number"
                dense
                hide-details = "auto"
                class = "x-small-input shrink"
                :rules = rules.group
              />
            </div>
          </template>

          <template #item.resolution="{ item }">
            <div class="d-flex justify-center">
              <v-text-field
                :value="item.resX + 'x' +`${(item.resX / getAR(item.AR)).toFixed(0)}`"
                flat
                dense
                persistent-hint
                disabled
                :hint="`${(item.resX **2 / getAR(item.AR) / 1000000 ).toFixed(1)}` + $t('mpx') + '   ' + getNameResoltion(item)"
              />
            </div>
          </template>
          <template #item.codec="{ item }">
            <div class="d-flex justify-center">
              <v-select
                :value="getParam('camera', 'codec', item)"
                @change="setParam('camera', 'codec', { id: item.id, codec: $event })"
                :items="codecs"
                flat
                dense
                hide-details
                class="middle-input shrink"
              />
            </div>
          </template>
          <template #item.fps="{ item }">
            <div class="d-flex justify-center">
              <v-select
                :value="getParam('camera', 'fps', item)"
                @change="setParam('camera', 'fps', { id: item.id, fps: $event })"
                :items="fps"
                flat
                dense
                hide-details
                class="small-input shrink"
              />
            </div>
          </template>
          <template #item.bitrate="{ item }">
            <div class="d-flex justify-center">
              <v-text-field
                :value = "getParam('camera', 'bitrate', item)"
                @input = "setParam('camera', 'bitrate', { id: item.id, bitrate: +$event })"
                flat
                dense
                hide-details = 'auto'
                :rules = rules.bitrate
                class="small-input shrink"
              />
            </div>
          </template>
          <template #item.activity="{ item }">
            <div class="d-flex justify-center">
              <v-select
                :value="activites.find(el => el.value === getParam('camera', 'activity', item))"
                @change="setParam('camera', 'activity', { id: item.id, activity: $event })"
                :items="activites"
                flat
                dense
                hide-details
                class="shrink mt-1"
                >
                <template #item = '{ item }'>
                  {{$t(item.name)}}
                </template>
                <template #selection = '{ item }'>
                  <span class = "pa-1 ma-0">{{$t(item.name)}}</span>
                </template>
              </v-select>
            </div>
          </template>
          <template #item.interval="{ item }">
            <div class="d-flex align-center justify-center">
              <v-text-field
                :value = "getParam('camera', 'interval', item)"
                @input = "setParam('camera', 'interval', { id: item.id, interval: +$event })"
                flat
                dense
                class="small-input shrink"
                type="number"
                hide-details = 'auto'
                :rules = rules.interval
              />
            </div>
          </template>
          <template slot="no-data">
            {{$t('table no data available')}}
          </template>
        </v-data-table>
      </v-tab-item>
      <!-- additional stream -->
      <v-tab-item>
        <v-data-table
          :headers = "streamsHeaders"
          :items = "STREAMS"
          v-model = "selStreamRow"
          show-select
          class="elevation-0 mt-2"
          :items-per-page = "-1"
          :height = "STREAMS.length < 6 ? 'auto' : 360"
          fixed-header
          hide-default-footer
          item-key = "id"
          >
          <template #top>
            <div class = "d-flex">
              <table-btn
                :btnData="{ icon: 'mdi-plus', descr: 'storage add stream' }"
                @click = "addStream()"
              />
              <table-btn
                :btnData="{ icon: 'mdi-delete', descr: 'storage delete streams' }"
                :disabled = "selStreamRow.length !== 0 ? false : true"
                @click = "removeStreams()"
              />
            </div>
          </template>
          <template v-for="header in streamsHeaders" #[headerSlotName(header.value)]>
            <div :key="header.text" align="center"> {{$t(header.text)}} </div>
          </template>
          <template #item.qty = "{ item }">
            <div class="d-flex justify-center">
              <v-text-field
                :value="item.qty"
                @input = "setParam('stream', 'qty', { id: item.id, qty: +$event })"
                flat
                dense
                hide-details = 'auto'
                type = "number"
                class = "small-input shrink"
                :rules = rules.qty
              />
            </div>
          </template>

          <template #item.group="{ item }">
            <div class="d-flex align-center justify-center">
              <v-text-field
                :value="item.group"
                @input = "setParam('stream', 'group', { id: item.id, group: +$event })"
                type = "number"
                dense
                hide-details = "auto"
                class = "x-small-input shrink"
                :rules = rules.group
              />
            </div>
          </template>

          <template #item.resolution="{ item }">
            <div class="d-flex align-center justify-center">
              <v-text-field
                :value="item.resX"
                @input = "setParam('stream', 'resX', { id: item.id, resX: +$event })"
                flat
                dense
                persistent-hint
                class="small-input shrink"
                hide-details = 'auto'
                :rules = rules.resX
              />
              <span class="mx-1 mt-1 align-self-center">x</span>
              <v-text-field
                :value="item.resY"
                @input = "setParam('stream', 'resY', { id: item.id, resY: +$event })"
                flat
                dense
                persistent-hint
                class="small-input shrink"
                hide-details = 'auto'
                :rules = rules.resY
              />
              <v-select
                :items="res"
                @change="setRes({ id: item.id, res: $event })"
                flat
                dense
                hide-details
                style = "width: 0 !important"
                class = "shrink ma-0 pa-0"
              >
                <template #item = '{ item }'>
                  <span class = "v-list-item__title" style = "width: 90px !important">{{item}}</span>
                </template>
              </v-select>
            </div>
          </template>
          <template #item.codec="{ item }">
            <div class="d-flex align-center justify-center">
              <v-select
                :value="item.codec"
                @change="setParam('stream', 'codec', { id: item.id, codec: $event })"
                :items="codecs"
                flat
                dense
                hide-details
                class="middle-input shrink ma-0 pa-0"
              />
            </div>
          </template>
          <template #item.fps="{ item }">
            <div class="d-flex align-center justify-center">
              <v-select
                :value="item.fps"
                @change="setParam('stream', 'fps', { id: item.id, fps: $event })"
                :items="fps"
                flat
                dense
                hide-details
                class="small-input shrink"
              />
            </div>
          </template>
          <template #item.bitrate="{ item }">
            <div class="d-flex align-center justify-center">
              <v-text-field
                :value = "getParam('stream', 'bitrate', item)"
                @input = "setParam('stream', 'bitrate', { id: item.id, bitrate: +$event })"
                flat
                dense
                hide-details = 'auto'
                :rules = rules.bitrate
              />
            </div>
          </template>
          <template #item.activity="{ item }">
            <div class="d-flex align-center justify-center">
              <v-select
                :value="item.activity"
                @change="setParam('stream', 'activity', { id: item.id, activity: $event })"
                :items="activites"
                flat
                dense
                hide-details
                >
                <template #item = '{ item }'>
                  {{$t(item.name)}}
                </template>
                <template #selection = '{ item }'>
                  {{$t(item.name)}}
                </template>
              </v-select>
            </div>
          </template>
          <template #item.interval = "{ item }">
            <div class="d-flex align-center justify-center">
              <v-text-field
                :value="item.interval"
                @input = "setParam('stream', 'interval', { id: item.id, interval: +$event })"
                flat
                dense
                class="small-input"
                type="number"
                hide-details = 'auto'
                :rules = rules.interval
              />
            </div>
          </template>
          <template slot="no-data">
            {{$t('table no data available')}}
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>
    <!-- intermediate result -->
    <v-divider class = "my-3"/>
    <div class="d-flex align-center justify-space-between mt-2">
      <v-text-field
        v-model="archiveDays"
        class="middle-input flex-grow-0"
        dense
        flat
        type="number"
        :suffix="$t('days')"
        hide-details = 'auto'
        :rules = rules.archiveDays
        >
        <template #label>
          {{$t('strorage archive')}}
        </template>
      </v-text-field>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { matrixResolutionArray, matrixTypeArray } from '~/assets/cameraParams'
import { calcBitrate } from '~/assets/cameraBitrate'
import tableBtn from '~/components/lib/srvTableBtn.vue'

export default {

  name: 'StorageCameras',

  components: {
    tableBtn
  },

  data: () => ({
    selStreamRow: [],
    selGroup: 'storage all',
    matrixType: matrixTypeArray,
    matrixResolution: matrixResolutionArray,
    codecs: ['H.264', 'H.265', 'H.265+'],
    fps: [10, 12, 15, 20, 25, 30],
    activites: [
      { name: 'storage camera activity extra-low', value: 'extra-low' },
      { name: 'storage camera activity low', value: 'low' },
      { name: 'storage camera activity middle', value: 'middle' },
      { name: 'storage camera activity high', value: 'high' }
    ],
    res: [
      '640x480', '720x576', '800x600', '1280x720',
      '1280x960', '1920x1080', '2048x1536', '2688x1520',
      '2560x1920', '3072x2048', '3840x2160', '4000x3000'
    ],
    headers: [
      { text: 'storage camera name', align: 'start', sortable: false, value: 'name', cellClass: 'column15' },
      { text: 'storage camera site', align: 'center', sortable: true, value: 'site', cellClass: 'column15' },
      { text: 'storage camera group', align: 'center', sortable: true, value: 'group', cellClass: 'column8' },
      { text: 'storage camera resolution', align: 'start', sortable: false, value: 'resolution', cellClass: 'column20' },
      { text: 'storage camera codec', align: 'start', sortable: false, value: 'codec', cellClass: 'column8' },
      { text: 'storage camera fps', align: 'start', sortable: false, value: 'fps', cellClass: 'column8' },
      { text: 'storage camera bitrate', align: 'start', sortable: false, value: 'bitrate', cellClass: 'column8' },
      { text: 'storage camera activity', align: 'start', sortable: false, value: 'activity', cellClass: 'column10' },
      { text: 'storage camera activity interval', align: 'start', sortable: false, value: 'interval', cellClass: 'column10' }
    ],
    settingsHeaders: [
      { text: 'storage camera codec', align: 'center', sortable: false, value: 'codec', width: '25%' },
      { text: 'storage camera fps', align: 'center', sortable: false, value: 'fps', width: '25%' },
      { text: 'storage camera activity', align: 'center', sortable: false, value: 'activity', width: '25%' },
      { text: 'storage camera activity interval', align: 'center', sortable: false, value: 'interval', width: '25%' }
    ]
  }),

  computed: {
    ...mapGetters([
      'CAMERAS'
    ]),

    ...mapState({
      STREAMS: state => state.storageStore.streams,
      COMMON_CAMERA_SETTINGS: state => state.storageStore.commonCameraSettings
    }),

    archiveDays: {
      get () {
        return this.$store.state.storageStore.archiveDays
      },
      set (value) {
        this.rules.archiveDays.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
          this.$store.commit('SET_ARCHIVE_DAYS', value)
      }
    },

    rules () {
      return {
        interval: [value => (Number.isInteger(+value) && (+value >= 1) && (+value <= 24)) || (this.$t('storage interval out of range') + ' 1-24')],
        bitrate: [value => (!isNaN(value) && (+value > 0)) || (this.$t('storage bitrate must be more') + ' 0')],
        archiveDays: [value => (Number.isInteger(+value) && (+value > 0)) || (this.$t('storage archive days must be more') + ' 0')],
        group: [value => (Number.isInteger(+value) && (+value >= 1) && (+value <= 99)) || (this.$t('storage group out of range') + '1-99')],
        qty: [value => (Number.isInteger(+value) && (+value > 0)) || (this.$t('storage qty must be more') + ' 0')],
        resX: [value => (Number.isInteger(+value) && (+value > 100)) || (this.$t('storage res must be more') + ' 100')],
        resY: [value => (Number.isInteger(+value) && (+value > 100)) || (this.$t('storage res must be more') + ' 100')]
      }
    },

    streamsHeaders () {
      return this.headers.filter(el => el.value !== 'site').map(el => (el.value === 'name')
        ? { text: 'storage qty streams', align: el.align, sortable: false, value: 'qty', cellClass: 'column30' }
        : el
      )
    },

    groups () {
      const arr1 = this.CAMERAS.map(el => +el.group)
      const arr2 = this.STREAMS.map(el => +el.group)
      return [...new Set([...arr1, ...arr2])].sort((a, b) => a - b)
    }
  },

  methods: {
    headerSlotName (value) {
      return 'header.' + value
    },

    cameraIcon (camera) {
      return this.matrixType.find(el => el.type === camera.type).icon
    },

    getAR (value) {
      const [x, y] = value.split(':')
      return x / y
    },

    getNameResoltion (camera) {
      return Object.keys(this.matrixResolution)
        .map(key => this.matrixResolution[key].find(el => el.resX === camera.resX))
        .find(el => !!el)?.name || ''
    },

    getSiteName (id) {
      return this.$store.state.sitesStore.sites.find(el => el.id === id).name
    },

    updateCamera (data) {
      this.$store.commit('UPDATE_OBJECT', data)
    },

    updateStream (data) {
      this.$store.commit('UPDATE_STREAM', data)
    },

    updateCameraBitrate (cameraId) {
      const camera = this.CAMERAS.find(el => el.id === cameraId)
      const bitrate = calcBitrate(camera.codec, camera.fps, camera.resX ** 2 / this.getAR(camera.AR) / 1000000, camera.activity)
      this.updateCamera({ id: camera.id, bitrate: +bitrate.toFixed(2) })
    },

    updateStreamBitrate (streamId) {
      const stream = this.STREAMS.find(el => el.id === streamId)
      const bitrate = calcBitrate(stream.codec, stream.fps, stream.resX * stream.resY / 1000000, stream.activity)
      this.updateStream({ id: stream.id, bitrate: +bitrate.toFixed(2) })
    },

    getParam (type, param, item) {
      const update = (type === 'camera') ? this.updateCamera : this.updateStream
      const updateBitrate = (type === 'camera') ? this.updateCameraBitrate : this.updateStreamBitrate
      if (!item[param]) {
        update({ id: item.id, [param]: this.COMMON_CAMERA_SETTINGS[param] })
        if (param === 'codec' || param === 'fps' || param === 'activity' || param === 'bitrate') updateBitrate(item.id)
      }
      return item[param]
    },

    setParam (type, param, item) {
      const update = (type === 'camera') ? this.updateCamera : this.updateStream
      const updateBitrate = (type === 'camera') ? this.updateCameraBitrate : this.updateStreamBitrate
      const rule = this.rules[param]
      if (!rule || (rule && rule.map(f => f(item[param]))).every(el => (typeof el === 'boolean') && el)) {
        update({ id: item.id, [param]: item[param] })
      }
      if (param === 'codec' || param === 'fps' || param === 'activity' || param === 'resX' || param === 'resY') updateBitrate(item.id)
    },

    setRes (stream) {
      const res = stream.res.split('x')
      this.$store.commit('UPDATE_STREAM', { id: stream.id, resX: +res[0] })
      this.$store.commit('UPDATE_STREAM', { id: stream.id, resY: +res[1] })
      this.updateStreamBitrate(stream.id)
    },

    setCommonParam (param, value) {
      const rule = this.rules[param]
      if (!rule || (rule && rule.map(f => f(value))).every(el => (typeof el === 'boolean') && el)) {
        this.$store.commit('UPDATE_STORAGE_COMMON_CAMERA_SETTINGS', { [param]: Number.isInteger(value) ? +value : value })
        this.CAMERAS.forEach(el => {
          this.updateCamera({ id: el.id, [param]: this.COMMON_CAMERA_SETTINGS[param] })
          this.updateCameraBitrate(el.id)
        })
        this.STREAMS.forEach(el => {
          this.updateStream({ id: el.id, [param]: this.COMMON_CAMERA_SETTINGS[param] })
          this.updateStreamBitrate(el.id)
        })
      }
    },

    addStream () {
      const id = Math.random().toString(8).slice(2)
      this.$store.commit('CREATE_STREAM', {
        id: id,
        group: 1,
        qty: 10,
        resX: 1920,
        resY: 1080,
        bitrate: null,
        ...this.COMMON_CAMERA_SETTINGS
      })
      this.updateStreamBitrate(id)
    },

    removeStreams () {
      this.$store.commit('DELETE_STREAMS', this.selStreamRow.map(el => el.id))
      this.selStreamRow = []
    }
  },

  watch: {
    groups (newGroups, oldGroups) {
      const newElems = newGroups.filter(el => !oldGroups.includes(el))
      newElems.forEach(el => this.$store.commit('ADD_STORAGE_CONFIG', el))
      const delElems = oldGroups.filter(el => !newGroups.includes(el))
      delElems.forEach(el => this.$store.commit('REMOVE_STORAGE_CONFIG', el))
    }
  }
}
</script>

<style scoped>

#storage-capacity {
  max-width: 1100px;
}

>>> .v-input__icon > .v-icon {
  height: 0;
  width: 0;
}

.x-small-input {
  width: 40px !important
}

.small-input {
  width: 60px !important
}

.middle-input {
  width: 90px !important
}

.middle2-input {
  width: 120px !important
}

>>> .v-select__selections input {
  display: none;
}
</style>

<style>
.column8 {
  width: 8% !important;
}
.column10 {
  width: 10% !important;
}

.column15 {
  width: 15% !important;
  word-break: break-all;
}

.column20 {
  width: 20% !important;
}

.column30 {
  width: 30% !important;
}

</style>
