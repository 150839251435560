<template>
<v-card>
  <v-card-title>
    {{$t('contacts')}}
    <v-spacer/>
    <v-icon @click="$emit('input')">mdi-close</v-icon>
  </v-card-title>
  <v-card-text>
    <contacts-body/>
  </v-card-text>
</v-card>
</template>

<script>
import ContactsBody from './ContactsBody.vue'

export default {

  name: 'Contacts',

  components: {
    ContactsBody
  },

  data: () => ({
  }),

  computed: {

  },

  methods: {

  },

  mounted () {

  }
}

</script>

<style scoped>

</style>
