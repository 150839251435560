<template>
<div id="storage-config" class="py-2" scrollable>
  <v-data-table
    :headers="headers"
    :items="groups"
    class="elevation-0 mt-2"
    :items-per-page="-1"
    :height="groups.length < 8 ? 'auto' : 410"
    fixed-header
    hide-default-footer
    >
    <template v-for="header in headers" #[headerSlotName(header.value)]>
      <div :key="header.text" align="center"> {{$t(header.text)}} </div>
    </template>

    <template #item.group="{ item }">
      <span class = "text-body-1">{{item.group}}</span>
    </template>
    <template #item.requireCapacity="{ item }">
      <span class="text-left text-body-1">
        {{ getCapacity(item.requireCapacity) }}
      </span>
    </template>
    <template #item.hdds="{ item }">
      <span class = "text-body-1">{{item.qtyHDD + ' x ' + item.capacityHDD + $t('tbyte')}}</span>
    </template>
    <template #item.hddGroups="{ item }">
      <div class = "text-body-1">
        <div v-if = "item.fullHDDGroup !== 0">
          {{ item.fullHDDGroup + 'x('+ item.maxGroup + ' + ' + item.hotSpare + 'HS) '}}
        </div>
        <div v-if = "item.lastGroupHDDQty !== 0">
          {{ item.lastGroupHDDQty + ' + ' + item.hotSpare + 'HS' }}
        </div>
      </div>
    </template>
    <template #item.bandwidth = "{ item }">
      <span class="text-left text-body-1">
        {{ getBandwidth(item.bandwidth) }}
      </span>
    </template>
    <template slot="no-data">
      {{$t('table no data available')}}
    </template>
    <template #body.append>
        <tr>
          <td align = "center" class = "text-body-1 font-weight-medium">{{$t('storage total')}}</td>
          <td align = "center" class = "text-body-1 font-weight-medium">{{ total.capacity.value + ' ' + $t(total.capacity.unit1) }}</td>
          <td align = "center">
            <ul class = "pa-0 ma-0 ">
              <li
                v-for = "item in getAllHDD()"
                :key = "item.capacityHDD"
                class = "text-body-1 font-weight-medium"
                style = "list-style-type: none">
                {{item.qty + ' x ' + item.capacityHDD + $t('tbyte')}}
              </li>
            </ul>
          </td>
          <td align = "center"></td>
          <td align = "center" class = "text-body-1 font-weight-medium">
            {{ total.bandwidth.value + ' ' + $t(total.bandwidth.unit1)+ '/' + $t(total.bandwidth.unit2) }}
          </td>
        </tr>
      </template>
  </v-data-table>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { calcStorage, checkStorageBandwidth, checkStorageCapacity } from '~/components/lib/calcStorageFunc'

export default {

  name: 'StorageResult',

  components: {
  },

  data: () => ({
    headers: [
      { text: 'storage camera group', align: 'center', sortable: true, value: 'group', cellClass: 'column20' },
      { text: 'storage necessary capacity', align: 'center', sortable: false, value: 'requireCapacity', cellClass: 'column20' },
      { text: 'storage necessary hdd', align: 'center', sortable: false, value: 'hdds', cellClass: 'column20' },
      { text: 'storage scheme group', align: 'center', sortable: false, value: 'hddGroups', cellClass: 'column20' },
      { text: 'storage bandwidth', align: 'center', sortable: false, value: 'bandwidth', cellClass: 'column20' }
    ]
  }),

  computed: {
    ...mapGetters([
      'CAMERAS'
    ]),

    ...mapState({
      STORAGE_CONFIGS: state => state.storageStore.storageConfigs,
      STREAMS: state => state.storageStore.streams,
      ARCHIVE_DAYS: state => state.storageStore.archiveDays
    }),

    groups () {
      return this.STORAGE_CONFIGS.map(config => {
        const cameras = this.CAMERAS.filter(cam => config.group === cam.group)
        const streams = this.STREAMS.filter(stream => config.group === stream.group)
        return {
          group: config.group,
          ...calcStorage(cameras, streams, config, this.ARCHIVE_DAYS)
        }
      })
    },

    total () {
      return {
        capacity: checkStorageCapacity(this.groups.reduce((sum, el) => sum + el.requireCapacity, 0)),
        bandwidth: checkStorageBandwidth(this.groups.reduce((sum, el) => sum + el.bandwidth, 0)),
        allHDD: this.getAllHDD()
      }
    }
  },

  methods: {
    headerSlotName (value) {
      return 'header.' + value
    },

    getCapacity (capacity) {
      const a = checkStorageCapacity(capacity)
      return a.value + ' ' + this.$t(a.unit1)
    },

    getBandwidth (bandwidth) {
      const a = checkStorageBandwidth(bandwidth)
      return a.value + ' ' + this.$t(a.unit1) + '/' + this.$t(a.unit2)
    },

    getAllHDD () {
      const listOfHDDs = [...new Set(this.STORAGE_CONFIGS.map(el => +el.capacityHDD))].sort((a, b) => a - b)
      return listOfHDDs.map(capacityHDD => ({
        capacityHDD,
        qty: this.groups.filter(e => e.capacityHDD === capacityHDD).reduce((sum, el) => sum + el.qtyHDD, 0)
      }))
    }
  }
}
</script>

<style scoped>

#storage-config {
max-width: 1100px;
}

.x-small-input {
width: 40px !important
}

.small-input {
width: 60px !important
}

.middle-input {
width: 90px !important
}

.middle2-input {
width: 120px !important
}

>>> .v-select__selections input {
display: none;
}

</style>
