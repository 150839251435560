export function setObjControls (typeOf) {
  let arr = []
  switch (typeOf) {
    // [ml, mr, mt, mb, br, bl, tl, tr, mtr]
    case 'tape':
    case 'camera':
    case 'limitsLine':
    case 'layoutEquip':
    case 'layoutCircle':
    case 'layoutLine': arr = [0, 0, 0, 0, 0, 0, 0, 0, 0]; break
    case 'layoutRect': arr = [0, 1, 0, 1, 1, 1, 1, 1, 1]; break
    case 'layoutImg': arr = [0, 0, 0, 0, 1, 1, 1, 1, 1]; break
    case 'layoutText': arr = [1, 1, 0, 0, 0, 0, 0, 0, 1]; break
    case 'layoutBrush': arr = [0, 0, 0, 0, 0, 0, 0, 0, 1]; break
    default: console.log('Object typeOf incorrect')
  }
  return ({ ml: arr[0], mr: arr[1], mt: arr[2], mb: arr[3], br: arr[4], bl: arr[5], tl: arr[6], tr: arr[7], mtr: arr[8] })
}
