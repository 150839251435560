<template>
  <v-container fluid class="ma-0 pa-0">
    <properties-row>
      <template #name> {{ $t('equipment name') }} </template>
      <v-text-field
        v-model.trim = "equipName"
        class = "mx-2 my-1 text-body-2"
        hide-details = "auto"
        flat
        dense
        :rules=rules.name
      />
    </properties-row>

    <properties-row style = "position: relative">
      <template #name> {{ $t('equipment type') }} </template>
      <v-btn
        @click.stop="dialog = true"
        icon
        x-large
        >
        <v-icon large>
          {{selectedType.icon}}
        </v-icon>
      </v-btn>
      <v-dialog
        v-model = "dialog"
        hide-overlay
        class = "elevation-0"
      >
        <v-card class = "icon-picker elevation-2 d-flex flex-wrap justify-center pa-2">
          <v-btn
            v-for = "item in matrixType"
            :key = "item.type"
            @click="dialog = false; selectedType = item.type"
            icon
            x-large
            :color = "item.type === selectedType.type ? equipColor : ''"
            >
            <v-icon large>
              {{item.icon}}
            </v-icon>
          </v-btn>
        </v-card>
      </v-dialog>
    </properties-row>

    <properties-row>
      <template #name> {{ $t('color') }} </template>
      <color-picker class = "ml-2" v-model = "equipColor"/>
    </properties-row>

    <properties-row>
      <template #name> {{ $t('equipment size') }} </template>
      <v-text-field
        v-model = "equipSize"
        class = "mx-2 my-0 text-body-2 small-input"
        style = "flex-grow: 0"
        :suffix = "$t('px')"
        flat
        dense
        hide-details = "auto"
        type="number"
        :rules = rules.size
      />
    </properties-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import colorPicker from './EditorColorPicker'
import EditorPropertiesRow from './EditorPropertiesRow'

export default {

  name: 'EditorPropertiesEquip',

  components: {
    colorPicker,
    propertiesRow: EditorPropertiesRow
  },

  data: () => ({
    matrixType: [
      { type: 'monitor', icon: 'equip-monitor' },
      { type: 'videowall', icon: 'equip-videowall', default: true },
      { type: 'pc', icon: 'equip-pc' },
      { type: 'printer', icon: 'equip-printer' },
      { type: 'recorder', icon: 'equip-recorder' },
      { type: 'storage', icon: 'equip-storage' },
      { type: 'coreRouter', icon: 'equip-core-router' },
      { type: 'router', icon: 'equip-router' },
      { type: 'switch', icon: 'equip-switch' },
      { type: 'ups', icon: 'equip-ups' },
      { type: 'upsDc', icon: 'equip-ups-dc' },
      { type: 'battery', icon: 'equip-battery' },
      { type: 'light', icon: 'equip-light' },
      { type: 'rack', icon: 'equip-rack' },
      { type: 'coupler', icon: 'equip-coupler' },
      { type: 'fiberOptic', icon: 'equip-fiber-optic' },
      { type: 'junctionBox', icon: 'equip-junction-box' },
      { type: 'arrowFromPoint', icon: 'equip-arrow-from-point' },
      { type: 'arrowToPointr', icon: 'equip-arrow-to-point' }
    ],
    dialog: false
  }),

  computed: {
    ...mapState({
      selObj: state => state.objectsStore.selObj,
      objectList: state => state.objectsStore.list
    }),

    equipName: {
      get () {
        return this.obj ? this.obj.name : ''
      },
      set (value) {
        this.obj && this.rules.name.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
          this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, name: value })
      }
    },

    selectedType: {
      get () {
        return this.obj && this.matrixType.find(el => el.type === this.obj.type)
      },
      set (value) {
        this.obj && this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, type: value })
      }
    },

    equipColor: {
      get () {
        return (this.obj) ? this.obj.color : '#00FF00'
      },
      set (value) {
        this.obj && this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, color: value })
      }
    },

    equipSize: {
      get () {
        return this.obj.size ? this.obj.size : 32
      },
      set (value) {
        this.obj && this.rules.size.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
          this.$store.commit('UPDATE_OBJECT', { id: this.obj.id, size: +value })
      }
    },

    obj () {
      return (this.selObj)
        ? this.selObj
        : false
    },

    rules () {
      return {
        name: [value => (value.length <= 25) || this.$t('equip properties max 25 characters')],
        size: [value => (Number.isInteger(+value) && (+value >= 16) && (+value <= 200)) || (this.$t('equip properties out of range') + '16-200')]
      }
    }
  },

  methods: {
  },

  watch: {
  },

  mounted () {
  }
}

</script>

<style scoped>
.small-input {
  width: 70px !important
}

.x-small-input {
  width: 35px !important
}

.icon-picker {
  position: absolute;
  top: 250px;
  right: 20px;
  width: 300px;
}

>>> .v-dialog {
  box-shadow: none;
}
</style>
