// all distance - m, all angles - radian

import { dist2points } from './mathFunction'

function getCamParams (position, camAttr, canvasData) { // cam = { x, y, dirX, dirY,...  }
  const DH = dist2points(position.cam, position.dir) / canvasData.zoom / 1000 // distance from cam to dir/handle point, m
  const f = camAttr.focal
  const AR = +camAttr.AR.split(':')[0] / +camAttr.AR.split(':')[1]

  let alpha = camAttr.anglesUserDef
    ? camAttr.anglesUserDef.alpha * Math.PI / 180
    : 2 * Math.atan(camAttr.sizeX / 2 / f) // horisonal angle cam, in radian

  let beta = camAttr.anglesUserDef
    ? camAttr.anglesUserDef.beta * Math.PI / 180
    : 2 * Math.atan(camAttr.sizeX / AR / 2 / f) // vertical angle FoV cam, in radian

  const distortion = (camAttr.anglesUserDef)
    ? getDistortionCoef(alpha, beta, camAttr.sizeX, camAttr.sizeX / AR, f)
    : false

  if (camAttr.corr) {
    [alpha, beta] = [beta, alpha]
  }
  const gamma = camAttr.gamma !== 'auto' ? camAttr.gamma * Math.PI / 180 : 'auto'

  return {
    alpha,
    beta,
    gamma,
    focal: f,
    typeInst: camAttr.typeInst,
    hCam: camAttr.hCam,
    hMaxAim: camAttr.hMaxAim,
    hMinAim: camAttr.hMinAim,
    distortion,
    DH,
    light: camAttr.light
  }

  function getDistortionCoef (alpha, beta, mx, my, f) {
    const r1 = f * Math.tan(alpha / 2)
    const r2 = f * Math.tan(beta / 2)
    const A = mx / 2 / r1 - 1
    const B = my / 2 / r2 - 1
    const det = (r1 ** 2) * (r2 ** 4) - (r1 ** 4) * (r2 ** 2)
    const k1 = (A * (r2 ** 4) - B * (r1 ** 4)) / det
    const k2 = (B * (r1 ** 2) - A * (r2 ** 2)) / det
    return { k1, k2 }
  }
}

export { getCamParams }
