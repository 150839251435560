/* eslint-disable */

import { vec2 } from 'gl-matrix'
import { check2PointsLine, findX2Line, checkX2Segment } from './mathFunction'

const epsilon = 0.075

function cross2d (a, b) {
  return (a[0] * b[1]) - (a[1] * b[0]);
}

function perp2d (v, clockwise) {
  clockwise = clockwise || false
  return (clockwise) ? vec2.fromValues(v[1], -v[0]) : vec2.fromValues(-v[1], v[0])
}

function areParallel (a, b) {
  return (Math.abs(cross2d(a, b)) <= epsilon)
}

function invLerp (line, point) {
  const t = vec2.create()
  vec2.sub(t, point, line.ends[0])
  return vec2.dot(t, line.vec) / line.len_2
}

function isPointOnLine (pt, line) {
  const v = vec2.create()
  vec2.sub(v, pt, line.ends[0])
  return areParallel(v, line.vec)
}

function pointOnLine (line, t) {
  var pt = vec2.create()
  vec2.scaleAndAdd(pt, line.ends[0], line.vec, t)
  return pt
}

function rotateDir (dir, cosA, sinA) {
  const xC = dir[0] * cosA
  const yC = dir[1] * cosA
  const xS = dir[0] * sinA
  const yS = dir[1] * sinA
  const rotDir = [
    vec2.fromValues(xC - yS, xS + yC),
    vec2.fromValues(xC + yS, -xS + yC)
  ]
  return rotDir
}

function pointSegShortestDistance (line, pt) {
  const e1ToPt = vec2.create()
  vec2.sub(e1ToPt, pt, line.ends[0])
  const num = vec2.dot(e1ToPt, line.vec)
  let s = num / line.len_2
  s = (s <= 0) ? 0 : ((s >= 1) ? 1 : s)
  const perp = vec2.create()
  vec2.scale(perp, line.vec, s)
  vec2.sub(perp, e1ToPt, perp)
  return vec2.sqrLen(perp)
}

function lineSegCircleXsect(line, centre, radius_2) {
  return pointSegShortestDistance(line, centre) < radius_2
}

function calcFieldOfView (camera, limitsLines) {
// camera = { x, y, dirX, dirY, angleFoV}
// x,y - camera point in canvas, px
// dirX, dirY - direction point in canvas, px
// angleFoV - radian. In advanced mode - use angle depth zone
// limitslines - arr of limitsline coords [ {coords: [x1, y1, x2, y2] }]
// linesFoV - array limits lines field of view [[x1, y1, x2, y2]...], px
  const halfFoV = camera.angleFoV / 2
  const halfFoVCos = Math.cos(halfFoV)
  const halfFoVSin = Math.sin(halfFoV)
  const visionRadius = camera.radius
  // зона проверки отклонения луча
  const halfAuxRayTilt = 8.72664625995e-3
  const halfAuxRayTiltCos = Math.cos(halfAuxRayTilt)
  const halfAuxRayTiltSin = Math.sin(halfAuxRayTilt)

  const scene = {
    boundaryLines: [], // in math used this method of define. In arr - close polygon or line
    observer: {
      loc: vec2.fromValues(camera.x, camera.y),
      dir: vec2.fromValues(camera.dirX - camera.x, camera.dirY - camera.y)
    }
  }

  const radius_2 = visionRadius * visionRadius

  scene.observer.sector = {
    radius: visionRadius,
    radius_2: radius_2,
    fovEdges: [{
      vec: vec2.create(),
      ends: [
        vec2.create(),
        vec2.create()
      ],
      len_2: radius_2
      },
      { vec: vec2.create(),
        ends: [
          vec2.create(),
          vec2.create()
        ],
        len_2: radius_2
      }
    ]
  }

  scene.observer.fov = {
    blockingEdges: [],
    anglePtSet: new Set(),
    anglePoints: []
  }

  // add linesFoV, add limitsline with fix bug with limitline inresect DN
  if (camera.linesFoV) {
    scene.boundaryLines.push(...camera.linesFoV.map(el => ({ coords: el })))
    const DN = camera.linesFoV[0] // [x1, y1, x2, y2]
    const P1 = { x: DN[0], y: DN[1] }
    const P2 = { x: DN[2], y: DN[3] }
    if (limitsLines.length !== 0) limitsLines.forEach(el => {
      const line = el.coords
      const M1 = { x: line[0], y: line[1] }
      const M2 = { x: line[2], y: line[3] }
      if (checkX2Segment([P1, P2], [M1, M2])) {
        const xPoint = findX2Line(P1, P2, M1, M2, 'segment')
        const cam = { x: camera.x, y: camera.y }
        const outPoint = check2PointsLine(P1, P2, M1, cam) !== -1 ? M1 : M2
        scene.boundaryLines.push({ coords: [xPoint.x, xPoint.y, outPoint.x, outPoint.y] })
      } else {
        scene.boundaryLines.push(el)
      }
    })
  } else {
    if (limitsLines.length !== 0) scene.boundaryLines.push(...limitsLines)
  }


  if (scene.boundaryLines.length === 0) return []

  constructEdges (scene.boundaryLines) //создается массив ребер и полигонов
  var PointInSector = {
    FrontSemicircle: 0, // point within sector-containing semicircle, but outside sector
    Behind: 1, // point behind sector
    Outside: 2, // point outside bounding circle
    Within: 4 // point contained within sector
  }
  var LineSegConfig = {
    Disjoint: 0,
    Parallel: 1,
    Intersect: 2
  }

  update()
  let pointsOfFoV = drawFoV()

  function isPointInSector (pt, sector) {
    const v = vec2.create()
    vec2.sub(v, pt, sector.centre)
    const dot = vec2.dot(v, sector.midDir)
    if (dot <= 0) return PointInSector.Behind

    if ((vec2.sqrLen(v) - sector.radius_2) > epsilon) return PointInSector.Outside

    if ((cross2d(sector.fovEdges[0].vec, v) <= 0) &&
        (cross2d(v, sector.fovEdges[1].vec) <= 0))
        return PointInSector.Within

    return PointInSector.FrontSemicircle
  }

  function lineSegArcXsect (line, sector) {
    const delta = vec2.create()
    vec2.sub(delta, line.ends[0], sector.centre)
    const b = vec2.dot(line.vec, delta)
    const d_2 = line.len_2
    const c = vec2.sqrLen(delta) - sector.radius_2
    const det = (b * b) - (d_2 * c)

    if (det > 0) {
      const det_sqrt = Math.sqrt(det)
      let t, t1, t2
      if (b >= 0) {
        t = b + det_sqrt
        t1 = -t / d_2
        t2 = -c / t
      } else {
        t = det_sqrt - b
        t1 = c / t
        t2 = t / d_2
      }
      let p1, p2, p1InSector, p2InSector
      let points = []
      if ((t1 >= 0) && (t1 <= 1)) {
        p1 = pointOnLine(line, t1)
        p1InSector = isPointInSector(p1, sector)
        if (p1InSector === PointInSector.Within) points.push(p1)
      }
      if ((t2 >= 0) && (t2 <= 1)) {
        p2 = pointOnLine(line, t2)
        p2InSector = isPointInSector(p2, sector)
        if (p2InSector === PointInSector.Within) points.push(p2);
      }

      if (p1 === undefined && p2 === undefined) return;

      if ((p1InSector === PointInSector.Behind) && (p2InSector === PointInSector.Behind))
        return { config: PointInSector.Behind }

      if (points.length)
        return { config: PointInSector.Within, points: points }
    }
  }

  function lineSegLineSegXsect (line1, line2, shouldComputePoint, isLine1Ray) {
    shouldComputePoint = shouldComputePoint || false
    isLine1Ray = isLine1Ray || false
    var result = { config: LineSegConfig.Disjoint }
    var l1p = perp2d(line1.vec)
    var f = vec2.dot(line2.vec, l1p)
    if (Math.abs(f) <= epsilon) {
      result.config = LineSegConfig.Parallel

      if (isLine1Ray && shouldComputePoint &&
        isPointOnLine(line2.ends[0], line1)) {
          var alpha = invLerp(line2, line1.ends[0])

          if ((alpha >= 0) && (alpha <= 1)) {
            result.t = 0
            result.point = vec2.create()
            vec2.copy(result.point, line1.ends[0])
          } else if (alpha < 0) {
            result.point = vec2.create()
            vec2.copy(result.point, line2.ends[0])
            result.t = invLerp(line1, result.point)
          }
        }
    } else {
      var c = vec2.create()
      vec2.sub(c, line1.ends[0], line2.ends[0])
      var e = vec2.dot(c, l1p)

      if (((f > 0) && (e >= 0) && (e <= f)) || ((f < 0) && (e <= 0) && (e >= f))) {
        var l2p = perp2d(line2.vec)
        var d = vec2.dot(c, l2p)

        if ((isLine1Ray && (((f > 0) && (d >= 0)) || ((f < 0) && (d <= 0)))) || (((f > 0) && (d >= 0) && (d <= f)) || ((f < 0) && (d <= 0) && (d >= f)))) {
          result.config = LineSegConfig.Intersect
          if (shouldComputePoint) {
            var s = d / f
            result.t = s
            result.point = pointOnLine(line1, s)
          }
        }
      }
    }
    return result
  }

  function addAnglePointWithAux (point, prevEdge, nextEdge, sector, anglePoints) {
    var currentSize = anglePoints.size
    anglePoints.add(point)

    if (currentSize != anglePoints.size) {
      var ray = vec2.create()
      vec2.sub(ray, point, sector.centre)
      var auxiliaries = rotateDir(ray, halfAuxRayTiltCos, halfAuxRayTiltSin)
      vec2.add(auxiliaries[0], sector.centre, auxiliaries[0])
      vec2.add(auxiliaries[1], sector.centre, auxiliaries[1])
      var projAxis = perp2d(ray)

      if ((nextEdge === undefined) || (nextEdge === prevEdge)) {
        const lineVec = (point === prevEdge.ends[0])
          ? prevEdge.vec
          : vec2.fromValues(-prevEdge.vec[0], -prevEdge.vec[1])
        const p = vec2.dot(lineVec, projAxis)

        if (p <= 0) anglePoints.add(auxiliaries[0])
        if (p >= 0) anglePoints.add(auxiliaries[1])
      } else {
        var p1 = vec2.dot(prevEdge.vec, projAxis)
        var p2 = vec2.dot(nextEdge.vec, projAxis)
        if ((p1 >= 0) && (p2 <= 0)) {
          anglePoints.add(auxiliaries[0])
        } else {
          if ((p1 <= 0) && (p2 >= 0)) anglePoints.add(auxiliaries[1])
        }
      }
    }
  }

  function checkboundaryLine (boundaryLine, sector, anglePoints, blockingEdges) {
    var n = boundaryLine.edges.length
    var prevEdge = boundaryLine.edges[n - 1]
    for (let i = 0; i < n; ++i) {
        var edge = boundaryLine.edges[i]

        if (lineSegCircleXsect(edge, sector.centre, sector.radius_2)) {
            var e1InSector = isPointInSector(edge.ends[0], sector)
            var e2InSector = isPointInSector(edge.ends[1], sector)

            if ((e1InSector === PointInSector.Behind) && (e2InSector === PointInSector.Behind)) continue;
            if ((e1InSector === PointInSector.Within) && (e2InSector === PointInSector.Within)) {
              addAnglePointWithAux(edge.ends[0], prevEdge, edge, sector, anglePoints)
              addAnglePointWithAux(edge.ends[1], edge, boundaryLine.edges[i + 1], sector, anglePoints)
              blockingEdges.push(edge)
            } else {
              var blocking = false
              if (e1InSector === PointInSector.Within) {
                addAnglePointWithAux(edge.ends[0], prevEdge, edge, sector, anglePoints)
                blocking = true
              }
              if (e2InSector === PointInSector.Within) {
                addAnglePointWithAux(edge.ends[1], edge, boundaryLine.edges[i + 1], sector, anglePoints)
                blocking = true
              }

              var edgeMayIntersectArc = (e1InSector === PointInSector.Outside) || (e2InSector === PointInSector.Outside)

              var testSegSegXsect = true
              if (edgeMayIntersectArc) {
                var arcXsectResult = lineSegArcXsect(edge, sector)
                if (arcXsectResult) {
                  if (arcXsectResult.config === PointInSector.Within) {
                    const len = arcXsectResult.points.length
                    for (let j = 0; j < len; ++j) anglePoints.add(arcXsectResult.points[j])
                    blocking = true
                  }
                  testSegSegXsect = (arcXsectResult.config !== PointInSector.Behind)
                }
              }

              if (blocking) {
                blockingEdges.push(edge)
              } else {
                if (testSegSegXsect && sector.fovEdges.some(function (sectorEdge) {
                  return LineSegConfig.Intersect === lineSegLineSegXsect(edge, sectorEdge).config
                }))
                blockingEdges.push(edge)
              }
            }
        }
        prevEdge = edge
    }
  }

  function makeRays (sector, anglePoints) {
    let ray = vec2.create()
    vec2.sub(ray, anglePoints[0], sector.centre)
    let rays = [ray]
    for (let i = 1, j = 0, n = anglePoints.length; i < n; ++i) {
      ray = vec2.create()
      vec2.sub(ray, anglePoints[i], sector.centre)
      if (!areParallel(ray, rays[j])) {
        rays.push(ray)
        ++j
      }
    }
    return rays
  }

  function updateSector (sector) {
    sector.centre = scene.observer.loc
    sector.midDir = scene.observer.dir
    var fovDirs = rotateDir(sector.midDir, halfFoVCos, halfFoVSin)
    vec2.scale(fovDirs[0], fovDirs[0], sector.radius)
    vec2.scale(fovDirs[1], fovDirs[1], sector.radius)
    var e0 = vec2.create()
    var e1 = vec2.create()
    vec2.add(e0, sector.centre, fovDirs[0])
    vec2.add(e1, sector.centre, fovDirs[1])
    var sectorEdges = sector.fovEdges
    vec2.copy(sectorEdges[0].vec, fovDirs[0])
    vec2.copy(sectorEdges[0].ends[0], sector.centre)
    vec2.copy(sectorEdges[0].ends[1], e0)
    vec2.copy(sectorEdges[1].vec, fovDirs[1])
    vec2.copy(sectorEdges[1].ends[0], sector.centre)
    vec2.copy(sectorEdges[1].ends[1], e1)
  }

  function sortAngularPoints (anglePoints, sector) {
      var aV = vec2.create()
      var bV = vec2.create()
      anglePoints.sort(function (a, b) {
        vec2.sub(aV, a, sector.centre)
        vec2.sub(bV, b, sector.centre)
        return cross2d(aV, bV)
      })
  }

  function calcQuadBezCurveCtrlPoint(v1, v2, centre, radius) {
    var ctrlPt = vec2.create()
    vec2.add(ctrlPt, v1, v2)
    vec2.normalize(ctrlPt, ctrlPt)
    vec2.scaleAndAdd(ctrlPt, centre, ctrlPt, radius * (2 - vec2.dot(v1, ctrlPt)))
    return ctrlPt
  }

  function shootRays(rays, blockingEdges, sector) {
      var line1IsRay = true, shouldComputePoint = true
      var n = rays.length
      var hitPoints = new Array(n)
      var ctrlPoints = new Array(n)
      var thisRay = { ends: [sector.centre] }, unitRay = vec2.create()
      var prevPointOnArc = false, prevUnitRay = vec2.create()
      var connector = vec2.create()
      var hitPoint
      for (var i = 0; i < n; ++i) {
        thisRay.vec = rays[i]
        thisRay.len_2 = vec2.sqrLen(thisRay.vec)
        hitPoint = hitPoints[i] = vec2.create()
        var t = undefined, blocker = undefined, hitDist_2 = undefined
        for (var j = 0, len = blockingEdges.length; j < len; ++j) {
          var res = lineSegLineSegXsect(thisRay, blockingEdges[j], shouldComputePoint, line1IsRay)
          if ((res.t !== undefined) && ((t === undefined) || (res.t < t))) {
            hitDist_2 = vec2.sqrDist(res.point, sector.centre)
            if (hitDist_2 > epsilon) {
              t = res.t
              vec2.copy(hitPoint, res.point)
              blocker = blockingEdges[j]
            }
          }
        }

        var pointOnArc = (t === undefined) || ((hitDist_2 + epsilon - sector.radius_2) >= 0)
        if (pointOnArc) {
          vec2.normalize(unitRay, thisRay.vec)
          vec2.scaleAndAdd(hitPoint, sector.centre, unitRay, sector.radius)
          if (prevPointOnArc) {
            var needsArc = true
            if (blocker) {
                vec2.sub(connector, hitPoints[i - 1], hitPoint)
                needsArc = !areParallel(blocker.vec, connector)
            }
            if (needsArc) ctrlPoints[i] = calcQuadBezCurveCtrlPoint(unitRay, prevUnitRay, sector.centre, sector.radius)
          }
          vec2.copy(prevUnitRay, unitRay)
        }
        prevPointOnArc = pointOnArc
      }
      return { hitPoints: hitPoints, ctrlPoints: ctrlPoints }
  }

  function update () {
    var sector = scene.observer.sector
    updateSector(sector)

    var fov = scene.observer.fov
    var anglePtSet = fov.anglePtSet
    anglePtSet.clear()
    var blockingEdges = fov.blockingEdges
    blockingEdges.length = 0
    for (var i = 0; i < scene.boundaryLines.length; ++i) {
      checkboundaryLine(scene.boundaryLines[i], sector, anglePtSet, blockingEdges)
    }

    var anglePoints = [sector.fovEdges[0].ends[1], ...anglePtSet, sector.fovEdges[1].ends[1]]
    sortAngularPoints(anglePoints, sector)
    var rays = makeRays(sector, anglePoints)
    var result = shootRays(rays, blockingEdges, sector)
    fov.anglePoints = anglePoints
    fov.rays = rays
    fov.hitPoints = result.hitPoints
    fov.ctrlPoints = result.ctrlPoints
  }

  function drawFoV () {
    const fov = scene.observer.fov
    let polygonCoords = []
    for (let i = 0; i < fov.hitPoints.length; ++i) {
      polygonCoords.push({ x: fov.hitPoints[i][0], y: fov.hitPoints[i][1] })
    }
    polygonCoords.push({ x: scene.observer.loc[0], y: scene.observer.loc[1] })
    return polygonCoords
  }

  function constructEdges (boundaryLines) {
    for (let i = 0; i < boundaryLines.length; ++i) {
      var p = boundaryLines[i] //перебор полигонов
      var pointCount = p.coords.length / 2 //количество точек - количество координат /2
      var points = new Array(pointCount)
      for (let j = 0; j < pointCount; ++j) {
        const idx = j * 2
        points[j] = vec2.fromValues(p.coords[idx], p.coords[idx + 1]) //составляется массив векторов из точек полигона
      }

      var edgeCount = (pointCount > 2) ? pointCount : (pointCount - 1) //отработка если нет следующего ребра, т.е. отрезков. Чтобы цикл не шел еще 1 раз в поисках следующего ребра
      var edges = new Array(edgeCount)
      for (let j = 0; j < edgeCount; ++j) {
        const k = (j + 1) % pointCount
        const v = vec2.create()
        vec2.sub(v, points[k], points[j])
        edges[j] = { vec: v, len_2: vec2.sqrLen(v), ends: [points[j], points[k]] }
      }
      p.edges = edges
    }
  }
  return pointsOfFoV
}

export { calcFieldOfView }
