const getDefaultState = () => {
  return {
    archiveDays: 30,
    streams: [],
    storageConfigs: [],
    commonConfigSettings: {
      RAID: 'RAID6',
      capacityHDD: 6,
      maxGroup: 14,
      hotSpare: 1
    },
    commonCameraSettings: {
      group: 1,
      codec: 'H.264',
      fps: 10,
      interval: 24,
      activity: 'middle'
    }
  }
}

export default {
  state: getDefaultState(),

  getters: {
    STORAGE_COMMON_CAMERA_SETTINGS: state => state.commonCameraSettings,
    STORAGE_COMMON_CONFIG_SETTINGS: state => state.commonConfigSettings
  },

  mutations: {
    DEFAULT_STORAGE_STATE (state) {
      Object.assign(state, getDefaultState())
    },

    SET_ARCHIVE_DAYS (state, days) {
      state.archiveDays = days
    },

    CREATE_STREAM (state, stream) { // stream = { id, ...data }
      state.streams.unshift(stream)
    },

    UPDATE_STREAM (state, data) { // data = { id, ...data }
      const idx = state.streams.findIndex(e => e.id === data.id)
      state.streams[idx] = Object.assign(state.streams[idx], data)
    },

    DELETE_STREAMS: (state, ids) => { // ids = [id1, id2, ...]
      ids.forEach(id => {
        const idx = state.streams.findIndex(e => e.id === id)
        state.streams.splice(idx, 1)
      })
    },

    INIT_STORAGE_CONFIGS: (state, configs) => {
      if (Array.isArray(configs) && configs.length !== 0) {
        configs.forEach(el => state.storageConfigs.push(el))
      } else {
        state.storageConfigs.push({ group: 1, ...getDefaultState().commonConfigSettings })
      }
    },

    ADD_STORAGE_CONFIG: (state, groupId) => {
      state.storageConfigs.push({
        group: groupId,
        ...state.commonConfigSettings
      })
    },

    REMOVE_STORAGE_CONFIG: (state, groupId) => {
      const idx = state.storageConfigs.findIndex(el => el.group === groupId)
      if (idx !== -1) state.storageConfigs.splice(idx, 1)
    },

    UPDATE_STORAGE_CONFIG: (state, data) => {
      const idx = state.storageConfigs.findIndex(el => el.group === data.group)
      if (idx !== -1) Object.assign(state.storageConfigs[idx], data)
    },

    UPDATE_STORAGE_COMMON_CONFIG_SETTINGS: (state, data) => {
      Object.assign(state.commonConfigSettings, data)
    },

    UPDATE_STORAGE_COMMON_CAMERA_SETTINGS: (state, data) => {
      Object.assign(state.commonCameraSettings, data)
    }
  },

  actions: {
  },

  computed: {

  }
}
