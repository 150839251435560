// all distance - m, all angles - radian

import { rotate3D, move3D, findXLinePlane, dist2points, checkX2Segment, findX2Line } from './mathFunction'

function calcZonesPoints (cam, S, checkLastCamZonePx) {
  /*
  calc zone points withot rotate, only base calc from (0,0) to DNm
  cam =
  alpha, beta - angles of cam with corr format in radians
  gamma - camera angle in radian
  focal,
  typeInst, cam.hCam, cam.hMaxAim, cam.hMinAim
  distortion - false or {k1, k2}
  DH - distanse from center camera to direction/handle point, m
  S - array of S_i
  */
  // not depend on type of calc method
  const zonesPoints = new Array(S.length).fill(false) // arr of arr points of limits line, m

  const MC = {} // const of selected calc method (Method Constant)
  let getFoV = () => {}
  let getPoints = () => {}

  // set params for calc
  if (!cam.distortion) { // w/o distortion
    if (!cam.typeInst) { // flat method
      getFoV = getFlatFoV
      getPoints = getSimpleFlatPoints
    } else { // adv method
      getFoV = getAdvFoV
      getPoints = getSimpleAdvPoints
    }
  } else { // with distortion
    MC.k1 = cam.distortion.k1
    MC.k2 = cam.distortion.k2
    if (MC.k1 < 0 && MC.k2 > 0 && 9 / 20 * MC.k1 ** 2 < MC.k2) MC.k2 = 0 // if PV exist - use one parametric model

    if (!cam.typeInst) { // flat method
      getFoV = getFlatFoV
      getPoints = getDistortionFlatPoints
    } else { // adv. method
      getFoV = getAdvFoV
      getPoints = getDistortionAdvPoints
    }
  }

  // main steps

  getFoV() // calc FoV
  const limitsPoints = S.map((_, idx) => getPoints(idx)) // get points of limits without start points (BZ or {0,0}), non for distortion - get all points
  for (let i = 0; i < S.length; ++i) {
    const currentPoints = addSymmetricPoints(limitsPoints[i])
    if (!currentPoints) continue // check empty points - only for adv method

    let closedFix = []
    if (!cam.distortion) closedFix = !cam.typeInst ? [{ x: 0, y: 0 }] : addSymmetricPoints([MC.BZ])
    const prevPoints = addSymmetricPoints(limitsPoints[i - 1] ? limitsPoints[i - 1] : closedFix)

    zonesPoints[i] = [
      ...currentPoints,
      ...prevPoints.reverse()
    ]
  }

  return {
    fov: {
      angle: MC.angleFoV,
      radius: MC.radiusFoV,
      radius2: MC.radiusFoV2 ? MC.radiusFoV2 : false,
      lines: MC.linesFoV,
      lines2: MC.linesFoV2 ? MC.linesFoV2 : false
    },
    zonesPoints: zonesPoints // [[{x,y}, {x,y}], [{x,y}, {x,y}] ...]
  } // arr for argument fov.js

  // block with function definition
  function getFlatFoV () {
    MC.angleFoV = cam.alpha
    MC.radiusFoV = cam.DH / Math.cos(MC.angleFoV / 2)
    MC.DN = { x: cam.DH, y: cam.DH * Math.tan(MC.angleFoV / 2) }
    MC.linesFoV = [
      [MC.DN.x, -MC.DN.y, MC.DN.x, MC.DN.y], // DN [x1, y1, x2, y2] m
      [MC.DN.x, MC.DN.y, 0, 0], // first sector line
      [0, 0, MC.DN.x, -MC.DN.y] // second sector line
    ]
  }

  function getAdvFoV () {
    const tanHalfA = Math.tan(cam.alpha / 2)
    const tanHalfB = Math.tan(cam.beta / 2)
    // define gamma and DN
    MC.DN = {}
    if (cam.gamma === 'auto') {
      MC.DN.x = cam.DH
      MC.gamma = Math.atan((cam.hCam - cam.hMaxAim) / cam.DH) + cam.beta / 2
    } else {
      MC.gamma = cam.gamma
      if (MC.gamma < cam.beta / 2) {
        MC.DN.x = cam.DH
      } else {
        const DNBase = (cam.hCam - cam.hMaxAim) / Math.tan(MC.gamma - cam.beta / 2)
        MC.DN.x = cam.DH < DNBase ? cam.DH : DNBase
      }
    }
    const distDN = calcSecantDist(MC.DN.x, cam.hCam - cam.hMaxAim, MC.gamma)
    MC.DN.y = tanHalfA * distDN

    // define blind zone BZ
    const pyr = [ // pyr - pyramid
      { x: 0, y: 0, z: 0 }, // start point
      { x: 1, y: tanHalfA, z: tanHalfB }, // point1
      { x: 1, y: tanHalfA, z: -tanHalfB }, // point2
      { x: 1, y: -tanHalfA, z: -tanHalfB }, // point3
      { x: 1, y: -tanHalfA, z: tanHalfB } // point4
    ]
      .map(point => rotate3D(point, 'Y', MC.gamma, false))
      .map(point => move3D(point, { x: 0, y: 0, z: cam.hCam - (cam.hMaxAim + cam.hMinAim) / 2 }))

    if (MC.gamma + cam.beta / 2 < Math.PI / 2) {
      const pointBZX = findXLinePlane(pyr[0], pyr[2], { A: 0, B: 0, C: 1, D: +(cam.hMaxAim - cam.hMinAim) / 2 })
      const pointH2BZ = findXLinePlane(pyr[0], pyr[1], { A: 1, B: 0, C: 0, D: -pointBZX.x }) // intersect top pyr edgy with plane x=BZ.x
      const pointH2BZH1 = findXLinePlane(pointH2BZ, pointBZX, { A: 0, B: 0, C: 1, D: -(cam.hMaxAim - cam.hMinAim) / 2 })
      MC.BZ = { x: pointH2BZH1.x, y: pointH2BZH1.y }
      MC.angleFoV = 2 * Math.atan(MC.BZ.y / MC.BZ.x)
    } else {
      const pointBZH1 = findXLinePlane(pyr[0], pyr[2], { A: 0, B: 0, C: 1, D: -(cam.hMaxAim - cam.hMinAim) / 2 })
      MC.BZ = { x: pointBZH1.x, y: pointBZH1.y }
      MC.angleFoV = Math.PI
      MC.linesFoV2 = [
        [MC.BZ.x, MC.BZ.y, MC.BZ.x, -MC.BZ.y], // blind zone
        [MC.DN.x, MC.DN.y, MC.BZ.x, MC.BZ.y], // first sector line
        [MC.BZ.x, -MC.BZ.y, MC.DN.x, -MC.DN.y] // second sector line
      ]
      // radius 2 - intersect BZ-DN and axis Y
      MC.radiusFoV2 = Math.max(
        dist2points({ x: 0, y: 0 }, findX2Line({ x: 0, y: 0 }, { x: 0, y: 1 }, MC.BZ, MC.DN, 'line')),
        dist2points({ x: 0, y: 0 }, MC.BZ)
      )
    }
    MC.linesFoV = [
      [MC.DN.x, MC.DN.y, MC.DN.x, -MC.DN.y], // DN [x1, y1, x2, y2] m,
      [MC.BZ.x, MC.BZ.y, MC.DN.x, MC.DN.y], // first sector line
      [MC.DN.x, -MC.DN.y, MC.BZ.x, -MC.BZ.y] // second sector line
    ]
    // include fix camera look down
    MC.radiusFoV = Math.max(
      dist2points({ x: 0, y: 0 }, findX2Line({ x: 0, y: 0 }, { x: 0, y: 1 }, MC.BZ, MC.DN, 'line')),
      dist2points({ x: 0, y: 0 }, MC.DN)
    )
    // distance to limits line
    MC.level = cam.hCam - (cam.hMaxAim + cam.hMinAim) / 2
    MC.distDN = calcSecantDist(MC.DN.x, MC.level, MC.gamma)
    MC.distBZ = calcSecantDist(MC.BZ.x, MC.level, MC.gamma)
  }

  function getSimpleFlatPoints (i) {
    const tanHalfA = Math.tan(cam.alpha / 2)
    return S[i] < cam.DH ? [{ x: S[i], y: S[i] * tanHalfA }] : [MC.DN]
  }

  function getSimpleAdvPoints (i) {
    // check BZ and DN
    if (MC.distBZ > S[i]) return false
    if (MC.distDN < S[i]) return [MC.DN]

    const n = rotate3D({ x: S[i], y: 0, z: 0 }, 'Y', MC.gamma, false)
    // Ax + By + Cz + D = 0 n=(A,B,C)
    const pS = move3D(n, { x: 0, y: 0, z: MC.level })
    const D = -n.x * pS.x - n.y * pS.y - n.z * pS.z // define coeff. D from: n.x * pS.x + n.y * pS.y + n.z * pS.z + D = 0
    const xLineFoVSiPlaneZ = findXLinePlane(
      { x: MC.DN.x, y: MC.DN.y, z: 0 },
      { x: MC.BZ.x, y: MC.BZ.y, z: 0 },
      { A: n.x, B: n.y, C: n.z, D: D }
    )

    return [{ x: xLineFoVSiPlaneZ.x, y: xLineFoVSiPlaneZ.y }]
  }

  function getDistortionFlatPoints (i) {
    // fast check - last zone with 1 px resolution
    if (i === S.length - 1 && checkLastCamZonePx) return [{ x: MC.DN.x, y: MC.DN.y }]

    let points = getDistortionPointsSimpleZone(i)
    if (!points) return false // optional check

    // cut points behind DN
    const idxDN = points.findIndex(el => el.x > MC.DN.x)
    if (idxDN !== -1) {
      const pointXDN = getXpoint(points, idxDN, MC.DN.x)
      points = points.slice(0, idxDN)
      points.push(pointXDN)
    }
    return points
  }

  function getDistortionAdvPoints (i) {
    // fast check - last zone with 1 px resolution
    if (i === S.length - 1 && checkLastCamZonePx) return [MC.BZ, MC.DN]

    let outPoints = []
    let flatPoints = []

    // get flat points with distortion and simple check DNm
    if (MC.gamma < Math.PI / 2) {
      // fast check S[i] - intersect plane OXY
      if (MC.distBZ > S[i]) return false
      flatPoints = getDistortionPointsSimpleZone(i, MC.distBZ, MC.distDN, { start: 5, end: 5, whole: 20 }, 'adv')
    } else {
      if (MC.distDN > S[i]) return false
      flatPoints = getDistortionPointsSimpleZone(i, MC.distDN, MC.distBZ, { start: 5, end: 5, whole: 20 }, 'adv')
    }
    if (!flatPoints) return false

    // create symmetric points
    let points = addAdvSymmetricPoints(flatPoints) // [[{x,y},{x,y}], [{x,y},{x,y}], ...] [0] - top, [1] - bottom
    if (MC.gamma > Math.PI / 2) points = points.map(el => ([el[1], el[0]])) // fix: [0] - top, [1] - bottom

    // rotate points by gamma angle and move points to h-(h1+h2)/2
    points = points.map(el => el
      .map(point => rotate3D(point, 'Y', MC.gamma, false))
      .map(point => move3D(point, { x: 0, y: 0, z: cam.hCam - (cam.hMaxAim + cam.hMinAim) / 2 }))
    )
    // find intersect pair point with axis OX on (BZ to DN), its only check without intersection point
    const pointsX = points.slice(0).filter(el => {
      const p1 = el[0]
      const p2 = el[1]
      if (p1.x !== p2.x || p1.y !== p2.y || p1.z !== p2.z) {
        const seg1 = [{ x: p1.x, y: p1.z }, { x: p2.x, y: p2.z }]
        const seg2 = [{ x: MC.BZ.x - 0.001, y: 0 }, { x: MC.DN.x + 0.001, y: 0 }] // 0.0001 add to check end line zone
        return checkX2Segment(seg1, seg2)
      }
    })
    // if no intersect points - break. additional check
    if (pointsX.length === 0) return false

    // find point start and end of FoV
    let changeStartSegment = false
    let changeEndSegment = false
    if (pointsX.length !== points.length) { // option 1 and option 2, not all points top arc under 0X
      const startIdx = points.indexOf(pointsX[0])
      const endIdx = points.indexOf(pointsX.at(-1))
      if (startIdx !== 0) {
        pointsX.unshift([points[startIdx - 1][1], points[startIdx][1]])
        changeStartSegment = true
      }
      if (endIdx !== points.length - 1) {
        const isOpt = points[endIdx + 1][0].z > 0 ? 1 : 0 // false = 0 - option1, true = 1 - option2
        pointsX.push([points[endIdx][isOpt], points[endIdx + 1][isOpt]])
        changeEndSegment = true
      }
    }
    points = pointsX
    const cosGamma = Math.cos(MC.gamma)
    outPoints = points.map((el, idx) => ({
      x: (findXLinePlane(el[0], el[1], { A: 0, B: 0, C: 1, D: 0 })).x, // intersection with plane OXY
      y: (changeEndSegment && idx === points.length - 1) || (changeStartSegment && idx === 0)
        ? 0
        : 0.5 * Math.sqrt((el[0].x - el[1].x) ** 2 + (el[0].z - el[1].z) ** 2 - ((el[0].z + el[1].z) / cosGamma) ** 2)
    }))
    if (MC.gamma > Math.PI / 2) outPoints.reverse()

    // remove point above line BZ-DN
    const k = (MC.DN.y - MC.BZ.y) / (MC.DN.x - MC.BZ.x)
    outPoints = outPoints.map(el => ({
      x: el.x,
      y: el.y > MC.BZ.y + k * (el.x - MC.BZ.x) ? MC.BZ.y + k * (el.x - MC.BZ.x) : el.y
    }))

    // fix for blind zone, if zone - circle
    outPoints.unshift({ x: MC.BZ.x, y: 0 })
    return outPoints
  }

  function calcSecantDist (pointX, level, gamma) {
    return level * Math.sin(gamma) + pointX * Math.cos(gamma)
  }

  function getXpoint (points, idx, dist) {
    return findX2Line(points[idx - 1], points[idx], { x: dist, y: 0 }, { x: dist, y: 1 }, 'line')
  }

  // get y coord from x coord to field of view with distortion
  function getDistortionY (k1, k2, f, S, x) {
    if (k2 !== 0) {
      let D = 9 * k1 * k1 - 20 * k2 * (1 - x / S)

      if (D < 0) {
        if (D > -0.00001) D = 0
        else Error('Error in constructing the field of view with distortion')
      }

      const y1S = (-3 * k1 + Math.sqrt(D)) / (10 * k2 * f * f)
      const y2S = (-3 * k1 - Math.sqrt(D)) / (10 * k2 * f * f)
      return x * Math.sqrt(
        (y1S > 0 && y2S > 0)
          ? Math.min(y1S, y2S)
          : y1S > 0 ? y1S : y2S
      )
    } else {
      return x * Math.sqrt((x / S - 1) / (3 * k1 * f * f))
    }
  }

  function getDistortionPointsSimpleZone (i, startInterval = 0, endInterval = S[i], pointDensity = { start: 1, end: 5, whole: 15 }, type = 'simple') {
    // startInterval and endInterval need for adv method
    // simple check input data
    if (startInterval > endInterval) return false
    if (startInterval >= S[i]) return false

    let zone1 = [] // points 0-PS
    let zone2 = [] // points PS-end

    if (type === 'simple') {
      const tanHalfA = Math.tan(cam.alpha / 2)
      const f = cam.focal

      // define PX
      const kd = 1 + 3 * MC.k1 * (f * tanHalfA) ** 2 + 5 * MC.k2 * (f * tanHalfA) ** 4
      let PS = {} // point of start FoV-line
      const PX = {} // point of intersect FoV with y = tanA/2 * x
      PX.x = kd * S[i]
      PX.y = PX.x * tanHalfA
      if (kd > 0 && kd < 1 && Math.abs(getDistortionY(MC.k1, MC.k2, f, S[i], PX.x) - PX.y) < 0.0001) PS = PX

      // get zone 2
      if (Object.keys(PS).length === 0 || PS.x < endInterval) {
        const start = PS && PS.x > startInterval ? PS.x : startInterval// start point zone 2
        const end = endInterval > S[i] ? S[i] : endInterval // end point zone 2
        zone2 = getPoints(start, end)
      }

      // get zone 1, only case if PS
      if (Object.keys(PS).length !== 0) {
        const start = startInterval
        const end = endInterval <= PS.x ? endInterval : PS.x

        zone1 = divideSegment({ x: start, y: 0 }, { x: end, y: 0 }, 1)
          .map(el => ({ x: el.x, y: el.x * tanHalfA }))
      }
    }

    if (type === 'adv') {
      zone2 = getPoints(startInterval, endInterval > S[i] ? S[i] : endInterval)
    }
    const outPoints = [...zone1, ...zone2]
    return outPoints.length > 1 ? outPoints : false

    function getPoints (start, end) {
      const xPoints = divideInterval(start, end, pointDensity)

      const outPoints = xPoints.map(e => ({ x: e, y: getDistortionY(MC.k1, MC.k2, cam.focal, S[i], e) }))
      if (start === 0) outPoints[0] = { x: 0, y: 0 } // repair first point
      if (end === S[i]) outPoints[zone2.length - 1] = { x: S[i], y: 0 } // repair last point

      return outPoints
    }

    // divide segment with different density of points, return result on x axis include start and end
    function divideInterval (start, end, density) { // density={start, end, whole}
      const nWhole = density.whole
      const nStart = density.start // add more points for start zone
      const nEnd = density.end // add more points for end zone
      const delta = (end - start) / nWhole
      const points = new Array(nWhole + nStart + nEnd - 1)
        .fill(true)
        .map((_, idx) =>
          idx <= nStart
            ? start + delta / nStart * idx
            : idx <= nWhole + nStart - 2
              ? start + delta * (idx - nStart + 1)
              : start + delta * (nWhole - 1) + delta / nEnd * (idx - nWhole - nStart + 2)
        )
      return points
    }

    // divide segment 2D on n-parts
    function divideSegment (point1, point2, n) { // density={start, end, whole}, return arr of points [{x,y}]
      if (!n) n = 1
      const deltaX = (point2.x - point1.x) / n
      const deltaY = (point2.y - point1.y) / n
      const points = new Array(n + 1)
        .fill(true)
        .map((_, idx) => ({
          x: point1.x + deltaX * idx,
          y: point1.y + deltaY * idx
        }))
      return points
    }
  }

  // add symmetric points 0x-axis of field of view
  function addSymmetricPoints (points) { // points = [{x,y},...]
    return points ? [...points, ...points.map(e => ({ x: e.x, y: -e.y })).slice(0).reverse()] : points
  }

  // add symmetric points 0x-axis of field of view for adv method
  function addAdvSymmetricPoints (points) { // points = [{x,y},...]
    return points.map(e => ([{ x: e.x, y: 0, z: e.y }, { x: e.x, y: 0, z: -e.y }]))
  }
}

function cachingDecorator (func) {
  const cache = new Map()

  return function () {
    const key = JSON.stringify({ ...arguments })
    if (cache.has(key)) return cache.get(key)

    const result = func(...arguments)
    cache.set(key, result)
    return result
  }
}

calcZonesPoints = cachingDecorator(calcZonesPoints) // eslint-disable-line

export { calcZonesPoints }
