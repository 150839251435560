<template>
  <div>
    <div
      id="left-menu-btns"
      class="d-flex flex-column"
    >
      <div
        v-for = "(menu, idx) in menus"
        :key = "idx"
        >
        <menu-btn
          v-if = "idx !== 3"
          :iconData = "menu"
          tooltipPos = "right"
        />
        <editor-left-sub-menu
          v-else-if = "idx === 3"
          :iconData = "menu"
          :subMenuData = "menu.submenus"
          tooltipPos = 'right'
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { eventBus } from '../../main'
import MenuBtn from './EditorMenuBtn'
import EditorLeftSubMenu from './EditorLeftSubMenu'

export default {

  name: 'EditorLeftMenu',

  components: {
    MenuBtn,
    EditorLeftSubMenu
  },

  data: function () {
    return {
      menus: [
        { name: 'mdi-plus', descr: 'zoom plus', evtname: 'zoom-plus', isPressed: false },
        { name: 'mdi-minus', descr: 'zoom minus', evtname: 'zoom-minus', isPressed: false },
        { name: 'mdi-ruler', descr: 'ruler', evtname: 'tape', isPressed: false },
        {
          name: 'mdi-view-grid-outline',
          descr: 'views',
          evtname: 'to-views',
          isPressed: false,
          isDisabled: false,
          tooltipDisabled: false,
          submenus: [
            { name: 'mdi-overscan', descr: 'scale to fit', evtname: 'scale-to-fit', isPressed: false },
            { name: 'mdi-view-grid-plus-outline', descr: 'add view', evtname: 'addView', isPressed: false },
            { name: 'mdi-format-list-bulleted', descr: 'list of views', evtname: 'listViews', isPressed: false, isDisabled: false }
          ]
        },
        { name: 'camera-dori', descr: 'hide dori', evtname: 'dori', isPressed: false }
      ]
    }
  },

  computed: {
    ...mapState({
      VIEWS: state => state.editorStore.views,
      CURRENT_SITE: state => state.sitesStore.currentSite,
      IS_SHOW_FOV: state => state.editorStore.isShowFoV
    }),

    triggerSelTape () {
      return this.$store.state.objectsStore.list.find(el => el.typeOf === 'tape') ? 1 : 0
    },

    triggerViews () {
      return this.VIEWS.filter(el => el.id !== 'current').length === 0 ? 1 : 0
    }
  },

  methods: {
    pressViewsBtn () {
      this.menus[3].isPressed = !this.menus[3].isPressed
      this.menus[3].tooltipDisabled = !this.menus[3].tooltipDisabled
    }
  },

  watch: {
    triggerSelTape () {
      this.menus[2].isPressed = this.triggerSelTape
      this.menus[2].evtname = !this.triggerSelTape ? 'tape' : 'tape-delete'
    },

    triggerViews () {
      this.menus[3].submenus[2].isDisabled = Boolean(this.triggerViews)
    },

    IS_SHOW_FOV () {
      this.menus[4].isPressed = !this.IS_SHOW_FOV
      eventBus.$emit('need-cameras-fov-recalc')
    }
  },

  mounted () {
    eventBus.$on('to-views', event => this.pressViewsBtn(event))
    eventBus.$on('dori', _ => this.$store.commit('UPDATE_SHOW_FOV', !this.IS_SHOW_FOV))
    this.menus[3].submenus[2].isDisabled = Boolean(this.triggerViews)
  },

  beforeDestroy () {
    eventBus.$off(['to-views', 'dori'])
  }
}

</script>

<style scoped>

</style>
