<template>
    <v-card
      width="100%"
      class="py-1 px-0 ma-0 elevation-2"
    >

      <v-card-title class="text-h5 justify-center">
        <slot name="login-title"></slot>
      </v-card-title>

      <v-card-text class="pb-0">
        <slot name="login-body"></slot>
      </v-card-text>

      <v-card-actions class="pt-0">
        <slot name="login-actions"></slot>
      </v-card-actions>
    </v-card>

</template>

<script>

export default {
  name: 'LoginFrame',

  data: () => ({
    showPassword: false,
    login: '',
    password: ''
  }),

  components: {

  },

  computed: {

  }
}
</script>
