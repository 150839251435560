<template>
  <div id = "camzone" class = "d-flex flex-column justify-start" scrollable>
    <div class = "ml-2">
      <v-checkbox
        :input-value="showLegend"
        @change="setShowLegend($event)"
        :label="$t('setting fov show legend')"
      />
    </div>
    <v-data-table
      :headers="headers"
      :items="camZone"
      hide-default-footer
      hide-default-header
      >
      <template #top>
        <div class = "d-flex flex-wrap mb-2">
          <table-btn
            :btnData="{ icon: 'mdi-plus', descr: 'fov add zone start' }"
            :disabled = "camZone.length >= 10"
            @click = "addCamZone('start')"
          />
          <table-btn
            :btnData="{ icon: 'mdi-plus', descr: 'fov add zone end' }"
            :disabled = "camZone.length >= 10"
            @click = "addCamZone('end')"
          />
          <table-btn
            :btnData="{ icon: 'mdi-numeric-1-box-outline', descr: 'fov only fov' }"
            @click = "onlyFov()"
          />
          <table-btn
            :btnData="{ icon: 'mdi-backup-restore', descr: 'fov restore default' }"
            @click = "restoreCamZones()"
          />
          </div>
      </template>
      <template #header="{ props }">
        <th v-for="(head,idx) in props.headers" :key="idx"><div class="text-body-2" style = "text-align: center">{{ $t(head.name) }}</div></th>
      </template>
      <template #item.name="{ item }">
        <v-text-field
          :value = "$te(item.name) ? $t(item.name) : item.name"
          @input = "changeCamZoneName($event, item)"
          dense
          hide-details="auto"
          class = "text-body-2 shrink"
          :rules = rules.name
        />
      </template>
      <template #item.color="{ item }">
        <color-picker
          :value="item.color"
          @input="changeCamZoneColor($event, item)"
          align="center"
        />
      </template>
      <template #item.px="{ item }">
        <div class = "ma-0 pa-0 d-flex justify-center">
          <v-text-field
            align="center"
            :value = "item.px"
            @input="changeCamZonePx($event, item)"
            hide-details = 'auto'
            type = "number"
            flat
            dense
            class = "text-body-2 shrink"
            style = "width: 110px !important"
            :suffix = "$t('px') + '/' + $t('m')"
            :rules = rules.px
            :error = "!checkFoVs[camZone.indexOf(item)]"
          />
        </div>
      </template>
      <template #item.action="{ item }">
        <v-hover #default = "{ hover }">
          <v-icon
            small
            v-if = "camZone.length > 1"
            @click="removeCamZone(item.id)"
            :color = "hover ? 'error' : ''"
            >
            mdi-delete
          </v-icon>
        </v-hover>
      </template>
      <template #foot>
        <th></th>
        <th></th>
        <th class = "d-flex justify-center font-weight-regular text-caption">
          <span
            v-if = "!checkFoVs.every(el => (typeof el === 'boolean') && el)"
            style = "max-width: 150px; text-align: left;"
            class = "error--text ma-0 pa-0"
            >
            {{$t('fov error px zone')}}
          </span>
        </th>
        <th></th>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { eventBus } from '../../../main'
import colorPicker from './../../Editor-components/EditorColorPicker'
import tableBtn from '../../lib/srvTableBtn.vue'
import { mapState } from 'vuex'

export default {

  name: 'SettingsFoV',

  components: {
    colorPicker,
    tableBtn
  },

  data: () => ({
    headers: [
      { name: 'settings zone name', align: 'start', sortable: false, value: 'name', width: '55%' },
      { name: 'settings zone color', align: 'center', sortable: false, value: 'color', width: '20%' },
      { name: 'settings zone px-m', align: 'center', sortable: false, value: 'px', width: '20%' },
      { name: '', align: 'center', sortable: false, value: 'action', width: '5%' }
    ],
    inputFoVs: []
  }),

  computed: {
    ...mapState({
      gridData: state => state.editorStore.gridData,
      camZone: state => state.editorStore.camZone,
      showLegend: state => state.editorStore.legend
    }),

    checkFoVs () {
      return this.inputFoVs.map((el, idx) => {
        return Boolean((this.inputFoVs[idx - 1] ? this.inputFoVs[idx - 1].px > el.px : true) &&
          (this.inputFoVs[idx + 1] ? this.inputFoVs[idx + 1].px < el.px : true))
      })
    },

    camZoneTrigger () {
      return JSON.stringify(this.camZone)
    },

    rules () {
      return {
        px: [value => (Number.isInteger(+value) && (value >= 1)) || this.$t('fov must be more 0')],
        name: [value => (value.length > 0) || this.$t('fov must be not empty')]
      }
    }
  },

  methods: {
    addCamZone (pos) {
      const id = Math.random().toString(8).slice(2)
      const px = calcPx.call(this)
      this.$store.commit(pos === 'start' ? 'ADD_START_CAMZONE' : 'ADD_END_CAMZONE', {
        id: id,
        name: 'fov new zone',
        color: '#00FF00',
        px: px
      });
      (pos === 'start')
        ? this.inputFoVs.unshift({ id, px })
        : this.inputFoVs.push({ id, px })
      function calcPx () {
        return (pos === 'start')
          ? this.camZone[0].px + 100
          : this.camZone[this.camZone.length - 1].px !== 1 ? this.camZone[this.camZone.length - 1].px - 1 : 1
      }
    },

    defaultInputs () {
      this.inputFoVs.splice(0)
      this.camZone.forEach(el => { this.inputFoVs.push({ id: el.id, px: el.px }) })
    },

    removeCamZone (zoneId) {
      this.$store.commit('DELETE_CAMZONE', zoneId)
      const idx = this.inputFoVs.findIndex(el => el.id === zoneId)
      this.inputFoVs.splice(idx, 1)
    },

    restoreCamZones () {
      this.$store.commit('DEFAULT_CAMZONES')
      this.defaultInputs()
    },

    onlyFov () {
      this.$store.commit('ONE_CAMZONE')
      this.defaultInputs()
    },

    changeCamZoneName (value, item) {
      const idx = this.camZone.indexOf(item)
      if (this.rules.name.map(f => f(value)).every(el => (typeof el === 'boolean') && el)) {
        this.$store.commit('SET_CAMZONE_NAME', { id: item.id, name: value })
      }
    },

    changeCamZonePx (input, item) {
      const idx = this.camZone.indexOf(item)
      if (this.rules.px.map(f => f(input)).every(el => (typeof el === 'boolean') && el)) {
        this.inputFoVs[idx].px = +input
        this.updateFoVs()
      }
    },

    updateFoVs () {
      this.camZone.forEach((el, idx) => {
        if (this.checkFoVs[idx]) this.$store.commit('SET_CAMZONE_PX', { id: el.id, px: this.inputFoVs[idx].px })
      })
    },

    changeCamZoneColor (value, item) {
      this.$store.commit('SET_CAMZONE_COLOR', { id: item.id, color: value })
    },

    setShowLegend (value) {
      this.$store.commit('SET_LEGEND_SHOW', value)
    }
  },

  watch: {
    camZoneTrigger (newZones, oldZones) {
      (newZones.length === oldZones.length)
        ? eventBus.$emit('need-cameras-update')
        : eventBus.$emit('need-cameras-fov-recalc')
    }
  },

  updated () {
  },

  mounted () {
    this.defaultInputs()
  }
}
</script>

<style scoped>

</style>
