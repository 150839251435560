import Vue from 'vue'

const getDefaultState = () => {
  return {
    list: [],
    selObj: false
  }
}

export default {
  state: getDefaultState(),

  getters: {
    CAMERAS: state => state.list.filter(el => el.typeOf === 'camera')
  },

  mutations: {
    DEFAULT_OBJECT_STATE (state) {
      Object.assign(state, getDefaultState())
    },

    CREATE_OBJECT (state, obj) {
      if (obj.typeOf === 'camera') {
        [
          'codec', 'fps', 'bitrate', 'activity', 'interval', 'group', // for calculate storage
          'gamma', 'light', 'lightIR', 'lightWL' // add for compatibility with SURVy v 1.1
        ].forEach(el => { if (!obj?.[el]) Vue.set(obj, el, null) }) // reactive props for calc storage for new cameras
      }
      state.list.push(obj)
    },

    UPDATE_OBJECT (state, dataObj) { // dataObj = { id, ...data }
      const idx = state.list.findIndex(e => e.id === dataObj.id)
      state.list[idx] = Object.assign(state.list[idx], dataObj)
    },

    DELETE_OBJECT (state, id) {
      const idx = state.list.findIndex(e => e.id === id)
      state.list.splice(idx, 1)
    },

    DELETE_OBJECTS (state, siteId) {
      state.list.filter(e => e.site === siteId).forEach(el => {
        const idx = state.list.findIndex(e => e === el)
        state.list.splice(idx, 1)
      })
    },

    SET_SELECTED_OBJECT (state, id) {
      state.selObj = state.list.find(e => e.id === id)
    },

    SET_THINKNESS_SELECTED_OBJECT (state, val) {
      state.selObj.strokeWidth = +val
    },

    SET_COLOR_SELECTED_OBJECT (state, val) {
      state.selObj.stroke = val
    },

    SET_BGCOLOR_SELECTED_OBJECT (state, val) {
      state.selObj.fill = val
    },

    SET_ANGLE_SELECTED_OBJECT (state, val) {
      state.selObj.angle = val
    },

    SET_OPACITY_SELECTED_OBJECT (state, val) {
      state.selObj.opacity = +val
    },

    SET_FONTSIZE_SELECTED_OBJECT (state, val) {
      state.selObj.fontSize = +val
    },

    SET_FONTSTYLE_SELECTED_OBJECT (state, val) {
      state.selObj.fontWeight = val.fontWeight
      state.selObj.fontStyle = val.fontStyle
      state.selObj.underline = val.underline
      state.selObj.linethrough = val.linethrough
    },

    SET_BWFILTER_SELECTED_OBJECT (state, val) { // for img true:false
      state.selObj.BWFilter = val
    },

    SET_BORDER_SELECTED_OBJECT (state, val) { // for img true:false
      state.selObj.border = val
    },

    SET_ZORDER_SELECTED_OBJECT (state, val) {
      state.selObj.zOrder = val
    }

  },

  actions: {
  },

  computed: {
  }
}
