<template>
  <v-card>
    <v-card-title>
      {{$t('sites') }}
      <v-spacer/>
      <v-btn
        icon
        @click = "$emit('close')"
        >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-data-table
          :headers = "headers"
          :items = "SITES"
          class = "elevation-0 mt-1"
          :items-per-page = "-1"
          hide-default-header
          hide-default-footer
          dense
          >

          <template #top>
            <div class = "d-flex">
              <table-btn
                :btnData = "{ icon: 'mdi-plus', descr: 'sites add site' }"
                @click = "addSite()"
              />
            </div>
          </template>

          <template #item.current="{ item }" >
            <v-icon
              v-if = "CURRENT_SITE === item.id"
              color = 'primary'
              >
              mdi-chevron-right
            </v-icon>
          </template>

          <template #item.name="{ item }" >
            <div
              v-if = "editItemId !== item.id "
              @click = "setSite(item.id)"
              class = "text-body-1 cursor-pointer"
              >
              {{item.name}}
            </div>
            <v-text-field v-else
              :value = "item.name"
              @input = "editSite($event, item.id)"
              autofocus
              @blur = "editItemId = ''"
              dense
              hide-details = "auto"
              background-color = "transparent"
              class = "ma-0 pa-0"
              :rules = rules.name
            />
          </template>

          <template #item.cameras="{ item }">
            <div class = "d-flex align-center ma-0 pa-0 justify-center">
              <v-icon
                v-if = "getQtyCameras(item.id) !== 0"
                small
                class = "ml-1"
                >
                mdi-cctv
              </v-icon>
              {{getQtyCameras(item.id)}}
            </div>
          </template>

          <template #item.actions="{ item }">
            <div class = "d-flex">
              <v-btn
                @click = "editItemId = item.id"
                icon
                >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                v-show = "SITES.length > 1"
                icon
                @click.stop = "deleteItem = item.id; dialogDelete = true"
                >
                <v-hover #default = "{ hover }">
                  <v-icon small :color = "hover ? 'error' : ''">mdi-delete</v-icon>
                </v-hover>
              </v-btn>
              <v-hover #default ="{ hover }">
                <div class = "d-flex flex-column" :style = "hover ? {} : { opacity: 0.4 }">
                  <v-btn
                    :disabled = "SITES.indexOf(item) === 0"
                    icon
                    x-small
                    plain
                    @click = upSite(item.id)
                    >
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled = "SITES.indexOf(item) === SITES.length - 1"
                    icon
                    x-small
                    plain
                    @click = downSite(item.id)
                    >
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </div>
              </v-hover>
            </div>
          </template>
      </v-data-table>
      <v-dialog
        v-model = "dialogDelete"
        width = "300"
        >
        <v-card>
          <v-card-title class = "red--text justify-center">{{$t('warning')}}</v-card-title>
          <v-card-text>
            <p style = "text-align: justify">{{$t('site remove warning')}}</p>
            <div class = "text-center ma-0 pa-0">{{$t('are you sure')}}</div>
          </v-card-text>
          <v-card-actions class = "justify-center">
            <div class = "d-flex shrink">
            <v-btn
              color = "grey"
              text
              @click = "confirmDeleteItem(deleteItem)"
              >
              {{$t('delete')}}
            </v-btn>
            <v-btn
              color = "primary"
              text
              @click = "dialogDelete = false; deleteItem = null"
              >
              {{$t('cancel')}}
            </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
    <v-card-actions>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import TableBtn from '../lib/srvTableBtn.vue'

export default {

  name: 'sitesDialog',

  components: {
    TableBtn
  },

  props: [],

  data: () => ({
    headers: [
      { value: 'current', align: 'right', cellClass: 'column5', sortable: false },
      { value: 'name', align: 'left', cellClass: 'column70', sortable: false },
      { value: 'cameras', align: 'center', cellClass: 'column10', sortable: false },
      { value: 'actions', align: 'center', cellClass: 'column20', sortable: false }
    ],
    editItemId: '',
    dialogDelete: false,
    deleteItem: null
  }),

  computed: {
    ...mapState({
      SITES: state => state.sitesStore.sites,
      CURRENT_SITE: state => state.sitesStore.currentSite
    }),

    ...mapGetters([
      'CAMERAS'
    ]),

    rules () {
      return {
        name: [value => (value !== '') || this.$t('sites must be not empty')]
      }
    }
  },

  methods: {
    addSite () {
      this.$store.dispatch('ADD_SITE', { id: Math.random().toString(8).slice(2), name: this.$t('new site') + ' #' + (+this.SITES.length + 1) })
    },

    editSite (value, id) {
      this.rules.name.map(f => f(value)).every(el => (typeof el === 'boolean') && el) &&
      this.$store.commit('UPDATE_SITE', { id: id, name: value })
    },

    upSite (id) {
      this.$store.commit('UP_SITE', id)
    },

    downSite (id) {
      this.$store.commit('DOWN_SITE', id)
    },

    deleteSite (id) {
      this.$store.dispatch('DELETE_SITE', id)
    },

    setSite (id) {
      this.$store.commit('SET_CURRENT_SITE', id)
    },

    getQtyCameras (id) {
      return this.CAMERAS.filter(e => e.site === id).length
    },

    confirmDeleteItem (id) {
      let idx
      if (this.CURRENT_SITE === id) {
        idx = this.SITES.findIndex(el => el.id === id)
        this.$store.commit('SET_CURRENT_SITE', this.SITES[idx + 1] ? this.SITES[idx + 1].id : this.SITES[idx - 1].id)
      }
      this.deleteSite(id)
      this.dialogDelete = false
      this.deleteItem = null
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer
}

.v-data-table >>> tr:hover {
  background: transparent !important;
}

</style>

<style>

.column5 {
  width: 5%
}
.column10 {
  width: 10%
}

.column20 {
  width: 20%
}

.column25 {
  width: 25%
}

.column70 {
  width: 70%
}
</style>
