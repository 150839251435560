<template>
  <div>
    <v-menu offset-y v-model = "showMenu">
      <template #activator="{ on: menu, attrs }">
        <v-btn
          icon
          plain
          color = "grey"
          v-bind = "attrs"
          v-on = "menu"
          >
          <v-icon>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item link @click = "showDialogRenameProject(item)">
          {{$t('table_projects_menu_rename')}}
        </v-list-item>
        <v-list-item
          link
          @click="item.camera_count === 0 ? deleteProject(item.id) : dialogDelete = true"
          >
          {{$t('table_projects_menu_delete')}}
        </v-list-item>
        <v-list-item link @click="cloneProject(item.id)">
          {{$t('table_projects_menu_clone')}}
        </v-list-item>
        <v-list-item link>
          <project-share-dialog :project = "item">
              <template #dialog-btn>
                <span @click = "showMenu = false">{{$t('table_projects_menu_share')}}</span>
              </template>
            </project-share-dialog>
        </v-list-item>
      </v-list>
    </v-menu>
    <project-dialog
      v-model = "showDialog"
      :item = "projectDataDialog"
      @project-info-edit = "renameProject"
    >
      <template #dialog-title>{{$t('project edit')}}</template>
      <template #dialog-btn-ok>{{$t('project edit ok')}}</template>
    </project-dialog>
    <!-- Delete dialog -->
    <v-dialog
      v-model = "dialogDelete"
      width = "300"
      >
      <v-card>
        <v-card-title class = "red--text justify-center">{{$t('warning')}}</v-card-title>
        <v-card-text>
          <p style = "text-align: justify">{{$t('project remove warning')}}</p>
          <div class = "text-center ma-0 pa-0">{{$t('are you sure')}}</div>
        </v-card-text>
        <v-card-actions class = "justify-center">
          <div class = "d-flex shrink">
            <v-btn
              color = "grey"
              text
              @click = "deleteProject(item.id)"
              >
              {{$t('delete')}}
            </v-btn>
            <v-btn
              color = "primary"
              text
              @click = "dialogDelete = false"
              >
              {{$t('cancel')}}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End of delete dialog -->
  </div>
</template>

<script>
import ProjectDialog from '../lib/srvProjectDialog'
import ProjectShareDialog from './ProjectShareDialog'
import { mapActions } from 'vuex'

export default {

  name: 'TableActionsMenu',

  components: {
    ProjectDialog,
    ProjectShareDialog
  },

  props: [
    'item'
  ],

  data: () => ({
    showDialog: false,
    projectDataDialog: {},
    dialogDelete: false,
    dialogShare: false,
    showMenu: false
  }),

  computed: {

  },

  methods: {

    ...mapActions([
      'DELETE_PROJECT',
      'CLONE_PROJECT',
      'RENAME_PROJECT'
    ]),

    showDialogRenameProject (projectData) {
      this.projectDataDialog = projectData
      this.showDialog = true
    },

    deleteProject (projectId) {
      this.DELETE_PROJECT(projectId)
    },

    cloneProject (projectId) {
      this.CLONE_PROJECT(projectId)
    },

    renameProject (projectData, projectId) {
      this.RENAME_PROJECT({ data: projectData, id: projectId })
    },

    shareProject (projectId) {
      this.dialogShare = true
    }
  },

  mounted () {
  }
}

</script>

<style scoped>
.text-initial {
  text-transform: initial !important;
}

</style>
