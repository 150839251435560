<template>
<v-card>
  <v-card-title>
    {{$t('about')}}
    <v-spacer/>
    <v-icon @click="$emit('input')">mdi-close</v-icon>
  </v-card-title>
  <v-card-text>
    <p> <span class="text-h6 font-weight-bold">SURVy</span><span class="text-caption"> v1.0 </span><span class="text-h6">simple CCTV calculator</span></p>
    <p>{{$t('about-body-1')}}</p>
    <p>{{$t('about-body-2')}}</p>
    <p>{{$t('about-body-3')}}</p>
    <p>{{$t('about-body-warning')}}</p>
    <p>{{$t('about-body-4')}}</p>
    <contacts-body/>
  </v-card-text>
</v-card>
</template>

<script>
import ContactsBody from './ContactsBody.vue'

export default {

  name: 'AboutDialog',

  components: {
    ContactsBody
  },

  data: () => ({
  }),

  computed: {

  },

  methods: {

  },

  mounted () {

  }
}

</script>

<style scoped>

</style>
