<template>
<v-card>
  <v-card-title>
    {{$t('terms of use')}}
    <v-spacer/>
    <v-icon @click="$emit('input')">mdi-close</v-icon>
  </v-card-title>
  <v-card-text>
    <div v-html = "textLicense"/>
  </v-card-text>
  <v-card-actions class="justify-end">
    <v-btn
      color="primary"
      text
      @click="$emit('input')"
      >
      {{$t('accept')}}
    </v-btn>
  </v-card-actions>
</v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TermsOfUse',

  components: {
  },

  data: () => ({
    textLicense: ''
  }),

  computed: {
    ...mapState({
      locale: state => state.locale
    })
  },

  methods: {
    async getLicense () {
      const url = this.locale === 'ru' ? '/LICENSE_RU.txt' : '/LICENSE_EN.txt'
      const blobFile = await fetch(url)
        .then(response => response.text())
        .catch(error => console.log(error))
      this.textLicense = blobFile.replaceAll('\r\n', '<br>')
    }
  },

  watch: {
    locale () {
      this.getLicense()
    }
  },

  mounted () {
    this.getLicense()
  }
}

</script>

<style scoped>
</style>
