<template>
<div>
  <v-card>
    <v-card-title>
      {{$t('pricing')}}
      <v-spacer/>
      <v-icon @click="$emit('input')">mdi-close</v-icon>
    </v-card-title>
    <v-card-text>
      <div class = "d-flex justify-space-around">
        <v-card
          v-for = "tariff in tariffs"
          :key = "tariff.name"
          width = "210"
          >
          <v-card-title
            :class = "tariff.color"
            class = "white--text text-center text-uppercase justify-center"
            >
            {{$t(tariff.name)}}
          </v-card-title>
          <v-card-text
            class = "pt-4 text-center text-body-1"
          >
            <div class="pa-0 pb-2 ma-0 d-flex align-center justify-center" style = "height: 48px">
              <span>
                <span>{{`${$te(tariff.projectsQty) ? $t(tariff.projectsQty) : tariff.projectsQty}`}}</span>
                <span>{{' ' + $t('tariff projects')}}</span>
              </span>
            </div>
            <div class="pa-0 ma-0 d-flex align-center justify-center" style = "height: 48px">
              <span>
                <span>{{`${$te(tariff.camerasQty) ? $t(tariff.camerasQty) : tariff.camerasQty}`}}</span>
                <span>{{' ' + $t('tariff cameras')}}</span>
              </span>
            </div>
            <div class="pa-0 ma-0 d-flex align-center justify-center flex-column" style = "height: 64px">
              <span>
                <span v-if = "tariff.name != 'tariff basic' && $t(tariff.pos) === 'before'">
                  {{getCurrency($t(tariff.priceCurrency))}}
                </span>
                <span class = "text-h4" :class = "tariff.color + '--text'">{{`${$te(tariff.price) ? $t(tariff.price) : tariff.price}`}}</span>
                <span v-if = "tariff.name != 'tariff basic' && $t(tariff.pos) === 'after'">
                  {{getCurrency($t(tariff.priceCurrency))}}
                </span>
              </span>
              <span v-if = "tariff.name != 'tariff basic'"  class = "text-caption" >{{$t('tariff per month')}}</span>
            </div>
          </v-card-text>
          <v-card-actions class = "justify-center" v-if = "tariff.name != 'tariff basic'">
            <v-btn
              dark
              :color = "tariff.color"
              class = "mb-3"
              @click.stop = "dialogContacts = true"
              >
              {{$t('tariff contact us')}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <v-dialog
    width = "600"
    scrollable
    v-model = "dialogContacts"
    >
    <contacts @input = "dialogContacts = false; $emit('input')"/>
  </v-dialog>
</div>
</template>

<script>
import Contacts from './Contacts.vue'

export default {
  name: 'Pricing',

  components: {
    Contacts
  },

  data: () => ({
    tariffs: [
      { name: 'tariff basic', color: 'green', camerasQty: 40, projectsQty: 5, price: 'tariff free', priceCurrency: '' },
      { name: 'tariff pro', color: 'primary', camerasQty: 'tariff unlimited', projectsQty: 'tariff unlimited', price: 'tariff pro price', priceCurrency: 'tariff currency', pos: 'tariff currency position' }
    ],
    dialogContacts: false
  }),

  computed: {
  },

  methods: {
    getCurrency (cur) {
      let out = ''
      switch (cur) {
        case 'USD': out = '$'; break
        case 'RUR': out = '\u{20BD}'; break
        default: out = 'n/a'
      }
      return out
    }
  },

  mounted () {
  }
}

</script>

<style scoped>
</style>
